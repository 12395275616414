import { CompanyAffiliateApplicationInitialValues } from '../../../types';

export const checkIsAllAffiliateChecked = (
  values: CompanyAffiliateApplicationInitialValues,
) => {
  const availableToApproveAffiliate = Object.values(values).filter(
    (item) => item !== null,
  );

  return !availableToApproveAffiliate.includes(false);
};
