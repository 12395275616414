import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { BonusInput, FreespinInput, InputMaybe } from 'generatedGraphql';
import { AffiliateMediaItemsSteps } from '../../AffiliatePromoCodeItem/const';
import { useCreateCompanyAffiliatePromoCodeMediaItem } from '../queries/generated/CreateCompanyAffiliatePromoCodeMediaItem';
import { CreateAffiliatePromoCodeValues } from '../types';
import { FieldName } from '../const';

interface Params {
  companyAffiliateId: string;
}

interface Result {
  onCreateAffiliatePromoCodeSubmitHandler: (
    values: CreateAffiliatePromoCodeValues,
  ) => void;
  createCompanyAffiliatePromoCodeLoading: boolean;
  createCompanyAffiliatePromoCodeError?: ApolloError;
}

export const useCreateAffiliatePromoCode = ({
  companyAffiliateId,
}: Params): Result => {
  const { updateModalStep } = useUpdateModalStep<AffiliateMediaItemsSteps>();

  const handleComplete = () => {
    updateModalStep(AffiliateMediaItemsSteps.BaseStep);

    toast.success('Промокод создан');
  };

  const [createCompanyAffiliatePromoCode, { loading, error }] =
    useCreateCompanyAffiliatePromoCodeMediaItem({
      onCompleted: handleComplete,
    });

  const onCreateAffiliatePromoCodeSubmitHandler = (
    values: CreateAffiliatePromoCodeValues,
  ) => {
    if (values[FieldName.Usage] === undefined) {
      return;
    }

    const freespin: InputMaybe<FreespinInput> =
      values[FieldName.ConnectionWithFreespins] &&
      values[FieldName.FreespinCount]
        ? {
            count: values[FieldName.FreespinCount],
            currency: values[FieldName.FreespinCurrency],
            gameId: values[FieldName.FreespinGameId],
            ...(values[FieldName.FreespinWager]
              ? {
                  bonusWager: values[FieldName.FreespinWager],
                }
              : null),
            ...(values[FieldName.FreespinMinBalance]
              ? {
                  minAccountBalanceAmount: {
                    amount: values[FieldName.FreespinMinBalance],
                    currency: values[FieldName.FreespinCurrency],
                  },
                }
              : null),
          }
        : null;

    const bonus: InputMaybe<BonusInput> =
      values[FieldName.ConnectionWithBonus] && values[FieldName.BonusAmount]
        ? {
            amount: {
              amount: values[FieldName.BonusAmount],
              currency: values[FieldName.BonusCurrency],
            },
            bonusConfig: {
              cashout: values[FieldName.BonusCashout],
              currency: values[FieldName.BonusCurrency],
              minAccountBalanceAmount: values[FieldName.BonusMinBalance],
              wager: values[FieldName.BonusWager],
            },
          }
        : null;

    createCompanyAffiliatePromoCode({
      variables: {
        input: {
          companyAffiliateId,
          // TODO: recheck and remove toString() after https://eyeconweb.atlassian.net/browse/APN-1186
          // This field should send string even if the user entered a number
          token: values[FieldName.Token].toString(),
          usageType: values[FieldName.Usage],
          activationLimit: values[FieldName.ActivationLimit],
          referralLinkMediaItem: {
            id: values[FieldName.ReferralLinkId],
          },
          freespin,
          bonus,
        },
      },
    });
  };

  return {
    onCreateAffiliatePromoCodeSubmitHandler,
    createCompanyAffiliatePromoCodeLoading: loading,
    createCompanyAffiliatePromoCodeError: error,
  };
};
