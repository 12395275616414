import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { FC } from 'react';
import styles from './UpdateAccessButtons.module.scss';

interface Props {
  dirty: boolean;
  loading: boolean;
  isAllTariffsSelected: boolean;
  toggleAllTariffs: () => void;
}

export const UpdateAccessButtons: FC<Props> = ({
  dirty,
  loading,
  isAllTariffsSelected,
  toggleAllTariffs,
}) => (
  <div className={styles.buttonContainer}>
    <Button
      data-testid="selectAllTariffsButton"
      onClick={toggleAllTariffs}
      theme={ButtonTheme.Primary}
      size={ButtonSize.Large}
      disabled={loading}
    >
      {!isAllTariffsSelected ? 'Выбрать всё' : 'Сбросить всё'}
    </Button>
    <Button
      data-testid="saveTariffsButton"
      type="submit"
      disabled={!dirty}
      isLoading={loading}
      theme={ButtonTheme.Success}
      size={ButtonSize.Large}
    >
      Сохранить
    </Button>
  </div>
);
