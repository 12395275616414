import React, { ReactElement, useContext } from 'react';
import { Currency } from 'commonTypes';
import classnames from 'classnames';
import { ModalActions, ModalContext } from 'ui/Modal';
import { formatSumWithCurrency } from 'utils/accounting';
import styles from './DepositItem.module.scss';

interface GetSumParams {
  amount?: number;
  currency?: Currency;
  isBold?: boolean;
}

export interface Value {
  getSum: (arg: GetSumParams) => ReactElement | null;
  updateModalStep: (step: number) => void;
}

export const useDepositItem = (): Value => {
  const { dispatch } = useContext(ModalContext);

  const getSum = ({
    amount,
    currency,
    isBold,
  }: GetSumParams): ReactElement | null => {
    if (!amount || !currency) {
      return null;
    }

    return (
      <div className={classnames(isBold && styles.bold)}>
        {formatSumWithCurrency(amount, currency)}
      </div>
    );
  };

  const updateModalStep = (step: number) => {
    dispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        step,
      },
    });
  };

  return { getSum, updateModalStep };
};
