import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { AffiliateReportOrderBy } from 'generatedGraphql';
import { useSearchContext } from 'src/contexts/SearchContext';
import {
  useGetRevSharePartnerReportLazyQuery,
  GetRevSharePartnerReportVariables,
} from '../queries/generated/GetRevSharePartnerReport';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetCurrency } from '../../../hooks/useGetCurrency';
import { useGetOrdering } from '../../../hooks/useGetOrdering';

const cellHeight = Heights.defaultCellHeight;

export const useGetRevSharePartnerReport = (partnerId: string) => {
  const period = useGetPeriodOfStatistic();
  const ordering = useGetOrdering<AffiliateReportOrderBy>(
    AffiliateReportOrderBy.AllDeposit,
  );

  const { searchValue } = useSearchContext();

  const { currency } = useGetCurrency();

  const baseVariables: GetRevSharePartnerReportVariables = {
    first: getCountByWindowHeight(cellHeight, 0),
    currency,
    id: partnerId,
    ordering,
    period,
  };

  const [reportVariables, setReportVariables] = useState(baseVariables);

  useEffect(() => {
    setReportVariables({
      ...baseVariables,
      search: searchValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, period.from, period.to, ordering.orderBy, ordering.direction]);

  const [
    loadRevSharePartnerReport,
    { data, loading, error, fetchMore, refetch },
  ] = useGetRevSharePartnerReportLazyQuery();

  const loadRevSharePartnerReportCallback = useCallback(
    () => loadRevSharePartnerReport({ variables: reportVariables }),
    [loadRevSharePartnerReport, reportVariables],
  );

  useBlockComponentState({
    loadData: loadRevSharePartnerReportCallback,
    loading,
    error,
  });

  const revShareReport = data?.partner.revShareReport;
  const edges = revShareReport?.edges;
  const total = revShareReport?.total;
  const hasTotalRow = !!total;
  const endCursor = revShareReport?.pageInfo.endCursor;
  const hasNextPage = revShareReport?.pageInfo.hasNextPage;

  const revSharePartnerReport = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return {
    refetch,
    revSharePartnerReport,
    hasTotalRow,
    partner: data?.partner,
    loadReport: () =>
      loadRevSharePartnerReport({
        variables: { ...reportVariables, search: searchValue },
      }),
  };
};
