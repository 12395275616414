import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { CompanyAffiliateApplicationModal } from '../components/CompanyAffiliateApplicationModal';
import { CompanyAffiliateApplicationsData } from '../queries/generated/CompanyAffiliateApplicationsData';

interface OpenModalParams {
  companyAffiliateApplication: CompanyAffiliateApplicationsData;
  initiatorId: string;
}

export const useOpenCompanyAffiliateApplicationModal = () => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const openModal = useCallback(
    ({ companyAffiliateApplication: { id }, initiatorId }: OpenModalParams) => {
      modalDispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${id}`,
          subTitle: 'Заявка партнёра компании',
          content: (
            <CompanyAffiliateApplicationModal
              companyAffiliateApplicationId={id}
            />
          ),
          initiatorId,
        },
      });
    },
    [modalDispatch],
  );

  return openModal;
};
