import { useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { InternalOutgoingMoneyTransfers } from './const';
import { useEnablePlayerInternalOutgoingMoneyTransfers } from './queries/generated/EnablePlayerInternalOutgoingMoneyTransfers';
import { useDisablePlayerInternalOutgoingMoneyTransfers } from './queries/generated/DisablePlayerInternalOutgoingMoneyTransfers';

export const useUpdateMoneyTransfersState = (id: string) => {
  const { dispatch } = useContext(ModalContext);

  const handleComplete = () => {
    dispatch({
      type: ModalActions.Close,
    });

    toast.success('Доступность переводов изменена');
  };

  const handleError = (error: ApolloError) => {
    errorToast({
      header: 'Доступность переводов не изменена',
      text: error.message,
      toastId: 'update-money-transfers-error',
    });
  };

  const [
    enableMoneyTransfers,
    { loading: enableMoneyTransfersLoading, error: enableMoneyTransfersError },
  ] = useEnablePlayerInternalOutgoingMoneyTransfers({
    variables: { input: { id } },
    onError: handleError,
    onCompleted: handleComplete,
  });

  const [
    disableMoneyTransfers,
    {
      loading: disableMoneyTransfersLoading,
      error: disableMoneyTransfersError,
    },
  ] = useDisablePlayerInternalOutgoingMoneyTransfers({
    variables: { input: { id } },
    onError: handleError,
    onCompleted: handleComplete,
  });

  const updateMoneyTransfersEnabledState = (
    moneyTransfersEnabledState: InternalOutgoingMoneyTransfers,
  ) => {
    if (moneyTransfersEnabledState === InternalOutgoingMoneyTransfers.Enabled) {
      enableMoneyTransfers();

      return;
    }

    disableMoneyTransfers();
  };

  return {
    loading: enableMoneyTransfersLoading || disableMoneyTransfersLoading,
    updateMoneyTransfersEnabledState,
    error: enableMoneyTransfersError || disableMoneyTransfersError,
  };
};
