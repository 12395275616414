import { useSearchParams } from 'react-router-dom';
import { StatisticDatePeriod } from 'src/const';
import { statisticPeriod } from '../../../../../const';

export const useSetDefaultPeriod = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setDefaultPeriod = () => {
    const newParams = new URLSearchParams(searchParams);

    newParams.set(
      StatisticDatePeriod.From,
      statisticPeriod.firstDayOfCurrentMonth || '',
    );
    newParams.set(StatisticDatePeriod.To, statisticPeriod.currentDay || '');
    setSearchParams(newParams);
  };

  return setDefaultPeriod;
};
