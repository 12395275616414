import { FC } from 'react';
import {
  NavigationGroupItemData,
  NavigationItemData,
} from 'commonComponents/Navigation/types';
import classnames from 'classnames';
import { NavigationGroupItemsList } from './components/NavigationGroupItemsList';
import { useGetNavigationGroups } from './hooks/useGetNavigationGroups';
import { useNavigationState } from '../../hooks/useNavigationState';
import styles from './NavigationRoutes.module.scss';

interface Props {
  links: Array<NavigationItemData | NavigationGroupItemData>;
  className?: string;
  subNavigationClassName?: string;
}

export const NavigationRoutes: FC<Props> = ({
  links,
  className,
  subNavigationClassName,
}) => {
  const {
    navigationState,
    toggleNavigationDropdown,
    openNavigationDropdownItem,
    selectNavigationItem,
  } = useNavigationState(links);

  const { topLinks, bottomLinks } = useGetNavigationGroups(links);

  return (
    <div className={classnames(className, styles.navigationItemsWrapper)}>
      <div>
        <NavigationGroupItemsList
          links={topLinks}
          selectNavigationItem={selectNavigationItem}
          navigationState={navigationState}
          openNavigationDropdownItem={openNavigationDropdownItem}
          toggleNavigationDropdown={toggleNavigationDropdown}
          subNavigationClassName={subNavigationClassName}
        />
      </div>
      <div>
        <NavigationGroupItemsList
          links={bottomLinks}
          selectNavigationItem={selectNavigationItem}
          navigationState={navigationState}
          openNavigationDropdownItem={openNavigationDropdownItem}
          toggleNavigationDropdown={toggleNavigationDropdown}
          subNavigationClassName={subNavigationClassName}
        />
      </div>
    </div>
  );
};
