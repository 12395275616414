import { FC, useContext, useEffect } from 'react';
import { ListAllOperations } from 'generatedGraphql';
import { Form, Formik } from 'formik';
import Input from 'ui/Input';
import Button, { ButtonTheme } from 'ui/Button';
import { useApolloClient } from '@apollo/client';
import { ModalActions, ModalContext } from 'ui/Modal';
import Error from 'ui/Error';
import { toast } from 'react-toastify';
import { Currency } from 'commonTypes';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import { PlayerData } from 'src/queries/generated/PlayerData';
import { INVALID_INTEGER_CHARS } from 'src/utils/blockInvalidChars';
import { useAddPlayerNameToSidebarSubTitle } from 'src/utils/hooks/useAddPlayerNameToSidebarSubTitle';
import { CurrencyDropdown } from 'commonComponents/CurrencyDropdown';
import { GameDropdown } from 'commonComponents/GameDropdown';
import { CreateFreespinValues, validate } from './validation';
import { useCreateFreespin } from './queries/generated/CreateFreespin';
import { DEFAULT_FREESPINS_COUNT, DEFAULT_WAGER_COUNT } from '../../const';
import { useGetActiveAccountCurrency } from './queries/generated/GetActiveAccountCurrency';

interface Props {
  playerId: string;
  hasFreespinsList: boolean;
  isFullScreenTable: boolean;
}

const searchMinLength = 3;

const CreateFreespinForm: FC<Props> = ({
  playerId,
  hasFreespinsList,
  isFullScreenTable,
}) => {
  const client = useApolloClient();
  const { dispatch } = useContext(ModalContext);

  const { data } = useGetActiveAccountCurrency({
    variables: {
      id: playerId,
    },
  });

  const player = client.readFragment({
    id: `Player:${playerId}`,
    fragment: PlayerData,
  });

  useAddPlayerNameToSidebarSubTitle(playerId);

  const initialValues: CreateFreespinValues = {
    player: player && `#${playerId} ${player.name}`,
    count: DEFAULT_FREESPINS_COUNT,
    bonusWager: DEFAULT_WAGER_COUNT,
    currency: data?.player.activeAccountCurrency as Currency,
    minAccountBalanceAmount: null,
  };

  const [createFreespin, { data: createResult, loading, error }] =
    useCreateFreespin({
      ...(hasFreespinsList
        ? {
            refetchQueries: [
              isFullScreenTable
                ? ListAllOperations.Query.GetFreespins
                : ListAllOperations.Query.GetFreespinsBlock,
            ],
          }
        : null),
    });

  useEffect(() => {
    if (createResult) {
      dispatch({ type: ModalActions.Close });

      toast.success('Фриспин начислен');
    }
  }, [createResult, dispatch]);

  const onSubmit = async (values: CreateFreespinValues) => {
    if (!values.gameId) {
      return;
    }

    await createFreespin({
      variables: {
        input: {
          count: Number(values.count),
          currency: values.currency,
          gameId: values.gameId,
          playerId,
          ...(values.bonusWager ? { bonusWager: values.bonusWager } : null),
          ...(values.minAccountBalanceAmount
            ? { minAccountBalanceAmount: values.minAccountBalanceAmount }
            : null),
        },
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnChange={false}
    >
      <Form>
        {error && <Error error={error} />}
        <FormRowsWrapper>
          <FormRow isWide>
            <Input name="player" disabled label="Игрок" />
          </FormRow>

          <FormRow isWide>
            <GameDropdown
              name="gameId"
              label="Слот"
              searchMinLength={searchMinLength}
            />
          </FormRow>

          <FormRow>
            <CurrencyDropdown name="currency" label="Валюта" />
            <Input.Number
              name="count"
              label="Количество"
              placeholder="0"
              invalidChars={INVALID_INTEGER_CHARS}
            />
          </FormRow>

          <FormRow>
            <Input.Number
              name="bonusWager"
              label="Вейджер"
              placeholder="Введите значение"
              invalidChars={INVALID_INTEGER_CHARS}
            />
            <Input.Number
              name="minAccountBalanceAmount"
              label="Минимальный баланс"
              placeholder="Введите значение"
              customMaxLength={15}
              decimalScale={1}
            />
          </FormRow>
        </FormRowsWrapper>

        <Button theme={ButtonTheme.Success} type="submit" isLoading={loading}>
          Начислить
        </Button>
      </Form>
    </Formik>
  );
};

export default CreateFreespinForm;
