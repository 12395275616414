import { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import classNames from 'classnames';
import ClickOutside, { useClickOutside } from 'ui/ClickOutside';
import {
  DropdownOptionsPosition,
  mapDropdownOptionsPositionToClass,
} from './const';
import styles from './DropdownMenu.module.scss';
import { DropdownOption } from './types';

interface Props {
  options: Array<DropdownOption>;
  onOptionSelect: (id: string) => void;
  triggerElement: ReactNode;
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  position?: DropdownOptionsPosition;
  triggerClassName?: string;
}

export const DropdownMenu: FC<Props> = ({
  options,
  triggerElement,
  onOptionSelect,
  isOpened,
  setIsOpened,
  position = DropdownOptionsPosition.Left,
  triggerClassName,
}) => {
  const onClickOutside = useClickOutside(setIsOpened);

  const onClickHandler = (id: string): void => {
    onOptionSelect(id);
    setIsOpened(false);
  };

  return (
    <div className={styles.container}>
      <div className={triggerClassName}>{triggerElement}</div>
      {isOpened && (
        <ClickOutside callback={onClickOutside}>
          <div
            className={classNames(
              styles.menu,
              mapDropdownOptionsPositionToClass[position],
            )}
            data-testid="dropdown-options-list"
          >
            {options.map((option) => (
              <div
                className={styles.option}
                key={option.id}
                onClick={() => onClickHandler(option.id)}
              >
                {option.label}
              </div>
            ))}
          </div>
        </ClickOutside>
      )}
    </div>
  );
};
