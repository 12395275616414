import {
  ReactElement,
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Collapse as ReactCollapse } from 'react-collapse';
import { BlockContext } from 'ui/Block/store';
import LoadMoreButton from 'ui/LoadMoreButton';
import { ModalContext } from 'ui/Modal';
import { Link } from 'react-router-dom';
import { throttle } from 'lodash-es';
import classnames from 'classnames';
import { PX_BEFORE_END_OF_SCREEN } from 'ui/Block/const';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { fetchMoreWithoutBlockLoader } from 'ui/Block/helpers';
import { LoadMoreStatus } from 'commonComponents/LoadMoreStatus';
import { ColumnDef } from '@tanstack/react-table';
import { Heights } from 'src/utils/getCountByWindowHeight';
import Table from 'ui/Table';
import styles from './BlockTable.module.scss';

interface Props<T extends object> {
  columns?: Array<ColumnDef<T>>;
  data?: Array<T>;
  onClickOnRow?: (arg: T, initiatorId: string) => void;
  className?: string;
  routeToCustomPage?: string;
  isFullScreenTable?: boolean;
  isEmpty?: boolean;
  summaryRow?: Array<ReactElement | string>;
  hasTotalRow?: boolean;
}

const cellHeight = Heights.defaultCellHeight;

const BlockTable = <T extends { __typename: string }>({
  columns,
  data,
  onClickOnRow,
  className,
  routeToCustomPage,
  isFullScreenTable,
  isEmpty,
  summaryRow,
  hasTotalRow,
}: Props<T>): ReactElement => {
  const { state, dispatch } = useContext(BlockContext);
  const { state: modal } = useContext(ModalContext);

  const { initiatorId } = modal;

  const onClick = (arg: T, cellId: string) => {
    if (onClickOnRow) {
      onClickOnRow(arg, cellId);
    }
  };

  const fetchMoreCallback = state.loadNew.onLoadNew as
    | (() => Promise<ApolloQueryResult<T>>)
    | undefined;
  const hasNextPage = state.loadNew.hasNew;

  const [prevScrollable, setPrevScrollable] = useState(0);

  const handleScroll = useCallback(() => {
    const scrollable = document.body.offsetHeight;
    const documentHeight = document.body.getBoundingClientRect().bottom;
    const heightViewport = window.innerHeight;

    if (prevScrollable > scrollable || prevScrollable) {
      setPrevScrollable(0);
    }

    const shouldFetchMore =
      hasNextPage &&
      fetchMoreCallback &&
      documentHeight - heightViewport <= PX_BEFORE_END_OF_SCREEN;

    const isScrollUpdated =
      prevScrollable === 0 ||
      (prevScrollable !== 0 && scrollable - prevScrollable >= cellHeight);

    if (shouldFetchMore && isScrollUpdated) {
      if (!state.isLoading && !state.error.data) {
        setPrevScrollable(scrollable);
        fetchMoreWithoutBlockLoader(fetchMoreCallback, dispatch);
      }
    }
  }, [
    prevScrollable,
    hasNextPage,
    fetchMoreCallback,
    state.isLoading,
    dispatch,
    state.error,
  ]);

  const handleScrollThrottle = throttle(handleScroll, 100);

  useLayoutEffect(() => {
    if (isFullScreenTable && fetchMoreCallback) {
      document.addEventListener('scroll', handleScrollThrottle);
    }

    return () => {
      document.removeEventListener('scroll', handleScrollThrottle);
    };
  }, [isFullScreenTable, fetchMoreCallback, handleScrollThrottle]);

  const isEndOfTheTable =
    isFullScreenTable && !state.loadNew.hasNew && !state.isLoading && !isEmpty;

  const tableContainerRef = useRef<HTMLDivElement>(null);

  return (
    <ReactCollapse isOpened={state.isOpened}>
      {state.isOpened && (
        <div
          data-testid="blockTable"
          // реф для того что бы связать скролл таблицы и хедера
          ref={tableContainerRef}
          className={classnames(styles.container, isEmpty && styles.isEmpty)}
        >
          {data && columns && (
            <Table
              // пробрасываем его в хедер, через таблицу
              tableContainerRef={tableContainerRef}
              columns={columns}
              data={data}
              onClickOnRow={onClickOnRow && onClick}
              className={className}
              activeId={initiatorId}
              hasStickyHeader={isFullScreenTable}
              summaryRow={summaryRow}
              hasTotalRow={hasTotalRow}
            />
          )}
          {isFullScreenTable && !isEndOfTheTable && (
            <LoadMoreStatus
              isLoading={state.isLoading}
              error={state.error.type}
              fetchMore={() => {
                if (fetchMoreCallback) {
                  fetchMoreWithoutBlockLoader(fetchMoreCallback, dispatch);
                }
              }}
              className={styles.tableStatus}
            />
          )}
          {isEndOfTheTable && (
            <LoadMoreStatus isEndOfList className={styles.tableStatus} />
          )}
          {routeToCustomPage && (
            <Link to={routeToCustomPage} key={routeToCustomPage}>
              <LoadMoreButton className={styles.button} label="Показать все" />
            </Link>
          )}
        </div>
      )}
    </ReactCollapse>
  );
};

export default BlockTable;
