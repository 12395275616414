import React, { FC } from 'react';
import classnames from 'classnames';
import Button, { ButtonTheme } from 'ui/Button';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { useAuth } from 'commonComponents/Auth';
import styles from './WithdrawalPaymentsHeader.module.scss';
import { WithdrawalModalSteps } from '../../types';

interface Props {
  listIsEmpty: boolean;
  remainingAmount?: number;
  withdrawalAdminId?: string;
}

const WithdrawalPaymentsHeader: FC<Props> = ({
  listIsEmpty,
  remainingAmount,
  withdrawalAdminId,
}) => {
  const auth = useAuth();

  const isAllowedToUpdateWithdrawal = (privileges: Array<UserPrivilege>) =>
    auth.privileges.areEveryGranted({
      privileges,
    });

  const userId = auth.user.id;
  const isCreatorWithdrawal = userId === withdrawalAdminId;

  const isCreatePaymentBtn =
    isCreatorWithdrawal &&
    isAllowedToUpdateWithdrawal([UserPrivilege.CreatePayment]) &&
    remainingAmount;

  const { updateModalStep } = useUpdateModalStep<WithdrawalModalSteps>();

  if (!listIsEmpty && !isCreatePaymentBtn) {
    return null;
  }

  return (
    <div
      className={classnames(styles.header, listIsEmpty && styles.withBorder)}
    >
      {listIsEmpty && (
        <div className={styles.emptyText}>Нет платежей на&nbsp;вывод</div>
      )}
      {isCreatePaymentBtn && (
        <div className={styles.buttonsContainer}>
          <Button
            theme={ButtonTheme.Primary}
            type="submit"
            onClick={() => updateModalStep(WithdrawalModalSteps.CreatePayment)}
          >
            Создать платеж
          </Button>
        </div>
      )}
    </div>
  );
};

export default WithdrawalPaymentsHeader;
