import { RewardTariffType } from 'commonTypes';
import { FormikErrors } from 'formik';
import { GenerateReferralLinkCode } from 'src/queries/generated/GenerateReferralLinkCode';
import { FieldName } from './const';

export interface CreateAffiliateReferralLinkValues {
  [FieldName.MediaCampaignId]: string;
  [FieldName.Code]: GenerateReferralLinkCode | null;
  [FieldName.Name]: string;
  [FieldName.TypeRewardTariff]: RewardTariffType | null;
  [FieldName.CPARewardTariffId]: string;
  [FieldName.FallbackTariffId]: string;
  [FieldName.RevShareRewardTariffId]: string;
}

export const validate = (
  values: CreateAffiliateReferralLinkValues,
): FormikErrors<CreateAffiliateReferralLinkValues> => {
  const errors: FormikErrors<CreateAffiliateReferralLinkValues> = {};

  if (!values[FieldName.Name]) {
    errors[FieldName.Name] = 'Поле не может быть пустым';
  }

  if (!values[FieldName.MediaCampaignId]) {
    errors[FieldName.MediaCampaignId] = 'Поле не может быть пустым';
  }

  if (!values[FieldName.Code]) {
    errors[FieldName.Code] = 'Поле не может быть пустым';
  }

  if (!values[FieldName.TypeRewardTariff]) {
    errors[FieldName.TypeRewardTariff] = 'Поле не может быть пустым';
  }

  if (
    values[FieldName.TypeRewardTariff] === RewardTariffType.Cpa &&
    !values[FieldName.CPARewardTariffId]
  ) {
    errors[FieldName.CPARewardTariffId] = 'Поле не может быть пустым';
  }

  if (
    values[FieldName.TypeRewardTariff] === RewardTariffType.Cpa &&
    !values[FieldName.FallbackTariffId]
  ) {
    errors[FieldName.FallbackTariffId] = 'Поле не может быть пустым';
  }

  if (
    values[FieldName.TypeRewardTariff] === RewardTariffType.RevShare &&
    values[FieldName.RevShareRewardTariffId] === ''
  ) {
    errors[FieldName.RevShareRewardTariffId] = 'Поле не может быть пустым';
  }

  return errors;
};
