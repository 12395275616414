import { useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { useUpdateCpaRewardTariff } from '../queries/generated/UpdateCpaRewardTariff';
import { EditTariffValues } from '../types';

interface Params {
  tariffId: string;
}

export const useEditTariffModal = ({ tariffId }: Params) => {
  const { dispatch } = useContext(ModalContext);
  const [updateTariff, { error, loading }] = useUpdateCpaRewardTariff();

  const handleError = () => {
    toast.error('Тариф не изменён');
  };

  const handleComplete = () => {
    dispatch({ type: ModalActions.Close });
    toast.success('Тариф изменён');
  };

  const onEditTariff = (values: EditTariffValues) => {
    const { rewardAmount, baselineAmount, countries, title } = values;

    if (rewardAmount && baselineAmount && countries) {
      updateTariff({
        variables: {
          input: {
            id: tariffId,
            title,
            rewardAmount,
            baselineAmount,
            countries,
          },
        },
        onError: handleError,
        onCompleted: handleComplete,
      });
    }
  };

  return { onEditTariff, loading, error };
};
