import { TournamentsTypeName } from 'commonTypes';

export const getNameTournamentByTypeName = (
  typeName: TournamentsTypeName,
): string => {
  switch (typeName) {
    case TournamentsTypeName.Cash:
      return 'Кэш-турнир';
    case TournamentsTypeName.Freespin:
      return 'Фриспин-турнир';
    case TournamentsTypeName.X:
      return 'Х-турнир';
    default:
      return '';
  }
};
