import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetExportPartnerClickIdReportVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
  period: SchemaTypes.DatePeriodInput;
}>;


export type GetExportPartnerClickIdReport = { __typename: 'Query', partner: { __typename: 'Partner', exportClickIdReport: string, id: string } };


export const GetExportPartnerClickIdReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetExportPartnerClickIdReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"period"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DatePeriodInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partner"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportClickIdReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"period"},"value":{"kind":"Variable","name":{"kind":"Name","value":"period"}}}]},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetExportPartnerClickIdReport__
 *
 * To run a query within a React component, call `useGetExportPartnerClickIdReport` and pass it any options that fit your needs.
 * When your component renders, `useGetExportPartnerClickIdReport` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportPartnerClickIdReport({
 *   variables: {
 *      id: // value for 'id'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetExportPartnerClickIdReport(baseOptions: ApolloReactHooks.QueryHookOptions<GetExportPartnerClickIdReport, GetExportPartnerClickIdReportVariables> & ({ variables: GetExportPartnerClickIdReportVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetExportPartnerClickIdReport, GetExportPartnerClickIdReportVariables>(GetExportPartnerClickIdReportDocument, options);
      }
export function useGetExportPartnerClickIdReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportPartnerClickIdReport, GetExportPartnerClickIdReportVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetExportPartnerClickIdReport, GetExportPartnerClickIdReportVariables>(GetExportPartnerClickIdReportDocument, options);
        }
export function useGetExportPartnerClickIdReportSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetExportPartnerClickIdReport, GetExportPartnerClickIdReportVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetExportPartnerClickIdReport, GetExportPartnerClickIdReportVariables>(GetExportPartnerClickIdReportDocument, options);
        }
export type GetExportPartnerClickIdReportHookResult = ReturnType<typeof useGetExportPartnerClickIdReport>;
export type GetExportPartnerClickIdReportLazyQueryHookResult = ReturnType<typeof useGetExportPartnerClickIdReportLazyQuery>;
export type GetExportPartnerClickIdReportSuspenseQueryHookResult = ReturnType<typeof useGetExportPartnerClickIdReportSuspenseQuery>;
export type GetExportPartnerClickIdReportQueryResult = Apollo.QueryResult<GetExportPartnerClickIdReport, GetExportPartnerClickIdReportVariables>;