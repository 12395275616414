import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Block from 'ui/Block';
import { BlockContext } from 'ui/Block/store';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { ModalActions, ModalContext } from 'ui/Modal';
import Privilege from 'src/components/Privilege';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import BlockTable from 'ui/Block/BlockTable';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { ReceiveGiftRestriction } from 'generatedGraphql';
import { ColumnDef } from '@tanstack/react-table';
import { UserPrivilege } from 'generatedUserPrivilege';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import styles from './BonusesTable.module.scss';
import { useGetBonusesByTableSize } from '../../hooks/useGetBonusesByTableSize';
import { useBonusOpenModal } from '../../hooks/useBonusOpenModal';
import CreateBonusForm from '../CreateBonusForm';
import { BonusBasicData } from '../../queries/generated/BonusBasicData';
import Cashback from '../Cashback/Cashback';

interface Props<T extends object> {
  playerId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
  receiveGiftRestriction?: Pick<
    ReceiveGiftRestriction,
    'bypassRestrictionIfApproved' | 'canceledAt'
  > | null;
}

const countPerPage = 10;

const BonusesTable = <T extends { __typename: string }>({
  playerId,
  columns,
  shouldReturnToPrevPage,
  routeToCustomPage,
  isFullScreenTable,
  receiveGiftRestriction,
}: Props<T>) => {
  const { state } = useContext(BlockContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);
  const { openBonusItem } = useBonusOpenModal();
  const [allowReceiveGift, setAllowReceiveGift] = useState(false);

  const { loadBonuses, data, loading, error, fetchMore, refetch } =
    useGetBonusesByTableSize(playerId, isFullScreenTable);

  const player = data?.player;
  const accounts = player?.accounts;
  const bonuses = player?.bonuses;

  const bonusesList = bonuses?.edges.map(({ node }) => node);
  const hasNextPage = !!bonuses?.pageInfo.hasNextPage;

  useEffect(() => {
    setAllowReceiveGift(
      receiveGiftRestriction === null ||
        (receiveGiftRestriction && receiveGiftRestriction?.canceledAt !== null)
        ? true
        : !!receiveGiftRestriction?.bypassRestrictionIfApproved,
    );
  }, [receiveGiftRestriction]);

  useBlockComponentState({
    loadData: loadBonuses,
    loading,
    error,
  });

  const refetchBonuses = () => {
    refetch?.();
  };

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            playerId,
            first: countPerPage,
            after: bonuses?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [playerId, bonuses?.pageInfo.endCursor],
  );

  useBlockTableComponentState({
    data,
    loading,
    hasNextPage,
    isFullScreenTable,
    fetchMoreCallback,
    refetch,
  });

  const addBonus = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatchModal({
      type: ModalActions.Open,
      payload: {
        content: (
          <CreateBonusForm
            playerId={playerId}
            isFullScreenTable={isFullScreenTable}
            isApprovalRequired={
              receiveGiftRestriction?.canceledAt === null &&
              receiveGiftRestriction?.bypassRestrictionIfApproved
            }
          />
        ),
        title: 'Начисление бонуса от\u00a0казино',
      },
    });
  };

  const openModal = (bonus: BonusBasicData, initiatorId: string) => {
    openBonusItem(bonus.id, initiatorId, bonus);
  };

  return (
    <Block
      title="Бонусы"
      id="playerBonuses"
      headerContent={
        <div className={styles.headerContent}>
          <ActionButton
            actionType={ButtonType.Update}
            onClick={refetchBonuses}
            hidden={!state.isOpened}
            disabled={state.isLoading}
          />
          <Privilege privileges={[UserPrivilege.CreateGiftBonus]}>
            <ActionButton
              actionType={ButtonType.Add}
              onClick={addBonus}
              disabled={!allowReceiveGift}
            />
          </Privilege>
        </div>
      }
      isEmpty={isListEmpty(bonusesList)}
      emptyText="Игроку не начислялись бонусы"
      extendedHeaderClassname={styles.extendedHeader}
      extendedHeader={
        state.isOpened && <Cashback accounts={accounts} playerId={playerId} />
      }
      shouldReturnToPrevPage={shouldReturnToPrevPage}
      shrinkLoaderWrapper={false}
    >
      {hasList(bonusesList) && (
        <BlockTable
          onClickOnRow={openModal}
          // TODO fix ts-ignore
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          data={bonusesList}
          routeToCustomPage={routeToCustomPage}
          isFullScreenTable={isFullScreenTable}
        />
      )}
    </Block>
  );
};

export default BonusesTable;
