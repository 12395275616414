import { useCallback, useMemo } from 'react';
import { useGetOutgoingMoneyTransfersByTableSize } from './useGetOutgoingMoneyTransfersByTableSize';

interface UseGetOutgoingMoneyTransfersParams {
  companyId: string;
  isFullScreenTable: boolean;
}

const COUNT_PER_PAGE = 10;

export const useGetOutgoingMoneyTransfers = <T>({
  companyId,
  isFullScreenTable,
}: UseGetOutgoingMoneyTransfersParams) => {
  const {
    loadOutgoingMoneyTransfers,
    data,
    loading,
    error,
    fetchMore,
    refetch,
  } = useGetOutgoingMoneyTransfersByTableSize(companyId, isFullScreenTable);

  const outgoingMoneyTransfers = data?.company.outgoingMoneyTransfers;
  const hasNextPage = data?.company.outgoingMoneyTransfers.pageInfo.hasNextPage;
  const endCursor = outgoingMoneyTransfers?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            id: companyId,
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [companyId, endCursor, fetchMore],
  );

  const outgoingMoneyTransfersList = useMemo(
    () =>
      data?.company.outgoingMoneyTransfers.edges.map(
        ({ node }) => node,
      ) as unknown as Array<T>,
    [data],
  );

  return {
    loadOutgoingMoneyTransfers,
    outgoingMoneyTransfersList,
    loading,
    error,
    refetch,
    fetchMoreCallback,
    hasNextPage,
    data,
  };
};
