import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { AffiliateReportOrderBy } from 'generatedGraphql';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useSearchContext } from 'src/contexts/SearchContext';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import {
  useGetRevShareCompanyReportLazyQuery,
  GetRevShareCompanyReportVariables,
} from '../queries/generated/GetRevShareCompanyReport';
import { useGetCurrency } from '../../../hooks/useGetCurrency';
import { useGetOrdering } from '../../../hooks/useGetOrdering';

const cellHeight = Heights.defaultCellHeight;

export const useGetRevShareCompanyReport = (companyId: string) => {
  const period = useGetPeriodOfStatistic();
  const ordering = useGetOrdering<AffiliateReportOrderBy>(
    AffiliateReportOrderBy.AllDeposit,
  );

  const { searchValue } = useSearchContext();

  const { currency } = useGetCurrency();

  const baseVariables: GetRevShareCompanyReportVariables = {
    first: getCountByWindowHeight(cellHeight, 0),
    currency,
    id: companyId,
    ordering,
    period,
  };

  const [reportVariables, setReportVariables] = useState(baseVariables);

  useEffect(() => {
    setReportVariables({
      ...baseVariables,
      search: searchValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, period.to, period.from, ordering.orderBy, ordering.direction]);

  const [
    loadRevShareCompanyReport,
    { data, loading, error, fetchMore, refetch },
  ] = useGetRevShareCompanyReportLazyQuery();

  const loadRevShareCompanyReportCallback = useCallback(
    () => loadRevShareCompanyReport({ variables: reportVariables }),
    [loadRevShareCompanyReport, reportVariables],
  );

  useBlockComponentState({
    loadData: loadRevShareCompanyReportCallback,
    loading,
    error,
  });

  const revShareReport = data?.company.revShareReport;
  const edges = revShareReport?.edges;
  const total = revShareReport?.total;
  const hasTotalRow = !!total;
  const endCursor = revShareReport?.pageInfo.endCursor;
  const hasNextPage = revShareReport?.pageInfo.hasNextPage;

  const revShareCompanyReportList = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  return {
    data,
    loadReport: () =>
      loadRevShareCompanyReport({
        variables: { ...reportVariables, search: searchValue },
      }),
    revShareCompanyReportList,
    loading,
    error,
    refetch,
    fetchMoreCallback,
    hasNextPage,
    hasTotalRow,
    company: data?.company,
  };
};
