import { useCallback, useContext, useState } from 'react';
import Block from 'ui/Block';
import { BlockContext } from 'ui/Block/store';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import BlockTable from 'ui/Block/BlockTable';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { TableFilter } from 'ui/TableFilter/TableFilter';
import { ColumnDef } from '@tanstack/react-table';
import { nbsp } from 'src/const';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useGetDepositsByTableSize } from '../hooks/useGetDepositsByTableSize';
import { DepositData } from '../queries/generated/DepositData';
import { useDepositOpenModal } from '../hooks/useDepositOpenModal';
import { getDepositFilters } from '../helpers';
import styles from './DepositTable.module.scss';
import { useGetDepositFilters } from './hooks/useGetDepositFilters';
import { countPerPage } from '../const';

interface Props<T extends object> {
  playerId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

const DepositsTable = <T extends { __typename: string }>({
  playerId,
  columns,
  shouldReturnToPrevPage,
  routeToCustomPage,
  isFullScreenTable,
}: Props<T>) => {
  const { state } = useContext(BlockContext);
  const { openDepositItem } = useDepositOpenModal();
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  const { loadDeposits, data, loading, error, fetchMore, refetch } =
    useGetDepositsByTableSize(playerId, isFullScreenTable);

  const { onChangeFilters, availableFiltersState, onSubmitFilter, isFiltered } =
    useGetDepositFilters({
      playerId,
      loadDeposits,
    });

  const { availableDepositFilters, allDepositFilters } = getDepositFilters(
    availableFiltersState,
  );

  const deposits = data?.player.deposits;

  const depositsList =
    deposits && (deposits.edges.map(({ node }) => node) as unknown as Array<T>);

  const hasNextPage = !!deposits?.pageInfo.hasNextPage;

  useBlockComponentState({
    loadData: loadDeposits,
    loading,
    error,
  });

  const refetchDeposits = () => {
    refetch?.();
  };

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            playerId,
            first: countPerPage,
            after: deposits?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [fetchMore, playerId, deposits?.pageInfo.endCursor],
  );

  useBlockTableComponentState({
    data,
    loading,
    hasNextPage,
    isFullScreenTable,
    fetchMoreCallback,
    refetch,
  });

  const openModal = (deposit: DepositData, initiatorId: string) => {
    const { id } = deposit;

    openDepositItem(id, initiatorId);
  };

  const isEmptyList = isListEmpty(depositsList);

  const isDisableFilterBtn = isEmptyList && !isFiltered;

  const emptyText =
    (isEmptyList &&
      isFiltered &&
      `Результаты${nbsp}по${nbsp}заданным фильтрам отсутствуют`) ||
    'Пользователь не совершал депозиты';

  return (
    <Block
      title="Депозиты"
      id="playerDeposits"
      headerContent={
        <div className={styles.actionsContainer}>
          {isFullScreenTable && (
            <ActionButton
              actionType={ButtonType.Filter}
              hidden={!state.isOpened}
              disabled={isDisableFilterBtn}
              onClick={() => setIsOpenFilters(!isOpenFilters)}
            />
          )}
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={refetchDeposits}
          />
        </div>
      }
      shrinkLoaderWrapper={false}
      emptyText={emptyText}
      isEmpty={isEmptyList}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      <>
        {isOpenFilters && isFullScreenTable && (
          <div className={styles.filterContainer}>
            <TableFilter
              availableFilters={availableDepositFilters}
              allFilters={allDepositFilters}
              onChangeFilters={onChangeFilters}
              onSubmit={onSubmitFilter}
            />
          </div>
        )}
        {hasList(depositsList) && (
          <BlockTable
            onClickOnRow={openModal}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            columns={columns}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data={depositsList}
            routeToCustomPage={routeToCustomPage}
            isFullScreenTable={isFullScreenTable}
          />
        )}
      </>
    </Block>
  );
};

export default DepositsTable;
