import { ModalActions, ModalContext, useModalManageData } from 'ui/Modal';
import { useContext } from 'react';
import { useGetRevShareRewardTariff } from '../../../../../queries/generated/GetRevShareRewardTariff';
import { RevShareRewardTariffsModalSteps } from '../const';

interface UseRevShareRewardTariffModalParams {
  tariffId: string;
}

export const useRevShareRewardTariffModal = ({
  tariffId,
}: UseRevShareRewardTariffModalParams) => {
  const { state, dispatch: modalDispatch } = useContext(ModalContext);

  const { data, loading, error } = useGetRevShareRewardTariff({
    variables: {
      tariffId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'rev-share-reward-tariffs-error' },
    hasData: !!data,
  });

  const goToEditTariff = () => {
    modalDispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        step: RevShareRewardTariffsModalSteps.Edit,
      },
    });
  };

  return {
    revShareRewardTariff: data?.revShareRewardTariff,
    goToEditTariff,
    step: state.step,
  };
};
