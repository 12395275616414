import React, { FC } from 'react';
import UpdateClientIdentities from 'commonComponents/UpdateClientIdentities';
import { useUpdateCompanyIdentities } from './hooks/useUpdateCompanyIdentities';
import { validate } from './validation';
import { useGetCompanyIdentities } from './hooks/useGetCompanyIdentities';

interface Props {
  id: string;
}

export const UpdateCompanyIdentities: FC<Props> = ({ id }) => {
  const { email } = useGetCompanyIdentities({ id });

  const { onUpdateCompanyIdentities, loading, error } =
    useUpdateCompanyIdentities({
      id,
    });

  if (!email) {
    return null;
  }

  return (
    <UpdateClientIdentities
      validate={validate}
      onUpdateClientIdentities={onUpdateCompanyIdentities}
      email={email}
      isLoading={loading}
      error={error}
    />
  );
};
