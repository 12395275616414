import React, { FC, useCallback, useState } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import Input from 'ui/Input';
import { Form, Formik } from 'formik';
import Button, { ButtonTheme } from 'ui/Button';
import Error from 'ui/Error';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import { Tooltip } from 'ui/Tooltip';
import { privateDefaultPath, Route } from '../../../../router/routes.const';
import styles from '../Auth.module.scss';
import { useAuth } from '../../Auth';
import { LogInValues, validateLogIn } from '../../validation';
import { AuthErrorMessages, AuthStatuses } from '../../const';

const LogIn: FC = () => {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: privateDefaultPath } };
  const navigate = useNavigate();

  const auth = useAuth();
  const [error, setError] = useState('');

  const { authStatus } = auth.user;

  const logInInitialValues = {
    email: '',
    password: '',
  };

  const onLogin = useCallback(
    async (values: LogInValues) => {
      setError('');
      try {
        await auth.logIn(values.email, values.password);
        navigate(Route.TWO_FACTOR, { state: { from } });
      } catch (e) {
        if (e instanceof Object) {
          if ((e as { status: number }).status === 401) {
            setError(AuthErrorMessages.LogIn401);
          } else {
            setError((e as { detail: string }).detail);
          }
        }
      }
    },
    [auth, from, navigate],
  );

  if (authStatus === AuthStatuses.Authenticated) {
    return <Navigate to={from} />;
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Вход</h1>
      <div className={styles.container}>
        <Formik
          initialValues={logInInitialValues}
          validate={validateLogIn}
          onSubmit={onLogin}
          validateOnChange={false}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form>
              {error && <Error error={error} />}

              <FormRowsWrapper>
                <FormRow isWide>
                  <Input type="email" name="email" label="Почта" autoFocus />
                </FormRow>

                <FormRow isWide>
                  <Input type="password" name="password" label="Пароль" />
                </FormRow>
              </FormRowsWrapper>
              <Tooltip
                tooltipContent={
                  !isValid || !dirty
                    ? 'Необходимо корректно заполнить все поля'
                    : undefined
                }
                placement="bottom"
                shouldSetWidthFitContent={false}
              >
                <Button
                  theme={ButtonTheme.Primary}
                  className={styles.button}
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  Войти
                </Button>
              </Tooltip>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LogIn;
