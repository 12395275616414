import { Dropdown } from 'ui/Dropdown';
import { FC, useEffect, useState } from 'react';
import { getDisplayName } from 'src/utils/getDisplayName';
import { useGetCountries } from './queries/generated/GetCountries';

interface Props {
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
}

export const CountriesDropdown: FC<Props> = ({
  name,
  label,
  disabled,
  placeholder,
}) => {
  const { data, error, loading } = useGetCountries();

  const countryOptions = data?.countries.map((code: string) => ({
    id: code,
    label: getDisplayName({ value: code, type: 'region' }) || code,
  }));

  const [optionsState, setOptionsState] = useState(countryOptions);
  const [isLoadedCountries, setIsLoadedCountries] = useState(false);

  const onSearchInList = (value: string) => {
    const opt = countryOptions?.filter((option) =>
      option.label.toUpperCase().includes(value.toUpperCase()),
    );

    setOptionsState(opt);
  };

  useEffect(() => {
    if (!isLoadedCountries && countryOptions) {
      setIsLoadedCountries(true);
      setOptionsState(countryOptions);
    }
  }, [countryOptions, isLoadedCountries, optionsState]);

  return (
    <Dropdown
      placeholder={placeholder}
      loadingError={error}
      loading={loading}
      onSearchInList={onSearchInList}
      isCheckbox
      disabled={disabled}
      name={name}
      label={label}
      options={optionsState}
    />
  );
};
