import { useEffect } from 'react';
import { ReferralLinkData } from 'commonComponents/ReferralLinkBlock';
import { useGetGenerateReferralLinkCodeLazyQuery } from 'queries/generated/GetGenerateReferralLinkCode';

export const useGetReferralCode = (
  referralLinkMediaItem: ReferralLinkData | null | undefined,
) => {
  const [
    generateReferralLink,
    {
      data: referralLinkData,
      loading: referralLinkLoading,
      error: referralLinkError,
    },
  ] = useGetGenerateReferralLinkCodeLazyQuery();

  useEffect(() => {
    if (!referralLinkMediaItem?.code) {
      generateReferralLink();
    }
  }, [generateReferralLink, referralLinkMediaItem?.code]);

  const referralLinkCodeInitial =
    referralLinkMediaItem?.code ||
    referralLinkData?.generateReferralLinkCode ||
    '';

  return {
    referralLinkCodeInitial,
    referralLinkLoading,
    referralLinkError,
  };
};
