import { FC, PropsWithChildren } from 'react';
import Modal, { ModalProvider } from 'ui/Modal';
import ToastContainer from 'ui/Toast/ToastContainer';
import { AppNavigation } from 'src/components/AppNavigation';
import styles from './styles/Layout.module.scss';
import { AuthStatuses, useAuth } from '../Auth';

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth();
  const { authStatus } = auth.user;

  return (
    <ModalProvider>
      <div className={styles.layout}>
        {authStatus === AuthStatuses.Authenticated ? (
          <>
            <AppNavigation />
            <div id="mainLayout" className={styles.mainLayout}>
              {children}
            </div>
          </>
        ) : (
          children
        )}
      </div>
      <Modal />
      <ToastContainer />
    </ModalProvider>
  );
};

export default Layout;
