import { getCountByWindowHeight, Heights } from 'utils/getCountByWindowHeight';
import { useGetFreespinsLazyQuery } from '../components/FreespinsTable/queries/generated/GetFreespins';
import { useGetFreespinsBlockLazyQuery } from '../components/FreespinsTable/queries/generated/GetFreespinsBlock';

const countPerPage = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetFreespinsByTableSize = (
  playerId: string,
  isFullTable: boolean,
) => {
  const [
    loadBlockFreespins,
    {
      data: blockData,
      loading: blockLoading,
      error: blockError,
      fetchMore: blockFetchMore,
      refetch: blockRefetch,
    },
  ] = useGetFreespinsBlockLazyQuery({
    variables: {
      playerId,
      first: countPerPage,
    },
  });

  const [
    loadPageFreespins,
    {
      data: pageData,
      loading: pageLoading,
      error: pageError,
      fetchMore: pageFetchMore,
      refetch: pageRefetch,
    },
  ] = useGetFreespinsLazyQuery({
    variables: {
      playerId,
      first: getCountByWindowHeight(cellHeight),
    },
  });

  const loadFreespins = isFullTable ? loadPageFreespins : loadBlockFreespins;
  const data = isFullTable ? pageData : blockData;
  const loading = isFullTable ? pageLoading : blockLoading;
  const error = isFullTable ? pageError : blockError;
  const fetchMore = isFullTable ? pageFetchMore : blockFetchMore;
  const refetch = isFullTable ? pageRefetch : blockRefetch;

  return {
    loadFreespins,
    data,
    loading,
    error,
    fetchMore,
    refetch,
  };
};
