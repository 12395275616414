import { MouseEvent, ReactElement, RefObject, useRef } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classnames from 'classnames';
import StickyHeader from 'ui/Block/BlockTable/components/StickyHeader/StickyHeader';
import styles from './Table.module.scss';
import { mapAlignToStyle } from './const';

interface Props<T extends object> {
  columns: Array<ColumnDef<T>>;
  data: Array<T>;
  onClickOnRow?: (arg: T, initiatorId: string) => void;
  className?: string;
  hasStickyHeader?: boolean;
  activeId?: string | null;
  tableContainerRef?: RefObject<HTMLDivElement>;
  summaryRow?: Array<ReactElement | string>;
  hasTotalRow?: boolean;
}

const Table = <T extends { __typename: string }>({
  columns,
  data,
  onClickOnRow,
  className,
  hasStickyHeader,
  activeId,
  tableContainerRef,
  summaryRow,
  hasTotalRow,
}: Props<T>) => {
  const tableInstance = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const pinnedColumnIndexes: Array<number> = [];

  const headerTableRef = useRef<HTMLTableRowElement>(null);
  const handleClick = (
    e: MouseEvent<HTMLDivElement>,
    arg: T,
    cellId: string,
  ) => {
    if (onClickOnRow && !window.getSelection()?.toString()) {
      onClickOnRow(arg, cellId);
    }
  };

  const getTrClasses = (itemID: string): string =>
    classnames(
      styles.tr,
      onClickOnRow && styles.clickable,
      activeId && activeId === itemID ? styles.active : null,
    );

  return (
    <>
      {hasStickyHeader && tableContainerRef && (
        <StickyHeader
          data={data}
          headerTableRef={headerTableRef}
          tableContainerRef={tableContainerRef}
          pinnedColumnIndexes={pinnedColumnIndexes}
        />
      )}
      <table
        className={classnames(
          className,
          styles.table,
          hasStickyHeader && styles.fullScreenTable,
          summaryRow && styles.hasSummaryRow,
          hasTotalRow && styles.hasTotalRow,
        )}
      >
        <thead className={styles.header}>
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <tr ref={headerTableRef} key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                const { meta, enablePinning } = header.column.columnDef;

                if (enablePinning) {
                  pinnedColumnIndexes.push(index);
                }

                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={classnames(
                      styles.th,
                      meta?.align && mapAlignToStyle[meta.align],
                      enablePinning && styles.stickyColumn,
                      meta?.hasRightBorder && styles.rightBorder,
                    )}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className={styles.tbody}>
          {summaryRow && (
            <tr className={styles.summaryRow}>
              {summaryRow.map((item, index) => {
                const isPinned = pinnedColumnIndexes.includes(index);

                return (
                  <td
                    className={classnames(
                      isPinned && styles.stickyColumn,
                      styles.cell,
                    )}
                  >
                    {item}
                  </td>
                );
              })}
            </tr>
          )}
          {tableInstance.getRowModel().rows.map((row) => {
            const itemID = row.original.__typename + row.id;

            return (
              <tr
                key={row.id}
                itemID={itemID}
                className={getTrClasses(itemID)}
                onClick={(e) => handleClick(e, row.original, itemID)}
              >
                {row.getVisibleCells().map((cell) => {
                  const { meta, enablePinning } = cell.column.columnDef;

                  return (
                    <td
                      className={classnames(
                        styles.td,
                        styles.cell,
                        meta?.align && mapAlignToStyle[meta.align],
                        enablePinning && styles.stickyColumn,
                        meta?.hasRightBorder && styles.rightBorder,
                      )}
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default Table;
