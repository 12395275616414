import { CardIcon } from 'ui/Card/CardIcon';
import { PropsWithChildren } from 'react';
import styles from './TableHeaderCellWithSort.module.scss';
import { getIcon } from './helpers';
import { useGetOrdering } from '../../hooks/useGetOrdering';
import { useChangeOrdering } from './hooks/useChangeOrdering';

interface Props<T> {
  orderBy: T;
  defaultOrderBy: T;
}

export const TableHeaderCellWithSort = <T extends string>({
  orderBy,
  defaultOrderBy,
  children,
}: PropsWithChildren<Props<T>>) => {
  const ordering = useGetOrdering<T>(defaultOrderBy);

  const isSelected = ordering.orderBy === orderBy;

  const handleChangeOrdering = useChangeOrdering();

  return (
    <button
      type="button"
      onClick={() =>
        handleChangeOrdering<T>({
          isSelected,
          orderBy,
          defaultOrderBy,
          selectedDirection: ordering.direction,
        })
      }
      className={styles.button}
    >
      {children}
      <CardIcon
        config={getIcon({
          direction: ordering.direction,
          isSelected,
        })}
        className={styles.iconContainer}
      />
    </button>
  );
};
