import { FormikErrors } from 'formik';
import { AdminRole } from 'commonTypes';

export interface EditAdminValues {
  firstName: string;
  lastName: string;
  email: string;
  roles: Array<AdminRole>;
}

interface EditAdminErrors {
  firstName?: string;
  lastName?: string;
  roles?: string;
}

export const validate = (
  values: EditAdminValues,
): FormikErrors<EditAdminErrors> => {
  const errors: FormikErrors<EditAdminErrors> = {};

  if (!values.firstName) {
    errors.firstName = 'Необходимо ввести имя';
  }

  if (!values.lastName) {
    errors.lastName = 'Необходимо ввести фамилию';
  }

  return errors;
};
