import { FormikErrors } from 'formik';
import { checkIsValidUrl } from 'utils/validators';
import { UpdateCompanyPersonalDataValues } from './types';

export const validate = (
  values: UpdateCompanyPersonalDataValues,
): FormikErrors<UpdateCompanyPersonalDataValues> => {
  const errors: FormikErrors<UpdateCompanyPersonalDataValues> = {};

  if (values.website && !checkIsValidUrl(values.website)) {
    errors.website = 'Неверный формат';
  }

  if (!values.contactValue) {
    errors.contactValue = 'Это поле обязательное';
  }

  return errors;
};
