import { FC, useContext } from 'react';
import Input from 'ui/Input';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import { Form, Formik, FormikHelpers } from 'formik';
import FormRow from 'ui/FormRow';
import Button, { ButtonTheme } from 'ui/Button';
import Error from 'ui/Error';
import { Tooltip } from 'ui/Tooltip';
import { Dropdown } from 'ui/Dropdown';
import { RewardTariffType } from 'commonTypes';
import { ReferralLinkData } from 'commonComponents/ReferralLinkBlock';
import { ModalActions, ModalContext } from 'ui/Modal';
import { MediaCampaignDropdown } from 'commonComponents/MediaCampaignDropdown';
import { CreateReferralLinkInput, validate } from './validation';
import { useSetReferralLink } from './hooks/useSetReferralLink';
import { tariffTypeOptions } from './const';
import { RevShareForm } from './components/RevShareForm';
import { CpaForm } from './components/CpaForm';
import { mediaItemTypeOptions } from '../../../../helpers';
import {
  getTooltipErrorText,
  getSubmitButtonText,
  getInitialValues,
} from './helpers';
import { useGetReferralCode } from './hooks/useGetReferralCode';
import { CompanyApplicationModalSteps } from '../../const';

interface Props {
  companyApplicationId: string;
  companyName: string;
  setReferralLinkMediaItem: (value: ReferralLinkData) => void;
  referralLinkMediaItem?: ReferralLinkData | null;
}

export const CreateReferralLink: FC<Props> = ({
  companyName,
  companyApplicationId,
  setReferralLinkMediaItem,
  referralLinkMediaItem,
}) => {
  const { dispatch } = useContext(ModalContext);

  const { setReferralLink } = useSetReferralLink({
    setReferralLinkMediaItem,
  });

  const { referralLinkError, referralLinkCodeInitial, referralLinkLoading } =
    useGetReferralCode(referralLinkMediaItem);

  const initialValues = getInitialValues({
    referralLinkMediaItem,
    companyName,
    companyApplicationId,
    referralLinkCodeInitial,
  });

  const onSubmitForm = (values: CreateReferralLinkInput) => {
    setReferralLink(values);

    dispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        step: CompanyApplicationModalSteps.BaseStep,
      },
    });
  };

  const resetTariff = (
    setFieldValue: FormikHelpers<typeof initialValues>['setFieldValue'],
  ) => {
    setFieldValue('cpaTariffId', '');
    setFieldValue('revShareTariffId', '');
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty, values, setFieldValue }) => (
        <Form>
          {referralLinkError && <Error error={referralLinkError} />}
          <FormRowsWrapper>
            <FormRow isWide>
              <Input name="companyName" label="Название компании" disabled />
            </FormRow>
            <FormRow isWide>
              <MediaCampaignDropdown
                name="mediaCampaignId"
                label="Медиа кампания"
              />
            </FormRow>
            <FormRow isWide>
              <Input
                name="referralLinkName"
                placeholder="Введите название"
                label="Название"
              />
            </FormRow>
            <FormRow isWide>
              <Dropdown
                disabled
                name="typeMediaItem"
                label="Тип элемента"
                options={mediaItemTypeOptions}
              />
            </FormRow>
            <FormRow isWide>
              <Input
                name="referralLinkCode"
                label="Реферальный код"
                placeholder="Укажите ссылку"
              />
            </FormRow>
            <FormRow isWide>
              <Dropdown
                onChange={() => {
                  resetTariff(setFieldValue);
                }}
                name="type"
                label="Модель оплаты"
                options={tariffTypeOptions}
              />
            </FormRow>
            {values.type === RewardTariffType.RevShare && <RevShareForm />}
            {values.type === RewardTariffType.Cpa && <CpaForm />}
          </FormRowsWrapper>
          <Tooltip
            tooltipContent={
              !isValid || !dirty
                ? getTooltipErrorText(referralLinkMediaItem)
                : undefined
            }
            placement="right"
            shouldSetWidthFitContent
          >
            <Button
              type="submit"
              isLoading={referralLinkLoading}
              disabled={!isValid || !dirty}
              theme={ButtonTheme.Success}
            >
              {getSubmitButtonText(referralLinkMediaItem)}
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
