import { FormikErrors } from 'formik';
import { RewardTariffType } from 'commonTypes';
import { CreateRewardTariffValues } from '../../types';

export const validate = (
  values: CreateRewardTariffValues,
): FormikErrors<CreateRewardTariffValues> => {
  const errors: FormikErrors<CreateRewardTariffValues> = {};

  if (!values.type) {
    errors.type = 'Необходимо выбрать тип';
  }

  if (
    values.type === RewardTariffType.RevShare &&
    !values.percent &&
    values.percent !== 0
  ) {
    errors.percent = 'Необходимо ввести процент';
  }

  if (
    values.type === RewardTariffType.RevShare &&
    values.percent &&
    values.percent > 99
  ) {
    errors.percent = 'Не может быть больше 99';
  }

  if (values.type === RewardTariffType.Cpa && !values.title) {
    errors.title = 'Необходимо ввести название';
  }

  if (values.type === RewardTariffType.Cpa && !values.countries.length) {
    errors.countries = 'Необходимо выбрать геолокацию';
  }

  if (values.type === RewardTariffType.Cpa && !values.baseline) {
    errors.baseline = 'Необходимо ввести Baseline';
  }

  if (values.type === RewardTariffType.Cpa && !values.reward) {
    errors.reward = 'Необходимо ввести вознаграждение';
  }

  return errors;
};
