import {
  Heights,
  getCountByWindowHeight,
} from 'src/utils/getCountByWindowHeight';
import { useCallback } from 'react';
import { useGetCompanyPromoCodeMediaItemsLazyQuery } from '../queries/generated/GetCompanyPromoCodeMediaItems';
import { useGetCompanyPromoCodeMediaItemsBlockLazyQuery } from '../queries/generated/GetCompanyPromoCodeMediaItemsBlock';
import { COUNT_PER_PAGE } from '../const';

interface UseGetPromoCodeMediaItemsByTableSizeParams {
  isFullScreenTable: boolean;
  companyId: string;
}

export const useGetPromoCodeMediaItemsByTableSize = ({
  companyId,
  isFullScreenTable,
}: UseGetPromoCodeMediaItemsByTableSizeParams) => {
  const countPerPage = isFullScreenTable
    ? getCountByWindowHeight(Heights.defaultCellHeight)
    : COUNT_PER_PAGE;

  const currentUseGetCompanyPromoCodeMediaItems = isFullScreenTable
    ? useGetCompanyPromoCodeMediaItemsLazyQuery
    : useGetCompanyPromoCodeMediaItemsBlockLazyQuery;

  const [
    loadCompanyPromoCodeMediaItems,
    { data, loading, error, fetchMore, refetch },
  ] = currentUseGetCompanyPromoCodeMediaItems({
    variables: {
      companyId,
      first: countPerPage,
    },
  });

  const promoCodeMediaItemsData = data?.company.promoCodeMediaItems;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: promoCodeMediaItemsData?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [promoCodeMediaItemsData?.pageInfo.endCursor, fetchMore],
  );

  return {
    promoCodeMediaItemsData,
    loadCompanyPromoCodeMediaItems,
    loading,
    error,
    refetch,
    fetchMoreCallback,
  };
};
