import { ListAllOperations } from 'generatedGraphql';
import { toast } from 'react-toastify';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext } from 'react';
import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { useAddCompanyAffiliates as useAddCompanyAffiliatesMutation } from '../queries/generated/AddCompanyAffiliates';
import { defineMessageByAffiliatesCount } from './helpers';
import { AffiliatesValues } from '../types';

export const useAddAffiliatesMutation = () => {
  const { dispatch } = useContext(ModalContext);

  const handleCompleted = (affiliatesCount: number) => {
    dispatch({
      type: ModalActions.Close,
    });

    toast.success(
      defineMessageByAffiliatesCount({ isCompleted: true, affiliatesCount }),
    );
  };

  const handleError = (error: ApolloError, affiliatesCount: number) => {
    errorToast({
      header: defineMessageByAffiliatesCount({
        isCompleted: false,
        affiliatesCount,
      }),
      text: error.message,
      toastId: 'add-affiliates-error',
    });
  };

  const [
    addCompanyAffiliatesMutation,
    { loading: addAffiliatesLoading, error: addAffiliatesError },
  ] = useAddCompanyAffiliatesMutation({
    refetchQueries: [ListAllOperations.Query.GetCompanyAffiliates],
  });

  const handleSubmit = (values: AffiliatesValues, companyId: string) => {
    const affiliates = values.affiliates.map((affiliate) => ({
      name: affiliate.name,
      ...(affiliate.note ? { note: affiliate.note } : null),
    }));

    const affiliatesCount = affiliates.length;

    addCompanyAffiliatesMutation({
      variables: {
        input: {
          companyId,
          affiliates,
        },
      },
      onCompleted: () => handleCompleted(affiliatesCount),
      onError: (error) => handleError(error, affiliatesCount),
    });
  };

  return {
    handleSubmit,
    addAffiliatesLoading,
    addAffiliatesError,
  };
};
