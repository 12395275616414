import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { faArrowAltToTop } from '@fortawesome/pro-solid-svg-icons';
import { CardIconColor } from 'ui/Card';
import WithdrawalSidebar from '../components/WithdrawalSidebar/WithdrawalSidebar';
import { WithdrawalData } from '../components/WithdrawalsTable/queries/generated/WithdrawalData';

interface WithdrawalOpenModelType<T extends object> {
  openWithdrawal: (arg: T, initiatorId: string) => void;
}

export const useWithdrawalOpenModal = <T extends object>(
  playerId: string,
): WithdrawalOpenModelType<T> => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const openWithdrawal = useCallback(
    async (withdrawal: T, initiatorId: string) => {
      const { id } = withdrawal as WithdrawalData;

      modalDispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${id}`,
          content: <WithdrawalSidebar playerId={playerId} withdrawalId={id} />,
          initiatorId,
          iconConfig: {
            icon: faArrowAltToTop,
            iconColor: CardIconColor.RED,
          },
          subTitle: 'Вывод',
        },
      });
    },
    [modalDispatch, playerId],
  );

  return {
    openWithdrawal,
  };
};
