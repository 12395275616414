import { useCallback, useRef } from 'react';
import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { nbsp } from 'src/const';
import { throttle } from 'lodash-es';
import { useGetCompanyAffiliatesForDropdownLazyQuery } from '../queries/generated/GetCompanyAffiliatesForDropdown';

const COUNT_PER_PAGE = 10;

export const useGetCompanyAffiliatesQuery = (companyId: string) => {
  const handleError = (error: ApolloError) => {
    errorToast({
      header: `При${nbsp}загрузке партнеров компании произошла ошибка`,
      text: error.message,
      toastId: 'get-company-affiliates-for-referral-link-media-item-error',
    });
  };

  const [
    loadCompanyAffiliates,
    { data, loading, fetchMore: fetchMoreCompanyAffiliates, error },
  ] = useGetCompanyAffiliatesForDropdownLazyQuery({
    variables: {
      id: companyId,
      first: COUNT_PER_PAGE,
    },
    onError: handleError,
  });

  const hasNextPage = data?.company.affiliates.pageInfo.hasNextPage;
  const endCursor = data?.company.affiliates.pageInfo.endCursor;

  const fetchMore = useCallback(
    () =>
      fetchMoreCompanyAffiliates({
        variables: {
          id: companyId,
          first: COUNT_PER_PAGE,
          after: endCursor,
        },
      }),
    [companyId, fetchMoreCompanyAffiliates, endCursor],
  );

  const loadCompanyAffiliateWithThrottle = useRef(
    throttle(async (searchText: string) => {
      await loadCompanyAffiliates({
        variables: {
          search: searchText,
          first: COUNT_PER_PAGE,
          id: companyId,
        },
      });
    }, 1000),
  ).current;

  return {
    loadCompanyAffiliateWithThrottle,
    data,
    loading,
    hasNextPage,
    fetchMore,
    error,
  };
};
