import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { formatSumAmount } from 'src/utils/accounting';
import { formatTableHeaderCellWithCurrency } from 'src/pages/Statistic/helpers';

import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { Currency } from 'commonTypes';
import { GeneralReportOrderBy } from 'generatedGraphql';
import { columnsIds, mapColumnIdToName, ColumnId } from '../const';
import {
  defaultGeneralReportOrderBy,
  STATISTIC_TOTAL_ROW_TITLE,
} from '../../../../../const';
import { RevShareCompanyPartnersReportRow } from '../../../types';
import { RevShareMediaOwnerCell } from '../../RevShareMediaOwnerCell';
import { useGetCurrency } from '../../../../../hooks/useGetCurrency';
import { TableHeaderCellWithSort } from '../../../../../components/TableHeaderCellWithSort';

const columnHelper = createColumnHelper<RevShareCompanyPartnersReportRow>();

const buildColumnsSource = (
  currency: Currency,
): ColumnsSource<RevShareCompanyPartnersReportRow> => [
  columnHelper.accessor(ColumnId.MediaOwner, {
    id: ColumnId.MediaOwner,
    header: mapColumnIdToName[ColumnId.MediaOwner],
    cell: ({ row }) => {
      if (!row.original.companyPartner && !row.original.company) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      return <RevShareMediaOwnerCell row={row.original} />;
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Hits, {
    id: ColumnId.Hits,
    header: mapColumnIdToName[ColumnId.Hits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hits),
  }),
  columnHelper.accessor(ColumnId.Hosts, {
    id: ColumnId.Hosts,
    header: mapColumnIdToName[ColumnId.Hosts],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hosts),
  }),
  columnHelper.accessor(ColumnId.Registrations, {
    id: ColumnId.Registrations,
    header: () => (
      <TableHeaderCellWithSort
        defaultOrderBy={defaultGeneralReportOrderBy}
        orderBy={GeneralReportOrderBy.Registrations}
      >
        {mapColumnIdToName[ColumnId.Registrations]}
      </TableHeaderCellWithSort>
    ),
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.registrations),
  }),
  columnHelper.accessor(ColumnId.FirstDeposits, {
    id: ColumnId.FirstDeposits,
    header: mapColumnIdToName[ColumnId.FirstDeposits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.firstDeposits),
  }),
  columnHelper.accessor(ColumnId.HostToRegConversion, {
    id: ColumnId.HostToRegConversion,
    header: mapColumnIdToName[ColumnId.HostToRegConversion],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.hostToRegConversion),
  }),
  columnHelper.accessor(ColumnId.RegToDepConversion, {
    id: ColumnId.RegToDepConversion,
    header: mapColumnIdToName[ColumnId.RegToDepConversion],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.regToDepConversion),
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.AllDeposit, {
    id: ColumnId.AllDeposit,
    header: () => (
      <TableHeaderCellWithSort
        defaultOrderBy={defaultGeneralReportOrderBy}
        orderBy={GeneralReportOrderBy.AllDeposit}
      >
        {formatTableHeaderCellWithCurrency(
          mapColumnIdToName[ColumnId.AllDeposit],
          currency,
        )}
      </TableHeaderCellWithSort>
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.allDeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.AverageDeposit, {
    id: ColumnId.AverageDeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AverageDeposit],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.averageDeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.AllRedeposit, {
    id: ColumnId.AllRedeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AllRedeposit],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.allRedeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Redeposits, {
    id: ColumnId.Redeposits,
    header: mapColumnIdToName[ColumnId.Redeposits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.redeposits),
  }),
  columnHelper.accessor(ColumnId.NewDeposit, {
    id: ColumnId.NewDeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NewDeposit],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerRegisteredStatistic.newDeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.IncomingMoneyTransfer, {
    id: ColumnId.IncomingMoneyTransfer,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.IncomingMoneyTransfer],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.incomingMoneyTransfer;

      return formatSumAmount(amount);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Withdrawal, {
    id: ColumnId.Withdrawal,
    header: () => (
      <TableHeaderCellWithSort
        defaultOrderBy={defaultGeneralReportOrderBy}
        orderBy={GeneralReportOrderBy.Withdrawal}
      >
        {formatTableHeaderCellWithCurrency(
          mapColumnIdToName[ColumnId.Withdrawal],
          currency,
        )}
      </TableHeaderCellWithSort>
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.withdrawal;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.NewWithdrawal, {
    id: ColumnId.NewWithdrawal,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NewWithdrawal],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.NewProfit, {
    id: ColumnId.NewProfit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NewProfit],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerRegisteredStatistic.newProfit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.DepositFee, {
    id: ColumnId.DepositFee,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.DepositFee],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.depositFee;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.WithdrawalFee, {
    id: ColumnId.WithdrawalFee,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.WithdrawalFee],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.withdrawalFee;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.AdminFee, {
    id: ColumnId.AdminFee,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AdminFee],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.adminFee;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Bonus, {
    id: ColumnId.Bonus,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Bonus],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.bonus;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.TournamentPrize, {
    id: ColumnId.TournamentPrize,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.TournamentPrize],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.tournamentPrize;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Jackpot, {
    id: ColumnId.Jackpot,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Jackpot],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.jackpot;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.NGR, {
    id: ColumnId.NGR,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NGR],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.financialIndicators.ngr;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Profit, {
    id: ColumnId.Profit,
    header: () => (
      <TableHeaderCellWithSort
        defaultOrderBy={defaultGeneralReportOrderBy}
        orderBy={GeneralReportOrderBy.Profit}
      >
        {formatTableHeaderCellWithCurrency(
          mapColumnIdToName[ColumnId.Profit],
          currency,
        )}
      </TableHeaderCellWithSort>
    ),
    cell: ({ row }) => {
      const { amount } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Upcoming, {
    id: ColumnId.Upcoming,
    header: () => (
      <TableHeaderCellWithSort
        defaultOrderBy={defaultGeneralReportOrderBy}
        orderBy={GeneralReportOrderBy.UpcomingReward}
      >
        {formatTableHeaderCellWithCurrency(
          mapColumnIdToName[ColumnId.Upcoming],
          currency,
        )}
      </TableHeaderCellWithSort>
    ),
    cell: ({ row }) => {
      const { amount } = row.original.affiliateUpcomingReward;

      return formatSumAmount(amount);
    },
  }),
];

export const useGetRevShareCompanyPartnersReportColumns = () => {
  const { currency } = useGetCurrency();
  const columnsSource = buildColumnsSource(currency);
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
