import {
  AccountEventTypeName,
  BonusAccountEventType,
  DepositAccountEventType,
  GameAccountEventType,
  JackpotAccountEventType,
  MoneyTransferAccountEventType,
  TournamentAccountEventType,
  WithdrawalAccountEventType,
} from 'commonTypes';
import { AccountEventBasicData } from '../queries/fragments/generated/AccountEventBasicData';

const mapDepositEventTypeToName: Record<DepositAccountEventType, string> = {
  [DepositAccountEventType.Success]: 'Успешный депозит',
};

const mapWithdrawalEventTypeToName: Record<WithdrawalAccountEventType, string> =
  {
    [WithdrawalAccountEventType.Declined]: 'Отклонение инвойса вывода',
    [WithdrawalAccountEventType.Created]: 'Создание инвойса вывода',
    [WithdrawalAccountEventType.Cancelled]: 'Отмена инвойса вывода',
  };

const mapGameEventTypeToName: Record<GameAccountEventType, string> = {
  [GameAccountEventType.Bet]: 'Игровые списания',
  [GameAccountEventType.Win]: 'Игровые зачисления',
};

const mapBonusEventTypeToName: Record<BonusAccountEventType, string> = {
  [BonusAccountEventType.Activated]: 'Активация бонуса',
  [BonusAccountEventType.Expired]: 'Бонус истек',
  [BonusAccountEventType.Cancelled]: 'Отмена бонуса',
  [BonusAccountEventType.Win]: 'Выигрыш с\u00A0бонуса',
};

const mapMoneyTransferEventTypeToName: Record<
  MoneyTransferAccountEventType,
  string
> = {
  [MoneyTransferAccountEventType.Incoming]: 'Входящий перевод',
};

const mapJackpotEventTypeToName: Record<JackpotAccountEventType, string> = {
  [JackpotAccountEventType.Win]: 'Получение выигрыша',
};

const mapTournamentEventTypeToName: Record<TournamentAccountEventType, string> =
  {
    [TournamentAccountEventType.Rebuy]: 'Докуп',
    [TournamentAccountEventType.Win]: 'Получение приза',
  };

export const getEventNameFromEvent = (
  event: AccountEventBasicData,
): string | null => {
  switch (event.__typename) {
    case AccountEventTypeName.DepositAccountEvent:
      return mapDepositEventTypeToName[event.depositType];
    case AccountEventTypeName.WithdrawalAccountEvent:
      return mapWithdrawalEventTypeToName[event.withdrawalType];
    case AccountEventTypeName.GameAccountEvent:
      return mapGameEventTypeToName[event.gameType];
    case AccountEventTypeName.BonusAccountEvent:
      return mapBonusEventTypeToName[event.bonusType];
    case AccountEventTypeName.MoneyTransferAccountEvent:
      return mapMoneyTransferEventTypeToName[event.moneyTransferType];
    case AccountEventTypeName.JackpotAccountEvent:
      return mapJackpotEventTypeToName[event.jackpotType];
    case AccountEventTypeName.TournamentAccountEvent:
      return mapTournamentEventTypeToName[event.tournamentType];
    default:
      return null;
  }
};
