import { FC, useContext, useMemo } from 'react';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { AccountEventGroupsTable } from './components/AccountEventGroupsTable';
import { useAccounts } from './hooks/useAccounts';
import { useEventGroups } from './hooks/useEventGroups';
import { AccountsTabs } from './components/AccountsTabs';

interface Props {
  id: string;
  shouldReturnToPrevPage?: boolean;
  isFullScreenTable?: boolean;
}

export const AccountsHistoryBlock: FC<Props> = withBlockProvider(
  ({
    id: playerId,
    shouldReturnToPrevPage = false,
    isFullScreenTable = false,
  }) => {
    const { state } = useContext(BlockContext);

    const {
      currentAccountId,
      accounts,
      accountsError,
      accountsLoading,
      accountsLoad,
      setCurrentAccountId,
      accountsRefetch,
    } = useAccounts({ playerId });

    const {
      eventGroupsData,
      eventGroupsError,
      eventGroupsLoading,
      evensGroupsFetchMore,
      eventGroupsRefetch,
    } = useEventGroups({
      isFullScreenTable,
      currentAccountId,
    });

    useBlockComponentState({
      loadData: accountsLoad,
      loading: accountsLoading || eventGroupsLoading,
      error: accountsError || eventGroupsError,
    });

    useBlockTableComponentState({
      data: eventGroupsData,
      fetchMoreCallback: evensGroupsFetchMore,
      refetch: eventGroupsRefetch,
      loading: eventGroupsLoading,
      hasNextPage: eventGroupsData?.pageInfo.hasNextPage,
      isFullScreenTable,
    });

    const eventGroups = useMemo(
      () => eventGroupsData?.edges.map(({ node }) => node),
      [eventGroupsData],
    );

    const handleTabChange = (tabId: string) => {
      setCurrentAccountId(tabId);
    };

    return (
      <Block
        title="История счета"
        id="playerAccountsHistory"
        headerContent={
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={() => accountsRefetch()}
          />
        }
        subHeader={
          !accountsLoading &&
          !!accounts?.length && (
            <AccountsTabs
              accounts={accounts}
              currentTabId={currentAccountId}
              setCurrentTabId={handleTabChange}
            />
          )
        }
        shrinkLoaderWrapper={false}
        emptyText="Игрок не совершал операций"
        isEmpty={isListEmpty(accounts) || isListEmpty(eventGroups)}
        shouldReturnToPrevPage={shouldReturnToPrevPage}
      >
        {hasList(eventGroups) && (
          <AccountEventGroupsTable
            eventGroups={eventGroups}
            isFullScreenTable={isFullScreenTable}
          />
        )}
      </Block>
    );
  },
);
