import { FC } from 'react';
import Error from 'ui/Error';
import { MessageBlockWithButton } from 'commonComponents/MessageBlockWithButton';
import { ModalItemsList } from 'ui/Modal/components/ModalItemsList';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useAuth } from 'commonComponents/Auth';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { useGetAffiliateReferralLink } from './hooks/useGetAffiliateReferralLink';
import { AffiliateReferralLinkItem } from './components/AffiliateReferralLinkItem';
import { AffiliateMediaItemsSteps } from '../AffiliatePromoCodeItem/const';

interface Props {
  companyId: string;
  companyAffiliateId: string;
  onDetailsClick: (referralLinkId: string) => void;
}

export const AffiliateReferralLinksList: FC<Props> = ({
  companyId,
  companyAffiliateId,
  onDetailsClick,
}) => {
  const auth = useAuth();

  const { referralLinks, loading, error, loadMore, isListEmpty } =
    useGetAffiliateReferralLink({
      companyId,
      companyAffiliateId,
    });

  const { updateModalStep } = useUpdateModalStep<AffiliateMediaItemsSteps>();

  if (error) {
    return <Error error={error} />;
  }

  const hasPrivilegeToCreateReferralLink = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.CreateCompanyAffiliateReferralLinkMediaItem],
  });

  return (
    <ModalItemsList
      topBlock={
        <MessageBlockWithButton
          message={isListEmpty ? 'Медиа элементы не найдены' : undefined}
          actionButtonConfig={
            hasPrivilegeToCreateReferralLink
              ? {
                  buttonText: 'Создать',
                  onButtonClick: () =>
                    updateModalStep(
                      AffiliateMediaItemsSteps.CreateReferralLink,
                    ),
                }
              : undefined
          }
        />
      }
      itemsListContent={referralLinks?.edges.map(({ node }) => (
        <AffiliateReferralLinkItem
          referralLink={node}
          key={node.id}
          onDetailsClick={onDetailsClick}
        />
      ))}
      loading={loading}
      paginationConfig={{
        loadMore,
        hasNextPage: !!referralLinks?.pageInfo.hasNextPage,
      }}
    />
  );
};
