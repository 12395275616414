import { UserPrivilege } from 'generatedUserPrivilege';
import { getCountByWindowHeight, Heights } from 'utils/getCountByWindowHeight';
import { useAuth } from 'commonComponents/Auth';
import { useGetWithdrawalsBlockLazyQuery } from '../components/WithdrawalsTable/queries/generated/GetWithdrawalsBlock';
import { useGetWithdrawalsLazyQuery } from '../components/WithdrawalsTable/queries/generated/GetWithdrawals';

const countPerPage = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetWithdrawalsByTableSize = (
  playerId: string,
  isFullTable: boolean,
) => {
  const auth = useAuth();

  const [
    loadBlockWithdrawals,
    {
      data: blockData,
      loading: blockLoading,
      error: blockError,
      fetchMore: blockFetchMore,
      refetch: blockRefetch,
    },
  ] = useGetWithdrawalsBlockLazyQuery({
    variables: {
      playerId,
      first: countPerPage,
      viewPaymentMethodAccount: auth.privileges.areEveryGranted({
        privileges: [UserPrivilege.ViewPaymentmethodAccount],
      }),
    },
  });

  const [
    loadPageWithdrawals,
    {
      data: pageData,
      loading: pageLoading,
      error: pageError,
      fetchMore: pageFetchMore,
      refetch: pageRefetch,
    },
  ] = useGetWithdrawalsLazyQuery({
    variables: {
      playerId,
      first: getCountByWindowHeight(cellHeight),
      viewPaymentMethodAccount: auth.privileges.areEveryGranted({
        privileges: [UserPrivilege.ViewPaymentmethodAccount],
      }),
    },
  });

  const loadWithdrawals = isFullTable
    ? loadPageWithdrawals
    : loadBlockWithdrawals;
  const data = isFullTable ? pageData : blockData;
  const loading = isFullTable ? pageLoading : blockLoading;
  const error = isFullTable ? pageError : blockError;
  const fetchMore = isFullTable ? pageFetchMore : blockFetchMore;
  const refetch = isFullTable ? pageRefetch : blockRefetch;

  return {
    loadWithdrawals,
    data,
    loading,
    error,
    fetchMore,
    refetch,
  };
};
