import { FC, useRef, useLayoutEffect, ReactElement } from 'react';
import classnames from 'classnames';
import capitalize from 'utils/capitalize';
import styles from './ProgressBar.module.scss';

export enum ProgressBarColor {
  Blue = 'blue',
  Green = 'green',
  Red = 'red',
  Yellow = 'yellow',
  Orange = 'orange',
  Indigo = 'indigo',
}
export enum HeaderStyle {
  Bold = 'bold',
  Regular = 'regular',
}

export enum ProgressBarView {
  Chart = 'chart',
  Limit = 'limit',
}

export interface ProgressBarProps {
  percent: number;
  className?: string;
  header?: string | ReactElement;
  startTitle?: string | ReactElement;
  endTitle?: string | ReactElement;
  barColor?: ProgressBarColor;
  headerStyle?: HeaderStyle;
  viewType?: ProgressBarView;
}

export const ProgressBar: FC<ProgressBarProps> = ({
  percent,
  className,
  header,
  startTitle,
  endTitle,
  barColor,
  headerStyle = HeaderStyle.Bold,
  viewType,
}) => {
  const barRef = useRef<HTMLDivElement>(null);
  const isChartView = viewType === ProgressBarView.Chart;
  const isLimitView = viewType === ProgressBarView.Limit;

  useLayoutEffect(() => {
    if (!barRef.current) {
      return;
    }

    if (!viewType && percent < 100) {
      barRef.current.style.background = `linear-gradient(90deg, #ffb74c ${percent}%, #ff9800 ${percent}%, #e8e8e8 0%)`;
    } else if (viewType) {
      barRef.current.style.width = `${percent}%`;
    }
  });

  const headerStyles = [
    styles.header,
    isLimitView && styles.limitHeader,
    headerStyle === HeaderStyle.Bold && styles.headerBold,
  ];

  const barStyles = [
    styles.bar,
    !isChartView && percent >= 100 && styles.barFull,
    isChartView && barColor && styles[`barColor${capitalize(barColor)}`],
  ];

  return (
    <div className={classnames(styles.progressBarContainer, className)}>
      {header && (
        <div
          data-testid="progressBarHeader"
          className={classnames(headerStyles)}
        >
          {header}
        </div>
      )}
      {isLimitView ? (
        <div
          className={styles.limitBarWrapper}
          data-testid="limitProgressbarWrapper"
        >
          <div
            className={classnames(
              styles.bar,
              barColor && styles[`barColor${capitalize(barColor)}`],
            )}
            data-testid="limitProgressBar"
            ref={barRef}
          />
        </div>
      ) : (
        <div
          className={classnames(barStyles)}
          ref={barRef}
          data-testid="progressBar"
        />
      )}
      {(startTitle || endTitle) && (
        <div
          data-testid="progressBarSubTextContainer"
          className={classnames(
            styles.subTitle,
            isLimitView && styles.limitSubTitle,
          )}
        >
          <div>{startTitle}</div>
          <div>{endTitle}</div>
        </div>
      )}
    </div>
  );
};
