import { useEffect, useState } from 'react';
import { DatePickerHandleProps, DatePickerStates } from '../types';

interface UseDatePickerDataParams {
  isSelectsRange: boolean;
  onSubmit: (date: DatePickerHandleProps) => void;
  onChange: (date: DatePickerHandleProps) => void;
  startDateInitial?: Date | null;
  endDateInitial?: Date | null;
  openDateInitial?: Date;
}

export const useDatePickerData = ({
  isSelectsRange,
  endDateInitial,
  onSubmit,
  startDateInitial,
  onChange,
  openDateInitial,
}: UseDatePickerDataParams) => {
  const [datePickerStates, setDatePickerStates] = useState<DatePickerStates>({
    showMonthYearPicker: false,
    showFullMonthYearPicker: false,
    showYearPicker: false,
  });

  const [selectsRange, setSelectsRange] = useState(isSelectsRange);

  const [startDate, setStartDate] = useState<Date | null>(
    startDateInitial || null,
  );
  const [endDate, setEndDate] = useState<Date | null>(endDateInitial || null);
  const [openDate, setOpenDate] = useState(
    openDateInitial || startDate || new Date(),
  );

  useEffect(() => {
    if (startDateInitial !== undefined) {
      setStartDate(startDateInitial);
    }

    if (endDateInitial !== undefined) {
      setEndDate(endDateInitial);
    }
  }, [endDateInitial, startDateInitial]);

  const handleChange = (date: DatePickerHandleProps) => {
    if (!date) {
      return;
    }

    if (
      !datePickerStates.showYearPicker &&
      !datePickerStates.showMonthYearPicker &&
      !datePickerStates.showFullMonthYearPicker
    ) {
      onChange(date);
    }

    if (!(date instanceof Date)) {
      const [start, end] = date;

      setStartDate(start);
      setEndDate(end);

      return;
    }

    if (
      datePickerStates.showMonthYearPicker &&
      datePickerStates.showFullMonthYearPicker
    ) {
      setOpenDate(date);
      setDatePickerStates({
        showMonthYearPicker: false,
        showFullMonthYearPicker: false,
        showYearPicker: false,
      });
      setSelectsRange(isSelectsRange);

      return;
    }

    if (datePickerStates.showYearPicker) {
      setOpenDate(date);
      setDatePickerStates({
        showMonthYearPicker: true,
        showFullMonthYearPicker: true,
        showYearPicker: false,
      });

      return;
    }

    if (!selectsRange) {
      setStartDate(date);
    }
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);

    if (isSelectsRange) {
      onSubmit([null, null]);
    } else {
      onSubmit(null);
    }
  };

  const handleSubmit = () => {
    if (selectsRange) {
      onSubmit([startDate, endDate]);
    } else {
      onSubmit(startDate);
    }
  };

  return {
    openDate,
    datePickerStates,
    setDatePickerStates,
    setSelectsRange,
    startDate,
    endDate,
    handleChange,
    selectsRange,
    handleReset,
    handleSubmit,
  };
};
