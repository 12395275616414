import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { RewardItem } from '../components/RewardItem';
import { RewardData } from '../queries/generated/RewardData';
import { getModalSubTitle } from '../helpers';
import { RewardModalSteps } from '../const';

interface OpenModalParams {
  reward: RewardData;
  initiatorId: string;
}

interface OpenModelResult {
  openModal: (args: OpenModalParams) => void;
}

export const useOpenModal = (): OpenModelResult => {
  const { dispatch } = useContext(ModalContext);

  const openModal = useCallback(
    ({ reward, initiatorId }: OpenModalParams) => {
      const { id } = reward;

      dispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${id}`,
          subTitle: getModalSubTitle(reward.affiliate),
          content: <RewardItem rewardId={id} />,
          step: RewardModalSteps.BaseStep,
          initiatorId,
        },
      });
    },
    [dispatch],
  );

  return {
    openModal,
  };
};
