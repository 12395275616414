import { useCallback, useEffect, useMemo, useState } from 'react';
import { getCountByWindowHeight, Heights } from 'utils/getCountByWindowHeight';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useSearchContext } from 'src/contexts/SearchContext';
import { useGetMediaCampaignsLazyQuery } from '../queries/generated/GetMediaCampaigns';
import { MediaCampaignData } from '../queries/generated/MediaCampaignData';

const cellHeight = Heights.defaultCellHeight;

export const useMediaCampaigns = () => {
  const { searchValue } = useSearchContext();

  const [variables, setVariables] = useState({
    first: getCountByWindowHeight(cellHeight, 0),
  });

  useEffect(() => {
    setVariables((oldVariables) => ({
      ...oldVariables,
      search: searchValue,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loadMediaCampaigns, { data, loading, error, fetchMore, refetch }] =
    useGetMediaCampaignsLazyQuery();

  const loadMediaCampaignsCallback = useCallback(
    () => loadMediaCampaigns({ variables }),
    [loadMediaCampaigns, variables],
  );

  useBlockComponentState({
    loadData: loadMediaCampaignsCallback,
    loading,
    error,
  });

  const mediaCampaigns: Array<MediaCampaignData> | undefined = useMemo(
    () => data?.mediaCampaigns?.edges.map(({ node }) => node),
    [data?.mediaCampaigns?.edges],
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: data?.mediaCampaigns?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [data?.mediaCampaigns?.pageInfo.endCursor, fetchMore],
  );

  return {
    mediaCampaigns,
    loadMediaCampaigns: () =>
      loadMediaCampaigns({
        variables: { ...variables, search: searchValue },
      }),
    data,
    loading,
    fetchMoreCallback,
    error,
    refetch,
  };
};
