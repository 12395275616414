import { FC, PropsWithChildren, useRef } from 'react';
import classnames from 'classnames';
import Button from 'ui/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-solid-svg-icons';
import {
  convertBytesToMegabytes,
  getFileNamesFromFileFormats,
} from './helpers';
import styles from './InputFile.module.scss';
import { useFileDragAndDrop } from './hooks/useFileDragAndDrop';
import { fileExtensionMap, FileFormat, MAX_SIZE } from './const';

interface Props {
  onChange: (files: FileList) => void;
  validFormats: Array<FileFormat>;
  isMultiple?: boolean;
  maxSize?: number;
  id?: string;
  className?: string;
}

export const InputFile: FC<PropsWithChildren<Props>> = ({
  onChange,
  validFormats,
  isMultiple = true,
  maxSize = MAX_SIZE,
  id = 'uploadFileInput',
  className,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { isDragActive, error, handleInputChange, dragHandlers } =
    useFileDragAndDrop({ onChange, validFormats, maxSize, inputRef });

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const subText = `${getFileNamesFromFileFormats(
    validFormats,
  )}  не более ${convertBytesToMegabytes(maxSize)}МБ`;

  const inputAcceptAttribute = validFormats
    .map((format) => fileExtensionMap[format])
    .join(', ');

  return (
    <div
      data-testid="inputFileContainer"
      className={classnames(
        className,
        styles.container,
        isDragActive && styles.dragActive,
        error && styles.error,
      )}
      {...dragHandlers}
    >
      <input
        className={styles.hidden}
        data-testid="originalInput"
        ref={inputRef}
        type="file"
        id={id}
        multiple={isMultiple}
        onChange={handleInputChange}
        accept={inputAcceptAttribute}
      />
      <label htmlFor={id} className={styles.label}>
        <div className={styles.innerContainer}>
          <div>
            <FontAwesomeIcon className={styles.icon} icon={faImage} />
          </div>
          <div>
            {error || (
              <>
                Перетащите сюда файлы или{' '}
                <Button
                  onClick={handleButtonClick}
                  className={styles.button}
                  linkStyled
                >
                  выберите вручную
                </Button>
              </>
            )}
          </div>
          <div className={styles.subText}>{subText}</div>
        </div>
      </label>
    </div>
  );
};
