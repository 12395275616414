import { OwnerTypeName } from 'commonTypes';
import { RewardData } from '../queries/generated/RewardData';

export const getModalSubTitle = (
  affiliate: RewardData['affiliate'],
): string => {
  if (affiliate.__typename === OwnerTypeName.Company) {
    return 'Вознаграждение компании';
  }

  return 'Вознаграждение партнёра';
};
