import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { PartnerBasicData } from 'queries/generated/PartnerBasicData';
import { ResetPartnerTwoFactor } from '../components/ResetPartnerTwoFactor/ResetPartnerTwoFactor';

interface UsePartnerEditParams {
  partner: PartnerBasicData;
}

interface PartnerEdit {
  resetPartnerTwoFactor: () => void;
}

export const usePartnerEdit = ({
  partner,
}: UsePartnerEditParams): PartnerEdit => {
  const { id, name, twoFactorEnabled } = partner;
  const { dispatch } = useContext(ModalContext);

  const resetPartnerTwoFactor = useCallback(async () => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        content: (
          <ResetPartnerTwoFactor
            partnerId={id}
            twoFactorEnabled={twoFactorEnabled}
          />
        ),
        title: 'Управление 2FA',
        subTitle: `${name} #${id}`,
      },
    });
  }, [dispatch, id, name, twoFactorEnabled]);

  return {
    resetPartnerTwoFactor,
  };
};
