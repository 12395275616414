import { FormikErrors } from 'formik';
import { SignInRestrictionType } from 'commonComponents/Ban/consts';

export interface BaningClient {
  expiredAt: null;
  reason: string;
  signInRestrictionType: SignInRestrictionType;
  expired: string;
  confirmation: string;
}

export interface BaningClientErrors {
  expiredAt: null;
  reason: string;
  signInRestrictionType: SignInRestrictionType;
  expired: string;
  confirmation: string;
}
export const validate = (
  values: BaningClient,
): FormikErrors<BaningClientErrors> => {
  const errors: FormikErrors<BaningClientErrors> = {};

  if (!values.expiredAt && values.expired === 'withDate') {
    errors.expiredAt = 'Необходимо выбрать дату';
  }

  if (!values.reason) {
    errors.reason = 'Необходимо ввести причину';
  }

  if (values.confirmation.toLowerCase() !== 'заблокировать') {
    errors.confirmation = 'Необходимо ввести подтверждение';
  }

  return errors;
};
