import { useFormikContext } from 'formik';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { CreateAffiliatePromoCodeValues } from '../../../types';
import { AffiliateMediaItemsSteps } from '../../../../AffiliatePromoCodeItem/const';
import { FieldName } from '../../../const';

interface useGoToNextStepResult {
  goToConnectionWithFreespinsAndBonusStep: () => void | false;
}

export const useGoToNextStep = (): useGoToNextStepResult => {
  const { isValid, setTouched } =
    useFormikContext<CreateAffiliatePromoCodeValues>();
  const { updateModalStep } = useUpdateModalStep<AffiliateMediaItemsSteps>();

  const goToConnectionWithFreespinsAndBonusStep = () => {
    // setTouched to show errors
    setTouched({
      [FieldName.Token]: true,
      [FieldName.Usage]: true,
      [FieldName.ReferralLinkId]: true,
    });

    if (isValid) {
      return updateModalStep(
        AffiliateMediaItemsSteps.CreatePromoCodeConnection,
      );
    }

    return false;
  };

  return { goToConnectionWithFreespinsAndBonusStep };
};
