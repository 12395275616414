import { RewardTariffType } from 'commonTypes';
import { rewardTariffMap } from 'src/const';

export const getAcceptAllRewardsPopupMessage = (
  rewardTariffsType: RewardTariffType,
) => {
  const tariffName = rewardTariffMap[rewardTariffsType];

  return `Вы уверены, что хотите подтвердить все ${tariffName} вознаграждения?`;
};
