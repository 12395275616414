import { useMemo } from 'react';
import { getPercentByMaxValue } from 'utils/getPercentByMaxValue';
import { GetPlayerAnalytics } from '../queries/generated/GetPlayerAnalytics';
import { MultiChartData } from '../components/MultiChart/MultiChart';

interface AnalyticsMultiChartData {
  betsWinsChartData: Array<MultiChartData>;
  depositsWithdrawalsChartData: Array<MultiChartData>;
}

export const useGetAnalyticsMultiChartsData = (
  playerAnalyticsData?: GetPlayerAnalytics,
): AnalyticsMultiChartData => {
  const analyticsMultiChartsData = useMemo(() => {
    const result: AnalyticsMultiChartData = {
      betsWinsChartData: [],
      depositsWithdrawalsChartData: [],
    };

    let maxBetWinsAmount = 0;
    let maxDepositWithdrawalAmount = 0;

    if (!playerAnalyticsData) {
      return result;
    }

    playerAnalyticsData.player.accounts.forEach(
      ({ statisticWithFixedCurrency }) => {
        const { betSum, winSum, depositSum, withdrawalSum } =
          statisticWithFixedCurrency;

        maxBetWinsAmount = Math.max(
          maxBetWinsAmount,
          betSum.amount,
          winSum.amount,
        );

        maxDepositWithdrawalAmount = Math.max(
          maxDepositWithdrawalAmount,
          depositSum.amount,
          withdrawalSum.amount,
        );
      },
    );

    playerAnalyticsData.player.accounts.forEach(
      ({ id, statistic, statisticWithFixedCurrency }) => {
        result.betsWinsChartData.push({
          id,
          firstSum: {
            amount: statistic.betSum.amount,
            currency: statistic.betSum.currency,
            percent: getPercentByMaxValue(
              statisticWithFixedCurrency.betSum.amount,
              maxBetWinsAmount,
            ),
          },
          secondSum: {
            amount: statistic.winSum.amount,
            currency: statistic.winSum.currency,
            percent: getPercentByMaxValue(
              statisticWithFixedCurrency.winSum.amount,
              maxBetWinsAmount,
            ),
          },
        });

        result.depositsWithdrawalsChartData.push({
          id,
          firstSum: {
            amount: statistic.depositSum.amount,
            currency: statistic.depositSum.currency,
            percent: getPercentByMaxValue(
              statisticWithFixedCurrency.depositSum.amount,
              maxDepositWithdrawalAmount,
            ),
          },
          secondSum: {
            amount: statistic.withdrawalSum.amount,
            currency: statistic.withdrawalSum.currency,
            percent: getPercentByMaxValue(
              statisticWithFixedCurrency.withdrawalSum.amount,
              maxDepositWithdrawalAmount,
            ),
          },
        });
      },
    );

    return result;
  }, [playerAnalyticsData]);

  return analyticsMultiChartsData;
};
