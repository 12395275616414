import { Action, CompanyAffiliateApplicationState } from './types';
import {
  RESET_AFFILIATES_IN_PROGRESS,
  SET_AFFILIATES_IN_PROGRESS,
  SET_EDITABLE_AFFILIATE_DATA,
} from './actionTypes';

export const reducer = (
  currentState: CompanyAffiliateApplicationState,
  action: Action,
) => {
  const newState = { ...currentState };

  switch (action.type) {
    case SET_AFFILIATES_IN_PROGRESS:
      newState.affiliatesInProgress = action.payload.affiliatesInProgress;

      return newState;

    case RESET_AFFILIATES_IN_PROGRESS:
      newState.affiliatesInProgress = null;

      return newState;

    case SET_EDITABLE_AFFILIATE_DATA:
      newState.editableAffiliate = action.payload.editableAffiliate;

      return newState;

    default:
      return currentState;
  }
};
