import { Dispatch, SetStateAction, useCallback } from 'react';
import { useField } from 'formik';

interface Params {
  name: string;
  isCheckbox?: boolean;
  onChange?: (value: string | Array<string>) => void;
  isValidationOnSubmitCalled: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  checkedOptionsIds: Array<string>;
  setCheckedOptionsIds: Dispatch<SetStateAction<Array<string>>>;
}

export const useGetHandlers = ({
  name,
  isCheckbox,
  onChange,
  isValidationOnSubmitCalled,
  setOpened,
  checkedOptionsIds,
  setCheckedOptionsIds,
}: Params) => {
  const [, meta, helpers] = useField(name);
  const { touched } = meta;
  const { setValue, setTouched } = helpers;

  const setFormValue = useCallback(
    (val: string | Array<string>) => {
      const isDropdownFieldEmpty = Array.isArray(val) && val.length === 0;

      if (!touched) {
        setTouched(true, false);
      }

      // Reset the touched param to hide error if the value is empty (before validation on submit)
      if (isCheckbox && isDropdownFieldEmpty && !isValidationOnSubmitCalled) {
        setTouched(false, false);
      }

      setValue(val, true);
      if (onChange) {
        onChange(val);
      }
    },
    [
      isCheckbox,
      isValidationOnSubmitCalled,
      onChange,
      setTouched,
      setValue,
      touched,
    ],
  );

  const onCheckBoxClick = useCallback(
    (optionId: string) => {
      const newCheckedOptionsIds = checkedOptionsIds.includes(optionId)
        ? checkedOptionsIds.filter((id: string) => id !== optionId)
        : checkedOptionsIds.concat(optionId);

      setCheckedOptionsIds(newCheckedOptionsIds);
      setFormValue(newCheckedOptionsIds);
    },
    [checkedOptionsIds, setCheckedOptionsIds, setFormValue],
  );

  const onOptionSelect = useCallback(
    (optionId: string) => {
      setFormValue(optionId);
      setOpened(false);
    },
    [setFormValue, setOpened],
  );

  return { onCheckBoxClick, onOptionSelect };
};
