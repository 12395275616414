import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { useGenerateReferralLinkCode } from 'queries/generated/GenerateReferralLinkCode';
import { nbsp } from '../../const';

export const useGenerateReferralCode = () => {
  const handleError = (error: ApolloError) => {
    errorToast({
      header: `При${nbsp}загрузке реферального кода произошла ошибка`,
      text: error.message,
      toastId: 'get-referral-code-error',
    });
  };

  const { data: referralCode, loading: referralCodeLoading } =
    useGenerateReferralLinkCode({
      onError: handleError,
    });

  return {
    referralCodeLoading,
    referralCode: referralCode?.generateReferralLinkCode,
  };
};
