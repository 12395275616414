import { useParams } from 'react-router';
import { CompanyPageContainer } from '../../components/CompanyPageContainer';
import { CompanyRewardTariffsBlock } from '../../components/CompanyRewardTariffsBlock';

export const CompanyRewardTariffs = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <CompanyPageContainer companyId={id}>
      <CompanyRewardTariffsBlock
        id={id}
        isFullScreenTable
        shouldReturnToPrevPage
      />
    </CompanyPageContainer>
  );
};
