import { FormikErrors } from 'formik';
import { FieldName } from './const';
import { EditReferralLinkValues } from './types';

export const validate = (
  values: EditReferralLinkValues,
): FormikErrors<EditReferralLinkValues> => {
  const errors: FormikErrors<EditReferralLinkValues> = {};

  if (!values[FieldName.Title]) {
    errors.title = 'Поле не может быть пустым';
  }

  return errors;
};
