import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useClickOutside } from 'ui/ClickOutside';
import {
  AvailableFilter,
  AvailableFilterListItem,
  Filter,
} from 'ui/TableFilter/types';
import { FilterType } from 'ui/TableFilter/const';

interface UseSelectedFilterParams<T> {
  setIsOpenedFiltersList: Dispatch<SetStateAction<boolean>>;
  selectedFilterId: string;
  selectedFilters: Array<Filter>;
  deleteFilter: (id: string) => void;
  toggleFilter: (isOpened: boolean, id: string) => void;
  setFilterValue: (id: string, value: string) => void;
  filter: AvailableFilter<T> | undefined;
}

interface UseSelectedFilterResult {
  handleDeleteFilter: () => void;
  handleClickOutside: (isClickedOutside: boolean) => void;
  handleClick: (id: string) => void;
  submitInput: (
    event: KeyboardEvent<HTMLInputElement>,
    filterTyp: FilterType,
  ) => void;
  setInputValue: Dispatch<SetStateAction<string>>;
  inputValue: string;
  optionsFilter: Array<AvailableFilterListItem> | undefined;
}

export const useSelectedFilter = <T extends string>({
  selectedFilterId,
  setIsOpenedFiltersList,
  selectedFilters,
  deleteFilter,
  setFilterValue,
  filter,
  toggleFilter,
}: UseSelectedFilterParams<T>): UseSelectedFilterResult => {
  const [inputValue, setInputValue] = useState('');
  const options = filter?.list;
  const [optionsFilter, setOptionFilter] = useState(options);

  const handleClick = useCallback(
    async (id: string) => {
      setFilterValue(selectedFilterId, id);

      setIsOpenedFiltersList(true);
    },
    [setFilterValue, selectedFilterId, setIsOpenedFiltersList],
  );

  const submitInput = useCallback(
    (event: KeyboardEvent<HTMLInputElement>, filterType: FilterType) => {
      if (filterType === FilterType.Input && event.code === 'Enter') {
        event.preventDefault();
        handleClick(inputValue);
      }
    },
    [handleClick, inputValue],
  );

  const handleDeleteFilter = useCallback(() => {
    deleteFilter(selectedFilterId);

    setIsOpenedFiltersList(true);
  }, [deleteFilter, selectedFilterId, setIsOpenedFiltersList]);

  const handleClickOutside = useClickOutside(() => {
    const currentFilter = selectedFilters.find(
      (item) => item.selectedFilterId === selectedFilterId,
    );

    if (!currentFilter?.selectedFilterValue) {
      deleteFilter(selectedFilterId);
    } else if (currentFilter?.isOpened) {
      toggleFilter(false, selectedFilterId);
    }
  });

  useEffect(() => {
    if (filter?.type === FilterType.List) {
      setOptionFilter(() =>
        options?.filter((item) =>
          item.textForSearch
            .toString()
            .toLowerCase()
            .includes(inputValue.toLowerCase()),
        ),
      );
    }
  }, [filter?.type, inputValue, options]);

  return {
    handleDeleteFilter,
    handleClickOutside,
    handleClick,
    inputValue,
    setInputValue,
    submitInput,
    optionsFilter,
  };
};
