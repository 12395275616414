import { CardIconColor, CardIconConfig } from 'ui/Card/CardIcon';
import {
  faCheckCircle,
  faHourglassEmpty,
  faCancel,
  faSpinner,
  faHandSparkles,
  faTicket,
  faSlotMachine,
} from '@fortawesome/pro-solid-svg-icons';
import { FreespinStatus, PromoCode } from 'generatedGraphql';
import { FreespinTypeTitle } from './const';
import { FreespinData } from './components/FreespinsTable/queries/generated/FreespinData';

export const getFreespinStatusIconConfig = (
  freespinStatus: FreespinStatus,
): CardIconConfig => {
  switch (freespinStatus) {
    case FreespinStatus.Active:
      return {
        icon: faSpinner,
        iconColor: CardIconColor.BLUE,
      };
    case FreespinStatus.Won:
      return {
        icon: faCheckCircle,
        iconColor: CardIconColor.GREEN,
      };
    case FreespinStatus.Canceled:
      return {
        icon: faCancel,
        iconColor: CardIconColor.RED,
      };
    case FreespinStatus.Expired:
      return {
        icon: faCancel,
        iconColor: CardIconColor.RED,
      };
    case FreespinStatus.Pending:
      return {
        icon: faHourglassEmpty,
        iconColor: CardIconColor.ORANGE,
      };
    default:
      return {
        icon: faCheckCircle,
        iconColor: CardIconColor.GREEN,
      };
  }
};

interface FreespinType {
  title: FreespinTypeTitle;
  iconConfig: CardIconConfig;
}

export const getFreespinType = (
  createdAutomatically: boolean,
  promoCode?: PromoCode | null,
): FreespinType => {
  let title: FreespinTypeTitle;
  let iconConfig: CardIconConfig;

  if (createdAutomatically) {
    title = FreespinTypeTitle.ForRegistration;
    iconConfig = {
      icon: faHandSparkles,
      iconColor: CardIconColor.VIOLET,
    };
  } else if (promoCode) {
    title = FreespinTypeTitle.ByPromoCode;
    iconConfig = {
      icon: faTicket,
      iconColor: CardIconColor.INDIGO,
    };
  } else {
    title = FreespinTypeTitle.FromCasino;
    iconConfig = {
      icon: faSlotMachine,
      iconColor: CardIconColor.BLUE,
    };
  }

  return { title, iconConfig };
};

export const getFreespinTypeData = <
  T extends Pick<FreespinData, 'createdAutomatically' | 'promoCode'>,
>(
  freespin: T,
): [string, CardIconConfig, string?] => {
  const { createdAutomatically, promoCode } = freespin;
  const { title, iconConfig } = getFreespinType(
    createdAutomatically,
    promoCode,
  );

  return [title, iconConfig, promoCode?.token];
};
