export const generateQueryString = (
  urlSearchParams: URLSearchParams,
  queryParams?: Array<string>,
) => {
  if (!queryParams?.length) return '';

  const transformedQueryParams = queryParams.reduce((acc, param) => {
    const value = urlSearchParams.get(param);

    return value !== null ? { ...acc, [param]: value } : acc;
  }, {});

  const queryString = Object.entries(transformedQueryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return queryString ? `?${queryString}` : '';
};
