import { UserPrivilege } from 'generatedUserPrivilege';
import { useCallback } from 'react';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetExportPartnerClickIdReportLazyQuery } from '../queries/generated/GetExportPartnerClickIdReport';
import { useExportClickIdReportHandlers } from '../../../helpers/useExportClickIdReportHandlers';

interface Params {
  partnerId: string;
}

export const useGetExportPartnerClickIdReport = ({ partnerId }: Params) => {
  const period = useGetPeriodOfStatistic();
  const {
    handleCompleteExportClickIdReport,
    handleErrorExportClickIdReport,
    downloadClickIdReportFileLoading,
  } = useExportClickIdReportHandlers();

  const [exportReport, { loading: getExportClickIdReportLinkLoading }] =
    useGetExportPartnerClickIdReportLazyQuery({
      onCompleted: (data) => {
        handleCompleteExportClickIdReport(data.partner.exportClickIdReport);
      },
      onError: handleErrorExportClickIdReport,
    });

  const getClickIdReport = useCallback(() => {
    exportReport({
      variables: {
        id: partnerId,
        period,
      },
    });
  }, [exportReport, partnerId, period]);

  return {
    getReport: getClickIdReport,
    loading:
      getExportClickIdReportLinkLoading || downloadClickIdReportFileLoading,
    privilege: UserPrivilege.ExportPartnerClickIdReport,
  };
};
