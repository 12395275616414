import { useCpaRewardTariffModal } from '../../../hooks/useCpaRewardTariffModal';
import { CpaRewardTariffData } from '../../../../../queries/generated/CpaRewardTariffData';

export const useRewardTariffsTable = () => {
  const openModal = useCpaRewardTariffModal();

  const handleRowClick = (
    cpaRewardTariff: CpaRewardTariffData,
    initiatorId: string,
  ) => {
    openModal({
      cpaRewardTariff,
      initiatorId,
    });
  };

  return { handleRowClick };
};
