import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { CompanyBasicData } from 'queries/generated/CompanyBasicData';
import { ResetCompanyTwoFactor } from '../components/ResetCompanyTwoFactor';

interface UseOpenResetCompanyTwoFactorModalParams {
  company: CompanyBasicData;
}

interface OpenResetCompanyTwoFactorModalResult {
  openResetCompanyTwoFactorModal: () => void;
}

export const useOpenResetCompanyTwoFactorModal = ({
  company,
}: UseOpenResetCompanyTwoFactorModalParams): OpenResetCompanyTwoFactorModalResult => {
  const { id, name, twoFactorEnabled } = company;
  const { dispatch } = useContext(ModalContext);

  const openResetCompanyTwoFactorModal = useCallback(() => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        content: (
          <ResetCompanyTwoFactor
            companyId={id}
            twoFactorEnabled={twoFactorEnabled}
          />
        ),
        title: 'Управление 2FA',
        subTitle: `${name} #${id}`,
      },
    });
  }, [dispatch, id, name, twoFactorEnabled]);

  return {
    openResetCompanyTwoFactorModal,
  };
};
