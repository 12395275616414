import { getCountByWindowHeight, Heights } from 'utils/getCountByWindowHeight';
import { useGetPlayerMessagesBlockLazyQuery } from '../components/MessagesTable/queries/generated/GetPlayerMessagesBlock';
import { useGetPlayerMessagesLazyQuery } from '../components/MessagesTable/queries/generated/GetPlayerMessages';

const countPerPage = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetMessagesByTableSize = (
  playerId: string,
  isFullTable: boolean,
) => {
  const [
    loadBlockMessages,
    {
      data: blockData,
      loading: blockLoading,
      error: blockError,
      fetchMore: blockFetchMore,
      refetch: blockRefetch,
    },
  ] = useGetPlayerMessagesBlockLazyQuery({
    variables: {
      playerId,
      first: countPerPage,
    },
  });

  const [
    loadPageMessages,
    {
      data: pageData,
      loading: pageLoading,
      error: pageError,
      fetchMore: pageFetchMore,
      refetch: pageRefetch,
    },
  ] = useGetPlayerMessagesLazyQuery({
    variables: {
      playerId,
      first: getCountByWindowHeight(cellHeight),
    },
  });

  const loadMessages = isFullTable ? loadPageMessages : loadBlockMessages;
  const data = isFullTable ? pageData : blockData;
  const loading = isFullTable ? pageLoading : blockLoading;
  const error = isFullTable ? pageError : blockError;
  const fetchMore = isFullTable ? pageFetchMore : blockFetchMore;
  const refetch = isFullTable ? pageRefetch : blockRefetch;

  return {
    loadMessages,
    data,
    loading,
    error,
    fetchMore,
    refetch,
  };
};
