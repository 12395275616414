import NowrapCell from 'ui/Table/components/NowrapCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import mayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { getWithdrawalStatusIconConfig } from 'src/helpers';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { formatSumWithCurrency } from 'src/utils/accounting';
import {
  playerPaymentNameMap,
  twoEmDash,
  withdrawalStatusMap,
} from 'src/const';
import { MultilineCellWithLink } from 'src/ui/Table/components/MultilineCellWithLink';
import { BoldSumCellByCompare } from 'ui/Table/components/BoldSumCellByCompare';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { ColumnId, mapColumnIdToName, columnsIds } from '../const';
import { WithdrawalListData } from '../../../queries/generated/WithdrawalListData';
import { getActorTypeByTypeName, getRouteByTypeName } from '../../../helpers';

const columnHelper = createColumnHelper<WithdrawalListData>();

const columnsSource: ColumnsSource<WithdrawalListData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.Actor, {
    id: ColumnId.Actor,
    header: mapColumnIdToName[ColumnId.Actor],
    cell: ({ row }) => {
      const { actor } = row.original;

      return (
        <MultilineCellWithLink
          isOpenInNewWindow
          title={actor.name}
          route={getRouteByTypeName(actor)}
          subTitle={getActorTypeByTypeName(actor)}
        />
      );
    },
  }),
  columnHelper.accessor(ColumnId.Account, {
    id: ColumnId.Account,
    header: mapColumnIdToName[ColumnId.Account],
    cell: ({ row }) => {
      const balance = row.original.account?.balance;

      // todo When task https://eyeconweb.atlassian.net/browse/APN-906 will be resolved - remove mayBeNullCell
      return mayBeNullCell(
        balance && formatSumWithCurrency(balance.amount, balance.currency),
        twoEmDash,
      );
    },
  }),
  columnHelper.accessor(ColumnId.PaymentMethod, {
    id: ColumnId.PaymentMethod,
    header: mapColumnIdToName[ColumnId.PaymentMethod],
    cell: ({ row }) => playerPaymentNameMap[row.original.paymentMethod.name],
  }),
  columnHelper.accessor(ColumnId.AccountName, {
    id: ColumnId.AccountName,
    header: mapColumnIdToName[ColumnId.AccountName],
    cell: ({ row }) => {
      const { paymentMethod } = row.original;

      return paymentMethod.account || paymentMethod.maskedAccount;
    },
  }),
  columnHelper.accessor(ColumnId.Bank, {
    id: ColumnId.Bank,
    header: mapColumnIdToName[ColumnId.Bank],
    cell: ({ row }) =>
      mayBeNullCell(row.original.paymentMethod.bank, twoEmDash),
  }),
  columnHelper.accessor(ColumnId.Sum, {
    id: ColumnId.Sum,
    header: mapColumnIdToName[ColumnId.Sum],
    cell: ({ row }) => {
      const { amount, currency } = row.original.sum;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(ColumnId.PaidSum, {
    id: ColumnId.PaidSum,
    header: mapColumnIdToName[ColumnId.PaidSum],
    cell: ({ row }) => {
      const { paidSum, sum } = row.original;

      return <BoldSumCellByCompare renderedSum={paidSum} comparedSum={sum} />;
    },
  }),
  columnHelper.accessor(ColumnId.Status, {
    id: ColumnId.Status,
    header: mapColumnIdToName[ColumnId.Status],
    cell: ({ row }) => {
      const { status } = row.original;

      return MultilineCellWithIcon({
        title: withdrawalStatusMap[status],
        iconConfig: getWithdrawalStatusIconConfig(status),
      });
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(ColumnId.UpdatedAt, {
    id: ColumnId.UpdatedAt,
    header: mapColumnIdToName[ColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetWithdrawalsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
