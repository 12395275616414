import { SumFlowDirection } from 'generatedGraphql';

const mapDirectionToSign = (
  direction?: SumFlowDirection | null,
): string | undefined => (direction === SumFlowDirection.Credit ? '-' : '+');

export const addSignToString = (
  str: string,
  direction?: SumFlowDirection | null,
) => (direction ? `${mapDirectionToSign(direction)}\u00a0${str}` : str);
