import { ApolloError } from '@apollo/client';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useModalManageData } from 'ui/Modal';
import { Currency, PaymentMethodName, PaymentProcessor } from 'commonTypes';
import { Option } from 'ui/Dropdown';
import { useMemo, useState } from 'react';
import { Sum } from 'generatedGraphql';
import { useAuth } from 'commonComponents/Auth';
import { paymentProcessorMap } from 'src/const';
import { useGetWithdrawalPayout } from '../queries/generated/GetWithdrawalPayout';
import { useGetPaymentProcessors } from '../queries/generated/GetPaymentProcessors';

interface Value {
  providerOptions: Array<Option>;
  paymentProcessorsLoading: boolean;
  paymentProcessorsError?: ApolloError;
  paymentMethodName?: PaymentMethodName;
  paymentMethodAccount?: string;
  paymentMethodMaskedAccount?: string;
  paymentAmount?: number;
  setPaymentAmount: (convertedAmount: number) => void;
  paymentRemainingAmount?: number;
  setPaymentRemainingAmount: (convertedAmount: number) => void;
  paymentCurrency?: Currency;
  setPaymentCurrency: (paymentCurrency: Currency) => void;
  paymentProvider?: PaymentProcessor;
  setPaymentProvider: (paymentProvider: PaymentProcessor) => void;
  isCryptoPaymentMethod?: boolean;
}

interface Params {
  withdrawalId: string;
  remainingSum?: Sum;
}

export const useAddPayment = ({
  withdrawalId,
  remainingSum,
}: Params): Value => {
  const { privileges } = useAuth();

  const [paymentAmount, setPaymentAmount] = useState(remainingSum?.amount);
  const [paymentRemainingAmount, setPaymentRemainingAmount] = useState(
    remainingSum?.amount,
  );
  const [paymentCurrency, setPaymentCurrency] = useState(
    remainingSum?.currency,
  );
  const [paymentProvider, setPaymentProvider] = useState<
    PaymentProcessor | undefined
  >(undefined);

  const {
    data,
    loading: getWithdrawalLoading,
    error: getWithdrawalError,
  } = useGetWithdrawalPayout({
    variables: {
      id: withdrawalId,
      viewPaymentMethodAccount: privileges.areEveryGranted({
        privileges: [UserPrivilege.ViewPaymentmethodAccount],
      }),
    },
  });

  const paymentMethod = data?.withdrawal.paymentMethod;
  const paymentMethodName = paymentMethod?.name;
  const paymentMethodAccount = paymentMethod?.account;
  const paymentMethodMaskedAccount = paymentMethod?.maskedAccount;
  const isCryptoPaymentMethod = paymentMethod?.crypto;

  const {
    data: paymentProcessorsResult,
    loading: paymentProcessorsLoading,
    error: paymentProcessorsError,
  } = useGetPaymentProcessors();
  const paymentProcessors = paymentProcessorsResult?.paymentProcessors;

  const providerOptions: Array<Option> = useMemo(() => {
    if (!paymentProcessors) {
      return [];
    }

    return paymentProcessors.map((processor: PaymentProcessor) => ({
      label: paymentProcessorMap[processor] || processor,
      id: processor,
    }));
  }, [paymentProcessors]);

  useModalManageData({
    loading: getWithdrawalLoading,
    hasData: !!data,
    errorConfig: {
      error: getWithdrawalError,
      toastId: 'get-withdrawal-payout',
    },
  });

  return {
    providerOptions,
    paymentProcessorsLoading,
    paymentProcessorsError,
    paymentMethodName,
    paymentMethodAccount,
    paymentMethodMaskedAccount,
    paymentRemainingAmount,
    setPaymentRemainingAmount,
    paymentAmount,
    setPaymentAmount,
    paymentCurrency,
    setPaymentCurrency,
    paymentProvider,
    setPaymentProvider,
    isCryptoPaymentMethod,
  };
};
