import { FC, SyntheticEvent } from 'react';
import { CardIcon } from 'ui/Card/CardIcon';
import { faClone } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';
import Button from 'ui/Button';
import { copyToClipboard } from 'utils/copyToClipboard';
import styles from './CopyButton.module.scss';

interface Props {
  value: string;
  toastMessage: string;
  className?: string;
}

export const CopyButton: FC<Props> = ({ value, toastMessage, className }) => {
  const handleCopyClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    copyToClipboard(value);
    toast.success(toastMessage);
  };

  return (
    <Button linkStyled onClick={handleCopyClick} className={className}>
      <CardIcon className={styles.iconContainer} config={{ icon: faClone }} />
    </Button>
  );
};
