import FormRow from 'ui/FormRow';
import { ToggleBlock } from 'commonComponents/ToggleBlock';
import Input from 'ui/Input';
import { useFormikContext } from 'formik';
import { INVALID_INTEGER_CHARS } from 'src/utils/blockInvalidChars';
import { CurrencyDropdown } from 'commonComponents/CurrencyDropdown';
import { GameDropdown } from 'commonComponents/GameDropdown';
import { CreatePromoCodeValues } from '../../../../types';
import { useToggleBlockHandlers } from '../../hooks/useToggleBlockHandlers';
import { FieldName, mapCreatePromoCodeFieldToTitle } from '../../../../const';

const SEARCH_MIN_LENGTH = 3;

export const FreespinToggleBlock = () => {
  const { values } = useFormikContext<CreatePromoCodeValues>();
  const { onFreespinBlockToggleHandler } = useToggleBlockHandlers();
  const { setFieldValue } = useFormikContext<CreatePromoCodeValues>();

  return (
    <FormRow isWide>
      <ToggleBlock
        name={FieldName.ConnectionWithFreespins}
        label={
          mapCreatePromoCodeFieldToTitle[FieldName.ConnectionWithFreespins]
        }
        isOpen={values[FieldName.ConnectionWithFreespins]}
        onToggle={(isChecked) => {
          onFreespinBlockToggleHandler(isChecked);
        }}
      >
        <FormRow isWide>
          <GameDropdown
            name={FieldName.FreespinGameId}
            label={mapCreatePromoCodeFieldToTitle[FieldName.FreespinGameId]}
            defaultValue={values[FieldName.FreespinGameName]}
            searchMinLength={SEARCH_MIN_LENGTH}
            onChange={(_, gameName) => {
              setFieldValue(FieldName.FreespinGameName, gameName);
            }}
          />
        </FormRow>

        <FormRow>
          <CurrencyDropdown
            name={FieldName.FreespinCurrency}
            label={mapCreatePromoCodeFieldToTitle[FieldName.FreespinCurrency]}
          />
          <Input.Number
            name={FieldName.FreespinCount}
            label={mapCreatePromoCodeFieldToTitle[FieldName.FreespinCount]}
            placeholder="Введите значение"
            invalidChars={INVALID_INTEGER_CHARS}
            customMaxLength={3}
          />
        </FormRow>

        <FormRow>
          <Input.Number
            name={FieldName.FreespinWager}
            label={mapCreatePromoCodeFieldToTitle[FieldName.FreespinWager]}
            placeholder="Введите значение"
            invalidChars={INVALID_INTEGER_CHARS}
            customMaxLength={2}
          />
          <Input.Number
            name={FieldName.FreespinMinBalance}
            label={mapCreatePromoCodeFieldToTitle[FieldName.FreespinMinBalance]}
            placeholder="Введите значение"
            customMaxLength={15}
            decimalScale={1}
          />
        </FormRow>
      </ToggleBlock>
    </FormRow>
  );
};
