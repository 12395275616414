import { useSearchParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import { generateQueryString } from '../helpers/generateQueryString';

interface GeneratePathParams {
  originalPath: string;
  params?: { [p: string]: string | null } | undefined;
  queryParams?: Array<string>;
}

export const useGeneratePathWithStatisticsQueryParams = () => {
  const [searchParams] = useSearchParams();

  const generatePathWithStatisticsQueryParams = ({
    originalPath,
    params,
    queryParams,
  }: GeneratePathParams) => {
    const urlSearchParams = new URLSearchParams(searchParams);

    const queryString = generateQueryString(urlSearchParams, queryParams);

    return `${generatePath(originalPath, params)}${queryString}`;
  };

  return {
    generatePathWithStatisticsQueryParams,
  };
};
