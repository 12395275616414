import { FormikErrors } from 'formik';

export interface DeletingClient {
  reason: string;
  confirmation: string;
}

export interface DeletingClientErrors {
  reason: string;
  confirmation: string;
}
export const validate = (
  values: DeletingClient,
): FormikErrors<DeletingClientErrors> => {
  const errors: FormikErrors<DeletingClientErrors> = {};

  if (!values.reason) {
    errors.reason = 'Необходимо ввести причину';
  }

  if (values.confirmation.toLowerCase() !== 'удалить') {
    errors.confirmation = 'Необходимо ввести подтверждение';
  }

  return errors;
};
