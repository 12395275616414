import { FC } from 'react';

import { CurrencyDropdown as CommonCurrencyDropdown } from 'commonComponents/CurrencyDropdown';
import { Formik } from 'formik';
import { STATISTIC_CURRENCY } from 'src/const';
import { useHandleChange } from './hooks/useHandleChange';
import { useGetCurrency } from '../../../../hooks/useGetCurrency';
import styles from './CurrencyDropdownWithFormik.module.scss';

export const CurrencyDropdownWithFormik: FC = () => {
  const handleChange = useHandleChange();
  const { currency } = useGetCurrency();

  return (
    <Formik
      initialValues={{ [STATISTIC_CURRENCY]: currency }}
      onSubmit={() => {}}
    >
      <CommonCurrencyDropdown
        name={STATISTIC_CURRENCY}
        label=""
        onChange={handleChange}
        withSearch={false}
        className={styles.currencyDropdownContainer}
        wrapperClassName={styles.currencyDropdownWrapper}
      />
    </Formik>
  );
};
