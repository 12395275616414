import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useSearchContext } from 'src/contexts/SearchContext';
import { getCountByWindowHeight, Heights } from 'utils/getCountByWindowHeight';
import { useGetPartnersLazyQuery } from '../queries/generated/GetPartners';
import { SEARCH_INPUT_HEIGHT } from '../../../const';

const cellHeight = Heights.defaultCellHeight;
const COUNT_PER_PAGE = 10;

export const usePartnersList = () => {
  const { searchValue } = useSearchContext();

  const baseVariables = {
    first: getCountByWindowHeight(cellHeight, SEARCH_INPUT_HEIGHT),
    search: '',
  };

  const [loadPartnersVariables, setLoadPartnersVariables] =
    useState(baseVariables);

  useEffect(() => {
    setLoadPartnersVariables({
      ...baseVariables,
      search: searchValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loadPartnersList, { data, loading, error, fetchMore, refetch }] =
    useGetPartnersLazyQuery({
      variables: loadPartnersVariables,
    });

  useBlockComponentState({
    loadData: loadPartnersList,
    loading,
    error,
  });

  const partnersList = useMemo(
    () => data?.partners?.edges.map(({ node }) => node),
    [data?.partners?.edges],
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: data?.partners?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [data?.partners?.pageInfo.endCursor, fetchMore],
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.partners?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  return {
    refetchPartnersList: refetch,
    partnersList,
    loadPartnersList: () =>
      loadPartnersList({
        variables: { ...loadPartnersVariables, search: searchValue },
      }),
  };
};
