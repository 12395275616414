import {
  Heights,
  getCountByWindowHeight,
} from 'src/utils/getCountByWindowHeight';
import { useCallback } from 'react';
import { useGetPartnerReferralLinkMediaItemsLazyQuery } from '../queries/generated/GetPartnerReferralLinkMediaItems';
import { useGetPartnerReferralLinkMediaItemsBlockLazyQuery } from '../queries/generated/GetPartnerReferralLinkMediaItemsBlock';
import { COUNT_PER_PAGE } from '../const';

interface UseGetReferralLinkMediaItemsByTableSizeParams {
  isFullScreenTable: boolean;
  partnerId: string;
}
export const useGetReferralLinkMediaItemsByTableSize = ({
  partnerId,
  isFullScreenTable,
}: UseGetReferralLinkMediaItemsByTableSizeParams) => {
  const countPerPage = isFullScreenTable
    ? getCountByWindowHeight(Heights.defaultCellHeight)
    : COUNT_PER_PAGE;

  const currentUseGetPartnerReferralLinkMediaItems = isFullScreenTable
    ? useGetPartnerReferralLinkMediaItemsLazyQuery
    : useGetPartnerReferralLinkMediaItemsBlockLazyQuery;

  const [
    loadPartnerReferralLinkMediaItems,
    { data, loading, error, fetchMore, refetch },
  ] = currentUseGetPartnerReferralLinkMediaItems({
    variables: {
      partnerId,
      first: countPerPage,
    },
  });

  const referralLinkMediaItemsData = data?.partner.referralLinkMediaItems;
  const endCursor = referralLinkMediaItemsData?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [fetchMore, endCursor],
  );

  return {
    referralLinkMediaItemsData,
    loadPartnerReferralLinkMediaItems,
    loading,
    error,
    refetch,
    fetchMoreCallback,
  };
};
