import { toast } from 'react-toastify';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import errorToast from 'ui/Toast/ErrorToast';
import { ApolloError } from '@apollo/client';
import { useUpdatePartnerReferralLinkMediaItemName } from '../queries/generated/UpdatePartnerReferralLinkMediaItemName';
import { AboutReferralLinkSteps, FieldName } from '../const';
import { EditReferralLinkValues } from '../types';

export const useUpdateReferralLinkName = () => {
  const { updateModalStep } = useUpdateModalStep<AboutReferralLinkSteps>();

  const [updateReferralLinkMediaItemName, { error, loading }] =
    useUpdatePartnerReferralLinkMediaItemName();

  const handleError = (editError: ApolloError) => {
    errorToast({
      header: 'Медиа элемент не изменён',
      text: editError.message,
      toastId: 'edit-partner-referral-link-error',
    });
  };

  const handleComplete = () => {
    toast.success('Медиа элемент изменён');

    updateModalStep(AboutReferralLinkSteps.BaseStep);
  };

  const handleUpdateReferralLinkName = (
    values: EditReferralLinkValues,
    referralLinkId: string,
  ) => {
    updateReferralLinkMediaItemName({
      variables: {
        input: {
          id: referralLinkId,
          name: values[FieldName.Title],
        },
      },
      onError: handleError,
      onCompleted: handleComplete,
    });
  };

  return {
    handleUpdateReferralLinkName,
    updateReferralLinkNameLoading: loading,
    updateReferralLinkNameError: error,
  };
};
