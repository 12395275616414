import { FormikErrors } from 'formik';
import { AffiliatesValues } from './types';
import { AddAffiliateField } from './const';

export const validate = (
  values: AffiliatesValues,
): FormikErrors<Record<string, string>> => {
  const errors: FormikErrors<Record<string, string>> = {};

  values.affiliates.forEach((affiliate, index) => {
    if (!affiliate.name) {
      errors[`${AddAffiliateField.Name}-${index}`] =
        'Поле не может быть пустым';
    }
  });

  return errors;
};
