import { ReactElement } from 'react';
import { CreatePromoCodeModalSteps } from '../const';
import { CreatePromoCodeFormBaseStep } from '../components/CreatePromoCodeFormBaseStep';
import { CreatePromoCodeFormSecondStep } from '../components/CreatePromoCodeFormSecondStep';
import { CreatePromoCodeFormConfirmStep } from '../components/CreatePromoCodeFormConfirmStep';

export const getCreatePromoCodeFormByStep = (
  step?: CreatePromoCodeModalSteps | null,
): ReactElement | null => {
  switch (step) {
    case CreatePromoCodeModalSteps.BaseStep:
      return <CreatePromoCodeFormBaseStep />;
    case CreatePromoCodeModalSteps.ConnectionWithFreespinsAndBonus:
      return <CreatePromoCodeFormSecondStep />;
    case CreatePromoCodeModalSteps.ConfirmCreation:
      return <CreatePromoCodeFormConfirmStep />;
    default:
      return null;
  }
};
