import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { toast } from 'react-toastify';
import { ListAllOperations } from 'generatedGraphql';
import { useDeletePostbackDisabledNotification as useDeletePostbackDisabledNotificationMutation } from '../queries/generated/DeletePostbackDisabledNotification';

export const useDeletePostbackDisabledNotification = (
  postbackDisabledNotificationId: string,
) => {
  const handleError = (error: ApolloError) => {
    errorToast({
      header: 'Уведомление о выключеном постбеке не было удалено',
      text: error.message,
      toastId: 'delete-postback-disabled-notification-error',
    });
  };

  const handleComplete = () => {
    toast.success('Уведомление о выключеном постбеке удалено');
  };

  const [deletePostbackDisabledNotification, { loading }] =
    useDeletePostbackDisabledNotificationMutation({
      variables: { input: { id: postbackDisabledNotificationId } },
      onError: handleError,
      onCompleted: handleComplete,
      refetchQueries: [
        ListAllOperations.Query.GetPostbackDisabledNotifications,
      ],
    });

  return {
    deletePostbackDisabledNotification,
    loading,
  };
};
