import { useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { useUpdateRevShareRewardTariff } from '../queries/generated/UpdateRevShareRewardTariff';
import { EditTariffValues } from '../types';

interface Params {
  tariffId: string;
}

export const useEditTariffModal = ({ tariffId }: Params) => {
  const { dispatch } = useContext(ModalContext);
  const [updateTariff, { error, loading }] = useUpdateRevShareRewardTariff();

  const handleError = () => {
    toast.error('Тариф не изменён');
  };

  const handleComplete = () => {
    dispatch({ type: ModalActions.Close });
    toast.success('Тариф изменён');
  };

  const onEditTariff = (values: EditTariffValues) => {
    updateTariff({
      variables: {
        input: {
          id: tariffId,
          percent: values.percent,
        },
      },
      onError: handleError,
      onCompleted: handleComplete,
    });
  };

  return { onEditTariff, loading, error };
};
