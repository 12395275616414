import { FC } from 'react';
import ClientHeader from 'commonComponents/ClientHeader';
import { CompanyBasicData } from 'queries/generated/CompanyBasicData';
import { useCompanyPrivileges } from './hooks/useCompanyPrivileges';
import { useCompanyMarkers } from './hooks/useCompanyMarkers';
import { useCompanyEditNote } from './hooks/useCompanyEditNote';
import { useCompanyEditNameModal } from './hooks/useCompanyEditNameModal';

interface Props {
  company: CompanyBasicData;
}

export const CompanyHeader: FC<Props> = ({ company }) => {
  const { id, name, note } = company;

  const { canViewCompanyNote, canUpdateCompanyNote, canUpdateCompanyName } =
    useCompanyPrivileges();

  const { openCompanyEditNameModal } = useCompanyEditNameModal({ id, name });

  const {
    updateCompanyNoteHandler,
    updateCompanyNoteResult,
    updateCompanyNoteLoading,
  } = useCompanyEditNote({ id });

  const markers = useCompanyMarkers({ company });

  return (
    <ClientHeader
      clientId={id}
      clientName={name}
      noteText={note?.text}
      markers={markers}
      canUpdateNote={canUpdateCompanyNote}
      canResetClientName={canUpdateCompanyName}
      canViewClientNote={canViewCompanyNote}
      updateNoteHandler={updateCompanyNoteHandler}
      updateNoteResult={updateCompanyNoteResult}
      updateNoteLoading={updateCompanyNoteLoading}
      resetClientName={openCompanyEditNameModal}
    />
  );
};
