import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { PartnerApplicationData } from '../queries/generated/PartnerApplicationData';
import { PartnerApplicationModal } from '../components/PartnerApplicationModal';
import { PartnerApplicationModalSteps } from '../const';

interface OpenModalParams {
  partnerApplication: PartnerApplicationData;
  initiatorId: string;
}

type UseCpaRewardTariffModalResult = (params: OpenModalParams) => void;

export const usePartnerApplicationModal = (): UseCpaRewardTariffModalResult => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const openModal = useCallback(
    async ({ partnerApplication: { id }, initiatorId }: OpenModalParams) => {
      modalDispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${id}`,
          subTitle: 'Заявка партнёра',
          content: <PartnerApplicationModal partnerApplicationId={id} />,
          step: PartnerApplicationModalSteps.BaseStep,
          initiatorId,
        },
      });
    },
    [modalDispatch],
  );

  return openModal;
};
