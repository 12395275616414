import { Form, Formik } from 'formik';
import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import Input from 'ui/Input';
import Error from 'ui/Error';
import { INVALID_INTEGER_CHARS } from 'src/utils/blockInvalidChars';
import { nbsp } from 'src/const';
import { validate } from './validation';
import { useEditTariffModal } from './hooks/useEditTariffModal';
import { FieldName } from './const';
import { EditTariffValues } from './types';

interface Props {
  tariffId: string;
  percent: number;
}

export const EditTariffModal: FC<Props> = ({ tariffId, percent }) => {
  const initialValues: EditTariffValues = {
    [FieldName.Percent]: percent,
  };

  const { onEditTariff, loading, error } = useEditTariffModal({
    tariffId,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onEditTariff}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <Input.Number
              invalidChars={INVALID_INTEGER_CHARS}
              placeholder={`Укажите процент от${nbsp}0 до${nbsp}99`}
              name={FieldName.Percent}
              label="Процент вознаграждения"
            />
          </FormRowsWrapper>
          <Button
            size={ButtonSize.Large}
            theme={ButtonTheme.Process}
            type="submit"
            isLoading={loading}
            disabled={!isValid || !dirty}
          >
            Сохранить
          </Button>
        </Form>
      )}
    </Formik>
  );
};
