import { ClientData } from 'commonComponents/ClientDataBlock/types';
import { AffiliatePlayerAccountStatus } from 'generatedGraphql';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { contactForCommunicationTypeMap, nbsp } from 'src/const';
import { GetCompanyAdditionalData } from '../queries/generated/GetCompanyAdditionalData';
import { PlayerAccountManagement } from '../components/PlayerAccountManagement';

interface GetCompanyDataConfigParams {
  data: GetCompanyAdditionalData | undefined;
  onCompanyIdentitiesEdit: () => void;
  onCompanyPersonalDataEdit: () => void;
  isCompanyIdentitiesEditBtnVisible: boolean;
  isCompanyPersonalDataEditBtnVisible: boolean;
  hasPrivilegeToDeclinePlayerAccount: boolean;
  onPlayerAccountEdit: () => void;
}

export const getCompanyDataConfig = ({
  data,
  onCompanyIdentitiesEdit,
  onCompanyPersonalDataEdit,
  isCompanyIdentitiesEditBtnVisible,
  isCompanyPersonalDataEditBtnVisible,
  hasPrivilegeToDeclinePlayerAccount,
  onPlayerAccountEdit,
}: GetCompanyDataConfigParams): ClientData | null => {
  if (!data) return null;

  const {
    company: {
      id,
      email,
      createdAt,
      lastLoginAt,
      trafficSource,
      website,
      contactForCommunication: { type, value },
      playerAccount,
    },
  } = data;

  const rowsList: ClientData = [
    {
      title: 'Данные входа',
      isEditButtonVisible: isCompanyIdentitiesEditBtnVisible,
      onEdit: onCompanyIdentitiesEdit,
      rows: [
        {
          title: 'Почта',
          value: email,
        },
        {
          title: 'Дата создания',
          value: createdAt && formatDate(createdAt, DateFormat.DefaultDateOld),
        },
        {
          title: 'Последний вход',
          value: lastLoginAt && formatDate(lastLoginAt, DateFormat.FullDate),
        },
      ],
    },
    {
      title: 'Персональные данные',
      isEditButtonVisible: isCompanyPersonalDataEditBtnVisible,
      onEdit: onCompanyPersonalDataEdit,
      rows: [
        {
          title: 'Вебсайт',
          value: website,
        },
        {
          title: `Контакт для${nbsp}связи`,
          value: contactForCommunicationTypeMap[type],
        },
        {
          title: `Номер/имя пользователя`,
          value,
        },
        {
          title: 'Источник трафика',
          value: trafficSource,
        },
      ],
    },
  ];

  if (playerAccount) {
    const isEditPlayerAccountButtonVisible =
      playerAccount.status === AffiliatePlayerAccountStatus.Approved &&
      hasPrivilegeToDeclinePlayerAccount;

    rowsList.push({
      title: 'Игровой аккаунт',
      isEditButtonVisible: isEditPlayerAccountButtonVisible,
      onEdit: onPlayerAccountEdit,
      rows: [
        {
          title: 'Аккаунт',
          value: (
            <PlayerAccountManagement
              playerAccount={playerAccount}
              companyId={id}
            />
          ),
        },
      ],
    });
  }

  return rowsList;
};
