import { GetCompanyAffiliateReferralLinkMediaItems } from '../queries/generated/GetCompanyAffiliateReferralLinkMediaItems';
import { TextOverflowMiddle } from '../components/TextOverflowMiddle';

export const getCompanyAffiliateReferralLinksOptions = (
  referralLinks?: GetCompanyAffiliateReferralLinkMediaItems['company']['affiliate']['referralLinkMediaItems']['edges'],
  labelWidth?: number,
) =>
  referralLinks?.map(({ node }) => ({
    label: (
      <div style={{ width: `${labelWidth}px` }}>
        <TextOverflowMiddle value={node.referralLink} />
      </div>
    ),
    id: node.id,
  }));
