import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { useFormikContext } from 'formik';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { FieldName } from '../../const';
import { DataCardMain } from './components/DataCardMain';
import { DataCardFreespinLink } from './components/DataCardFreespinLink';
import { DataCardBonusLink } from './components/DataCardBonusLink';
import { CreateAffiliatePromoCodeValues } from '../../types';
import { AffiliateMediaItemsSteps } from '../../../AffiliatePromoCodeItem/const';
import styles from './CreateAffiliatePromoCodeFormConfirmStep.module.scss';

interface Props {
  submitLoading: boolean;
}

export const CreateAffiliatePromoCodeFormConfirmStep: FC<Props> = ({
  submitLoading,
}) => {
  const { isValid, values } =
    useFormikContext<CreateAffiliatePromoCodeValues>();
  const { updateModalStep } = useUpdateModalStep<AffiliateMediaItemsSteps>();

  return (
    <>
      <DataCardMain />
      {values[FieldName.ConnectionWithFreespins] && <DataCardFreespinLink />}
      {values[FieldName.ConnectionWithBonus] && <DataCardBonusLink />}

      <div className={styles.actionButtons}>
        <Button
          size={ButtonSize.Large}
          theme={ButtonTheme.Process}
          onClick={() =>
            updateModalStep(AffiliateMediaItemsSteps.CreatePromoCodeConnection)
          }
        >
          Назад
        </Button>
        <Button
          size={ButtonSize.Large}
          theme={ButtonTheme.Success}
          type="submit"
          isLoading={submitLoading}
          disabled={!isValid}
        >
          Создать
        </Button>
      </div>
    </>
  );
};
