import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { Route } from 'src/router/routes.const';
import { formatSumAmount } from 'src/utils/accounting';
import { formatTableHeaderCellWithCurrency } from 'src/pages/Statistic/helpers';

import { generatePath } from 'react-router';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { twoEmDash } from 'src/const';
import { Currency } from 'commonTypes';
import { MediaItemReportOrderBy } from 'generatedGraphql';
import { CpaReferralLinkReportData } from '../../../queries/generated/CpaReferralLinkReportData';
import { columnsIds, ColumnId, mapColumnIdToName } from '../const';
import {
  defaultMediaItemReportOrderBy,
  STATISTIC_TOTAL_ROW_TITLE,
} from '../../../../../const';
import { AddTwoLinedSubTitle } from '../../../../../components/AddTwoLinedSubTitle';
import { CpaTariffCell } from '../../CpaTariffCell';
import { getFirstDepositInfo } from '../../../../helpers';
import { getReferralStatus } from '../../../helpers';
import { TableHeaderCellWithSort } from '../../../../../components/TableHeaderCellWithSort';

const columnHelper = createColumnHelper<CpaReferralLinkReportData>();

const buildColumnsSource = (
  currency: Currency,
): ColumnsSource<CpaReferralLinkReportData> => [
  columnHelper.accessor(ColumnId.Player, {
    id: ColumnId.Player,
    header: mapColumnIdToName[ColumnId.Player],
    cell: ({ row }) => {
      if (!row.original.player) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name, email, phone } = row.original.player;
      const playerUrl = generatePath(Route.PLAYER, { id });

      return (
        <MultilineCellWithLink
          isOpenInNewWindow
          title={name}
          subTitle={
            <AddTwoLinedSubTitle firstValue={email} secondValue={phone} />
          }
          route={playerUrl}
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.FirstDeposit, {
    id: ColumnId.FirstDeposit,
    header: mapColumnIdToName[ColumnId.FirstDeposit],
    cell: ({ row }) =>
      MayBeNullCell(getFirstDepositInfo(row.original.firstDeposit), twoEmDash),
  }),
  columnHelper.accessor(ColumnId.Deposit, {
    id: ColumnId.Deposit,
    header: () => (
      <TableHeaderCellWithSort
        defaultOrderBy={defaultMediaItemReportOrderBy}
        orderBy={MediaItemReportOrderBy.AllDeposit}
      >
        {formatTableHeaderCellWithCurrency(
          mapColumnIdToName[ColumnId.Deposit],
          currency,
        )}
      </TableHeaderCellWithSort>
    ),
    cell: ({ row }) => {
      const { amount } = row.original.deposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.ReferralLink, {
    id: ColumnId.ReferralLink,
    header: mapColumnIdToName[ColumnId.ReferralLink],
    cell: ({ row }) => {
      const { cpaRewardTariffReward, cpaRewardTariffBaseline } = row.original;

      return (
        <CpaTariffCell
          reward={cpaRewardTariffReward}
          baseLine={cpaRewardTariffBaseline}
        />
      );
    },
  }),
  columnHelper.accessor(ColumnId.ReferralStatus, {
    id: ColumnId.ReferralStatus,
    header: mapColumnIdToName[ColumnId.ReferralStatus],
    cell: ({ row }) =>
      MayBeNullCell(getReferralStatus(row.original.referralStatus), twoEmDash),
  }),
  columnHelper.accessor(ColumnId.Redeposit, {
    id: ColumnId.Redeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Redeposit],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.redeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Redeposits, {
    id: ColumnId.Redeposits,
    header: mapColumnIdToName[ColumnId.Redeposits],
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Withdrawal, {
    id: ColumnId.Withdrawal,
    header: () => (
      <TableHeaderCellWithSort
        defaultOrderBy={defaultMediaItemReportOrderBy}
        orderBy={MediaItemReportOrderBy.Withdrawal}
      >
        {formatTableHeaderCellWithCurrency(
          mapColumnIdToName[ColumnId.Withdrawal],
          currency,
        )}
      </TableHeaderCellWithSort>
    ),
    cell: ({ row }) => {
      const { amount } = row.original.withdrawal;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.NGR, {
    id: ColumnId.NGR,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NGR],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.financialIndicators.ngr;

      return formatSumAmount(amount);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Profit, {
    id: ColumnId.Profit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Profit],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.financialIndicators.profit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Upcoming, {
    id: ColumnId.Upcoming,
    header: () => (
      <TableHeaderCellWithSort
        defaultOrderBy={defaultMediaItemReportOrderBy}
        orderBy={MediaItemReportOrderBy.UpcomingReward}
      >
        {formatTableHeaderCellWithCurrency(
          mapColumnIdToName[ColumnId.Upcoming],
          currency,
        )}
      </TableHeaderCellWithSort>
    ),
    cell: ({ row }) => {
      const { amount } = row.original.affiliateRewardStatistic.upcoming;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Paid, {
    id: ColumnId.Paid,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Paid],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.affiliateRewardStatistic.paid;

      return formatSumAmount(amount);
    },
  }),
];

export const useGetCpaReferralLinkReportColumns = () => {
  // TODO: uncomment the line when the backend is ready [ALS-719]
  // const { currency } = useGetCurrency();
  const currency = Currency.Usd;

  const columnsSource = buildColumnsSource(currency);
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
