import React, { SyntheticEvent, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import CreateMessageForm from '../components/CreateMessageForm';

interface CreateFreespinType {
  onCreateMessage: (e: SyntheticEvent) => void;
}

export const useCreateMessage = <T extends object>(
  playerId: string,
  isFullScreenTable: boolean,
  messagesList?: Array<T>,
): CreateFreespinType => {
  const { dispatch } = useContext(ModalContext);

  const onCreateMessage = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch({
      type: ModalActions.Open,
      payload: {
        content: (
          <CreateMessageForm
            playerId={playerId}
            hasMessagesList={!!messagesList}
            isFullScreenTable={isFullScreenTable}
          />
        ),
        title: 'Отправить сообщение',
      },
    });
  };

  return { onCreateMessage };
};
