import BlockTable from 'ui/Block/BlockTable';
import { hasList } from 'ui/Block/BlockTable/utils';
import { ColumnDef } from '@tanstack/react-table';

interface Props<T extends object> {
  isFullScreenTable: boolean;
  routeToCustomPage?: string;
  revShareRewardTariffs?: Array<T>;
  columns: Array<ColumnDef<T>>;
}

export const RevShareRewardTariffsTable = <T extends { __typename: string }>({
  isFullScreenTable,
  routeToCustomPage,
  revShareRewardTariffs,
  columns,
}: Props<T>) => {
  if (!hasList(revShareRewardTariffs)) {
    return null;
  }

  return (
    <BlockTable
      isFullScreenTable={isFullScreenTable}
      data={revShareRewardTariffs}
      columns={columns}
      routeToCustomPage={routeToCustomPage}
    />
  );
};
