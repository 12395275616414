import { FormikErrors } from 'formik';
import { AcceptDepositInput } from 'generatedGraphql';

export const validate = (
  values: AcceptDepositInput,
): FormikErrors<AcceptDepositInput> => {
  const errors: FormikErrors<AcceptDepositInput> = {};

  if (!values.amount) {
    errors.amount = 'Введите фактическую сумму';
  }

  return errors;
};
