import { ModalContext, Tabs, TabsWrapper } from 'ui/Modal';
import { FC, useContext, useState } from 'react';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { AffiliatePromoCodesList } from '../AffiliatePromoCodesList';
import { AffiliateMediaItemsSteps } from '../AffiliatePromoCodeItem/const';
import { AffiliatePromoCodeItemDetails } from '../AffiliatePromoCodeItemDetails';
import { useUpdateAffiliateModalHeader } from './hooks/useUpdateAffiliateModalHeader';
import { AffiliateReferralLinksList } from '../AffiliateReferralLinkList';
import { CreateAffiliatePromoCode } from '../CreateAffiliatePromoCode';
import { useGetPrivilegeToShowMediaItems } from './hooks/useGetPrivilegeToShowMediaItems';
import { AffiliateReferralLinkItemDetails } from '../AffiliateReferralLinkItemDetails';
import { CreateAffiliateReferralLink } from '../CreateAffiliateReferralLink';

interface Props {
  companyId: string;
  companyAffiliateId: string;
  companyAffiliateName: string;
}

export const AffiliateItem: FC<Props> = ({
  companyId,
  companyAffiliateId,
  companyAffiliateName,
}) => {
  const { state } = useContext(ModalContext);
  const [promoCodeId, setPromoCodeId] = useState<string>();
  const [referralLinkId, setReferralLinkId] = useState<string>();

  const { updateModalStep } = useUpdateModalStep<AffiliateMediaItemsSteps>();

  const {
    hasPrivilegeForViewCompanyAffiliateReferralLinks,
    hasPrivilegeForViewCompanyAffiliatePromoCodes,
  } = useGetPrivilegeToShowMediaItems();

  useUpdateAffiliateModalHeader({
    currentReferralLinkId: referralLinkId,
    currentPromoCodeId: promoCodeId,
    companyAffiliateId,
    companyAffiliateName,
  });

  if (
    state.step === AffiliateMediaItemsSteps.ReferralLinkDetails &&
    referralLinkId
  ) {
    return (
      <AffiliateReferralLinkItemDetails
        companyId={companyId}
        affiliateId={companyAffiliateId}
        referralLinkId={referralLinkId}
      />
    );
  }

  if (state.step === AffiliateMediaItemsSteps.PromoCodeDetails && promoCodeId) {
    return (
      <AffiliatePromoCodeItemDetails
        companyId={companyId}
        affiliateId={companyAffiliateId}
        promoCodeId={promoCodeId}
      />
    );
  }

  const isCreatePromoCodeFormSteps =
    state.step === AffiliateMediaItemsSteps.CreatePromoCodeBaseInfo ||
    state.step === AffiliateMediaItemsSteps.CreatePromoCodeConnection ||
    state.step === AffiliateMediaItemsSteps.CreatePromoCodeConfirm;

  if (isCreatePromoCodeFormSteps) {
    return (
      <CreateAffiliatePromoCode
        companyId={companyId}
        companyAffiliateId={companyAffiliateId}
        companyAffiliateName={companyAffiliateName}
      />
    );
  }

  const isCreateReferralLinkStep =
    state.step === AffiliateMediaItemsSteps.CreateReferralLink;

  if (isCreateReferralLinkStep) {
    return (
      <CreateAffiliateReferralLink companyAffiliateId={companyAffiliateId} />
    );
  }

  const tabs: Array<Tabs> = [
    {
      title: 'Информация',
      content: <span>Not implemented yet</span>,
      isVisible: false,
    },
    {
      title: 'Ссылки',
      content: (
        <AffiliateReferralLinksList
          companyAffiliateId={companyAffiliateId}
          companyId={companyId}
          onDetailsClick={(currentReferralLinkId) => {
            setReferralLinkId(currentReferralLinkId);
            updateModalStep(AffiliateMediaItemsSteps.ReferralLinkDetails);
          }}
        />
      ),
      isVisible: hasPrivilegeForViewCompanyAffiliateReferralLinks,
    },
    {
      title: 'Промокоды',
      content: (
        <AffiliatePromoCodesList
          companyId={companyId}
          companyAffiliateId={companyAffiliateId}
          onDetailsClick={(currentPromoCodeId) => {
            setPromoCodeId(currentPromoCodeId);
            updateModalStep(AffiliateMediaItemsSteps.PromoCodeDetails);
          }}
        />
      ),
      isVisible: hasPrivilegeForViewCompanyAffiliatePromoCodes,
    },
  ];

  return <TabsWrapper tabs={tabs} activeTabDefault={state.activeTab || 0} />;
};
