import React, { FC, useEffect, useRef } from 'react';
import { Maybe } from 'generatedGraphql';
import { faFolderOpen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { ButtonTheme } from 'ui/Button';
import Note from 'commonComponents/Note';
import { UserPrivilege } from 'generatedUserPrivilege';
import Privilege from 'src/components/Privilege';
import { DateFormat, formatDate } from 'utils/formatDate';
import styles from './DocumentSidebarContent.module.scss';
import { useUpdateDocumentNote } from '../../queries/generated/UpdateDocumentNote';
import { DEFAULT_IMAGE_SIZE } from '../DocumentsList/const';

interface Props {
  documentId: string;
  createdAt: string;
  note: Maybe<string>;
  onDelete: (id: string) => void;
  isDeleteLoading: boolean;
}

const DocumentSidebarContent: FC<Props> = ({
  documentId,
  createdAt,
  note,
  onDelete,
  isDeleteLoading,
}) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  /* Fix for error: Unable to preventDefault inside passive event listener invocation.
   * It appears on sidebar wheel event with opened lightbox.
   */
  const wheelHandler = (event: WheelEvent) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const lightboxSidebar = sidebarRef.current;

    lightboxSidebar?.addEventListener('wheel', wheelHandler);

    return () => {
      lightboxSidebar?.removeEventListener('wheel', wheelHandler);
    };
  }, []);

  const [updateDocumentNote, { data: updateResult, loading: updateLoading }] =
    useUpdateDocumentNote();
  const updateDocumentNoteHandler = (updatedText: string) => {
    updateDocumentNote({
      variables: {
        input: {
          documentId,
          note: updatedText,
        },
        thumbnailImageUrlHeight: DEFAULT_IMAGE_SIZE,
        thumbnailImageUrlWidth: DEFAULT_IMAGE_SIZE,
      },
    });
  };

  return (
    <div className={styles.container} ref={sidebarRef}>
      <div className={styles.header}>
        <div className={styles.documentIcon}>
          <FontAwesomeIcon icon={faFolderOpen} />
        </div>
        <div className={styles.title}>
          <h2 className={styles.id}>{`#${documentId}`}</h2>
          <span className={styles.subTitle}>Документ</span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.row}>
          <span className={styles.createdAtTitle}>Создан</span>
          <span className={styles.createdAt}>
            {formatDate(createdAt, DateFormat.FullDate)}
          </span>
        </div>
        <div className={styles.note}>
          <span className={styles.noteTitle}>Примечание</span>
          <Note
            updateNote={updateDocumentNoteHandler}
            note={note as string}
            updateResult={updateResult}
            updateNoteLoading={updateLoading}
            isEditButtonVisible
          />
        </div>
        <Privilege privileges={[UserPrivilege.DeleteDocument]}>
          <Button
            theme={ButtonTheme.Process}
            onClick={() => {
              onDelete(documentId);
            }}
            disabled={isDeleteLoading}
            type="submit"
            className={styles.deleteButton}
          >
            Удалить документ
          </Button>
        </Privilege>
      </div>
    </div>
  );
};

export default DocumentSidebarContent;
