import { FC, useMemo } from 'react';
import classnames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faClock,
  faExclamationCircle,
  faSyncAlt,
} from '@fortawesome/pro-solid-svg-icons';
import capitalize from 'utils/capitalize';
import styles from './CardStatus.module.scss';

interface Props {
  statusColor?: CardStatusColor;
  statusText: string;
}

export enum CardStatusColor {
  GREY = 'grey',
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
}

const getIcon = (statusColor: CardStatusColor) => {
  switch (statusColor) {
    case CardStatusColor.GREEN:
      return faCheckCircle;
    case CardStatusColor.GREY:
      return faSyncAlt;
    case CardStatusColor.RED:
      return faExclamationCircle;
    case CardStatusColor.YELLOW:
      return faClock;
    default:
      return null;
  }
};

const CardStatus: FC<Props> = ({
  statusColor = CardStatusColor.GREY,
  statusText,
}) => {
  const icon = useMemo(() => getIcon(statusColor), [statusColor]);

  return (
    <div
      className={classnames(
        styles.status,
        styles[`status${capitalize(statusColor)}`],
      )}
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      <span>{statusText}</span>
    </div>
  );
};

export default CardStatus;
