import { FC, useContext } from 'react';
import { ModalContext, TabsWrapper } from 'ui/Modal';
import { CreatePayment } from 'commonComponents/CreatePayment';
import { useWithdrawalSidebarTabs } from './hooks/useWithdrawalSidebarTabs';
import { useGetWithdrawalPaymentsStatistic } from './queries/generated/GetWithdrawalPaymentsStatistic';
import { PAYMENTS_COUNT_PER_PAGE } from '../../const';
import { useWithdrawalSidebarSteps } from './hooks/useWithdrawalSidebarSteps';
import { WithdrawalModalSteps } from '../../types';
import { useCreatePayment } from './hooks/useCreatePayment';
import { AcceptPaymentForm } from '../AcceptPaymentForm';
import { WithdrawalItemContext, withWithdrawalItemProvider } from '../../store';

interface Props {
  withdrawalId: string;
}

export const WithdrawalSidebar: FC<Props> = withWithdrawalItemProvider(
  ({ withdrawalId }) => {
    const { state: modalState } = useContext(ModalContext);
    const { state: withdrawalState } = useContext(WithdrawalItemContext);

    const { paymentPaidSum, paymentId } = withdrawalState;

    const { data } = useGetWithdrawalPaymentsStatistic({
      variables: { id: withdrawalId, first: PAYMENTS_COUNT_PER_PAGE },
    });

    let activeTabDefault = 0;
    const { activeTab } = modalState;

    if (activeTab) {
      activeTabDefault = activeTab;
    }

    const remainingSum = data?.withdrawal.remainingSum;

    const {
      handleCreatePayment,
      loading: createPaymentLoading,
      error: createPaymentError,
    } = useCreatePayment({
      withdrawalId,
    });

    const { tabs } = useWithdrawalSidebarTabs({
      withdrawalId,
      withdrawal: data?.withdrawal,
    });

    useWithdrawalSidebarSteps({ withdrawalId });

    if (modalState.step === WithdrawalModalSteps.CreatePayment) {
      return (
        <CreatePayment
          handleCreatePayment={handleCreatePayment}
          createPaymentLoading={createPaymentLoading}
          createPaymentError={createPaymentError}
          withdrawalId={withdrawalId}
          remainingSum={remainingSum}
          actorInfo={data?.withdrawal.actor}
        />
      );
    }

    if (
      modalState.step === WithdrawalModalSteps.AcceptPayment &&
      paymentPaidSum &&
      paymentId
    ) {
      return (
        <AcceptPaymentForm id={paymentId} paymentPaidSum={paymentPaidSum} />
      );
    }

    return <TabsWrapper activeTabDefault={activeTabDefault} tabs={tabs} />;
  },
);
