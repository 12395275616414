import { FC, useState } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { Route } from 'src/router/routes.const';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { RewardTariffType } from 'commonTypes';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'src/utils/storage';
import SearchInput from 'ui/SearchInput';
import { STATISTIC_QUERY_PARAMS } from 'src/const';
import { STATISTIC_NO_DATA } from '../../const';
import { useGeneratePathWithStatisticsQueryParams } from '../../hooks/useGeneratePathWithStatisticsQueryParams';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import { BlockSubHeaderCompanyAffiliateInfo } from '../../components/BlockSubHeaderCompanyAffiliateInfo';
import { useGetCpaCompanyAffiliateReport } from './hooks/useGetCpaCompanyAffiliateReport';
import { CpaCompanyAffiliateReportTable } from './components/CpaCompanyAffiliateReportTable';
import {
  CPA_COMPANY_AFFILIATE_REPORT_INPUT_NAME,
  CPA_COMPANY_AFFILIATE_REPORT_SEARCH_PLACEHOLDER,
  CPA_COMPANY_AFFILIATE_STATISTIC_BLOCK_TITLE,
} from './const';
import { useGetExportCompanyAffiliateCpaReport } from './hooks/useGetExportCompanyAffiliateCpaReport';
import { useGetExportCompanyAffiliateClickIdReport } from './hooks/useGetExportCompanyAffiliateClickIdReport';

interface Props {
  companyId: string;
  affiliateId: string;
}

const Component: FC<Props> = withBlockProvider(({ companyId, affiliateId }) => {
  const [isAllTimeCpa, setIsAllTimeCpa] = useState(false);
  const {
    data,
    loadReport,
    cpaCompanyAffiliateReportList,
    loading,
    refetch,
    fetchMoreCallback,
    hasNextPage,
    hasTotalRow,
    affiliate,
  } = useGetCpaCompanyAffiliateReport({
    companyId,
    affiliateId,
    isAllTimeCpa,
  });

  const exportReportConfig = useGetExportCompanyAffiliateCpaReport({
    companyId,
    affiliateId,
    isAllTimeCpa,
  });

  const exportClickIdReportConfig = useGetExportCompanyAffiliateClickIdReport({
    companyId,
    affiliateId,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  const { generatePathWithStatisticsQueryParams } =
    useGeneratePathWithStatisticsQueryParams();

  const customPathToPrevPage = generatePathWithStatisticsQueryParams({
    originalPath: Route.STATISTIC_CPA_COMPANY_PARTNERS,
    queryParams: STATISTIC_QUERY_PARAMS,
    params: {
      id: companyId,
    },
  });

  const listExist = hasList(cpaCompanyAffiliateReportList);

  return (
    <>
      <SearchInput
        name={CPA_COMPANY_AFFILIATE_REPORT_INPUT_NAME}
        placeholder={CPA_COMPANY_AFFILIATE_REPORT_SEARCH_PLACEHOLDER}
        onSubmit={loadReport}
      />
      <Block
        title={CPA_COMPANY_AFFILIATE_STATISTIC_BLOCK_TITLE}
        id="cpaCompanyAffiliateStatistic"
        headerContent={
          <StatisticBlockHeaderContent
            hasList={listExist}
            exportReportConfig={exportReportConfig}
            handleRefetchClick={refetch}
            rewardTariffType={RewardTariffType.Cpa}
            showAllCpa={(value) => setIsAllTimeCpa(value)}
            exportClickIdReportConfig={exportClickIdReportConfig}
          />
        }
        subHeader={
          affiliate && (
            <BlockSubHeaderCompanyAffiliateInfo
              companyName={affiliate.company.name}
              affiliateName={affiliate.name}
            />
          )
        }
        shrinkLoaderWrapper={false}
        emptyText={STATISTIC_NO_DATA}
        isEmpty={isListEmpty(cpaCompanyAffiliateReportList)}
        shouldReturnToPrevPage
        isPermanentOpened
        customPathToPrevPage={customPathToPrevPage}
      >
        {listExist && (
          <CpaCompanyAffiliateReportTable
            cpaCompanyAffiliateReport={cpaCompanyAffiliateReportList}
            hasTotalRow={hasTotalRow}
          />
        )}
      </Block>
    </>
  );
});

export const CpaCompanyAffiliateReport = () => {
  const { companyId, affiliateId } = useParams();

  if (!companyId || !affiliateId) {
    return null;
  }

  return (
    <SearchContextProvider pageType={PageTypeStorage.CpaCompanyAffiliateReport}>
      <Component companyId={companyId} affiliateId={affiliateId} />
    </SearchContextProvider>
  );
};
