import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPartnersAccessToRevShareRewardTariffsVariables = SchemaTypes.Exact<{
  partnerId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetPartnersAccessToRevShareRewardTariffs = { __typename: 'Query', partner: { __typename: 'Partner', id: string, accessToRevShareRewardTariffs: Array<{ __typename: 'AccessToRevShareRewardTariff', accessible: boolean, id: string, name: string }> } };


export const GetPartnersAccessToRevShareRewardTariffsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPartnersAccessToRevShareRewardTariffs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"partnerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"partner"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"partnerId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessToRevShareRewardTariffs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessible"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetPartnersAccessToRevShareRewardTariffs__
 *
 * To run a query within a React component, call `useGetPartnersAccessToRevShareRewardTariffs` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnersAccessToRevShareRewardTariffs` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnersAccessToRevShareRewardTariffs({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetPartnersAccessToRevShareRewardTariffs(baseOptions: ApolloReactHooks.QueryHookOptions<GetPartnersAccessToRevShareRewardTariffs, GetPartnersAccessToRevShareRewardTariffsVariables> & ({ variables: GetPartnersAccessToRevShareRewardTariffsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPartnersAccessToRevShareRewardTariffs, GetPartnersAccessToRevShareRewardTariffsVariables>(GetPartnersAccessToRevShareRewardTariffsDocument, options);
      }
export function useGetPartnersAccessToRevShareRewardTariffsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPartnersAccessToRevShareRewardTariffs, GetPartnersAccessToRevShareRewardTariffsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPartnersAccessToRevShareRewardTariffs, GetPartnersAccessToRevShareRewardTariffsVariables>(GetPartnersAccessToRevShareRewardTariffsDocument, options);
        }
export function useGetPartnersAccessToRevShareRewardTariffsSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetPartnersAccessToRevShareRewardTariffs, GetPartnersAccessToRevShareRewardTariffsVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetPartnersAccessToRevShareRewardTariffs, GetPartnersAccessToRevShareRewardTariffsVariables>(GetPartnersAccessToRevShareRewardTariffsDocument, options);
        }
export type GetPartnersAccessToRevShareRewardTariffsHookResult = ReturnType<typeof useGetPartnersAccessToRevShareRewardTariffs>;
export type GetPartnersAccessToRevShareRewardTariffsLazyQueryHookResult = ReturnType<typeof useGetPartnersAccessToRevShareRewardTariffsLazyQuery>;
export type GetPartnersAccessToRevShareRewardTariffsSuspenseQueryHookResult = ReturnType<typeof useGetPartnersAccessToRevShareRewardTariffsSuspenseQuery>;
export type GetPartnersAccessToRevShareRewardTariffsQueryResult = Apollo.QueryResult<GetPartnersAccessToRevShareRewardTariffs, GetPartnersAccessToRevShareRewardTariffsVariables>;