import { RestrictionTypeName } from 'commonTypes';
import { Option } from 'ui/Dropdown';
import getEnumKeys from 'src/utils/getEnumKeys';
import { restrictionTypeMap } from './helpers';

export enum ColumnId {
  Id = 'id',
  Type = '__typename',
  Reason = 'reason',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Type]: 'Тип',
  [ColumnId.Reason]: 'Причина',
  [ColumnId.Status]: 'Статус',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.UpdatedAt]: 'Обновлён',
};

export const restrictionOptions: Array<Option> = getEnumKeys(
  RestrictionTypeName,
).map((restriction: keyof typeof RestrictionTypeName) => ({
  label: restrictionTypeMap[RestrictionTypeName[restriction]],
  id: RestrictionTypeName[restriction],
}));

export const blockColumnsIds = [
  ColumnId.Type,
  ColumnId.Reason,
  ColumnId.Status,
  ColumnId.UpdatedAt,
];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.Type,
  ColumnId.Reason,
  ColumnId.Status,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
];
