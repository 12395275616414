import {
  arrow,
  autoUpdate,
  offset,
  useDelayGroupContext,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  flip,
  shift,
  useRole,
  useClick,
} from '@floating-ui/react';
import { useMemo, useRef, useState } from 'react';
import { TooltipOptions } from '../types';

export const useTooltip = ({
  initialOpen = false,
  placement = 'top-start',
  isOpen: outsideControlledOpen,
  clickable,
  button,
  horizontalOffset,
}: TooltipOptions = {}) => {
  const [innerControlledOpen, setInnerControlledOpen] = useState(initialOpen);
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const open = clickable
    ? innerControlledOpen
    : (outsideControlledOpen ?? uncontrolledOpen);
  const setOpen = clickable ? setInnerControlledOpen : setUncontrolledOpen;

  const { delay } = useDelayGroupContext();
  const arrowRef = useRef(null);

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: 6,
        alignmentAxis: horizontalOffset || 0,
      }),
      flip(),
      shift(),
      arrow({ element: arrowRef, padding: 6 }),
    ],
  });

  const { context } = data;

  const hover = useHover(context, {
    move: false,
    enabled: !clickable && !outsideControlledOpen,
    delay,
  });
  const focus = useFocus(context, {
    enabled: !clickable && !outsideControlledOpen,
  });
  const click = useClick(context, {
    enabled: Boolean(clickable),
  });

  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([click, hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      clickable,
      button,
      open,
      setOpen,
      ...interactions,
      ...data,
      arrowRef,
    }),
    [clickable, button, open, setOpen, interactions, data, arrowRef],
  );
};
