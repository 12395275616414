import { MoneyTransferType } from 'commonTypes';
import { CardIconConfig } from 'ui/Card/CardIcon';
import { moneyTransferTypeMap } from '../../../../../const';
import { getMoneyTransferIconConfig } from './getMoneyTransferIconConfig';

export const getTransferType = (
  type: MoneyTransferType,
): [string, CardIconConfig] => {
  const formattedStatus = moneyTransferTypeMap[type];
  const iconConf = getMoneyTransferIconConfig(type);

  return [formattedStatus, iconConf];
};
