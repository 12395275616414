import { useEffect, useState } from 'react';
import { useGetCompanyQualifiedReferralConfirmationSettingsLazyQuery } from '../queries/generated/GetCompanyQualifiedReferralConfirmationSettings';
import { useGetPartnerQualifiedReferralConfirmationSettingsLazyQuery } from '../queries/generated/GetPartnerQualifiedReferralConfirmationSettings';

export const useCheckHasAffiliateConfirmationSettings = () => {
  const [hasCompanyConfirmationSetting, setHasCompanyConfirmationSetting] =
    useState(false);
  const [hasPartnerConfirmationSetting, setHasPartnerConfirmationSetting] =
    useState(false);

  const [loadCompanyConfirmationSettings] =
    useGetCompanyQualifiedReferralConfirmationSettingsLazyQuery({
      fetchPolicy: 'network-only',
    });
  const [loadPartnerConfirmationSettings] =
    useGetPartnerQualifiedReferralConfirmationSettingsLazyQuery({
      fetchPolicy: 'network-only',
    });

  const checkHasCompanyConfirmationSettings = (companyId: string) => {
    loadCompanyConfirmationSettings({
      variables: { companyId, first: 1 },
      onCompleted: (data) => {
        setHasCompanyConfirmationSetting(
          !!data.company.qualifiedReferralConfirmationSettings.edges.length,
        );
      },
    });
  };
  const checkHasPartnerConfirmationSettings = (partnerId: string) => {
    loadPartnerConfirmationSettings({
      variables: { partnerId, first: 1 },
      onCompleted: (data) => {
        setHasPartnerConfirmationSetting(
          !!data.partner.qualifiedReferralConfirmationSettings.edges.length,
        );
      },
    });
  };

  const [hasAffiliateConfirmationSetting, setHasAffiliateConfirmationSetting] =
    useState(false);

  useEffect(() => {
    setHasAffiliateConfirmationSetting(
      hasCompanyConfirmationSetting || hasPartnerConfirmationSetting,
    );
  }, [hasCompanyConfirmationSetting, hasPartnerConfirmationSetting]);

  return {
    checkHasCompanyConfirmationSettings,
    checkHasPartnerConfirmationSettings,
    hasAffiliateConfirmationSetting,
  };
};
