import { PLAYER_HEADER_HEIGHT } from '../../../../const';
import {
  MAX_DOCUMENTS_IN_ROW,
  MIN_DOCUMENT_WIDTH,
} from './components/DocumentsList/const';

const MAIN_LAYOUT_ID = '#mainLayout';

export const getInitialDocumentsCount = (): number => {
  // Count by window height
  const windowHeight: number = window.innerHeight;
  const countByWindowHeight: number = Math.ceil(
    (windowHeight - PLAYER_HEADER_HEIGHT) /
      (MIN_DOCUMENT_WIDTH / MAX_DOCUMENTS_IN_ROW),
  );

  // Count by main layout width
  let documentsInRow: number | undefined;
  const mainLayout: HTMLDivElement | null =
    document.querySelector(MAIN_LAYOUT_ID);

  if (mainLayout) {
    let mainLayoutWidth: number = mainLayout.clientWidth;

    mainLayoutWidth -=
      parseFloat(getComputedStyle(mainLayout).paddingLeft) +
      parseFloat(getComputedStyle(mainLayout).paddingRight);

    documentsInRow = Math.floor(mainLayoutWidth / MIN_DOCUMENT_WIDTH);
  }

  if (documentsInRow && countByWindowHeight <= MAX_DOCUMENTS_IN_ROW) {
    return documentsInRow;
  }

  return countByWindowHeight;
};
