import classnames from 'classnames';
import { FC } from 'react';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApolloError } from '@apollo/client';
import Button from '../Button';
import styles from './LoadMoreButton.module.scss';

interface Props {
  onLoadMore?: () => void;
  label?: string;
  loading?: boolean;
  className?: string;
  error?: ApolloError | null;
}

const LoadMoreButton: FC<Props> = ({
  label = 'Показать ещё',
  className,
  onLoadMore,
  loading,
  error,
}) => {
  if (!loading && !error) {
    return (
      <button
        type="button"
        className={classnames(styles.loadMore, className)}
        onClick={onLoadMore}
        disabled={loading}
      >
        {label}
      </button>
    );
  }

  return (
    <div
      className={classnames(
        styles.loadMoreContainer,
        error && styles.error,
        className,
      )}
    >
      {loading && (
        <FontAwesomeIcon className={styles.loader} icon={faSpinnerThird} spin />
      )}
      {error && (
        <span>
          Не удалось загузить данные.{' '}
          <Button linkStyled onClick={onLoadMore}>
            Обновить
          </Button>{' '}
        </span>
      )}
    </div>
  );
};

export default LoadMoreButton;
