import { getDropdownListByMap } from 'utils/getDropdownListByMap';
import { depositStatusMap } from '../../../../../const';
import { getStatusIcon } from './getStatusIcon';
import { DepositStatusItem } from '../components/DepositStatusItem/DepositStatusItem';

export const getStatusesList = getDropdownListByMap(depositStatusMap).map(
  ({ label, id }) => {
    const [icon, color] = getStatusIcon(id);

    const iconConfig = {
      icon,
      iconColor: color,
    };

    return {
      id,
      label: <DepositStatusItem status={label} iconConfig={iconConfig} />,
      textForSearch: label,
    };
  },
);
