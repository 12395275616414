import { SetStateAction } from 'react';
import { FormikErrors, FormikValues } from 'formik';
import { AccessToTariffsInitialValues } from '../types';
import { checkIsAllTariffsChecked } from './checkIsAllTariffsChecked';

export const changeAllCheckbox = (
  setValues: (
    values: SetStateAction<FormikValues>,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<FormikValues>>,
  values: AccessToTariffsInitialValues,
) => {
  const changedValues: AccessToTariffsInitialValues = {};

  Object.entries(values).forEach(([key]) => {
    changedValues[key] = !checkIsAllTariffsChecked(values);
  });

  setValues(changedValues);
};
