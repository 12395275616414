import { FC, ReactElement } from 'react';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { Route } from 'src/router/routes.const';
import { STATISTIC_QUERY_PARAMS } from 'src/const';
import { useGeneratePathWithStatisticsQueryParams } from '../../hooks/useGeneratePathWithStatisticsQueryParams';

interface Props {
  title: string;
  id: string;
  subTitle?: string | ReactElement | null;
  route: Route;
}

export const MultilineCellWithLinkWithPeriodQueryParams: FC<Props> = ({
  title,
  id,
  subTitle,
  route,
}) => {
  const { generatePathWithStatisticsQueryParams } =
    useGeneratePathWithStatisticsQueryParams();

  const path = generatePathWithStatisticsQueryParams({
    originalPath: route,
    queryParams: STATISTIC_QUERY_PARAMS,
    params: { id },
  });

  return (
    <MultilineCellWithLink
      title={title}
      subTitle={subTitle}
      route={path}
      hasIcon={false}
    />
  );
};
