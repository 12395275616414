import { FC, useContext } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Block from 'ui/Block';
import SearchInput from 'ui/SearchInput';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { UserPrivilege } from 'generatedUserPrivilege';
import Privilege from 'commonComponents/Privilege';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { PageTypeStorage } from 'utils/storage';
import styles from './MediaCampaigns.module.scss';
import { MediaItemsTable } from './components/MediaItemsTable';
import { useOpenModal } from './hooks/useOpenModal';
import { useMediaCampaigns } from './hooks/useMediaCampaigns';
import {
  MEDIA_CAMPAIGNS_SEARCH_INPUT_NAME,
  MEDIA_CAMPAIGNS_SEARCH_PLACEHOLDER,
} from './const';
import { SearchContextProvider } from '../../contexts/SearchContext';

const Component: FC = withBlockProvider(() => {
  const { openModal } = useOpenModal();
  const { state } = useContext(BlockContext);

  const {
    data,
    fetchMoreCallback,
    loadMediaCampaigns,
    loading,
    refetch,
    mediaCampaigns,
  } = useMediaCampaigns();

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.mediaCampaigns?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  return (
    <>
      <SearchInput
        name={MEDIA_CAMPAIGNS_SEARCH_INPUT_NAME}
        placeholder={MEDIA_CAMPAIGNS_SEARCH_PLACEHOLDER}
        onSubmit={loadMediaCampaigns}
      />
      <Block
        title="Медиа кампании"
        id="mediaCampaigns"
        headerContent={
          <div className={styles.headerContent}>
            <Privilege privileges={[UserPrivilege.CreateMediaCampaign]}>
              <ActionButton
                actionType={ButtonType.Add}
                hidden={!state.isOpened}
                disabled={state.isLoading}
                onClick={openModal}
              />
            </Privilege>
            <ActionButton
              actionType={ButtonType.Update}
              hidden={!state.isOpened}
              disabled={state.isLoading}
              onClick={() => refetch()}
            />
          </div>
        }
        shrinkLoaderWrapper={false}
        emptyText="Медиа Кампании не найдены"
        isEmpty={isListEmpty(mediaCampaigns)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(mediaCampaigns) && (
          <MediaItemsTable mediaItems={mediaCampaigns} />
        )}
      </Block>
    </>
  );
});

export const MediaCampaigns = () => (
  <SearchContextProvider pageType={PageTypeStorage.MediaCampaignsPage}>
    <Component />
  </SearchContextProvider>
);
