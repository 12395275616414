import {
  faArrowDownArrowUp,
  faArrowDownWideShort,
  faArrowUpWideShort,
} from '@fortawesome/pro-solid-svg-icons';
import { OrderDirection } from 'generatedGraphql';
import { CardIconColor } from 'ui/Card';

interface GetIconParams {
  isSelected: boolean;
  direction: OrderDirection;
}

export const getIcon = ({ direction, isSelected }: GetIconParams) => {
  if (!isSelected) {
    return { icon: faArrowDownArrowUp };
  }

  switch (direction) {
    case OrderDirection.Asc:
      return { icon: faArrowUpWideShort, iconColor: CardIconColor.BLUE };
    case OrderDirection.Desc:
      return { icon: faArrowDownWideShort, iconColor: CardIconColor.BLUE };

    default:
      return { icon: faArrowDownArrowUp };
  }
};
