import { WagerStatus } from 'generatedGraphql';

export const getHeaderByStatus = (
  status: WagerStatus,
  inProgressText: string,
): string | undefined => {
  switch (status) {
    case WagerStatus.Won:
    case WagerStatus.WonDueToWithdrawal:
      return 'Отыгран';
    case WagerStatus.InQueue:
      return 'В\u00a0очереди';
    case WagerStatus.InProgress:
      return inProgressText;
    default:
      return undefined;
  }
};

export const checkIsWonStatus = (status: WagerStatus) =>
  status === WagerStatus.Won || status === WagerStatus.WonDueToWithdrawal;
