import { FC, useState } from 'react';
import { RewardTariffType } from 'commonTypes';
import { withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { RewardTariffsTabs } from 'commonComponents/RewardTariffsTabs';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { RewardsTable } from './components/RewardsTable';
import { useGetRewards } from './hooks/useGetRewards';
import { RewardsHeaderActions } from './components/RewardsHeaderActions';

export const Rewards: FC = withBlockProvider(() => {
  const [rewardTariffsType, setRewardTariffsType] = useState(
    RewardTariffType.RevShare,
  );

  const {
    rewardsList,
    loadRewards,
    loading,
    error,
    data,
    refetch,
    fetchMoreCallback,
  } = useGetRewards(rewardTariffsType);

  useBlockComponentState({
    loadData: loadRewards,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.rewards?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  return (
    <Block
      title="Вознаграждения"
      id="rewards"
      headerContent={
        <RewardsHeaderActions
          rewardTariffType={rewardTariffsType}
          refetch={refetch}
        />
      }
      subHeader={
        <RewardTariffsTabs
          setRewardTariffsType={setRewardTariffsType}
          currentTabId={rewardTariffsType}
        />
      }
      shrinkLoaderWrapper={false}
      emptyText="Вознаграждения отсутствуют"
      isEmpty={isListEmpty(rewardsList)}
      shouldReturnToPrevPage={false}
      isPermanentOpened
    >
      {hasList(rewardsList) && <RewardsTable rewards={rewardsList} />}
    </Block>
  );
});
