import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FC } from 'react';
import styles from './CheckBoxOption.module.scss';
import { Option } from '../../types';

interface Props {
  option: Option;
  isChecked: boolean;
}

export const CheckBoxOption: FC<Props> = ({ isChecked, option }) => (
  <>
    <div
      className={classnames(
        styles.checkboxControl,
        isChecked && styles.checked,
      )}
    >
      {isChecked && <FontAwesomeIcon icon={faCheck} />}
    </div>
    {option.label}
  </>
);
