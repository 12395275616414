import { FC, InputHTMLAttributes, useId, useRef } from 'react';
import classnames from 'classnames';
import { FormFieldWrapper } from 'ui/FormFieldWrapper';
import styles from './input.module.scss';
import { useInputManagement } from '../hooks/useInputManagement';
import { InputSize } from '../const';
import { CommonProps } from '../types';

type OriginalInputAttributes = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'name'
>;

export interface InputProps extends CommonProps, OriginalInputAttributes {
  invalidChars?: Array<string>;
}

export const Input: FC<InputProps> = ({
  name,
  label,
  wrapperClassName,
  inputClassName,
  autoFocus,
  invalidChars,
  disabled,
  inputSize = InputSize.Default,
  onChange,
  errorTooltipPlacement = 'bottom-start',
  errorTooltipArrowOffset,
  ...rest
}) => {
  const id = useId();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { handleChange, handleKeyDown, handleBlur, field, touched, error } =
    useInputManagement({ name, inputRef, autoFocus, invalidChars, onChange });

  return (
    <FormFieldWrapper
      name={name}
      idForLabel={id}
      label={label}
      wrapperClassName={wrapperClassName}
      errorTooltipPlacement={errorTooltipPlacement}
      errorTooltipArrowOffset={errorTooltipArrowOffset}
    >
      <input
        className={classnames(
          inputClassName,
          styles.input,
          error && touched && styles.error,
          styles[inputSize],
        )}
        id={id}
        value={field.value || ''}
        onKeyDown={handleKeyDown}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
        name={field.name}
        disabled={disabled}
        ref={inputRef}
        autoComplete="off"
        {...rest}
      />
    </FormFieldWrapper>
  );
};
