import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { AffiliateReportOrderBy } from 'generatedGraphql';
import { useSearchContext } from 'src/contexts/SearchContext';
import { Currency } from 'commonTypes';
import {
  useGetCpaPartnerReportLazyQuery,
  GetCpaPartnerReportVariables,
} from '../queries/generated/GetCpaPartnerReport';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetOrdering } from '../../../hooks/useGetOrdering';

const cellHeight = Heights.defaultCellHeight;

interface UseGetCpaPartnerReportParams {
  partnerId: string;
  isAllTimeCpa: boolean;
}

export const useGetCpaPartnerReport = ({
  partnerId,
  isAllTimeCpa,
}: UseGetCpaPartnerReportParams) => {
  const period = useGetPeriodOfStatistic();
  const ordering = useGetOrdering<AffiliateReportOrderBy>(
    AffiliateReportOrderBy.AllDeposit,
  );

  const { searchValue } = useSearchContext();

  // TODO: uncomment the line when the backend is ready [ALS-719]
  // const { currency } = useGetCurrency();
  const currency = Currency.Usd;

  const baseVariables: GetCpaPartnerReportVariables = {
    first: getCountByWindowHeight(cellHeight, 0),
    currency,
    id: partnerId,
    ordering,
    period,
    showAllTimeCpa: isAllTimeCpa,
  };

  const [reportVariables, setReportVariables] = useState(baseVariables);

  useEffect(() => {
    setReportVariables({
      ...baseVariables,
      search: searchValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currency,
    period.from,
    period.to,
    isAllTimeCpa,
    ordering.orderBy,
    ordering.direction,
  ]);

  const [loadCpaPartnerReport, { data, loading, error, fetchMore, refetch }] =
    useGetCpaPartnerReportLazyQuery();

  const loadCpaPartnerReportCallback = useCallback(
    () => loadCpaPartnerReport({ variables: reportVariables }),
    [loadCpaPartnerReport, reportVariables],
  );

  useBlockComponentState({
    loadData: loadCpaPartnerReportCallback,
    loading,
    error,
  });

  const cpaReport = data?.partner.cpaReport;
  const edges = cpaReport?.edges;
  const total = cpaReport?.total;
  const hasTotalRow = !!total;
  const endCursor = cpaReport?.pageInfo.endCursor;
  const hasNextPage = cpaReport?.pageInfo.hasNextPage;

  const cpaPartnerReport = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return {
    refetch,
    cpaPartnerReport,
    hasTotalRow,
    partner: data?.partner,
    loadReport: () =>
      loadCpaPartnerReport({
        variables: { ...reportVariables, search: searchValue },
      }),
  };
};
