import { Route } from 'src/router/routes.const';
import { QualifiedReferralConfirmationSettingData } from '../../../queries/generated/QualifiedReferralConfirmationSettingData';

export const getAffiliateInfo = (
  affiliate: QualifiedReferralConfirmationSettingData['affiliate'],
) => {
  const isCompany = affiliate?.__typename === 'Company';
  const { name } = affiliate;
  const email = isCompany ? affiliate?.email : affiliate?.partnerEmail;
  const route = isCompany
    ? `${Route.COMPANIES}/${affiliate.id}`
    : `${Route.PARTNERS}/${affiliate?.id}`;

  return {
    name,
    email,
    route,
  };
};
