import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useCallback } from 'react';
import { useGetCompanyReferralLinksBlockLazyQuery } from '../queries/generated/GetCompanyReferralLinksBlock';
import { useGetCompanyReferralLinksLazyQuery } from '../queries/generated/GetCompanyReferralLinks';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

interface UseGetReferralLinksByTableSizeParams {
  companyId: string;
  isFullScreenTable: boolean;
}

export const useGetReferralLinksByTableSize = ({
  companyId,
  isFullScreenTable,
}: UseGetReferralLinksByTableSizeParams) => {
  const countPerPageByTableSize = isFullScreenTable
    ? getCountByWindowHeight(cellHeight)
    : COUNT_PER_PAGE;

  const currentUseGetCompanyReferralLinks = isFullScreenTable
    ? useGetCompanyReferralLinksLazyQuery
    : useGetCompanyReferralLinksBlockLazyQuery;

  const [
    loadCompanyReferralLinkMediaItems,
    { data, loading, error, fetchMore, refetch },
  ] = currentUseGetCompanyReferralLinks({
    variables: {
      companyId,
      first: countPerPageByTableSize,
    },
  });

  const referralLintMediaItemsData = data?.company.referralLinkMediaItems;
  const endCursor = referralLintMediaItemsData?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  return {
    loadCompanyReferralLinkMediaItems,
    fetchMoreCallback,
    referralLintMediaItemsData,
    loading,
    error,
    fetchMore,
    refetch,
  };
};
