import { GetMediaCampaignsForDropdown } from '../queries/generated/GetMediaCampaignsForDropdown';

export const getMediaCampaignsOptions = (
  mediaCampaigns?: GetMediaCampaignsForDropdown['mediaCampaigns']['edges'],
) =>
  mediaCampaigns?.map(({ node }) => ({
    label: node.name,
    id: node.id,
    labelRight: node.url,
  }));
