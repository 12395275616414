import { Currency } from 'commonTypes';
import { PlayerStatistic, WithdrawalLimits } from 'generatedGraphql';
import { formatSumWithCurrency } from 'utils/accounting';
import { enDash } from '../../../../../const';

interface WithdrawalLimitsValues {
  dailyProgress: number;
  monthlyProgress: number;
  dailyLimit: number;
  monthlyCryptoLimit: number;
  dailySum: number;
  monthlyCryptoSum: number;
  currency: Currency;
  profit: number;
}

export const getLimitsValues = (
  withdrawalLimits?: WithdrawalLimits,
  statistic?: PlayerStatistic,
): WithdrawalLimitsValues => {
  const dailyLimit = withdrawalLimits?.dailyLimit.amount || 0;
  const monthlyCryptoLimit = withdrawalLimits?.monthlyCryptoLimit.amount || 0;

  const dailySum = statistic?.dailyWithdrawalSum.amount || 0;
  const monthlyCryptoSum = statistic?.monthlyCryptoWithdrawalSum.amount || 0;

  const currency = withdrawalLimits?.dailyLimit.currency || Currency.Usd;
  const profit = statistic?.profit.amount || 0;

  const dailyProgress = (dailySum / dailyLimit) * 100;
  const monthlyProgress = (monthlyCryptoSum / monthlyCryptoLimit) * 100;

  return {
    dailyLimit,
    monthlyCryptoLimit,
    dailySum,
    monthlyCryptoSum,
    currency,
    profit,
    dailyProgress,
    monthlyProgress,
  };
};

export const formatSumToProfit = (amount: number, currency: Currency) =>
  amount < 0
    ? `${enDash}${formatSumWithCurrency(Math.abs(amount), currency)}`
    : formatSumWithCurrency(amount, currency);
