import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdateRevShareRewardTariffVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateRevShareRewardTariffInput;
}>;


export type UpdateRevShareRewardTariff = { __typename: 'Mutation', updateRevShareRewardTariff: { __typename: 'UpdateRevShareRewardTariffPayload', revShareRewardTariff: { __typename: 'RevShareRewardTariff', id: string, percent: any } } };


export const UpdateRevShareRewardTariffDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateRevShareRewardTariff"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateRevShareRewardTariffInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateRevShareRewardTariff"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revShareRewardTariff"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"percent"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UpdateRevShareRewardTariffMutationFn = Apollo.MutationFunction<UpdateRevShareRewardTariff, UpdateRevShareRewardTariffVariables>;

/**
 * __useUpdateRevShareRewardTariff__
 *
 * To run a mutation, you first call `useUpdateRevShareRewardTariff` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRevShareRewardTariff` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRevShareRewardTariff, { data, loading, error }] = useUpdateRevShareRewardTariff({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRevShareRewardTariff(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRevShareRewardTariff, UpdateRevShareRewardTariffVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateRevShareRewardTariff, UpdateRevShareRewardTariffVariables>(UpdateRevShareRewardTariffDocument, options);
      }
export type UpdateRevShareRewardTariffHookResult = ReturnType<typeof useUpdateRevShareRewardTariff>;
export type UpdateRevShareRewardTariffMutationResult = Apollo.MutationResult<UpdateRevShareRewardTariff>;
export type UpdateRevShareRewardTariffMutationOptions = Apollo.BaseMutationOptions<UpdateRevShareRewardTariff, UpdateRevShareRewardTariffVariables>;