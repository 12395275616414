import { Dropdown } from 'ui/Dropdown';
import { FC, useState } from 'react';
import { currencyOptions } from 'utils/accounting';

interface Props {
  name: string;
  className?: string;
  wrapperClassName?: string;
  withSearch?: boolean;
  label: string;
  disabled?: boolean;
  onChange?: (value: string | Array<string>) => void;
}

export const CurrencyDropdown: FC<Props> = ({
  name,
  label,
  disabled,
  withSearch = true,
  onChange,
  className,
  wrapperClassName,
}) => {
  const [optionsState, setOptionsState] = useState(currencyOptions);

  const onSearchInList = (e: string) => {
    const filteredOptions = currencyOptions.filter((option) => {
      if (typeof option.label === 'string') {
        return option.label.includes(e.toUpperCase());
      }

      return undefined;
    });

    setOptionsState(filteredOptions);
  };

  return (
    <Dropdown
      name={name}
      label={label}
      className={className}
      wrapperClassName={wrapperClassName}
      options={optionsState}
      onSearchInList={withSearch ? onSearchInList : undefined}
      disabled={disabled}
      onChange={onChange}
    />
  );
};
