import { FC } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { PageTypeStorage } from 'utils/storage';
import SearchInput from 'ui/SearchInput';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { useGetQualifiedReferrals } from './hooks/useGetQualifiedReferrals';
import { QualifiedReferralsTable } from './components/QualifiedReferralsTable';
import { QualifiedReferralsHeaderActions } from './components/QualifiedReferralsHeaderActions';
import {
  QUALIFIED_REFERRALS_SEARCH_INPUT_NAME,
  QUALIFIED_REFERRALS_SEARCH_PLACEHOLDER,
} from './const';

const Component: FC = withBlockProvider(() => {
  const {
    loadQualifiedReferralsList,
    data,
    loading,
    refetch,
    fetchMoreCallback,
    hasNextPage,
    qualifiedReferralsList,
  } = useGetQualifiedReferrals();

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return (
    <>
      <SearchInput
        name={QUALIFIED_REFERRALS_SEARCH_INPUT_NAME}
        placeholder={QUALIFIED_REFERRALS_SEARCH_PLACEHOLDER}
        onSubmit={loadQualifiedReferralsList}
      />
      <Block
        title="Квалифицированные игроки"
        id="qualifiedReferralsList"
        headerContent={<QualifiedReferralsHeaderActions refetch={refetch} />}
        shrinkLoaderWrapper={false}
        emptyText="Квалифицированных игроков нет"
        isEmpty={isListEmpty(qualifiedReferralsList)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(qualifiedReferralsList) && (
          <QualifiedReferralsTable
            qualifiedReferralsList={qualifiedReferralsList}
          />
        )}
      </Block>
    </>
  );
});

export const QualifiedReferralsList = () => (
  <SearchContextProvider pageType={PageTypeStorage.QualifiedReferralsPage}>
    <Component />
  </SearchContextProvider>
);
