import { toast } from 'react-toastify';
import errorToast from 'ui/Toast/ErrorToast';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext } from 'react';
import { ApolloError } from '@apollo/client';
import { ListAllOperations } from 'generatedGraphql';
import { nbsp } from 'src/const';
import { useUpdateAccessToCompanyRevShareRewardTariffs } from '../queries/generated/UpdateAccessToCompanyRevShareRewardTariffs';
import { AccessToTariffsInitialValues } from '../types';

export const useSubmitForm = (
  companyId: string,
  isFullScreenTable: boolean,
) => {
  const { dispatch } = useContext(ModalContext);

  const onCompleted = () => {
    toast.success('Партнерские тарифы изменены');
    dispatch({
      type: ModalActions.Close,
    });
  };

  const onError = (error: ApolloError) => {
    errorToast({
      header: `При${nbsp}управлении партнерскими тарифами произошла ошибка`,
      text: error.message,
      toastId: 'update-access-to-company-rev-share-reward-tariffs-error',
    });
  };

  const refetchQueries = [
    isFullScreenTable
      ? ListAllOperations.Query.GetCompanyRevShareRewardTariffs
      : ListAllOperations.Query.GetCompanyRevShareRewardTariffsBlock,
  ];

  const [updateAccessToCompanyRevShareRewardTariffs, { loading }] =
    useUpdateAccessToCompanyRevShareRewardTariffs({
      onError,
      onCompleted,
      refetchQueries,
    });

  const handleSubmitForm = (values: AccessToTariffsInitialValues) => {
    const filteredTariffs = Object.fromEntries(
      Object.entries(values).filter(([, value]) => value),
    );

    const accessedRevShareTariffsIds = Object.keys(filteredTariffs);

    updateAccessToCompanyRevShareRewardTariffs({
      variables: {
        input: {
          id: companyId,
          accessedRevShareTariffIds: accessedRevShareTariffsIds,
        },
      },
    });
  };

  return { handleSubmitForm, loading };
};
