import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { PostbackDisabledNotificationsListModalSteps } from '../../../const';

type UseOpenDisabledPostbackReferralLinkStepResult = (id: string) => void;

export const useOpenDisabledPostbackReferralLinkStep =
  (): UseOpenDisabledPostbackReferralLinkStepResult => {
    const { dispatch } = useContext(ModalContext);

    const openDisabledPostbackReferralLinkStep = useCallback(
      (referralLinkMediaItemId: string) => {
        dispatch({
          type: ModalActions.UpdateModalProps,
          payload: {
            title: `#${referralLinkMediaItemId}`,
            subTitle: 'Медиа элемент',
            step: PostbackDisabledNotificationsListModalSteps.ReferralLink,
            previousStep: PostbackDisabledNotificationsListModalSteps.BaseStep,
          },
        });
      },
      [dispatch],
    );

    return openDisabledPostbackReferralLinkStep;
  };
