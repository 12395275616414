import { MediaItemTypeName } from 'commonTypes';
import { useMemo } from 'react';
import { useGetPromoCodeMediaItemsByTableSize } from '../components/PromoCodeMediaItemTable/hooks/useGetPromoCodeMediaItemsByTableSize';
import { useGetReferralLinksByTableSize } from '../components/ReferralLinkMediaItemTable/hooks/useGetReferralLinksByTableSize';
import { useGetPromoCodeMediaItemsColumns } from '../components/PromoCodeMediaItemTable/hooks/useGetPromoCodeMediaItemsColumns';
import { useGetReferralLinksColumns } from '../components/ReferralLinkMediaItemTable/hooks/useGetReferralLinksColumns';

interface UseGetMediaItemsDataParams {
  mediaItemsType: MediaItemTypeName;
  companyId: string;
  isFullScreenTable: boolean;
}

export const useGetMediaItemsData = ({
  mediaItemsType,
  companyId,
  isFullScreenTable,
}: UseGetMediaItemsDataParams) => {
  const {
    referralLintMediaItemsData,
    fetchMoreCallback: fetchMoreReferralLink,
    loadCompanyReferralLinkMediaItems,
    loading: loadingReferralLink,
    error: errorReferralLink,
    refetch: refetchReferralLink,
  } = useGetReferralLinksByTableSize({
    isFullScreenTable,
    companyId,
  });

  const {
    promoCodeMediaItemsData,
    fetchMoreCallback: fetchMorePromoCode,
    loadCompanyPromoCodeMediaItems,
    loading: loadingPromoCode,
    error: errorPromoCode,
    refetch: refetchPromoCode,
  } = useGetPromoCodeMediaItemsByTableSize({
    isFullScreenTable,
    companyId,
  });

  const isPromoCode = mediaItemsType === MediaItemTypeName.PromoCode;
  const fetchMore = isPromoCode ? fetchMorePromoCode : fetchMoreReferralLink;
  const error = isPromoCode ? errorPromoCode : errorReferralLink;
  const loading = isPromoCode ? loadingPromoCode : loadingReferralLink;
  const refetch = isPromoCode ? refetchPromoCode : refetchReferralLink;

  const loadCompanyMediaItem = isPromoCode
    ? loadCompanyPromoCodeMediaItems
    : loadCompanyReferralLinkMediaItems;

  const mediaItemsData = isPromoCode
    ? promoCodeMediaItemsData
    : referralLintMediaItemsData;

  const mediaItems = useMemo(
    () => mediaItemsData?.edges.map(({ node }) => node),
    [mediaItemsData],
  );

  const hasNextPage = mediaItemsData?.pageInfo.hasNextPage;

  const { columns: promoCodeColumns } =
    useGetPromoCodeMediaItemsColumns(isFullScreenTable);
  const { columns: referralLinkColumns } =
    useGetReferralLinksColumns(isFullScreenTable);

  const columns = isPromoCode ? promoCodeColumns : referralLinkColumns;

  return {
    hasNextPage,
    mediaItems,
    mediaItemsData,
    refetch,
    loadCompanyMediaItem,
    loading,
    error,
    fetchMore,
    columns,
  };
};
