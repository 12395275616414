import classnames from 'classnames';
import { FC, ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons';
import styles from './Button.module.scss';
import { ButtonSize, ButtonTheme, IconType, LinkButtonTheme } from './const';
import { getIcon } from './helpers';

interface Props {
  className?: string;
  theme?: ButtonTheme | LinkButtonTheme;
  isLoading?: boolean;
  linkStyled?: boolean;
  size?: ButtonSize;
  icon?: IconType | IconDefinition;
  disabled?: boolean;
  underlined?: boolean;
}

const Button: FC<
  PropsWithChildren<Props & ButtonHTMLAttributes<HTMLButtonElement>>
> = ({
  children,
  className,
  theme,
  isLoading,
  linkStyled,
  icon,
  underlined,
  type = 'button',
  size = ButtonSize.Medium,
  disabled,
  ...rest
}) => {
  const buttonClasses = classnames(
    className,
    linkStyled ? styles.link : styles.button,
    theme && styles[linkStyled ? `${theme}ThemeLink` : `${theme}ThemeButton`],
    type === 'submit' && styles.submitButton,
    styles[size],
  );

  const shouldShowLoader = isLoading && !linkStyled;

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={isLoading || disabled}
      className={buttonClasses}
      {...rest}
    >
      {shouldShowLoader && (
        <span data-testid="loader" className={styles.spinIcon}>
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        </span>
      )}

      <span
        data-testid="buttonContentContainer"
        className={classnames(
          shouldShowLoader && styles.hidden,
          styles.contentWrapper,
        )}
      >
        {icon && <FontAwesomeIcon data-testid="icon" icon={getIcon(icon)} />}
        <span className={classnames(underlined && styles.underlined)}>
          {children}
        </span>
      </span>
    </button>
  );
};

export default Button;
