import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { getCountByWindowHeight, Heights } from 'utils/getCountByWindowHeight';
import { useGetCompanyAffiliateApplicationsLazyQuery } from '../queries/generated/GetCompanyAffiliateApplications';
import { useSearchContext } from '../../../contexts/SearchContext';

const cellHeight = Heights.defaultCellHeight;

const COUNT_PER_PAGE = 10;

export const useCompanyAffiliateApplicationsData = () => {
  const { searchValue } = useSearchContext();

  const [variables, setVariables] = useState({
    first: getCountByWindowHeight(cellHeight, 0),
  });

  useEffect(() => {
    setVariables((oldVariables) => ({
      ...oldVariables,
      search: searchValue,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    loadCompanyAffiliateApplications,
    { data, loading, error, fetchMore, refetch },
  ] = useGetCompanyAffiliateApplicationsLazyQuery();

  const loadQualifiedReferralsListCallback = useCallback(
    () => loadCompanyAffiliateApplications({ variables }),
    [loadCompanyAffiliateApplications, variables],
  );

  useBlockComponentState({
    loadData: loadQualifiedReferralsListCallback,
    loading,
    error,
  });

  const companyAffiliateApplicationsList = useMemo(
    () => data?.companyAffiliateApplications?.edges.map(({ node }) => node),
    [data?.companyAffiliateApplications?.edges],
  );

  const endCursor = data?.companyAffiliateApplications?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.companyAffiliateApplications?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  return {
    refetchCompanyAffiliateApplicationsList: refetch,
    companyAffiliateApplicationsList,
    loadCompanyAffiliateApplications: () =>
      loadCompanyAffiliateApplications({
        variables: { ...variables, search: searchValue },
      }),
  };
};
