import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCellAlign } from 'ui/Table/const';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { BonusConfig } from 'generatedGraphql';
import { twoEmDash } from 'src/const';
import { BonusData } from '../queries/generated/BonusData';
import { getStatusConfig, getTypeBonus } from '../helpers';
import {
  blockColumnsIds,
  ColumnId,
  mapColumnIdToName,
  pageColumnsIds,
} from '../const';

const columnHelper = createColumnHelper<BonusData>();

const getBetSumCell = (config: BonusConfig) => {
  const minAccountBalance = config.winConditions?.betSum;

  return MayBeNullCell(
    minAccountBalance &&
      formatSumWithCurrency(
        minAccountBalance.amount,
        minAccountBalance.currency,
      ),
    twoEmDash,
  );
};

const columnsSource: ColumnsSource<BonusData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.Type, {
    id: ColumnId.Type,
    header: mapColumnIdToName[ColumnId.Type],
    cell: ({ row }) => {
      const [title, iconConfig, subTitle] = getTypeBonus(row.original);

      return MultilineCellWithIcon({ title, iconConfig, subTitle });
    },
  }),
  columnHelper.accessor(ColumnId.Sum, {
    id: ColumnId.Sum,
    header: mapColumnIdToName[ColumnId.Sum],
    cell: ({ row }) => {
      const { amount, currency } = row.original.sum;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.Wager, {
    id: ColumnId.Wager,
    header: mapColumnIdToName[ColumnId.Wager],
    cell: ({ row }) => {
      const { winConditions } = row.original.config;

      if (winConditions) {
        return MayBeNullCell(winConditions.wager?.toString(), twoEmDash);
      }

      return null;
    },
  }),
  columnHelper.accessor(ColumnId.Cashout, {
    id: ColumnId.Cashout,
    header: mapColumnIdToName[ColumnId.Cashout],
    cell: ({ row }) => {
      const { config } = row.original;

      if (config) {
        MayBeNullCell(config.cashout, twoEmDash);
      }

      return null;
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.MinBalance, {
    id: ColumnId.MinBalance,
    header: mapColumnIdToName[ColumnId.MinBalance],
    cell: ({ row }) => {
      const { minAccountBalance } = row.original.config;

      return MayBeNullCell(
        minAccountBalance &&
          formatSumWithCurrency(
            minAccountBalance.amount,
            minAccountBalance.currency,
          ),
        twoEmDash,
      );
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.BetSum, {
    id: ColumnId.BetSum,
    header: mapColumnIdToName[ColumnId.BetSum],
    cell: ({ row }) => {
      const { config } = row.original;

      if (config) {
        getBetSumCell(config);
      }

      return null;
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.WinSum, {
    id: ColumnId.WinSum,
    header: mapColumnIdToName[ColumnId.WinSum],
    cell: ({ row }) => {
      const { winSum } = row.original;

      return MayBeNullCell(
        winSum && formatSumWithCurrency(winSum.amount, winSum.currency),
        twoEmDash,
      );
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.Balance, {
    id: ColumnId.Balance,
    header: mapColumnIdToName[ColumnId.Balance],
    cell: ({ row }) => {
      const { balance } = row.original;

      return formatSumWithCurrency(balance.amount, balance.currency);
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(ColumnId.Status, {
    id: ColumnId.Status,
    header: mapColumnIdToName[ColumnId.Status],
    cell: ({ row }) => {
      const [title, iconConfig] = getStatusConfig(row.original.status);

      return MultilineCellWithIcon({ title, iconConfig });
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(ColumnId.UpdatedAt, {
    id: ColumnId.UpdatedAt,
    header: mapColumnIdToName[ColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetBonusesColumns = (isFullScreenTable: boolean) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource,
  );

  return {
    columns,
  };
};
