import { FC, useMemo } from 'react';
import classnames from 'classnames';
import { CardStatusColor } from 'ui/Card/CardDataList/CardDataList';
import capitalize from 'utils/capitalize';
import generateUniqueKeys from 'utils/uniqueKeys';
import styles from './CardInfo.module.scss';

interface Props {
  info: Array<string | JSX.Element>;
  statusColor?: CardStatusColor;
}

const CardInfo: FC<Props> = ({ info, statusColor }) => {
  const infoWithKeys = useMemo(() => info && generateUniqueKeys(info), [info]);

  return (
    <div className={styles.info}>
      {statusColor && (
        <div
          className={classnames(
            styles.status,
            styles[`statusColor${capitalize(statusColor)}`],
          )}
        />
      )}
      {infoWithKeys.map(
        ({ payload, id }) => payload && <span key={id}>{payload}</span>,
      )}
    </div>
  );
};

export default CardInfo;
