import React, { FC, ReactNode } from 'react';
import { Checkbox, CheckboxSize } from 'ui/Checkbox';
import classnames from 'classnames';
import styles from './FormWarning.module.scss';

interface Props {
  title?: string | ReactNode;
  subTitle: string;
  children: ReactNode;
  checkbox?: {
    label: string;
    name: string;
    type?: 'important' | 'default';
    checkboxSize?: CheckboxSize;
    className?: string;
    wrapperClassName?: string;
  };
  onChangeCheckboxValue?: (value: boolean) => void;
}

const FormWarning: FC<Props> = ({
  title,
  subTitle,
  children,
  checkbox,
  onChangeCheckboxValue,
}) => (
  <div className={styles.descWrapper}>
    {title && <h3 className={styles.descTitle}>{title}</h3>}
    <div className={styles.descSubTitle}>{subTitle}</div>
    <div className={styles.descContent}>{children}</div>
    {checkbox && (
      <Checkbox
        label={checkbox.label}
        name={checkbox.name}
        checkboxSize={checkbox.checkboxSize}
        className={checkbox.className}
        wrapperClassName={classnames(
          styles.checkboxWrapper,
          checkbox.wrapperClassName,
        )}
        onChange={onChangeCheckboxValue}
        errorTooltipPlacement="bottom-start"
      />
    )}
  </div>
);

export default FormWarning;
