import { UserPrivilege } from 'generatedUserPrivilege';
import { useCallback } from 'react';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetExportCompanyRevShareReportLazyQuery } from '../queries/generated/GetExportCompanyRevShareReport';
import { useExportReportHandlers } from '../../../helpers/useHandleCompleteExportReport';
import { useGetCurrency } from '../../../hooks/useGetCurrency';

interface UseGetExportCompanyRevShareReportParams {
  companyId: string;
}

export const useGetExportCompanyRevShareReport = ({
  companyId,
}: UseGetExportCompanyRevShareReportParams) => {
  const period = useGetPeriodOfStatistic();
  const {
    handleCompleteExportReport,
    handleErrorExportReport,
    downloadReportFileLoading,
  } = useExportReportHandlers();

  const [exportReport, { loading: getExportReportLinkLoading }] =
    useGetExportCompanyRevShareReportLazyQuery({
      onCompleted: (data) => {
        handleCompleteExportReport(data.company.exportRevShareReport);
      },
      onError: handleErrorExportReport,
    });

  const { currency } = useGetCurrency();

  const getReport = useCallback(() => {
    exportReport({
      variables: {
        id: companyId,
        currency,
        period,
      },
    });
  }, [companyId, currency, exportReport, period]);

  return {
    getReport,
    loading: getExportReportLinkLoading || downloadReportFileLoading,
    privilege: UserPrivilege.ExportCompanyRevShareReport,
  };
};
