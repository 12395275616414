import { SignInRestrictionStatus } from 'generatedGraphql';
import { CardIconConfig } from 'ui/Card/CardIcon';
import { signInRestrictionStatusMap } from '../../../../../../../const';
import { getSignInRestrictionStatusIcon } from './getSignInRestrictionStatusIcon';

export const getStatusConfig = (
  status: SignInRestrictionStatus,
): [string, CardIconConfig] => {
  const formattedStatus = signInRestrictionStatusMap[status];
  const iconConf = getSignInRestrictionStatusIcon(status);

  return [formattedStatus, iconConf];
};
