import { FC, useMemo } from 'react';
import { generatePath } from 'react-router';
import { withBlockProvider } from 'ui/Block/store';
import { Route } from 'src/router/routes.const';
import { OutgoingMoneyTransfersTable } from './components/OutgoingMoneyTransfersTable';
import { useGetOutgoingMoneyTransfersColumns } from './components/OutgoingMoneyTransfersTable/hooks/useGetOutgoingMoneyTransfersColumns';

interface Props {
  id: string;
}

export const OutgoingMoneyTransfersBlock: FC<Props> = withBlockProvider(
  ({ id }) => {
    const { columns } = useGetOutgoingMoneyTransfersColumns(false);

    const routeToCustomPage = useMemo(
      () => generatePath(Route.PARTNER_OUTGOING_MONEY_TRANSFERS, { id }),
      [id],
    );

    return (
      <OutgoingMoneyTransfersTable
        columns={columns}
        partnerId={id}
        routeToCustomPage={routeToCustomPage}
        isFullScreenTable={false}
      />
    );
  },
);
