import { UserPrivilege } from 'generatedUserPrivilege';
import { useCallback } from 'react';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetExportReferralLinkMediaItemRevShareReportLazyQuery } from '../queries/generated/GetExportReferralLinkMediaItemRevShareReport';
import { useExportReportHandlers } from '../../../helpers/useHandleCompleteExportReport';
import { useGetCurrency } from '../../../hooks/useGetCurrency';

interface UseGetExportReferralLinkMediaItemRevShareReportParams {
  referralLinkId: string;
}

export const useGetExportReferralLinkMediaItemRevShareReport = ({
  referralLinkId,
}: UseGetExportReferralLinkMediaItemRevShareReportParams) => {
  const period = useGetPeriodOfStatistic();
  const {
    handleCompleteExportReport,
    handleErrorExportReport,
    downloadReportFileLoading,
  } = useExportReportHandlers();

  const [exportReport, { loading: getExportReportLinkLoading }] =
    useGetExportReferralLinkMediaItemRevShareReportLazyQuery({
      onCompleted: (data) => {
        handleCompleteExportReport(data.mediaItem.exportRevShareReport);
      },
      onError: handleErrorExportReport,
    });

  const { currency } = useGetCurrency();

  const getReport = useCallback(() => {
    exportReport({
      variables: {
        id: referralLinkId,
        currency,
        period,
      },
    });
  }, [currency, exportReport, period, referralLinkId]);

  return {
    getReport,
    loading: getExportReportLinkLoading || downloadReportFileLoading,
    privilege: UserPrivilege.ExportReferralLinkMediaItemRevShareReport,
  };
};
