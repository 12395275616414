import { GraphQLRequest } from '@apollo/client';
import { CustomFetchOptions } from './types';
import { uploadFetch } from './uploadFetch';

export const apolloFetch = (
  uri: URL | RequestInfo,
  options?: CustomFetchOptions,
) => {
  if (!options) {
    return fetch(uri);
  }

  const fetchFn =
    options.onProgress || options.onAbortPossible ? uploadFetch : fetch;

  const body: GraphQLRequest =
    options.body instanceof FormData
      ? JSON.parse(options.body.get('operations')?.toString() || '')
      : JSON.parse(options.body?.toString() || '');

  const operationName = body.operationName || '';

  return fetchFn(`${uri}?name=${operationName}`, options);
};
