import { FC } from 'react';
import { Formik, Form, FormikErrors } from 'formik';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import Input from 'ui/Input';
import Error from 'ui/Error';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import { ApolloError } from '@apollo/client';
import { UpdateClientIdentitiesPayload } from './types';

interface Props {
  phone?: string | null;
  email?: string | null;
  isLoading: boolean;
  validate?: (
    values: UpdateClientIdentitiesPayload,
  ) => FormikErrors<UpdateClientIdentitiesPayload>;
  error?: ApolloError;
  onUpdateClientIdentities: (values: UpdateClientIdentitiesPayload) => void;
}

const UpdateClientIdentities: FC<Props> = ({
  phone,
  email,
  validate,
  onUpdateClientIdentities,
  error,
  isLoading,
}) => {
  const isPhoneAvailable = phone !== undefined;
  const initialValues: UpdateClientIdentitiesPayload = {
    phone: !isPhoneAvailable ? undefined : phone,
    email: email || null,
  };

  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      onSubmit={onUpdateClientIdentities}
      enableReinitialize
    >
      <Form>
        {error && <Error error={error} />}
        <FormRowsWrapper>
          <FormRow isWide>
            <Input name="email" label="Почта" />
          </FormRow>

          {isPhoneAvailable && (
            <FormRow isWide>
              <Input name="phone" label="Телефон" />
            </FormRow>
          )}
        </FormRowsWrapper>

        <Button
          size={ButtonSize.Large}
          theme={ButtonTheme.Process}
          type="submit"
          isLoading={isLoading}
        >
          Изменить
        </Button>
      </Form>
    </Formik>
  );
};

export default UpdateClientIdentities;
