import { Dispatch, FC, SetStateAction } from 'react';
import classnames from 'classnames';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { ActionButton, ButtonSize, ButtonType } from 'ui/ActionButton';
import styles from './DatePeriodLabel.module.scss';
import { useSetDefaultPeriod } from './hooks/useSetDefaultPeriod';
import { statisticPeriod } from '../../../../const';

interface Props {
  isDatePickerOpened: boolean;
  period: {
    from: string;
    to: string;
  };
  setIsDatePickerOpened: Dispatch<SetStateAction<boolean>>;
}

export const DatePeriodLabel: FC<Props> = ({
  isDatePickerOpened,
  period,
  setIsDatePickerOpened,
}) => {
  const isSelectedDefaultPeriod =
    statisticPeriod.currentDay === period.to &&
    statisticPeriod.firstDayOfCurrentMonth === period.from;

  const setDefaultPeriod = useSetDefaultPeriod();

  const periodFrom = formatDate(
    period.from,
    DateFormat.DateWithFullYearMonthDate,
  );
  const periodTo = formatDate(period.to, DateFormat.DateWithFullYearMonthDate);

  return (
    <div
      className={classnames(
        styles.datePeriodLabel,
        isDatePickerOpened && styles.active,
        isSelectedDefaultPeriod && styles.withoutButton,
      )}
      onClick={() => setIsDatePickerOpened(!isDatePickerOpened)}
    >
      <div className={styles.labelText}>Период статистики:</div>
      <div className={styles.period}>{`${periodFrom} – ${periodTo}`}</div>
      {!isSelectedDefaultPeriod && (
        <div className={styles.closeButtonContainer}>
          <ActionButton
            dataTestId="closeButton"
            noHoverBg
            size={ButtonSize.Small}
            actionType={ButtonType.Close}
            onClick={setDefaultPeriod}
          />
        </div>
      )}
    </div>
  );
};
