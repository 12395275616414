import NowrapCell from 'ui/Table/components/NowrapCell/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell/MayBeNullCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon/MultilineCellWithIcon';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { twoEmDash } from 'src/const';
import {
  blockColumnsIds,
  mapColumnIdToName,
  pageColumnsIds,
  ColumnId,
} from '../const';
import { signInRestrictionProtectedToType } from '../../../helpers';
import { SignInRestrictionHistoryData } from '../../../queries/generated/SignInRestrictionHistoryData';
import { getStatusConfig } from '../helpers';

const columnHelper = createColumnHelper<SignInRestrictionHistoryData>();

const columnsSource: ColumnsSource<SignInRestrictionHistoryData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.Type, {
    id: ColumnId.Type,
    header: mapColumnIdToName[ColumnId.Type],
    cell: ({ row }) => signInRestrictionProtectedToType(row.original.protected),
  }),
  columnHelper.accessor(ColumnId.Reason, {
    id: ColumnId.Reason,
    header: mapColumnIdToName[ColumnId.Reason],
  }),
  columnHelper.accessor(ColumnId.Status, {
    id: ColumnId.Status,
    header: mapColumnIdToName[ColumnId.Status],
    cell: ({ row }) => {
      const [title, iconConfig] = getStatusConfig(row.original.status);

      return MultilineCellWithIcon({ title, iconConfig });
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.lockedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(ColumnId.ExpiredAt, {
    id: ColumnId.ExpiredAt,
    header: mapColumnIdToName[ColumnId.ExpiredAt],
    cell: ({ row }) => {
      const { expiredAt } = row.original;

      return MayBeNullCell(
        expiredAt && (
          <NowrapCell>{formatDate(expiredAt, DateFormat.FullDate)}</NowrapCell>
        ),
        twoEmDash,
      );
    },
  }),
];

export const useGetSignInRestrictionsHistoryColumns = (
  isFullScreenTable: boolean,
) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource,
  );

  return {
    columns,
  };
};
