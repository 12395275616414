import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useParams } from 'react-router';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { ExternalMoneyTransferItem } from '../../ExternalMoneyTransferItem';
import { PlayerExternalMoneyTransferData } from '../queries/generated/PlayerExternalMoneyTransferData';

interface UseOpenExternalMoneyTransferModalResult<T> {
  openModal: (arg: T, initiatorId: string) => void;
}

export const useOpenExternalMoneyTransferModal = <
  T extends object,
>(): UseOpenExternalMoneyTransferModalResult<T> => {
  const { dispatch } = useContext(ModalContext);
  const { id: playerId } = useParams();

  const openModal = useCallback(
    (externalMoneyTransfer: T, initiatorId: string) => {
      if (!playerId) {
        return;
      }

      const { id } = externalMoneyTransfer as PlayerExternalMoneyTransferData;

      dispatch({
        type: ModalActions.Open,
        payload: {
          title: addHashTagToId(id),
          subTitle: 'Внешний перевод',
          content: (
            <ExternalMoneyTransferItem
              externalMoneyTransferId={id}
              playerId={playerId}
            />
          ),
          initiatorId,
        },
      });
    },
    [dispatch, playerId],
  );

  return {
    openModal,
  };
};
