export interface ComparisonResult {
  shouldShowOverForm?: boolean;
  delta?: number;
}

const compareLimitWithPayment = (paymentAmount: number, limit: number) => {
  const delta = limit - paymentAmount;
  const comparisonResult: ComparisonResult = {};

  if (delta < 0) {
    comparisonResult.delta = delta;
    comparisonResult.shouldShowOverForm = false;
  } else if (delta === 0) {
    comparisonResult.shouldShowOverForm = true;
  }

  return comparisonResult;
};

const getComparisonResultByPaymentMethodType = (
  paymentAmount: number,
  dailyLimit: number,
  monthlyCryptoLimit: number,
  isCryptoPaymentMethod?: boolean,
) => {
  if (isCryptoPaymentMethod) {
    return compareLimitWithPayment(paymentAmount, monthlyCryptoLimit);
  }

  return compareLimitWithPayment(paymentAmount, dailyLimit);
};

export const getComparisonResult = (
  loading: boolean,
  paymentAmount?: number,
  dailyLimit?: number,
  monthlyCryptoLimit?: number,
  isCryptoPaymentMethod?: boolean,
  previousDailyLimit?: number,
  previousMonthlyCryptoLimit?: number,
): ComparisonResult | undefined => {
  if (paymentAmount && dailyLimit && monthlyCryptoLimit) {
    return getComparisonResultByPaymentMethodType(
      paymentAmount,
      dailyLimit,
      monthlyCryptoLimit,
      isCryptoPaymentMethod,
    );
  }

  /*
   * Previous values from query need to prevent refreshing warning block
   * when user convert payment currency
   * */
  if (
    loading &&
    paymentAmount &&
    previousDailyLimit &&
    previousMonthlyCryptoLimit
  ) {
    return getComparisonResultByPaymentMethodType(
      paymentAmount,
      previousDailyLimit,
      previousMonthlyCryptoLimit,
      isCryptoPaymentMethod,
    );
  }

  return undefined;
};
