import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useSearchContext } from 'src/contexts/SearchContext';
import { useGetQualifiedReferralsLazyQuery } from '../queries/generated/GetQualifiedReferrals';

const cellHeight = Heights.defaultCellHeight;
const COUNT_PER_PAGE = 10;

export const useGetQualifiedReferrals = () => {
  const { searchValue } = useSearchContext();

  const [variables, setVariables] = useState({
    first: getCountByWindowHeight(cellHeight, 0),
  });

  useEffect(() => {
    setVariables((oldVariables) => ({
      ...oldVariables,
      search: searchValue,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    loadQualifiedReferralsList,
    { data, loading, error, fetchMore, refetch },
  ] = useGetQualifiedReferralsLazyQuery();

  const loadQualifiedReferralsListCallback = useCallback(
    () => loadQualifiedReferralsList({ variables }),
    [loadQualifiedReferralsList, variables],
  );

  useBlockComponentState({
    loadData: loadQualifiedReferralsListCallback,
    loading,
    error,
  });

  const hasNextPage = data?.qualifiedReferrals?.pageInfo.hasNextPage;
  const endCursor = data?.qualifiedReferrals?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  const qualifiedReferralsList = useMemo(
    () => data?.qualifiedReferrals?.edges.map(({ node }) => node),
    [data?.qualifiedReferrals?.edges],
  );

  return {
    loadQualifiedReferralsList: () =>
      loadQualifiedReferralsList({
        variables: { ...variables, search: searchValue },
      }),
    data,
    loading,
    error,
    refetch,
    hasNextPage,
    fetchMoreCallback,
    qualifiedReferralsList,
  };
};
