import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { useLocation } from 'react-router';
import { AccountEventGroupBasicData } from '../../queries/fragments/generated/AccountEventGroupBasicData';
import { AccountEventGroupData } from '../../queries/fragments/generated/AccountEventGroupData';
import { useAccountEventGroupModal } from '../../hooks/useAccountEventGroupModal';
import { useGetAccountEventGroupsColumns } from './hooks/useGetAccountEventGroupsColumns';

interface Props {
  eventGroups?: Array<AccountEventGroupBasicData | AccountEventGroupData>;
  isFullScreenTable: boolean;
}

export const AccountEventGroupsTable: FC<Props> = ({
  eventGroups,
  isFullScreenTable,
}) => {
  const location = useLocation();

  const openAccountEventsModal = useAccountEventGroupModal();
  const { columns } = useGetAccountEventGroupsColumns(isFullScreenTable);

  const handleRowClick = (
    accountEventGroup: AccountEventGroupBasicData | AccountEventGroupData,
    initiatorId: string,
  ) => {
    openAccountEventsModal({
      accountEventGroup,
      initiatorId,
    });
  };

  return (
    <BlockTable
      onClickOnRow={handleRowClick}
      columns={columns}
      data={eventGroups}
      routeToCustomPage={
        isFullScreenTable ? undefined : `${location.pathname}/accounts-history`
      }
      isFullScreenTable={isFullScreenTable}
    />
  );
};
