import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { useFormikContext } from 'formik';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { CreatePromoCodeModalSteps, FieldName } from '../../const';
import styles from './CreatePromoCodeFormConfirmStep.module.scss';
import { DataCardMain } from './components/DataCardMain';
import { DataCardFreespinLink } from './components/DataCardFreespinLink';
import { DataCardBonusLink } from './components/DataCardBonusLink';
import { CreatePromoCodeValues } from '../../types';

export const CreatePromoCodeFormConfirmStep: FC = () => {
  const { isValid, values, isSubmitting } =
    useFormikContext<CreatePromoCodeValues>();
  const { updateModalStep } = useUpdateModalStep<CreatePromoCodeModalSteps>();

  return (
    <>
      <DataCardMain />
      {values[FieldName.ConnectionWithFreespins] && <DataCardFreespinLink />}
      {values[FieldName.ConnectionWithBonus] && <DataCardBonusLink />}

      <div className={styles.actionButtons}>
        <Button
          size={ButtonSize.Large}
          theme={ButtonTheme.Process}
          onClick={() =>
            updateModalStep(
              CreatePromoCodeModalSteps.ConnectionWithFreespinsAndBonus,
            )
          }
        >
          Назад
        </Button>

        <Button
          size={ButtonSize.Large}
          theme={ButtonTheme.Success}
          type="submit"
          isLoading={isSubmitting}
          disabled={!isValid}
        >
          Создать
        </Button>
      </div>
    </>
  );
};
