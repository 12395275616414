import { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { useModalManageData } from 'ui/Modal';
import { DataCard } from 'commonComponents/DataCard';
import { DateFormat, formatDate } from 'utils/formatDate';
import { useGetPlayerMessage } from './queries/generated/GetPlayerMessage';
import useDeletePlayerMessage from './useDeleteMessage';
import styles from './MessageItem.module.scss';

interface Props {
  messageId: string;
  isFullScreenTable: boolean;
}

const MessageItem: FC<Props> = ({ messageId, isFullScreenTable }) => {
  const { data, loading, error } = useGetPlayerMessage({
    variables: {
      id: messageId,
    },
  });

  const { deleteMessage } = useDeletePlayerMessage(
    messageId,
    isFullScreenTable,
  );

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-player-message-error' },
    hasData: !!data,
  });

  if (!data) {
    return null;
  }

  const { createdAt, author, text, deletable } = data.message;

  const name = `${author.firstName} ${author.lastName}`;

  return (
    <>
      <div className={styles.text}>{text}</div>

      <DataCard
        rows={[
          { title: 'Автор', value: name },
          {
            title: 'Создан',
            value: formatDate(createdAt, DateFormat.FullDate),
          },
        ]}
        customFooter={
          deletable && (
            <Button
              onClick={deleteMessage}
              type="submit"
              isLoading={loading}
              theme={ButtonTheme.Important}
            >
              Удалить
            </Button>
          )
        }
      />
    </>
  );
};

export default MessageItem;
