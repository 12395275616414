import { useCallback } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import Block from 'ui/Block';
import BlockTable from 'ui/Block/BlockTable';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { ColumnDef } from '@tanstack/react-table';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useCreateFreespin } from '../../hooks/useCreateFreespin';
import { useFreespinOpenModal } from '../../hooks/useFreespinOpenModal';
import FreespinsBlockHeaderContent from '../FreespinsBlockHeaderContent';
import { useGetFreespinsByTableSize } from '../../hooks/useGetFreespinsByTableSize';

const countPerPage = 10;

interface Props<T extends object> {
  playerId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

const FreespinsTable = <T extends { __typename: string }>({
  playerId,
  columns,
  shouldReturnToPrevPage,
  routeToCustomPage,
  isFullScreenTable,
}: Props<T>) => {
  const { openModal } = useFreespinOpenModal<T>();

  const { loadFreespins, data, loading, error, fetchMore, refetch } =
    useGetFreespinsByTableSize(playerId, isFullScreenTable);

  const freespins = data?.player.freespins;
  const freespinsList =
    freespins &&
    (freespins.edges.map(({ node }) => node) as unknown as Array<T>);
  const hasNextPage = data?.player.freespins.pageInfo.hasNextPage;

  const { onCreateFreespin } = useCreateFreespin<T>(
    playerId,
    isFullScreenTable,
    freespinsList,
  );

  useBlockComponentState({
    loadData: loadFreespins,
    loading,
    error,
  });

  const refetchFreespins = () => {
    refetch?.();
  };

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            playerId,
            first: countPerPage,
            after: freespins?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [playerId, freespins?.pageInfo.endCursor, fetchMore],
  );

  useBlockTableComponentState({
    data,
    loading,
    hasNextPage,
    isFullScreenTable,
    fetchMoreCallback,
    refetch,
  });

  return (
    <Block
      title="Фриспины"
      id="playerFreespins"
      headerContent={
        <FreespinsBlockHeaderContent
          refetchFreespins={refetchFreespins}
          onCreateFreespin={onCreateFreespin}
        />
      }
      isEmpty={isListEmpty(freespinsList)}
      emptyText="Игроку не начислялись фриспины"
      shrinkLoaderWrapper={false}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(freespinsList) && (
        <BlockTable
          isFullScreenTable={isFullScreenTable}
          data={freespinsList}
          columns={columns}
          onClickOnRow={openModal}
          routeToCustomPage={routeToCustomPage}
        />
      )}
    </Block>
  );
};

export default FreespinsTable;
