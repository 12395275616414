import { getDropdownListByMap } from 'utils/getDropdownListByMap';
import { moneyTransferTypeMap } from '../../../../../const';
import { getMoneyTransferIconConfig } from './getMoneyTransferIconConfig';
import { MoneyTransferType } from '../components/MoneyTransferType';

export const getMoneyTransferTypesList = () =>
  getDropdownListByMap(moneyTransferTypeMap).map(({ label, id }) => {
    const iconConfig = getMoneyTransferIconConfig(id);

    return {
      id,
      label: <MoneyTransferType status={label} iconConfig={iconConfig} />,
      textForSearch: label,
    };
  });
