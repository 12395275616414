import { FormikErrors } from 'formik';
import { DeclinePartnerApplicationInput } from 'generatedGraphql';

export const validate = (
  values: DeclinePartnerApplicationInput,
): FormikErrors<DeclinePartnerApplicationInput> => {
  const errors: FormikErrors<DeclinePartnerApplicationInput> = {};

  if (!values.reason) {
    errors.reason = 'Укажите причину отклонения';
  }

  if (values.reason.length > 100) {
    errors.reason = 'Максимальное количество символов 100';
  }

  return errors;
};
