import { useCallback, useMemo, useState } from 'react';
import { WithdrawalFilterInput } from 'generatedGraphql';
import { WithdrawalFilterMember } from 'commonTypes';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useAuth } from 'commonComponents/Auth';
import { GetWithdrawalsVariables } from '../queries/generated/GetWithdrawals';
import { useGetAvailableWithdrawalFiltersLazyQuery } from '../queries/generated/GetAvailableWithdrawalFilters';
import { allWithdrawalFilters } from '../const';

const COUNT_PER_PAGE = 10;

interface LoadWithdrawalsVariablesWithFilter extends GetWithdrawalsVariables {
  filter: WithdrawalFilterInput;
}

interface LoadWithdrawalsParams {
  variables: LoadWithdrawalsVariablesWithFilter;
}

interface UseGetWithdrawalFiltersParams {
  playerId: string;
  loadWithdrawals: (params: LoadWithdrawalsParams) => void;
}

export const useGetWithdrawalFilter = ({
  playerId,
  loadWithdrawals,
}: UseGetWithdrawalFiltersParams) => {
  const [getAvailableWithdrawalsFiltersData, { data }] =
    useGetAvailableWithdrawalFiltersLazyQuery();
  const auth = useAuth();

  const [isFiltered, setIsFiltered] = useState(false);

  const onSubmitFilter = useCallback(
    (filters: WithdrawalFilterInput) => {
      loadWithdrawals({
        variables: {
          playerId,
          first: COUNT_PER_PAGE,
          filter: filters,
          viewPaymentMethodAccount: auth.privileges.areEveryGranted({
            privileges: [UserPrivilege.ViewPaymentmethodAccount],
          }),
        },
      });

      if (filters && !Object.keys(filters).length) {
        setIsFiltered(false);
      }

      setIsFiltered(true);
    },
    [auth.privileges, loadWithdrawals, playerId],
  );

  const availableFiltersState = useMemo(
    () => data?.player.availableWithdrawalFilters || [],
    [data?.player.availableWithdrawalFilters],
  );

  const onChangeFilters = useCallback(
    (filters: Array<WithdrawalFilterMember>) => {
      getAvailableWithdrawalsFiltersData({
        variables: {
          id: playerId,
          usedFilters: filters,
        },
      });
    },
    [getAvailableWithdrawalsFiltersData, playerId],
  );

  const availableWithdrawalFilters = useMemo(
    () =>
      allWithdrawalFilters.filter((item) =>
        availableFiltersState.includes(item.filterId),
      ),
    [availableFiltersState],
  );

  return {
    onChangeFilters,
    availableWithdrawalFilters,
    onSubmitFilter,
    isFiltered,
  };
};
