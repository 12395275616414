import { nbsp, rewardTariffMap, StatisticOrdering } from 'src/const';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { RewardTariffType } from 'commonTypes';
import {
  AffiliateReportOrderBy,
  CompanyPartnerReportOrderBy,
  GeneralReportOrderBy,
  MediaItemReportOrderBy,
  OrderDirection,
} from 'generatedGraphql';

export const GENERAL_STATISTIC_BLOCK_TITLE = 'Статистика';
export const STATISTIC_NO_DATA = `Данных за${nbsp}этот период нет`;
export const STATISTIC_TOTAL_ROW_TITLE = `Итоговый${nbsp}показатель`;
export const CPA_PARTNER_STATISTIC_BLOCK_TITLE = `Детализация по${nbsp}партнёру ${
  rewardTariffMap[RewardTariffType.Cpa]
}`;
export const REV_SHARE_COMPANY_PARTNERS_STATISTIC_BLOCK_TITLE = `Детализация по${nbsp}компании ${
  rewardTariffMap[RewardTariffType.RevShare]
}`;
export const CPA_COMPANY_PARTNERS_STATISTIC_BLOCK_TITLE = `Детализация по${nbsp}компании ${
  rewardTariffMap[RewardTariffType.Cpa]
}`;

const today = new Date().toISOString();
const formattedToday = formatDate(today, DateFormat.DefaultDateWithDash);
const firstDayOfMonth = new Date(new Date().setDate(1)).toISOString();
const formattedFirstDayOfMonth = formatDate(
  firstDayOfMonth,
  DateFormat.DefaultDateWithDash,
);

export const statisticPeriod: {
  firstDayOfCurrentMonth: string;
  currentDay: string;
} = {
  firstDayOfCurrentMonth: formattedFirstDayOfMonth,
  currentDay: formattedToday,
};

export const clearableSearchParamsByTabChange: Array<string> = [
  StatisticOrdering.OrderBy,
  StatisticOrdering.Direction,
];

export const GENERAL_REPORT_SEARCH_PLACEHOLDER = 'Поиск партнёра / компании';
export const GENERAL_REPORT_INPUT_NAME = 'generalReportSearch';

export const defaultGeneralReportOrderBy = GeneralReportOrderBy.AllDeposit;
export const defaultAffiliateReportOrderBy = AffiliateReportOrderBy.AllDeposit;
export const defaultCompanyPartnerReportOrderBy =
  CompanyPartnerReportOrderBy.AllDeposit;
export const defaultMediaItemReportOrderBy = MediaItemReportOrderBy.AllDeposit;
export const defaultDirection = OrderDirection.Desc;
