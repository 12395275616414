import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Filter, FormattedFilters } from 'ui/TableFilter/types';
import { useTableFilterState } from 'ui/TableFilter/hooks/useTableFilterState';

interface UseTableFilterResult {
  removeAllFilters: () => void;
  choiceFilter: (id: string) => void;
  handleSubmit: () => void;
  isEditFilterValue: boolean;
  selectedFilters: Array<Filter>;
  deleteFilter: (id: string) => void;
  setFilterValue: (id: string, value: string) => void;
  toggleFilter: (isOpened: boolean, id: string) => void;
}

interface UseTableFilterParams<T> {
  setIsOpenedFiltersList: Dispatch<SetStateAction<boolean>>;
  onSubmit: (filters: FormattedFilters) => void;
  onChangeFilters: (filterNames: Array<T>) => void;
}

export const useTableFilter = <T extends string>({
  setIsOpenedFiltersList,
  onSubmit,
  onChangeFilters,
}: UseTableFilterParams<T>): UseTableFilterResult => {
  const [isEditFilterValue, setIsEditFilterValue] = useState(false);
  const {
    selectedFilters,
    setSelectedFilterId,
    removeAllSelectedFilters,
    deleteFilterFromState,
    setFilterValue,
    toggleFilter,
  } = useTableFilterState();

  useEffect(() => {
    const lastFilter = selectedFilters.at(-1);

    setIsEditFilterValue(!!lastFilter && !lastFilter?.selectedFilterValue);
  }, [selectedFilters]);

  useEffect(() => {
    const filterChangedWithEmptyValue = selectedFilters.find(
      (item) => item.selectedFilterValue === null,
    );

    if (filterChangedWithEmptyValue) {
      return;
    }

    const stateFilters = selectedFilters.map(
      ({ selectedFilterId }) => selectedFilterId as T,
    );

    onChangeFilters(stateFilters);
  }, [onChangeFilters, selectedFilters]);

  const removeAllFilters = () => {
    removeAllSelectedFilters();
    onSubmit({});
  };

  const deleteFilter = (selectedFilterId: string) => {
    const isLastFilter =
      selectedFilters.length === 1 &&
      selectedFilters[0].selectedFilterId === selectedFilterId;

    deleteFilterFromState(selectedFilterId);

    if (isLastFilter) {
      onSubmit({});
    }
  };

  const choiceFilter = (id: string) => {
    setIsOpenedFiltersList(false);

    setSelectedFilterId(id);
  };

  const handleSubmit = () => {
    setIsOpenedFiltersList(false);

    const filters = selectedFilters.reduce<FormattedFilters>(
      (acc, filter) => ({
        ...acc,
        [filter.selectedFilterId]: filter.selectedFilterValue || '',
      }),
      {},
    );

    onSubmit(filters);
  };

  return {
    choiceFilter,
    removeAllFilters,
    isEditFilterValue,
    handleSubmit,
    selectedFilters,
    deleteFilter,
    setFilterValue,
    toggleFilter,
  };
};
