import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { getCountByWindowHeight, Heights } from 'utils/getCountByWindowHeight';
import {
  useGetCompaniesLazyQuery,
  GetCompaniesVariables,
} from '../queries/generated/GetCompanies';
import { useSearchContext } from '../../../contexts/SearchContext';

const cellHeight = Heights.defaultCellHeight;
const COUNT_PER_PAGE = 10;

export const useCompaniesList = () => {
  const { searchValue } = useSearchContext();
  const baseVariables: GetCompaniesVariables = {
    first: getCountByWindowHeight(cellHeight, 0),
  };

  const [companiesVariables, setCompaniesVariables] = useState(baseVariables);

  useEffect(() => {
    setCompaniesVariables({
      ...baseVariables,
      search: searchValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loadCompaniesList, { data, loading, error, fetchMore, refetch }] =
    useGetCompaniesLazyQuery({
      variables: companiesVariables,
    });

  useBlockComponentState({
    loadData: loadCompaniesList,
    loading,
    error,
  });

  const companiesList = useMemo(
    () => data?.companies?.edges.map(({ node }) => node),
    [data?.companies?.edges],
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: data?.companies?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [data?.companies?.pageInfo.endCursor, fetchMore],
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.companies?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  return {
    loadCompanies: () =>
      loadCompaniesList({
        variables: { ...companiesVariables, search: searchValue },
      }),
    refetchCompanies: refetch,
    companiesList,
  };
};
