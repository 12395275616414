import { useEffect, useState } from 'react';
import { AccessToRevShareRewardTariff } from 'generatedGraphql';
import { AccessToTariffsInitialValues } from '../types';

export interface Params {
  tariffs?: Array<AccessToRevShareRewardTariff>;
}

export const useGetInitialValues = ({ tariffs }: Params) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    const initialValues: AccessToTariffsInitialValues = {};

    tariffs?.forEach(({ id, accessible }) => {
      initialValues[id] = accessible;
    });

    setValues(initialValues);
  }, [tariffs]);

  return values;
};
