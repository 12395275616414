import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { getCountryISOtoDisplayName } from 'src/utils/getCountryISOtoDisplayName';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { UserPrivilege } from 'generatedUserPrivilege';
import Privilege from 'commonComponents/Privilege';
import { useCpaRewardTariffModal } from './hooks/useCpaRewardTariffModal';
import { CpaRewardTariffsModalSteps } from './const';
import { EditTariffModal } from '../EditTariffModal/EditTariffModal';

interface Props {
  tariffId: string;
}

export const CpaRewardTariffModal: FC<Props> = ({ tariffId }) => {
  const { goToEditName, cpaRewardTariff, step } = useCpaRewardTariffModal({
    tariffId,
  });

  if (!cpaRewardTariff) {
    return null;
  }

  const { title, rewardSum, countries, createdAt, baselineSum } =
    cpaRewardTariff;

  if (step === CpaRewardTariffsModalSteps.EditNameStep) {
    return (
      <EditTariffModal
        tariffId={tariffId}
        title={title}
        rewardAmount={rewardSum.amount}
        countries={countries}
        baselineAmount={baselineSum.amount}
      />
    );
  }

  return (
    <DataCard
      rows={[
        {
          title: 'Название',
          value: title,
        },
        {
          title: 'Модель оплаты',
          value: 'CPA',
        },
        {
          title: 'Геолокация',
          value: getCountryISOtoDisplayName(countries),
        },
        {
          title: 'Baseline',
          value: formatSumWithCurrency(
            baselineSum.amount,
            baselineSum.currency,
          ),
        },
        {
          title: 'Вознаграждение',
          value: formatSumWithCurrency(rewardSum.amount, rewardSum.currency),
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
      ]}
      customFooter={
        <Privilege privileges={[UserPrivilege.UpdateCpaRewardTariff]}>
          <Button
            size={ButtonSize.Large}
            onClick={goToEditName}
            theme={ButtonTheme.Process}
          >
            Редактировать
          </Button>
        </Privilege>
      }
    />
  );
};
