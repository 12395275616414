import { toast } from 'react-toastify';
import { ListAllOperations } from 'generatedGraphql';
import { RewardTariffType } from 'commonTypes';
import { useCreateCompanyAffiliateReferralLinkMediaItem } from 'src/queries/generated/CreateCompanyAffiliateReferralLinkMediaItem';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import errorToast from 'ui/Toast/ErrorToast';
import { ApolloError } from '@apollo/client';
import { CreateAffiliateReferralLinkValues } from '../validation';
import { AffiliateMediaItemsSteps } from '../../AffiliatePromoCodeItem/const';

interface Result {
  onCreate: (
    companyAffiliateId: string,
    values: CreateAffiliateReferralLinkValues,
  ) => void;
  loading: boolean;
  error?: ApolloError;
}

export const useCreateAffiliateReferralLink = (): Result => {
  const { updateModalStep } = useUpdateModalStep<AffiliateMediaItemsSteps>();

  const handleComplete = () => {
    toast.success('Реферальная ссылка создана');

    updateModalStep(AffiliateMediaItemsSteps.BaseStep);
  };

  const handleError = (error: ApolloError) => {
    errorToast({
      header: 'Реферальная ссылка не создана',
      text: error.message,
      toastId: 'create-company-affiliate-referral-link-error',
    });
  };

  const [createCompanyAffiliateReferralLink, { loading, error }] =
    useCreateCompanyAffiliateReferralLinkMediaItem({
      refetchQueries: [ListAllOperations.Query.GetMediaItemsReferralLink],
      onError: handleError,
      onCompleted: handleComplete,
    });

  const onCreate = (
    companyAffiliateId: string,
    {
      name,
      code,
      cpaRewardTariffId,
      fallbackTariffId,
      revShareRewardTariffId,
      typeRewardTariff,
      mediaCampaignId,
    }: CreateAffiliateReferralLinkValues,
  ) => {
    const hasCpaTariff =
      !!cpaRewardTariffId && typeRewardTariff === RewardTariffType.Cpa;
    const hasRevShareTariff =
      !!revShareRewardTariffId &&
      typeRewardTariff === RewardTariffType.RevShare;

    const referralLinkMediaItemInput = {
      name,
      code,
      mediaItemRewardTariff: {
        ...(hasCpaTariff
          ? {
              cpaRewardTariff: {
                id: cpaRewardTariffId,
                fallbackRewardTariffId: fallbackTariffId,
              },
            }
          : null),

        ...(hasRevShareTariff
          ? {
              revShareRewardTariff: {
                id: revShareRewardTariffId,
              },
            }
          : null),
      },
      mediaCampaignId,
    };

    createCompanyAffiliateReferralLink({
      variables: {
        input: {
          referralLinkMediaItem: referralLinkMediaItemInput,
          companyAffiliateId,
        },
      },
    });
  };

  return {
    onCreate,
    loading,
    error,
  };
};
