import { FC, memo, useMemo } from 'react';
import Input from 'ui/Input';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashCan,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { Tooltip } from 'ui/Tooltip';
import { TextOverflow } from 'ui/TextOverflow';
import styles from './DocumentToUpload.module.scss';
import { LoadingFileStatus } from '../../const';

import { fileToImageSrc, getDocumentToUploadData } from '../../helpers';
import { HandleDelete, HandleUpdateNote, LocalFile } from '../../types';
import { getBlurCssStyleValue } from './helpers';
import { DocumentStatusIcon } from '../DocumentStatusIcon';

interface Props {
  document: LocalFile;
  createDocumentsLoading: boolean;
  uploadFileToServer: (item: LocalFile) => Promise<void>;
  onUpdateNote: HandleUpdateNote;
  onDelete: HandleDelete;
}

export const DocumentToUpload: FC<Props> = memo(
  ({
    document,
    createDocumentsLoading,
    uploadFileToServer,
    onDelete,
    onUpdateNote,
  }) => {
    const imageSrc = useMemo(
      () => fileToImageSrc(document.file),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const { id, name, note, status, uploadedPercent } =
      getDocumentToUploadData(document);

    const isError = status === LoadingFileStatus.Error;

    const handleTextareaChange = (value: string) => {
      onUpdateNote(id, value);
    };

    const handleDeleteButtonClick = () => {
      onDelete(id, status);
    };

    const handleRepeatButtonClick = () => {
      uploadFileToServer(document);
    };

    return (
      <div className={styles.mainContainer}>
        <div
          className={classnames(
            styles.imageContainer,
            status !== LoadingFileStatus.Uploaded && styles.darkened,
          )}
        >
          {imageSrc && (
            <img
              style={getBlurCssStyleValue(status, uploadedPercent)}
              className={styles.image}
              src={imageSrc}
              alt="document to upload"
            />
          )}
          <DocumentStatusIcon
            uploadedPercent={uploadedPercent}
            status={status}
            onRepeatButtonClick={handleRepeatButtonClick}
          />
        </div>
        <div className={styles.contentContainer}>
          <div
            className={classnames(
              styles.titleBlock,
              isError && styles.errorInput,
            )}
          >
            <div className={styles.titleTextContainer}>
              {isError && (
                <Tooltip
                  tooltipTriggerClassName={styles.errorIcon}
                  tooltipContent="Не&nbsp;удалось загрузить файл"
                >
                  <FontAwesomeIcon
                    data-testid="errorIcon"
                    icon={faTriangleExclamation}
                  />
                </Tooltip>
              )}
              <div className={styles.title}>
                <TextOverflow key={`${isError}${document.id}`} text={name} />
              </div>
            </div>
            <button
              data-testid="deleteFileButton"
              onClick={handleDeleteButtonClick}
              className={classnames(
                styles.deleteButton,
                createDocumentsLoading && styles.disabledDeleteButton,
              )}
              type="button"
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
          <div className={styles.noteContainer}>
            <Formik initialValues={[]} onSubmit={() => {}}>
              <Input.Textarea
                onChange={handleTextareaChange}
                value={note || ''}
                label="Примечание"
                placeholder="Добавьте примечание"
                name="fileNote"
                disabled={createDocumentsLoading}
                isResizable
              />
            </Formik>
          </div>
        </div>
      </div>
    );
  },
);
