import { Form, Formik } from 'formik';
import { FC } from 'react';
import Error from 'ui/Error';
import { UpdateAccessButtons } from './components/UpdateAccessButtons';
import { useGetPartnersAccessToRevShareRewardTariffs } from './queries/generated/GetPartnersAccessToRevShareRewardTariffs';
import { TariffCard } from './components/TariffCard';
import { AccessToTariffsInitialValues } from './types';
import { useGetInitialValues } from './hooks/useGetInitialValues';
import { useSubmitForm } from './hooks/useSubmitForm';
import { checkIsAllTariffsChecked, changeAllCheckbox } from './helpers';

interface Props {
  partnerId: string;
  isFullScreenTable: boolean;
}

export const UpdateAccessToTariffsModal: FC<Props> = ({
  partnerId,
  isFullScreenTable,
}) => {
  const {
    data,
    loading: getTariffsLoading,
    error,
  } = useGetPartnersAccessToRevShareRewardTariffs({
    variables: {
      partnerId,
    },
  });

  const accessToRevShareRewardTariffs =
    data?.partner.accessToRevShareRewardTariffs;

  const initialValues: AccessToTariffsInitialValues = useGetInitialValues({
    tariffs: accessToRevShareRewardTariffs,
  });

  const { handleSubmitForm, loading: updateTariffsLoading } = useSubmitForm(
    partnerId,
    isFullScreenTable,
  );

  const loading = updateTariffsLoading || getTariffsLoading;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      enableReinitialize
    >
      {({ values, setValues, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <UpdateAccessButtons
            toggleAllTariffs={() => changeAllCheckbox(setValues, values)}
            isAllTariffsSelected={checkIsAllTariffsChecked(values)}
            loading={loading}
            dirty={dirty}
          />
          {accessToRevShareRewardTariffs?.map(({ id, name }) => (
            <TariffCard key={id} id={id} name={name} />
          ))}
        </Form>
      )}
    </Formik>
  );
};
