import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import {
  blockColumnsIds,
  mapColumnIdToName,
  ColumnId,
  pageColumnsIds,
} from '../const';
import { MessageData } from '../components/MessagesTable/queries/generated/MessageData';

const columnHelper = createColumnHelper<MessageData>();

const columnsSource: ColumnsSource<MessageData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.Text, {
    id: ColumnId.Text,
    header: mapColumnIdToName[ColumnId.Text],
  }),
  columnHelper.accessor(ColumnId.Author, {
    id: ColumnId.Author,
    header: mapColumnIdToName[ColumnId.Author],
    cell: ({ row }) => {
      const { author } = row.original;

      if (author) {
        return `${author.firstName} ${author.lastName}`;
      }

      return null;
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => formatDate(row.original.createdAt, DateFormat.FullDate),
  }),
];

export const useGetMessagesColumns = (isFullScreenTable: boolean) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource,
  );

  return {
    columns,
  };
};
