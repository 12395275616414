import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { MediaItemPromoCodeBasicData } from 'src/queries/generated/MediaItemPromoCodeBasicData';
import { MediaItemPromoCodeModal } from '../../MediaItemPromoCodeModal';

interface OpenModalParams {
  mediaItemPromoCode: MediaItemPromoCodeBasicData;
  initiatorId: string;
}

type UseOpenMediaItemPromoCodeModalResult = (params: OpenModalParams) => void;

export const useOpenMediaItemPromoCodeModal =
  (): UseOpenMediaItemPromoCodeModalResult => {
    const { dispatch: modalDispatch } = useContext(ModalContext);

    const openModal = useCallback(
      ({ mediaItemPromoCode: { id }, initiatorId }: OpenModalParams) => {
        modalDispatch({
          type: ModalActions.Open,
          payload: {
            title: `#${id}`,
            subTitle: 'Промокод',
            content: <MediaItemPromoCodeModal mediaItemPromoCodeId={id} />,
            initiatorId,
          },
        });
      },
      [modalDispatch],
    );

    return openModal;
  };
