import { FC, useContext, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { AffiliateCard } from '../../../AffiliateCard';
import styles from './AffiliatesForm.module.scss';
import { useGetProcessAffiliateHandlers } from './hooks/useGetProcessAffiliateHandlers';
import {
  AffiliateInApproveProcessStatus,
  CompanyAffiliateApplicationContext,
} from '../../../../store';
import { useGetInitialValues } from './hooks/useGetInitialValues';
import { ChangeAffiliateStatusButtons } from './components/ChangeAffiliateStatusButtons';
import { LeftContent } from './components/LeftContent/LeftContent';
import { GetCompanyAffiliateApplication } from '../../../../queries/generated/GetCompanyAffiliateApplication';

interface Props {
  affiliates: GetCompanyAffiliateApplication['companyAffiliateApplication']['items'];
  isEditable: boolean;
}

export const AffiliatesForm: FC<Props> = ({ isEditable, affiliates }) => {
  const initialValues = useGetInitialValues({ affiliates });

  const { state } = useContext(CompanyAffiliateApplicationContext);

  const affiliatesInProgressState = useMemo(
    () => ({ ...state.affiliatesInProgress }),
    [state.affiliatesInProgress],
  );

  const {
    resetAffiliateStatus,
    goToEditPartnerCompanyAffiliatesApplicationStep,
  } = useGetProcessAffiliateHandlers();

  return (
    <div data-testid="affiliatesFromBlock">
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form>
            <ChangeAffiliateStatusButtons
              isEditable={isEditable}
              values={values}
              setFieldValue={setFieldValue}
            />
            <div className={styles.affiliatesContainer}>
              {affiliates.map((affiliate) => (
                <AffiliateCard
                  onEditAffiliate={() =>
                    goToEditPartnerCompanyAffiliatesApplicationStep({
                      name: affiliate.name,
                      note: affiliate.note?.text,
                      affiliateId: affiliate.id,
                    })
                  }
                  onResetAffiliateStatus={
                    affiliatesInProgressState[affiliate.id] !==
                    AffiliateInApproveProcessStatus.InProcess
                      ? (id) => resetAffiliateStatus(id, setFieldValue)
                      : undefined
                  }
                  statusOrCheckbox={
                    <LeftContent
                      affiliateId={affiliate.id}
                      affiliatesInProgressState={affiliatesInProgressState}
                      isEditable={isEditable}
                    />
                  }
                  key={affiliate.id}
                  affiliate={affiliate}
                />
              ))}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
