import { CompanyApplicationStatus } from 'generatedGraphql';
import { CardIconColor, CardIconConfig } from 'ui/Card/CardIcon';
import {
  faBan,
  faCircleCheck,
  faHourglassEmpty,
  faSpinner,
} from '@fortawesome/pro-solid-svg-icons';

const statusIconMap: Record<
  CompanyApplicationStatus,
  CardIconConfig | undefined
> = {
  [CompanyApplicationStatus.Active]: {
    icon: faCircleCheck,
    iconColor: CardIconColor.GREEN,
  },
  [CompanyApplicationStatus.Declined]: {
    icon: faBan,
    iconColor: CardIconColor.RED,
  },
  [CompanyApplicationStatus.Pending]: {
    icon: faHourglassEmpty,
    iconColor: CardIconColor.ORANGE,
  },
  [CompanyApplicationStatus.InProgress]: {
    icon: faSpinner,
    iconColor: CardIconColor.BLUE,
  },
  [CompanyApplicationStatus.Unknown]: {
    icon: faBan,
    iconColor: CardIconColor.RED,
  },
};

export const getCompanyApplicationStatusIcon = (
  status: CompanyApplicationStatus,
): CardIconConfig =>
  statusIconMap[status] || {
    icon: faCircleCheck,
    iconColor: CardIconColor.GREEN,
  };
