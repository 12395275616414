import { FC, useContext } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { RewardTariffType } from 'commonTypes';
import { RewardTariffsTabs } from 'commonComponents/RewardTariffsTabs';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useCreateRewardTariffModal } from '../CreateRewardTariff/hooks/useCreateRewardTariffModal';
import { CpaRewardTariffsTable } from './components/CpaRewardTariffsTable';
import { useCpaRewardTariff } from './hooks/useCpaRewardTariff';
import styles from './CpaRewardTariffs.module.scss';

interface Props {
  setRewardTariffsType: (type: RewardTariffType) => void;
}

export const CpaRewardTariffs: FC<Props> = withBlockProvider(
  ({ setRewardTariffsType }) => {
    const openModal = useCreateRewardTariffModal();
    const { refetchCpaRewardTariffs, cpaRewardTariffs } = useCpaRewardTariff();
    const { state } = useContext(BlockContext);

    return (
      <Block
        title="Партнёрские тарифы"
        id="cpaTariffs"
        headerContent={
          <div className={styles.headerContent}>
            <ActionButton
              actionType={ButtonType.Add}
              hidden={!state.isOpened}
              disabled={state.isLoading}
              onClick={openModal}
            />
            <ActionButton
              actionType={ButtonType.Update}
              hidden={!state.isOpened}
              disabled={state.isLoading}
              onClick={() => refetchCpaRewardTariffs()}
            />
          </div>
        }
        subHeader={
          <RewardTariffsTabs
            setRewardTariffsType={setRewardTariffsType}
            currentTabId={RewardTariffType.Cpa}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText="Нет тарифов"
        isEmpty={isListEmpty(cpaRewardTariffs)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(cpaRewardTariffs) && (
          <CpaRewardTariffsTable cpaRewardTariffs={cpaRewardTariffs} />
        )}
      </Block>
    );
  },
);
