import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { CpaRewardTariffData } from '../../../queries/generated/CpaRewardTariffData';
import { CpaRewardTariffModal } from '../components/CpaRewardTariffModal';

interface OpenModalParams {
  cpaRewardTariff: CpaRewardTariffData;
  initiatorId: string;
}

type UseCpaRewardTariffModalResult = (params: OpenModalParams) => void;

export const useCpaRewardTariffModal = (): UseCpaRewardTariffModalResult => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const openModal = useCallback(
    async ({ cpaRewardTariff: { id }, initiatorId }: OpenModalParams) => {
      modalDispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${id}`,
          subTitle: 'Партнёрский тариф',
          content: <CpaRewardTariffModal tariffId={id} />,
          initiatorId,
        },
      });
    },
    [modalDispatch],
  );

  return openModal;
};
