import React, { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { useParams } from 'react-router';
import { TransferData } from '../queries/generated/TransferData';
import TransferItem from '../components/TransferItem';
import { getTransferType } from '../helpers';

interface TransferOpenModelType<T> {
  openModal: (arg: T, initiatorId: string) => void;
}

export const useTransferOpenModal = <
  T extends object,
>(): TransferOpenModelType<T> => {
  const { dispatch } = useContext(ModalContext);
  const { id: playerId } = useParams<{ id: string }>();

  const openModal = useCallback(
    (transfer: T, initiatorId: string) => {
      if (!playerId) {
        return;
      }

      const { id, type } = transfer as TransferData;
      const [title, iconConfig] = getTransferType(type);

      dispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${id}`,
          subTitle: title,
          content: <TransferItem transferId={id} playerId={playerId} />,
          iconConfig,
          initiatorId,
        },
      });
    },
    [dispatch, playerId],
  );

  return {
    openModal,
  };
};
