import { getCountByWindowHeight, Heights } from 'utils/getCountByWindowHeight';
import { useGetRestrictionsBlockLazyQuery } from '../queries/generated/GetRestrictionsBlock';
import { useGetRestrictionsLazyQuery } from '../queries/generated/GetRestrictions';

const countPerPage = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetRestrictionsByTableSize = (
  playerId: string,
  isFullTable: boolean,
) => {
  const [
    loadBlockRestrictions,
    {
      data: blockData,
      loading: blockLoading,
      error: blockError,
      fetchMore: blockFetchMore,
      refetch: blockRefetch,
    },
  ] = useGetRestrictionsBlockLazyQuery({
    variables: {
      playerId,
      first: countPerPage,
    },
  });

  const [
    loadPageRestrictions,
    {
      data: pageData,
      loading: pageLoading,
      error: pageError,
      fetchMore: pageFetchMore,
      refetch: pageRefetch,
    },
  ] = useGetRestrictionsLazyQuery({
    variables: {
      playerId,
      first: getCountByWindowHeight(cellHeight),
    },
  });

  const loadRestrictions = isFullTable
    ? loadPageRestrictions
    : loadBlockRestrictions;
  const data = isFullTable ? pageData : blockData;
  const loading = isFullTable ? pageLoading : blockLoading;
  const error = isFullTable ? pageError : blockError;
  const fetchMore = isFullTable ? pageFetchMore : blockFetchMore;
  const refetch = isFullTable ? pageRefetch : blockRefetch;

  return {
    loadRestrictions,
    data,
    loading,
    error,
    fetchMore,
    refetch,
  };
};
