import { UserPrivilege } from 'generatedUserPrivilege';
import { useCallback, useContext, useMemo } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { NoteData } from 'queries/generated/NoteData';
import { useAuth } from 'commonComponents/Auth';
import {
  UpdatePlayerNote,
  useUpdatePlayerNote,
} from '../../../queries/generated/UpdatePlayerNote';
import ResetPlayerName from '../components/ResetPlayerName';

interface Values {
  canUpdateNote: boolean;
  updateNoteResult?: UpdatePlayerNote | null;
  updateNoteHandler: (value: string) => void;
  updateNoteLoading: boolean;
  note?: NoteData;
  canViewPlayerNote: boolean;
  canResetPlayerName: boolean;
  resetPlayerName: () => void;
}

interface Props {
  id: string;
  name: string;
  defaultName: string;
}

const usePlayerHeaderData = ({ id, name, defaultName }: Props): Values => {
  const { dispatch } = useContext(ModalContext);
  const auth = useAuth();

  const [updateNote, { data: updateNoteResult, loading: updateNoteLoading }] =
    useUpdatePlayerNote();

  const updateNoteHandler = (updatedText: string) => {
    updateNote({
      variables: {
        input: {
          playerId: id,
          text: updatedText,
        },
      },
    });
  };

  const resetPlayerName = useCallback(async () => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        content: (
          <ResetPlayerName
            id={id}
            playerName={name}
            playerDefaultName={defaultName}
          />
        ),
        title: 'Сброс имени аккаунта',
        subTitle: `${name} #${id}`,
      },
    });
  }, [defaultName, dispatch, id, name]);

  const canResetPlayerName = useMemo(
    () =>
      auth.privileges.areEveryGranted({
        privileges: [UserPrivilege.ResetPlayerName],
      }),
    [auth.privileges],
  );

  const canUpdateNote = useMemo(
    () =>
      auth.privileges.areEveryGranted({
        privileges: [UserPrivilege.UpdatePlayerNote],
      }),
    [auth.privileges],
  );

  const canViewPlayerNote = useMemo(
    () =>
      auth.privileges.areEveryGranted({
        privileges: [UserPrivilege.ViewPlayerNote],
      }),
    [auth.privileges],
  );

  return {
    canUpdateNote,
    canViewPlayerNote,
    canResetPlayerName,
    updateNoteHandler,
    updateNoteResult,
    updateNoteLoading,
    resetPlayerName,
  };
};

export default usePlayerHeaderData;
