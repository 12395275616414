import { Option } from 'ui/Dropdown';
import { QualifiedReferralConfirmationSettingType } from 'generatedGraphql';
import { qualifiedReferralConfirmationSettingTypeMap } from '../const';

export const getConfirmationSettingTypeOptions = (): Array<
  Option<QualifiedReferralConfirmationSettingType>
> =>
  Object.values(QualifiedReferralConfirmationSettingType).map(
    (setting: QualifiedReferralConfirmationSettingType) => ({
      label: qualifiedReferralConfirmationSettingTypeMap[setting],
      id: setting,
    }),
  );
