import { QualifiedReferralStatus } from 'generatedGraphql';
import { CardIconColor, CardIconConfig } from 'ui/Card/CardIcon';
import {
  faCancel,
  faCircleCheck,
  faHourglassEmpty,
  faSpinner,
  faQuestion,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { nbsp } from 'src/const';

const qualifiedReferralStatusMap: {
  [key in QualifiedReferralStatus]: string;
} = {
  [QualifiedReferralStatus.Approved]: 'Подтверждён',
  [QualifiedReferralStatus.AutoApproved]: 'Подтвержден (автоматически)',
  [QualifiedReferralStatus.Pending]: `В${nbsp}ожидании`,
  [QualifiedReferralStatus.Moderation]: 'Модерация',
  [QualifiedReferralStatus.Review]: `На${nbsp}проверке`,
  [QualifiedReferralStatus.Rejected]: 'Отклонён',
  [QualifiedReferralStatus.Unknown]: 'Unknown',
};

const getQualifiedReferralStatusIcon = (
  status: QualifiedReferralStatus,
): CardIconConfig => {
  let icon: IconProp;
  let iconColor;

  switch (status) {
    case QualifiedReferralStatus.AutoApproved:
    case QualifiedReferralStatus.Approved:
      icon = faCircleCheck;
      iconColor = CardIconColor.GREEN;
      break;
    case QualifiedReferralStatus.Pending:
      icon = faHourglassEmpty;
      iconColor = CardIconColor.ORANGE;
      break;
    case QualifiedReferralStatus.Moderation:
    case QualifiedReferralStatus.Review:
      icon = faSpinner;
      iconColor = CardIconColor.BLUE;
      break;
    case QualifiedReferralStatus.Rejected:
      icon = faCancel;
      iconColor = CardIconColor.RED;
      break;
    default:
      icon = faQuestion;
      iconColor = CardIconColor.GREY;
  }

  return {
    icon,
    iconColor,
  };
};

export const getQualifiedReferralStatus = (
  status: QualifiedReferralStatus,
): [CardIconConfig, string] => {
  const statusText = qualifiedReferralStatusMap[status];
  const iconConfig = getQualifiedReferralStatusIcon(status);

  return [iconConfig, statusText];
};
