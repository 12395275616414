import { FC } from 'react';
import { Tabs } from 'ui/Tabs';
import { MediaItemTypeName } from 'commonTypes';
import { mediaItemsTabs } from './const';

interface Props {
  currentTabId?: MediaItemTypeName;
  onClick: (type: MediaItemTypeName) => void;
}

export const MediaItemsTabs: FC<Props> = ({ currentTabId, onClick }) => {
  const handleTabChange = (id: MediaItemTypeName) => {
    onClick(id);
  };

  return (
    <Tabs
      tabs={mediaItemsTabs}
      currentTabId={currentTabId}
      onChangeTab={handleTabChange}
    />
  );
};
