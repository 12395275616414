import { FC } from 'react';
import { RouterLinkSize } from 'ui/RouterLink/const';
import { RouterLink } from 'ui/RouterLink';
import { generatePath } from 'react-router';
import { Route } from 'src/router/routes.const';
import { GetPlayerAdditionalData } from '../../queries/generated/GetPlayerAdditionalData';

interface Props {
  affiliate?: GetPlayerAdditionalData['player']['affiliateAccount'];
}

export const AffiliateLink: FC<Props> = ({ affiliate }) => {
  if (!affiliate) {
    return null;
  }

  const isPartner = affiliate.__typename === 'Partner';

  const affiliateEmail = isPartner
    ? affiliate.partnerEmail
    : affiliate.companyEmail;

  const pathToAffiliate = generatePath(
    isPartner ? Route.PARTNER : Route.COMPANY,
    { id: affiliate.id },
  );

  return (
    <RouterLink path={pathToAffiliate} size={RouterLinkSize.Large}>
      {affiliateEmail
        ? `${affiliate.name} (${affiliateEmail})`
        : `${affiliate.name}`}
    </RouterLink>
  );
};
