import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { PlayerState } from 'generatedGraphql';
import UnDeletion from 'commonComponents/UnDelete';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { PlayerBasicData } from 'queries/generated/PlayerBasicData';
import ResetPlayerName from '../components/ResetPlayerName/ResetPlayerName';
import UpdatePlayerRank from '../components/UpdatePlayerRank/UpdatePlayerRank';
import ResetPlayerTwoFactor from '../components/ResetPlayerTwoFactor/ResetPlayerTwoFactor';
import ChangePlayerState from '../components/ChangePlayerState';
import { useChangePlayerState } from '../components/ChangePlayerState/useChangePlayerState';
import { UpdatePlayerInternalOutgoingMoneyTransfers } from '../components/UpdatePlayerInternalOutgoingMoneyTransfers';

interface Args {
  player: PlayerBasicData;
}

interface Return {
  resetName: () => void;
  updatePlayerRank: () => void;
  resetPlayerTwoFactor: () => void;
  signInBlocked: () => void;
  unDeletion: () => void;
  updatePlayerInternalOutgoingMoneyTransfers: () => void;
}

export const usePlayerEdit = ({ player }: Args): Return => {
  const { dispatch } = useContext(ModalContext);

  const {
    id,
    name,
    defaultName,
    twoFactorEnabled,
    rank,
    deletedAt,
    state,
    signInRestriction,
    internalOutgoingMoneyTransfersEnabled,
  } = player;

  const { loading, onUnDeleted, error } = useChangePlayerState({ id });

  // TODO: unused function. In project we use "resetPlayerName" from usePlayerHeaderData.tsx
  const resetName = useCallback(async () => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        content: (
          <ResetPlayerName
            id={id}
            playerName={name}
            playerDefaultName={defaultName}
          />
        ),
        title: 'Сброс имени аккаунта',
        subTitle: `${name} #${id}`,
      },
    });
  }, [dispatch, id, name, defaultName]);

  const updatePlayerRank = useCallback(async () => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        content: <UpdatePlayerRank id={id} playerRank={rank} />,
        title: 'Изменение статуса',
        subTitle: `${name} #${id}`,
      },
    });
  }, [dispatch, id, rank, name]);

  const resetPlayerTwoFactor = useCallback(async () => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        content: (
          <ResetPlayerTwoFactor id={id} twoFactorEnabled={twoFactorEnabled} />
        ),
        title: 'Управление 2FA',
        subTitle: `${name} #${id}`,
      },
    });
  }, [dispatch, id, name, twoFactorEnabled]);

  const signInBlocked = useCallback(async () => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        content: (
          <ChangePlayerState id={id} signInRestriction={signInRestriction} />
        ),
        title: 'Блокировка доступа в\u00a0аккаунт',
        subTitle: `${name} #${id}`,
      },
    });
  }, [dispatch, id, signInRestriction, name]);

  const unDeletion = useCallback(async () => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        content: (
          <UnDeletion
            isWillDeleted={state === PlayerState.WillBeDeleted}
            onUnDeleted={onUnDeleted}
            error={error}
            isLoading={loading}
            signInRestrictionReason={signInRestriction?.reason}
            deletedAt={deletedAt}
          />
        ),
        title: 'Доступ в\u00a0аккаунт',
        subTitle: `${name} #${id}`,
      },
    });
  }, [
    dispatch,
    onUnDeleted,
    error,
    loading,
    signInRestriction?.reason,
    deletedAt,
    state,
    name,
    id,
  ]);

  const updatePlayerInternalOutgoingMoneyTransfers = useCallback(() => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        content: (
          <UpdatePlayerInternalOutgoingMoneyTransfers
            id={id}
            isMoneyTransfersEnabled={internalOutgoingMoneyTransfersEnabled}
          />
        ),
        title: 'Доступность переводов',
        subTitle: `${name} ${addHashTagToId(id)}`,
      },
    });
  }, [dispatch, id, internalOutgoingMoneyTransfersEnabled, name]);

  return {
    resetName,
    updatePlayerRank,
    resetPlayerTwoFactor,
    signInBlocked,
    unDeletion,
    updatePlayerInternalOutgoingMoneyTransfers,
  };
};
