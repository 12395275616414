import {
  BonusStatus,
  CashbackBonus,
  FreespinBonus,
  PromoCodeBonus,
} from 'generatedGraphql';
import { CardIconColor } from 'ui/Card';
import { BonusTypeName } from 'commonTypes';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBan,
  faCircleCheck,
  faGift,
  faHandHoldingHeart,
  faHandSparkles,
  faHourglassEmpty,
  faPiggyBank,
  faSlotMachine,
  faSpinner,
  faTicket,
} from '@fortawesome/pro-solid-svg-icons';
import { CardIconConfig } from 'ui/Card/CardIcon';
import capitalize from 'src/utils/capitalize';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { bonusNameMap, bonusStatusMap } from './const';
import { BonusData } from './queries/generated/BonusData';
import { BonusBasicData } from './queries/generated/BonusBasicData';

export const getBonusStatusIcon = (status: BonusStatus): CardIconConfig => {
  let icon: IconProp;
  let iconColor;

  switch (status) {
    case BonusStatus.Active:
      icon = faSpinner;
      iconColor = CardIconColor.BLUE;
      break;
    case BonusStatus.Won:
      icon = faCircleCheck;
      iconColor = CardIconColor.GREEN;
      break;
    case BonusStatus.Pending:
      icon = faHourglassEmpty;
      iconColor = CardIconColor.ORANGE;
      break;
    case BonusStatus.Canceled:
    case BonusStatus.AutoCanceled:
    case BonusStatus.Expired:
      icon = faBan;
      iconColor = CardIconColor.RED;
      break;
    default:
      icon = faCircleCheck;
      iconColor = CardIconColor.GREEN;
      break;
  }

  return { icon, iconColor };
};

export const getStatusConfig = (
  status: BonusStatus,
): [string, CardIconConfig | undefined] => {
  const formattedStatus = bonusStatusMap[status];
  const iconConf = getBonusStatusIcon(status);

  return [formattedStatus, iconConf];
};

export const getTypeBonus = (
  bonus: BonusData | BonusBasicData,
): [string, CardIconConfig, string?] => {
  const typeName = bonus.__typename as BonusTypeName;

  switch (typeName) {
    case BonusTypeName.Freespin:
      return [
        bonusNameMap[typeName],
        {
          icon: faSlotMachine,
          iconColor: CardIconColor.BLUE,
        },
        `#${(bonus as FreespinBonus)?.freespin?.id}`,
      ];
    case BonusTypeName.PersonalBonus:
      return [
        bonusNameMap[typeName],
        {
          icon: faGift,
          iconColor: CardIconColor.PINK,
        },
        undefined,
      ];
    case BonusTypeName.Cashback:
      return [
        bonusNameMap[typeName],
        {
          icon: faPiggyBank,
          iconColor: CardIconColor.YELLOW,
        },
        capitalize(
          formatDate(
            (bonus as CashbackBonus).rewardPeriod,
            DateFormat.ShortDate,
          ),
        ),
      ];
    case BonusTypeName.Gift:
      return [
        bonusNameMap[typeName],
        {
          icon: faHandHoldingHeart,
          iconColor: CardIconColor.PINK,
        },
        undefined,
      ];
    case BonusTypeName.FirstDeposit:
      return [
        bonusNameMap[typeName],
        {
          icon: faHandSparkles,
          iconColor: CardIconColor.PURPLE,
        },
        undefined,
      ];
    case BonusTypeName.PromoCode:
      return [
        bonusNameMap[typeName],
        {
          icon: faTicket,
          iconColor: CardIconColor.INDIGO,
        },
        (bonus as PromoCodeBonus)?.promoCode?.token,
      ];
    default:
      return [
        bonusNameMap[BonusTypeName.FirstDeposit],
        {
          icon: faHandSparkles,
          iconColor: CardIconColor.PURPLE,
        },
        undefined,
      ];
  }
};
