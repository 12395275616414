import { cloneDeep } from 'lodash-es';

/**
 * Add an offset equal to the client's timezone offset to compensate
 * for cases when time in local timezone is converted to UTC.
 * For example, when 00:00:00 in UTC+3 is converted to UTC, it becomes 21:00:00 UTC+0.
 * The function adjusts this by subtracting the offset,
 * effectively making 00:00:00 UTC+3 equivalent to 00:00:00 UTC.
 */

export const addHoursOffsetToDate = (date: Date) => {
  const offsetTimeZone = new Date().getTimezoneOffset() / 60;

  const clonedDate = cloneDeep(date);

  clonedDate.setHours(-offsetTimeZone);

  return clonedDate;
};
