import Input, { InputSize } from 'ui/Input';
import { Dispatch, FC, SetStateAction } from 'react';
import { useField } from 'formik';
import { LoadMoreStatus } from 'commonComponents/LoadMoreStatus';
import { useInfiniteScroll } from 'src/utils/hooks/useInfiniteScroll';
import {
  getIsSearchWarningRender,
  getSearchWarningText,
  getDropdownOptions,
} from '../../helpers';
import { DropdownOptions } from '../DropdownOptions';
import { Option } from '../../types';
import styles from './DropdownList.module.scss';
import {
  DROPDOWN_SEARCH,
  END_OF_LIST_TEXT,
  SEARCH_INPUT_PLACEHOLDER,
} from './const';

interface Props {
  onSearchInList?: (value: string) => void;
  isCheckbox?: boolean;
  fetchMoreConfig?: {
    fetchMore?: () => void;
    hasNextPage?: boolean;
  };
  loading?: boolean;
  emptyListText: string;
  searchMinLength?: number;
  isValidationOnSubmitCalled: boolean;
  name: string;
  onChange?: (value: string | Array<string>) => void;
  setOpened: Dispatch<SetStateAction<boolean>>;
  checkedOptionsIds: Array<string>;
  setCheckedOptionsIds: Dispatch<SetStateAction<Array<string>>>;
  options: Array<Option> | null;
}

export const DropdownList: FC<Props> = ({
  onSearchInList,
  isCheckbox,
  fetchMoreConfig,
  loading,
  emptyListText,
  searchMinLength,
  isValidationOnSubmitCalled,
  name,
  onChange,
  setOpened,
  checkedOptionsIds,
  setCheckedOptionsIds,
  options,
}) => {
  const DROPDOWN_SEARCH_FIELD_NAME = `${name}_${DROPDOWN_SEARCH}`;
  const [fieldInput] = useField(DROPDOWN_SEARCH_FIELD_NAME);

  const loadTrigger = useInfiniteScroll(fetchMoreConfig?.fetchMore);

  const dropdownOptions = getDropdownOptions({
    options,
    checkedOptionsIds,
    isCheckbox,
  });

  const isSearchWarningRender = getIsSearchWarningRender({
    searchMinLength,
    loading,
    onSearchInList,
    fieldInputValue: fieldInput.value,
    options: dropdownOptions,
  });

  const isEndOfNotEmptyList =
    !fetchMoreConfig?.hasNextPage && !!dropdownOptions?.length;

  const isListWithFetchLoading = fetchMoreConfig && loading;

  const isOptionsListEmpty = !dropdownOptions || dropdownOptions.length === 0;
  const isEmptyListTextVisible =
    !isSearchWarningRender && !loading && isOptionsListEmpty;

  return (
    <div className={styles.menu}>
      {onSearchInList && (
        <Input
          name={DROPDOWN_SEARCH_FIELD_NAME}
          placeholder={SEARCH_INPUT_PLACEHOLDER}
          onChange={onSearchInList}
          wrapperClassName={styles.searchInputWrapper}
          inputSize={InputSize.Small}
          autoFocus
        />
      )}
      {isSearchWarningRender && searchMinLength ? (
        <div className={styles.searchWarningText}>
          {getSearchWarningText(searchMinLength)}
        </div>
      ) : (
        <DropdownOptions
          options={dropdownOptions}
          name={name}
          isCheckbox={isCheckbox}
          isValidationOnSubmitCalled={isValidationOnSubmitCalled}
          onChange={onChange}
          setOpened={setOpened}
          setCheckedOptionsIds={setCheckedOptionsIds}
          checkedOptionsIds={checkedOptionsIds}
        />
      )}
      {loadTrigger}
      {fetchMoreConfig &&
        !isSearchWarningRender &&
        (isEndOfNotEmptyList || isListWithFetchLoading) && (
          <LoadMoreStatus
            className={styles.dropdownLoader}
            fetchMore={fetchMoreConfig.fetchMore}
            isLoading={loading}
            isEndOfList={isEndOfNotEmptyList}
            messages={{
              complete: END_OF_LIST_TEXT,
            }}
          />
        )}
      {isEmptyListTextVisible && (
        <div className={styles.searchWarningText}>{emptyListText}</div>
      )}
    </div>
  );
};
