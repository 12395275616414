import NowrapCell from 'ui/Table/components/NowrapCell/NowrapCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { contactForCommunicationTypeMap } from 'src/const';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { mapColumnIdToName, ColumnId, columnsIds } from '../const';
import { getCompanyAffiliateApplicationStatus } from '../../../helpers';
import { CompanyAffiliateApplicationsData } from '../../../queries/generated/CompanyAffiliateApplicationsData';

const columnHelper = createColumnHelper<CompanyAffiliateApplicationsData>();

const columnsSource: ColumnsSource<CompanyAffiliateApplicationsData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.Company, {
    id: ColumnId.Company,
    header: mapColumnIdToName[ColumnId.Company],
    cell: ({ row }) => {
      const { email, name } = row.original.company;

      return MultilineCellWithIcon({
        title: name,
        subTitle: email || null,
      });
    },
  }),
  columnHelper.accessor(ColumnId.ContactForCommunication, {
    id: ColumnId.ContactForCommunication,
    header: mapColumnIdToName[ColumnId.ContactForCommunication],
    cell: ({ row }) => {
      const { value, type } = row.original.company.contactForCommunication;

      return MultilineCellWithIcon({
        title: value,
        subTitle: contactForCommunicationTypeMap[type],
      });
    },
  }),
  columnHelper.accessor(ColumnId.Status, {
    id: ColumnId.Status,
    header: mapColumnIdToName[ColumnId.Status],
    cell: ({ row }) => {
      const { status, admin, statistic } = row.original;
      const [title, iconConfig] = getCompanyAffiliateApplicationStatus(
        status,
        admin,
        statistic,
      );

      return MultilineCellWithIcon({ title, iconConfig });
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(ColumnId.UpdatedAt, {
    id: ColumnId.UpdatedAt,
    header: mapColumnIdToName[ColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetCompanyAffiliateApplicationsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
