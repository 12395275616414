import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetExportCompanyAffiliateClickIdReportVariables = SchemaTypes.Exact<{
  affiliateId: SchemaTypes.Scalars['ID']['input'];
  companyId: SchemaTypes.Scalars['ID']['input'];
  period: SchemaTypes.DatePeriodInput;
}>;


export type GetExportCompanyAffiliateClickIdReport = { __typename: 'Query', company: { __typename: 'Company', id: string, affiliate: { __typename: 'CompanyAffiliate', exportClickIdReport: string, id: string } } };


export const GetExportCompanyAffiliateClickIdReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetExportCompanyAffiliateClickIdReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"affiliateId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"period"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DatePeriodInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"affiliate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"affiliateId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportClickIdReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"period"},"value":{"kind":"Variable","name":{"kind":"Name","value":"period"}}}]},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetExportCompanyAffiliateClickIdReport__
 *
 * To run a query within a React component, call `useGetExportCompanyAffiliateClickIdReport` and pass it any options that fit your needs.
 * When your component renders, `useGetExportCompanyAffiliateClickIdReport` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportCompanyAffiliateClickIdReport({
 *   variables: {
 *      affiliateId: // value for 'affiliateId'
 *      companyId: // value for 'companyId'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetExportCompanyAffiliateClickIdReport(baseOptions: ApolloReactHooks.QueryHookOptions<GetExportCompanyAffiliateClickIdReport, GetExportCompanyAffiliateClickIdReportVariables> & ({ variables: GetExportCompanyAffiliateClickIdReportVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetExportCompanyAffiliateClickIdReport, GetExportCompanyAffiliateClickIdReportVariables>(GetExportCompanyAffiliateClickIdReportDocument, options);
      }
export function useGetExportCompanyAffiliateClickIdReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportCompanyAffiliateClickIdReport, GetExportCompanyAffiliateClickIdReportVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetExportCompanyAffiliateClickIdReport, GetExportCompanyAffiliateClickIdReportVariables>(GetExportCompanyAffiliateClickIdReportDocument, options);
        }
export function useGetExportCompanyAffiliateClickIdReportSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetExportCompanyAffiliateClickIdReport, GetExportCompanyAffiliateClickIdReportVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetExportCompanyAffiliateClickIdReport, GetExportCompanyAffiliateClickIdReportVariables>(GetExportCompanyAffiliateClickIdReportDocument, options);
        }
export type GetExportCompanyAffiliateClickIdReportHookResult = ReturnType<typeof useGetExportCompanyAffiliateClickIdReport>;
export type GetExportCompanyAffiliateClickIdReportLazyQueryHookResult = ReturnType<typeof useGetExportCompanyAffiliateClickIdReportLazyQuery>;
export type GetExportCompanyAffiliateClickIdReportSuspenseQueryHookResult = ReturnType<typeof useGetExportCompanyAffiliateClickIdReportSuspenseQuery>;
export type GetExportCompanyAffiliateClickIdReportQueryResult = Apollo.QueryResult<GetExportCompanyAffiliateClickIdReport, GetExportCompanyAffiliateClickIdReportVariables>;