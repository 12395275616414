import { useState } from 'react';
import { RewardTariffType } from 'commonTypes';
import { RevShareRewardTariffs } from './components/RevShareRewardTariffs';
import { CpaRewardTariffs } from './components/CpaRewardTariffs';

export const RewardTariffs = () => {
  const [rewardTariffsType, setRewardTariffsType] = useState(
    RewardTariffType.RevShare,
  );

  if (rewardTariffsType === RewardTariffType.Cpa) {
    return <CpaRewardTariffs setRewardTariffsType={setRewardTariffsType} />;
  }

  return <RevShareRewardTariffs setRewardTariffsType={setRewardTariffsType} />;
};
