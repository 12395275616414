import { useCallback } from 'react';

export const useClickOutside = (
  setOpened: (isOpened: boolean) => void,
): ((isClickedOutside: boolean) => void) =>
  useCallback(
    (isClickedOutside: boolean) => {
      if (isClickedOutside) {
        setOpened(false);
      }
    },
    [setOpened],
  );
