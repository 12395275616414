import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import { FocusEvent } from 'react';
import { useGetReferralLinkMediaItemByUrlLazyQuery } from 'src/queries/generated/GetReferralLinkMediaItemByUrl';
import { FieldName } from '../../../const';
import { CreatePromoCodeValues } from '../../../types';

interface useGetReferralLinkMediaItemByUrlResult {
  getReferralLinkByUrl: (event: FocusEvent<HTMLInputElement>) => void;
  getReferralLinkByUrlLoading: boolean;
}

export const useGetReferralLinkMediaItemByUrl =
  (): useGetReferralLinkMediaItemByUrlResult => {
    const { setFieldValue, values, validateForm } =
      useFormikContext<CreatePromoCodeValues>();

    const [
      getReferralLinkMediaItemByUrlQuery,
      { loading: getReferralLinkByUrlLoading },
    ] = useGetReferralLinkMediaItemByUrlLazyQuery();

    const getReferralLinkByUrl = (event: FocusEvent<HTMLInputElement>) => {
      const referralLink = event.target.value;

      if (referralLink === '') {
        return;
      }

      validateForm(values);

      getReferralLinkMediaItemByUrlQuery({
        variables: {
          url: referralLink,
        },
        onCompleted: (data) => {
          setFieldValue(
            FieldName.ReferralLinkId,
            data.referralLinkMediaItemByUrl.id,
            true,
          );
          setFieldValue(
            FieldName.MediaCampaignId,
            data.referralLinkMediaItemByUrl.mediaCampaign.id,
          );
          setFieldValue(
            FieldName.MediaCampaignName,
            data.referralLinkMediaItemByUrl.mediaCampaign.name,
          );
          setFieldValue(
            FieldName.MediaCampaignUrl,
            data.referralLinkMediaItemByUrl.mediaCampaign.url,
          );
        },
        onError: () => {
          setFieldValue(FieldName.ReferralLinkId, undefined, true);

          toast.error('Реферальная ссылка не существует');
        },
      });
    };

    return {
      getReferralLinkByUrl,
      getReferralLinkByUrlLoading,
    };
  };
