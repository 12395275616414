import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { CompanyAffiliateBasicData } from '../queries/generated/CompanyAffiliateBasicData';
import { AffiliateItem } from '../components/AffiliateItem';

interface UseAffiliateModalResult<T> {
  openModal: (arg: T, initiatorId: string, companyId: string) => void;
}

export const useAffiliateModal = <
  T extends object,
>(): UseAffiliateModalResult<T> => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const openModal = useCallback(
    (companyAffiliate: T, initiatorId: string, companyId: string) => {
      const { id: companyAffiliateId, name } =
        companyAffiliate as CompanyAffiliateBasicData;

      modalDispatch({
        type: ModalActions.Open,
        payload: {
          title: `#${companyAffiliateId}`,
          subTitle: name,
          content: (
            <AffiliateItem
              companyId={companyId}
              companyAffiliateId={companyAffiliateId}
              companyAffiliateName={name}
            />
          ),
          initiatorId,
        },
      });
    },
    [modalDispatch],
  );

  return {
    openModal,
  };
};
