import { FC, useContext } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { RewardTariffType } from 'commonTypes';
import { RewardTariffsTabs } from 'commonComponents/RewardTariffsTabs';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useCreateRewardTariffModal } from '../CreateRewardTariff/hooks/useCreateRewardTariffModal';
import { RevShareRewardTariffsTable } from './components/RevShareRewardTariffsTable';
import { useRevShareRewardTariffs } from './hooks/useRevShareRewardTariffs';
import styles from './RevShareRewardTariffs.module.scss';

interface Props {
  setRewardTariffsType: (type: RewardTariffType) => void;
}

export const RevShareRewardTariffs: FC<Props> = withBlockProvider(
  ({ setRewardTariffsType }) => {
    const { state } = useContext(BlockContext);

    const { refetchRevShareRewardTariffs, revShareRewardTariffs } =
      useRevShareRewardTariffs();

    const openModal = useCreateRewardTariffModal();

    return (
      <Block
        title="Партнёрские тарифы"
        id="revShareTariffs"
        headerContent={
          <div className={styles.headerContent}>
            <ActionButton
              actionType={ButtonType.Add}
              hidden={!state.isOpened}
              disabled={state.isLoading}
              onClick={openModal}
            />
            <ActionButton
              actionType={ButtonType.Update}
              hidden={!state.isOpened}
              disabled={state.isLoading}
              onClick={() => refetchRevShareRewardTariffs()}
            />
          </div>
        }
        subHeader={
          <RewardTariffsTabs
            setRewardTariffsType={setRewardTariffsType}
            currentTabId={RewardTariffType.RevShare}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText="Нет тарифов"
        isEmpty={isListEmpty(revShareRewardTariffs)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(revShareRewardTariffs) && (
          <RevShareRewardTariffsTable
            cpaRewardTariffs={revShareRewardTariffs}
          />
        )}
      </Block>
    );
  },
);
