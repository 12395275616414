import { FC } from 'react';
import {
  NavigationGroupItemData,
  NavigationItemData,
} from 'commonComponents/Navigation/types';
import { NavigationRoutes } from './components';
import styles from './styles/Navigation.module.scss';

interface Props {
  links: Array<NavigationItemData | NavigationGroupItemData>;
}

export const Navigation: FC<Props> = ({ links }) => (
  <nav data-testid="navigation" className={styles.sidebarWrapper}>
    <div className={styles.sidebar}>
      <NavigationRoutes
        subNavigationClassName={styles.subNavigation}
        links={links}
      />
    </div>
  </nav>
);
