import { useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { ApolloError } from '@apollo/client';
import errorToast from 'ui/Toast/ErrorToast';
import { toast } from 'react-toastify';
import { nbsp } from '../../../../../../../const';
import {
  CompanyAffiliateApplicationContext,
  resetAffiliatesInProgressAction,
} from '../../../store';

export const useGetQueriesHandlers = () => {
  const { dispatch } = useContext(ModalContext);
  const { dispatch: dispatchCompanyAffiliateApplication } = useContext(
    CompanyAffiliateApplicationContext,
  );

  const handleErrorUpdateCompanyAffiliateApplication = (error: ApolloError) => {
    errorToast({
      header: `При${nbsp}обновлении произошла ошибка`,
      text: error.message,
      toastId: 'update-company-affiliate-application-status',
    });
  };

  const handleCompleteConfirmCompanyAffiliateApplication = () => {
    dispatch({
      type: ModalActions.Close,
    });

    toast.success('Заявка успешно обработана');
  };

  const resetAffiliatesInProgress = () => {
    dispatchCompanyAffiliateApplication(resetAffiliatesInProgressAction());
  };

  return {
    resetAffiliatesInProgress,
    handleCompleteConfirmCompanyAffiliateApplication,
    handleErrorUpdateCompanyAffiliateApplication,
  };
};
