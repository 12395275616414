import { FC } from 'react';
import { HeaderStyle, ProgressBar, ProgressBarProps } from 'ui/ProgressBar';
import { Wager, WagerStatus } from 'generatedGraphql';
import errorToast from 'ui/Toast/ErrorToast';
import { TooltipQuestionMark } from 'ui/TooltipQuestionMark';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { ProgressBarWagerType } from './const';
import styles from './ProgressBarWager.module.scss';
import { checkIsWonStatus, getHeaderByStatus } from './helpers';

interface Props {
  wager?: Wager | null;
  type: ProgressBarWagerType;
  tooltipText?: string;
  className?: string;
}

const ProgressBarWager: FC<Props> = ({ wager, type, className }) => {
  if (!wager) {
    return null;
  }

  const { status, wonSum, sum } = wager;

  if (wonSum.currency !== sum.currency) {
    errorToast({
      header: 'Currency error',
      text: "Paid wager sum currency doesn't match with the wager sum currency",
      toastId: wager.id,
    });

    return null;
  }

  const percent = checkIsWonStatus(wager.status)
    ? 100
    : Math.round((wonSum.amount / sum.amount) * 100);
  const sumString = formatSumWithCurrency(sum.amount, sum.currency);
  const wonSumString = formatSumWithCurrency(wonSum.amount, wonSum.currency);

  const progressBarProps: ProgressBarProps = {
    percent,
    headerStyle: HeaderStyle.Regular,
  };

  const progressBarHeader = getHeaderByStatus(status, wonSumString);

  if (!progressBarHeader) {
    errorToast({
      header: 'Unknown money transfer wager status',
      text: `Available wagers statuses: ${Object.keys(WagerStatus).join(
        ',',
      )}. Got status: ${status}`,
      toastId: wager.id,
    });
  }

  if (type === ProgressBarWagerType.Full) {
    progressBarProps.startTitle = `Отыграно: ${wonSumString}`;
    progressBarProps.endTitle = sumString;
  } else {
    progressBarProps.header = progressBarHeader;
  }

  if (
    status === WagerStatus.WonDueToWithdrawal &&
    progressBarProps.startTitle
  ) {
    progressBarProps.startTitle = (
      <div className={styles.startTitleContainer}>
        {progressBarProps.startTitle}
        <TooltipQuestionMark
          tooltipContent={
            <>
              Перевод считается отыгранным, поскольку
              <br />
              был совершен успешный вывод
            </>
          }
        />
      </div>
    );
  }

  return <ProgressBar className={className} {...progressBarProps} />;
};

export default ProgressBarWager;
