import { FormikErrors } from 'formik';
import { Currency } from 'commonTypes';

export interface CreateFreespinValues {
  player: string;
  count: string;
  currency: Currency;
  gameId?: string;
  bonusWager?: number;
  minAccountBalanceAmount?: number | null;
}

export interface CreateFreespinErrors {
  count?: string;
  currency?: string;
  gameId?: string;
}

export const validate = (
  values: CreateFreespinValues,
): FormikErrors<CreateFreespinErrors> => {
  const errors: FormikErrors<CreateFreespinErrors> = {};

  if (!values.count) {
    errors.count = 'Необходимо ввести количество';
  }

  if (!values.currency) {
    errors.currency = 'Необходимо выбрать валюту';
  }

  if (!values.gameId) {
    errors.gameId = 'Необходимо выбрать слот';
  }

  return errors;
};
