import { useCallback, useState } from 'react';
import { DepositFilterInput } from 'generatedGraphql';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useAuth } from 'commonComponents/Auth';
import { DepositFilterMember } from 'commonTypes';
import { useGetAvailableDepositFiltersLazyQuery } from '../../queries/generated/GetAvailableDepositFilters';
import { countPerPage } from '../../const';
import { GetDepositsBlockVariables } from '../../queries/generated/GetDepositsBlock';

interface LoadDepositsVariablesWithFilter extends GetDepositsBlockVariables {
  filter: DepositFilterInput;
}

interface LoadDepositsVariables {
  variables: LoadDepositsVariablesWithFilter;
}

interface UseGetDepositFiltersParams {
  playerId: string;
  loadDeposits: (a: LoadDepositsVariables) => void;
}

export const useGetDepositFilters = ({
  playerId,
  loadDeposits,
}: UseGetDepositFiltersParams) => {
  const [getAvailableDepositFiltersData, { data }] =
    useGetAvailableDepositFiltersLazyQuery();

  const auth = useAuth();

  const [isFiltered, setIsFiltered] = useState(false);

  const onSubmitFilter = useCallback(
    (filters: DepositFilterInput) => {
      loadDeposits({
        variables: {
          playerId,
          first: countPerPage,
          viewPaymentMethodAccount: auth.privileges.areEveryGranted({
            privileges: [UserPrivilege.ViewPaymentmethodAccount],
          }),
          filter: filters,
        },
      });

      if (filters && !Object.keys(filters).length) {
        setIsFiltered(false);
      }

      setIsFiltered(true);
    },
    [auth.privileges, loadDeposits, playerId],
  );

  const availableFiltersState = data?.player.availableDepositFilters || [];

  const onChangeFilters = useCallback(
    (filters: Array<DepositFilterMember>) => {
      getAvailableDepositFiltersData({
        variables: {
          id: playerId,
          usedFilters: filters,
        },
      });
    },
    [getAvailableDepositFiltersData, playerId],
  );

  return {
    onChangeFilters,
    availableFiltersState,
    onSubmitFilter,
    isFiltered,
  };
};
