import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { ModalActions, ModalContext } from 'ui/Modal';
import { UpdateClientIdentitiesPayload } from 'commonComponents/UpdateClientIdentities/types';
import { useUpdateCompanyIdentities as useUpdateCompanyIdentitiesMutation } from '../queries/generated/UpdateCompanyIdentities';

interface UseUpdateCompanyIdentitiesParams {
  id: string;
}

interface Result {
  loading: boolean;
  error?: ApolloError;
  onUpdateCompanyIdentities: (values: UpdateClientIdentitiesPayload) => void;
}

export const useUpdateCompanyIdentities = ({
  id,
}: UseUpdateCompanyIdentitiesParams): Result => {
  const { dispatch } = useContext(ModalContext);

  const handleCompleted = () => {
    dispatch({
      type: ModalActions.Close,
    });

    toast.success('Данные компании изменены');
  };

  const [updateCompanyIdentities, { error, loading }] =
    useUpdateCompanyIdentitiesMutation({
      onCompleted: handleCompleted,
    });

  const onUpdateCompanyIdentities = ({
    email,
  }: UpdateClientIdentitiesPayload) => {
    if (email) {
      updateCompanyIdentities({
        variables: {
          input: {
            id,
            email,
          },
        },
      });
    }
  };

  return {
    onUpdateCompanyIdentities,
    loading,
    error,
  };
};
