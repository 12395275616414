import React, { ReactElement } from 'react';
import styles from './MayBeNullCell.module.scss';

const MayBeNullCell = (
  value?: ReactElement | string | number | null,
  emptyText = 'Нет данных',
): ReactElement => {
  if (value) {
    return <div>{value}</div>;
  }

  return <div className={styles.noValue}>{emptyText}</div>;
};

export default MayBeNullCell;
