import { CompanyAffiliateApplicationStatus } from 'generatedGraphql';
import { CardIconColor, CardIconConfig } from 'ui/Card/CardIcon';
import {
  faBan,
  faCircleCheck,
  faHourglassEmpty,
  faQuestion,
  faSpinner,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const getCompanyAffiliateApplicationStatusIcon = (
  status: CompanyAffiliateApplicationStatus,
): CardIconConfig => {
  let icon: IconProp;
  let iconColor;

  switch (status) {
    case CompanyAffiliateApplicationStatus.Active:
      icon = faCircleCheck;
      iconColor = CardIconColor.GREEN;
      break;
    case CompanyAffiliateApplicationStatus.Declined:
      icon = faBan;
      iconColor = CardIconColor.RED;
      break;
    case CompanyAffiliateApplicationStatus.Pending:
      icon = faHourglassEmpty;
      iconColor = CardIconColor.ORANGE;
      break;
    case CompanyAffiliateApplicationStatus.InProgress:
      icon = faSpinner;
      iconColor = CardIconColor.BLUE;
      break;
    default:
      icon = faQuestion;
      iconColor = CardIconColor.GREY;
      break;
  }

  return { icon, iconColor };
};
