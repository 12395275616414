import { UserPrivilege } from 'generatedUserPrivilege';
import { useCallback } from 'react';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetExportPartnerRevShareReportLazyQuery } from '../queries/generated/GetExportPartnerRevShareReport';
import { useExportReportHandlers } from '../../../helpers/useHandleCompleteExportReport';
import { useGetCurrency } from '../../../hooks/useGetCurrency';

interface UseGetExportPartnerRevShareReportParams {
  partnerId: string;
}

export const useGetExportPartnerRevShareReport = ({
  partnerId,
}: UseGetExportPartnerRevShareReportParams) => {
  const period = useGetPeriodOfStatistic();
  const {
    handleCompleteExportReport,
    handleErrorExportReport,
    downloadReportFileLoading,
  } = useExportReportHandlers();

  const [exportReport, { loading: getExportReportLinkLoading }] =
    useGetExportPartnerRevShareReportLazyQuery({
      onCompleted: (data) => {
        handleCompleteExportReport(data.partner.exportRevShareReport);
      },
      onError: handleErrorExportReport,
    });

  const { currency } = useGetCurrency();

  const getReport = useCallback(() => {
    exportReport({
      variables: {
        id: partnerId,
        currency,
        period,
      },
    });
  }, [currency, exportReport, partnerId, period]);

  return {
    getReport,
    loading: getExportReportLinkLoading || downloadReportFileLoading,
    privilege: UserPrivilege.ExportPartnerRevShareReport,
  };
};
