import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { SumData } from '../../../../../../../../queries/generated/SumData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdateCpaRewardTariffVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateCpaRewardTariffInput;
}>;


export type UpdateCpaRewardTariff = { __typename: 'Mutation', updateCpaRewardTariff: { __typename: 'UpdateCpaRewardTariffPayload', cpaRewardTariff: { __typename: 'CpaRewardTariff', countries: Array<any>, id: string, title: string, baselineSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency }, rewardSum: { __typename: 'Sum', amount: number, currency: CommonType.Currency } } } };


export const UpdateCpaRewardTariffDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateCpaRewardTariff"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateCpaRewardTariffInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateCpaRewardTariff"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cpaRewardTariff"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"baselineSum"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SumData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"countries"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"rewardSum"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SumData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]}},...SumData.definitions]} as unknown as DocumentNode;
export type UpdateCpaRewardTariffMutationFn = Apollo.MutationFunction<UpdateCpaRewardTariff, UpdateCpaRewardTariffVariables>;

/**
 * __useUpdateCpaRewardTariff__
 *
 * To run a mutation, you first call `useUpdateCpaRewardTariff` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCpaRewardTariff` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCpaRewardTariff, { data, loading, error }] = useUpdateCpaRewardTariff({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCpaRewardTariff(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCpaRewardTariff, UpdateCpaRewardTariffVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateCpaRewardTariff, UpdateCpaRewardTariffVariables>(UpdateCpaRewardTariffDocument, options);
      }
export type UpdateCpaRewardTariffHookResult = ReturnType<typeof useUpdateCpaRewardTariff>;
export type UpdateCpaRewardTariffMutationResult = Apollo.MutationResult<UpdateCpaRewardTariff>;
export type UpdateCpaRewardTariffMutationOptions = Apollo.BaseMutationOptions<UpdateCpaRewardTariff, UpdateCpaRewardTariffVariables>;