import { CardIconConfig } from 'ui/Card/CardIcon';
import { ApolloError } from '@apollo/client';
import createStore from 'utils/createStore';

interface ModalState {
  isOpened: boolean;
  content: JSX.Element | null;
  isLoading: boolean | null;
  title?: string | JSX.Element | null;
  subTitle?: string | null;
  iconConfig?: CardIconConfig | null;
  step?: number | null;
  initiatorId?: string | null;
  error?: ApolloError | null;
  activeTab?: number | null;
  previousStep?: number | null;
  isCloseDisabled: boolean;
  hasGoBackButtonForSteps?: boolean;
}

enum ModalActions {
  DisableClose = 'DisableClose',
  Open = 'Open',
  Close = 'Close',
  UpdateModalProps = 'UpdateModalProps',
  SetIsLoading = 'SetIsLoading',
  SetError = 'SetError',
  UpdateActiveTab = 'UpdateActiveTab',
}

type Action =
  | {
      type: ModalActions.Open;
      payload: {
        content: JSX.Element;
        title: string | JSX.Element;
        subTitle?: string | null;
        iconConfig?: CardIconConfig | null;
        initiatorId?: string | null;
        step?: number | null;
        previousStep?: number | null;
        hasGoBackButtonForSteps?: boolean;
      };
    }
  | {
      type: ModalActions.Close;
    }
  | {
      type: ModalActions.UpdateModalProps;
      payload: {
        title?: string | JSX.Element | null;
        subTitle?: string;
        step?: number | null;
        iconConfig?: CardIconConfig | null;
        initiatorId?: string | null;
        previousStep?: number | null;
      };
    }
  | {
      type: ModalActions.SetIsLoading;
      payload: {
        isLoading: boolean;
      };
    }
  | {
      type: ModalActions.SetError;
      payload: {
        error?: ApolloError;
      };
    }
  | {
      type: ModalActions.UpdateActiveTab;
      payload: {
        activeTab?: number | null;
      };
    }
  | {
      type: ModalActions.DisableClose;
      payload: {
        isCloseDisabled: boolean;
      };
    };

const initialState: ModalState = {
  isOpened: false,
  content: null,
  title: null,
  subTitle: null,
  iconConfig: null,
  step: null,
  initiatorId: null,
  isLoading: null,
  error: null,
  activeTab: null,
  previousStep: null,
  isCloseDisabled: false,
  hasGoBackButtonForSteps: true,
};

const reducer = (currentState: ModalState, action: Action) => {
  const newState = { ...currentState };

  switch (action.type) {
    case ModalActions.DisableClose:
      newState.isCloseDisabled = action.payload.isCloseDisabled;

      return newState;

    case ModalActions.Open:
      newState.isOpened = true;
      newState.content = action.payload.content;
      newState.title = action.payload.title;
      newState.subTitle = action.payload.subTitle;
      newState.iconConfig = action.payload.iconConfig;
      newState.step = action.payload.step || 1;
      newState.previousStep = newState.step;
      newState.initiatorId = action.payload.initiatorId;
      if (action.payload.hasGoBackButtonForSteps !== undefined) {
        newState.hasGoBackButtonForSteps =
          action.payload.hasGoBackButtonForSteps;
      }

      return newState;

    case ModalActions.Close:
      newState.isOpened = false;
      newState.content = null;
      newState.title = null;
      newState.subTitle = null;
      newState.iconConfig = null;
      newState.initiatorId = null;
      newState.step = null;
      newState.isLoading = null;
      newState.error = null;
      newState.activeTab = null;
      newState.previousStep = null;
      newState.hasGoBackButtonForSteps = true;

      return newState;

    case ModalActions.UpdateModalProps:
      return {
        ...newState,
        ...action.payload,
        step: action.payload.step || currentState.step,
        previousStep: action.payload.previousStep || currentState.previousStep,
      };

    case ModalActions.SetIsLoading:
      newState.isLoading = action.payload.isLoading;

      return newState;

    case ModalActions.SetError:
      newState.error = action.payload.error;

      return newState;

    case ModalActions.UpdateActiveTab:
      newState.activeTab = action.payload.activeTab;

      return newState;

    default:
      return currentState;
  }
};

const [ModalContext, ModalProvider] = createStore(reducer, initialState);

export { ModalContext, ModalProvider, ModalActions };
