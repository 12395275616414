import { AffiliateType } from 'generatedGraphql';
import { useCallback, useRef } from 'react';
import { throttle } from 'lodash-es';
import { useGetPartnerReferralCodesLazyQuery } from '../queries/generated/GetPartnerReferralCodes';
import { useGetCompanyReferralCodesLazyQuery } from '../queries/generated/GetCompanyReferralCodes';

interface UseGetReferralCodeParams {
  affiliateType: AffiliateType;
}

const COUNT_PER_PAGE = 10;

export const useGetReferralCodes = ({
  affiliateType,
}: UseGetReferralCodeParams) => {
  const [
    loadCompanyReferralCodes,
    {
      data: getCompanyReferralCodesResult,
      loading: getCompanyReferralCodesLoading,
      fetchMore: getCompanyReferralCodesFetchMore,
    },
  ] = useGetCompanyReferralCodesLazyQuery();

  const [
    loadPartnerReferralCodes,
    {
      data: getPartnerReferralCodesResult,
      loading: getPartnerReferralCodesLoading,
      fetchMore: getPartnerReferralCodesFetchMore,
    },
  ] = useGetPartnerReferralCodesLazyQuery();

  const getReferralCodesLoading =
    affiliateType === AffiliateType.Company
      ? getCompanyReferralCodesLoading
      : getPartnerReferralCodesLoading;

  const getReferralCodesFetchMore =
    affiliateType === AffiliateType.Company
      ? getCompanyReferralCodesFetchMore
      : getPartnerReferralCodesFetchMore;

  const referralLinkMediaItems =
    affiliateType === AffiliateType.Company
      ? getCompanyReferralCodesResult?.company.referralLinkMediaItems
      : getPartnerReferralCodesResult?.partner.referralLinkMediaItems;

  const loadReferralCodes = (searchText: string, affiliateId: string) => {
    if (affiliateType === AffiliateType.Company) {
      loadCompanyReferralCodes({
        variables: {
          companyId: affiliateId,
          search: searchText,
          first: COUNT_PER_PAGE,
        },
      });
    } else {
      loadPartnerReferralCodes({
        variables: {
          partnerId: affiliateId,
          search: searchText,
          first: COUNT_PER_PAGE,
        },
      });
    }
  };

  const loadReferralCodesWithThrottle = useRef(
    throttle(async (searchText: string, affiliateId: string) => {
      await loadReferralCodes(searchText, affiliateId);
    }, 1000),
  ).current;

  const referralCodesOptions = referralLinkMediaItems?.edges.map(
    ({ node: { code } }) => ({
      id: code,
      label: code,
    }),
  );

  const hasNextPage = referralLinkMediaItems?.pageInfo.hasNextPage;
  const endCursor = referralLinkMediaItems?.pageInfo.endCursor;

  const fetchMore = useCallback(
    () =>
      getReferralCodesFetchMore({
        variables: {
          first: COUNT_PER_PAGE,
          after: endCursor,
        },
      }),
    [getReferralCodesFetchMore, endCursor],
  );

  return {
    loadReferralCodesWithThrottle,
    referralCodesOptions,
    hasNextPage,
    fetchMore,
    getReferralCodesLoading,
  };
};
