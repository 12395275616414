import {
  CompanyAffiliateApplicationStatistic,
  CompanyAffiliateApplicationStatus,
} from 'generatedGraphql';
import { CardIconConfig } from 'ui/Card/CardIcon';
import { companyAffiliateApplicationStatusMap, nbsp } from '../../../const';
import { getCompanyAffiliateApplicationStatusIcon } from './getCompanyAffiliateApplicationStatusIcon';
import { CompanyAffiliateApplicationsData } from '../queries/generated/CompanyAffiliateApplicationsData';

export const getCompanyAffiliateApplicationStatus = (
  status: CompanyAffiliateApplicationStatus,
  admin?: CompanyAffiliateApplicationsData['admin'] | null,
  statistic?: CompanyAffiliateApplicationStatistic,
): [string, CardIconConfig] => {
  const formattedStatus = companyAffiliateApplicationStatusMap[status];
  const iconConfig = getCompanyAffiliateApplicationStatusIcon(status);

  let statusText = formattedStatus;

  if (status === CompanyAffiliateApplicationStatus.InProgress && admin) {
    const { firstName, lastName } = admin;

    statusText = `${formattedStatus}:${nbsp}${firstName}${nbsp}${lastName}`;
  }

  if (status === CompanyAffiliateApplicationStatus.Active && statistic) {
    const { approved, total } = statistic;

    statusText = `${formattedStatus}${nbsp}(${approved}/${total})`;
  }

  return [statusText, iconConfig];
};
