import {
  ApolloLink,
  Operation,
  FetchResult,
  NextLink,
} from '@apollo/client/link/core';
import { Observable, Observer } from '@apollo/client/utilities';

interface OperationQueueEntry {
  operation: Operation;
  forward: NextLink;
  observer: Observer<FetchResult>;
  subscription?: { unsubscribe: () => void };
}

class DelayedQueueLink extends ApolloLink {
  private opQueue: Array<OperationQueueEntry> = [];

  public run(): void {
    this.opQueue.forEach(({ operation, forward, observer }) => {
      forward(operation).subscribe(observer);
    });
    this.opQueue = [];
  }

  public clean(): void {
    this.opQueue.forEach(({ operation, observer }) => {
      observer.next?.({ data: { [operation.operationName]: null } });
      observer.complete?.();
    });
    this.opQueue = [];
  }

  public isNotEmpty(): boolean {
    return this.opQueue.length > 0;
  }

  public request(
    operation: Operation,
    forward: NextLink,
  ): Observable<FetchResult> {
    const { delayed } = operation.getContext();

    if (!delayed) {
      return forward(operation);
    }

    return new Observable<FetchResult>((observer: Observer<FetchResult>) => {
      const operationEntry = { operation, forward, observer };

      this.enqueue(operationEntry);

      return () => this.cancelOperation(operationEntry);
    });
  }

  private cancelOperation(entry: OperationQueueEntry) {
    this.opQueue = this.opQueue.filter((e) => e !== entry);
  }

  private enqueue(entry: OperationQueueEntry) {
    this.opQueue.push(entry);
  }
}

export default new DelayedQueueLink();
