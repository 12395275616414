import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { ModalActions, ModalContext } from 'ui/Modal';
import { ContactForCommunicationType } from 'commonTypes';
import { useUpdateCompanyPersonalData as useUpdateCompanyPersonalDataMutation } from '../queries/generated/UpdateCompanyPersonalData';

interface UpdateCompanyPersonalDataParams {
  website?: string | null;
  type: ContactForCommunicationType;
  value: string;
}

interface UseUpdateCompanyPersonalDataParams {
  id: string;
}

interface UseUpdateCompanyPersonalDataResult {
  loading: boolean;
  error?: ApolloError;
  onUpdateCompanyPersonalData: (
    values: UpdateCompanyPersonalDataParams,
  ) => void;
}

export const useUpdateCompanyPersonalData = ({
  id,
}: UseUpdateCompanyPersonalDataParams): UseUpdateCompanyPersonalDataResult => {
  const { dispatch } = useContext(ModalContext);

  const handleCompleted = () => {
    dispatch({
      type: ModalActions.Close,
    });

    toast.success('Данные компании изменены');
  };

  const [updateCompanyPersonalData, { error, loading }] =
    useUpdateCompanyPersonalDataMutation({
      onCompleted: handleCompleted,
    });

  const onUpdateCompanyPersonalData = ({
    type,
    value,
    website,
  }: UpdateCompanyPersonalDataParams) => {
    updateCompanyPersonalData({
      variables: {
        input: {
          id,
          website,
          type,
          value,
        },
      },
    });
  };

  return {
    onUpdateCompanyPersonalData,
    loading,
    error,
  };
};
