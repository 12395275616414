import { FormikErrors } from 'formik';
import { FieldName } from './const';
import { CreateAffiliatePromoCodeValues } from './types';
import { AffiliateMediaItemsSteps } from '../AffiliatePromoCodeItem/const';

export const validate = (
  values: CreateAffiliatePromoCodeValues,
  formStep?: AffiliateMediaItemsSteps | null,
): FormikErrors<CreateAffiliatePromoCodeValues> => {
  const errors: FormikErrors<CreateAffiliatePromoCodeValues> = {};

  if (!values[FieldName.Token]) {
    errors.token = 'Поле не может быть пустым';
  }

  if (values[FieldName.InvalidToken]) {
    errors.token = 'Токен уже существует';
  }

  if (!values[FieldName.Usage]) {
    errors.usage = 'Поле не может быть пустым';
  }

  if (!values[FieldName.ReferralLinkId]) {
    errors.referralLinkId = 'Поле не может быть пустым';
  }

  const isConnectionStep =
    formStep === AffiliateMediaItemsSteps.CreatePromoCodeConnection;
  const isFreespinCountEmpty =
    values[FieldName.ConnectionWithFreespins] &&
    !values[FieldName.FreespinCount];
  const isBonusAmountEmpty =
    values[FieldName.ConnectionWithBonus] && !values[FieldName.BonusAmount];

  if (isConnectionStep && isFreespinCountEmpty) {
    errors.freespinCount = 'Поле не может быть пустым';
  }

  if (isConnectionStep && isBonusAmountEmpty) {
    errors.bonusAmount = 'Поле не может быть пустым';
  }

  return errors;
};
