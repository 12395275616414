import { useState } from 'react';
import { bodyScrollLocker } from 'src/utils/bodyScrollLocker';

export interface DocumentImage {
  imageUrl: string;
}

interface LightboxControlsReturnType {
  documentIndex: number;
  currentDocument?: DocumentImage;
  nextDocument: DocumentImage;
  prevDocument: DocumentImage;
  handleClick: (index: number) => void;
  handleMoveNext: () => void;
  handleMovePrev: () => void;
  reactModalStyle: {
    overlay?: Record<string, string>;
    content: Record<string, string>;
  };
  onLightboxOpen: () => void;
  onLightboxClose: () => void;
}
export const useLightboxControls = (
  documentsImages: Array<DocumentImage>,
): LightboxControlsReturnType => {
  const [documentIndex, setDocumentIndex] = useState(-1);

  // Switch to first element if last element was removed
  if (documentsImages.length > 0 && documentsImages.length === documentIndex) {
    setDocumentIndex(0);
  }

  const currentDocument = documentsImages[documentIndex];
  const nextIndex = (documentIndex + 1) % documentsImages.length;
  const nextDocument = documentsImages[nextIndex] || currentDocument;
  const prevIndex =
    (documentIndex + documentsImages.length - 1) % documentsImages.length;
  const prevDocument = documentsImages[prevIndex] || currentDocument;

  const handleClick = (index: number) => setDocumentIndex(index);
  const handleClose = () => setDocumentIndex(-1);
  const handleMovePrev = () => setDocumentIndex(prevIndex);
  const handleMoveNext = () => setDocumentIndex(nextIndex);

  const reactModalStyle = {
    content: {
      backgroundColor: 'rgba(0,0,0,80%)',
    },
  };

  const setToolbarTopPosition = () => {
    const toolbox: HTMLDivElement | null =
      document.querySelector('.ril__toolbar');
    const lightboxWrapper: HTMLDivElement | null = document.querySelector(
      '.customLightboxWrapper',
    );

    if (toolbox && lightboxWrapper) {
      const { top } = lightboxWrapper.getBoundingClientRect();

      toolbox.style.top = `-${top - 10}px`; // 10 - offset by design
    }
  };

  const onLightboxOpen = () => {
    bodyScrollLocker.lock();
    setToolbarTopPosition();
    window.addEventListener('resize', setToolbarTopPosition);
  };

  const onLightboxClose = () => {
    bodyScrollLocker.unLock();
    handleClose();
    window.removeEventListener('resize', setToolbarTopPosition);
  };

  return {
    documentIndex,
    currentDocument,
    nextDocument,
    prevDocument,
    handleClick,
    handleMoveNext,
    handleMovePrev,
    reactModalStyle,
    onLightboxOpen,
    onLightboxClose,
  };
};
