import { FC, useContext, useEffect } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { ModalActions, ModalContext } from 'ui/Modal';
import Privilege from 'commonComponents/Privilege';
import { UserPrivilege } from 'generatedUserPrivilege';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { useRevShareRewardTariffModal } from './hooks/useRevShareRewardTariffModal';
import { RevShareRewardTariffsModalSteps } from './const';
import { EditTariffModal } from '../EditTariffModal/EditTariffModal';

interface Props {
  tariffId: string;
}

export const RevShareRewardTariffModal: FC<Props> = ({ tariffId }) => {
  const { state, dispatch: modalDispatch } = useContext(ModalContext);

  const { revShareRewardTariff, goToEditTariff, step } =
    useRevShareRewardTariffModal({
      tariffId,
    });

  useEffect(() => {
    if (state.step === RevShareRewardTariffsModalSteps.Base) {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: addHashTagToId(tariffId),
          subTitle: 'Партнёрский тариф',
        },
      });

      return;
    }

    modalDispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        title: 'Редактирование тарифа',
        subTitle: `Партнёрский тариф ${addHashTagToId(tariffId)}`,
      },
    });
  }, [tariffId, modalDispatch, state.step]);

  if (!revShareRewardTariff) {
    return null;
  }

  const { percent, createdAt } = revShareRewardTariff;

  if (step === RevShareRewardTariffsModalSteps.Edit) {
    return <EditTariffModal tariffId={tariffId} percent={percent} />;
  }

  return (
    <DataCard
      rows={[
        {
          title: 'Название',
          value: `RevShare ${revShareRewardTariff.percent || 0}%`,
        },
        {
          title: 'Модель оплаты',
          value: 'RevShare',
        },
        {
          title: 'Процент',
          value: `${percent}%`,
        },
        {
          title: 'Создан',
          value: formatDate(createdAt, DateFormat.FullDate),
        },
      ]}
      customFooter={
        <div>
          <Privilege privileges={[UserPrivilege.UpdateRevShareRewardTariff]}>
            <Button
              size={ButtonSize.Large}
              onClick={goToEditTariff}
              theme={ButtonTheme.Process}
            >
              Редактировать
            </Button>
          </Privilege>
        </div>
      }
    />
  );
};
