import Button, { ButtonTheme } from 'ui/Button';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { useState } from 'react';
import ClickOutside, { useClickOutside } from 'ui/ClickOutside';
import { SelectedFilter } from 'ui/TableFilter/components/SelectedFilter/SelectedFilter';
import { FiltersDropdown } from 'ui/TableFilter/components/FiltersDropdown/FiltersDropdown';
import { AvailableFilter, FormattedFilters } from 'ui/TableFilter/types';
import { useTableFilter } from 'ui/TableFilter/hooks/useTableFilter';
import { getAvailableFiltersList } from 'ui/TableFilter/helpers';
import { NoFiltersSelected } from 'ui/TableFilter/components/NoFiltersSelected/NoFilterdSelected';
import styles from './TableFilter.module.scss';

interface Props<T> {
  availableFilters: Array<AvailableFilter<T>>;
  allFilters: Array<AvailableFilter<T>>;
  onSubmit: (filters: FormattedFilters) => void;
  onChangeFilters: (filterNames: Array<T>) => void;
}

export const TableFilter = <T extends string>({
  availableFilters,
  allFilters,
  onSubmit,
  onChangeFilters,
}: Props<T>) => {
  const [isOpenedFiltersList, setIsOpenedFiltersList] = useState(false);

  const onClickOutside = useClickOutside(setIsOpenedFiltersList);

  const {
    choiceFilter,
    removeAllFilters,
    isEditFilterValue,
    handleSubmit,
    selectedFilters,
    deleteFilter,
    setFilterValue,
    toggleFilter,
  } = useTableFilter({
    setIsOpenedFiltersList,
    onSubmit,
    onChangeFilters,
  });

  const availableFilterList = getAvailableFiltersList(availableFilters);

  return (
    <ClickOutside callback={onClickOutside}>
      <div className={styles.tableFilter}>
        <div
          className={styles.left}
          onClick={() => setIsOpenedFiltersList(!isOpenedFiltersList)}
        >
          <div className={styles.filtersContainer}>
            {isOpenedFiltersList && !!availableFilterList.length && (
              <FiltersDropdown
                onClick={choiceFilter}
                items={availableFilterList}
              />
            )}
            {!!selectedFilters?.length && (
              <div className={styles.filterContainer}>
                {selectedFilters.map(
                  ({ selectedFilterId, selectedFilterValue, isOpened }) => (
                    <SelectedFilter
                      toggleFilter={toggleFilter}
                      selectedFilters={selectedFilters}
                      setFilterValue={setFilterValue}
                      deleteFilter={deleteFilter}
                      key={selectedFilterId}
                      selectedFilter={{
                        selectedFilterId,
                        selectedFilterValue,
                        isOpened,
                      }}
                      setIsOpenedFiltersList={setIsOpenedFiltersList}
                      allFilters={allFilters}
                    />
                  ),
                )}
              </div>
            )}
          </div>
          {!selectedFilters?.length && <NoFiltersSelected />}
        </div>

        <div className={styles.right}>
          {!!selectedFilters?.length && (
            <div className={styles.closeAllFilter}>
              <ActionButton
                dataTestId="deleteAllFilters"
                onClick={removeAllFilters}
                actionType={ButtonType.Close}
              />
            </div>
          )}
          <Button
            onClick={handleSubmit}
            disabled={!selectedFilters.length || isEditFilterValue}
            theme={ButtonTheme.Primary}
          >
            Применить
          </Button>
        </div>
      </div>
    </ClickOutside>
  );
};
