import { FC, PropsWithChildren } from 'react';
import createStore from 'utils/createStore';
import { reducer } from './reducer';
import { State } from './types';

const initialState: State = {
  paymentPaidSum: null,
  paymentId: null,
};

const [WithdrawalItemContext, WithdrawalItemProvider] = createStore(
  reducer,
  initialState,
);

export { WithdrawalItemContext, WithdrawalItemProvider };

export const withWithdrawalItemProvider = <Props extends object>(
  OriginalComponent: FC<Props>,
): FC<PropsWithChildren<Props>> => {
  const WrappedComponent: FC<PropsWithChildren<Props>> = (props) => (
    <WithdrawalItemProvider>
      <OriginalComponent {...props} />
    </WithdrawalItemProvider>
  );

  WrappedComponent.displayName = `withWithdrawalItemProvider(${OriginalComponent.name})`;

  return WrappedComponent;
};
