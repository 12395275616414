import { AccountEventTypeName, Falsy } from 'commonTypes';
import { KeyValueRowProps } from 'ui/KeyValueRow';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { AccountEventData } from '../../../../queries/fragments/generated/AccountEventData';
import { FromToBalance } from '../FromToBalance';

export const getRowsByEvent = (
  event: AccountEventData,
): Array<KeyValueRowProps | Falsy> => {
  const balanceRow: KeyValueRowProps = {
    title: 'Баланс',
    value: (
      <FromToBalance
        direction={event.billingBalance.delta.direction}
        from={event.billingBalance.from.amount}
        to={event.billingBalance.to.amount}
        currency={event.billingBalance.to.currency}
      />
    ),
  };

  const createdAtRow: KeyValueRowProps = {
    title: 'Создан',
    value: formatDate(event.createdAt, DateFormat.FullDate),
  };

  switch (event.__typename) {
    case AccountEventTypeName.GameAccountEvent:
      return [
        { title: 'Игра', value: `${event.game.name} #${event.game.id}` },
        balanceRow,
        event.gameEventBonusBalance && {
          title: 'Бонусный баланс',
          value: (
            <FromToBalance
              direction={event.gameEventBonusBalance.delta.direction}
              from={event.gameEventBonusBalance.from.amount}
              to={event.gameEventBonusBalance.to.amount}
            />
          ),
        },
        createdAtRow,
      ];
    case AccountEventTypeName.BonusAccountEvent:
      return [
        balanceRow,
        event.bonusEventBonusBalance && {
          title: 'Бонусный баланс',
          value: (
            <FromToBalance
              direction={event.bonusEventBonusBalance.delta.direction}
              from={event.bonusEventBonusBalance.from.amount}
              to={event.bonusEventBonusBalance.to.amount}
            />
          ),
        },
        createdAtRow,
      ];
    case AccountEventTypeName.TournamentAccountEvent:
      return [
        {
          title: 'Вид турнира',
          value: `${event.tournament.name} #${event.tournament.id}`,
        },
        balanceRow,
        createdAtRow,
      ];
    case AccountEventTypeName.JackpotAccountEvent:
      return [
        {
          title: 'Вид джекпота',
          value: `${event.jackpot.name} #${event.jackpot.id}`,
        },
        balanceRow,
        createdAtRow,
      ];

    default:
      return [balanceRow, createdAtRow];
  }
};
