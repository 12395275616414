import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { MediaItemReportOrderBy } from 'generatedGraphql';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useSearchContext } from 'src/contexts/SearchContext';
import { Currency } from 'commonTypes';
import {
  useGetCpaReferralLinkReportLazyQuery,
  GetCpaReferralLinkReportVariables,
} from '../queries/generated/GetCpaReferralLinkReport';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetOrdering } from '../../../hooks/useGetOrdering';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

interface CpaReferralLinkReportParams {
  referralLinkId: string;
  isAllTimeCpa: boolean;
}

export const useGetCpaReferralLinkReport = ({
  referralLinkId,
  isAllTimeCpa,
}: CpaReferralLinkReportParams) => {
  const period = useGetPeriodOfStatistic();
  const ordering = useGetOrdering<MediaItemReportOrderBy>(
    MediaItemReportOrderBy.AllDeposit,
  );

  const { searchValue } = useSearchContext();

  // TODO: uncomment the line when the backend is ready [ALS-719]
  // const { currency } = useGetCurrency();
  const currency = Currency.Usd;

  const baseVariables: GetCpaReferralLinkReportVariables = {
    currency,
    first: getCountByWindowHeight(cellHeight, 0),
    id: referralLinkId,
    ordering,
    period,
    showAllTimeCpa: isAllTimeCpa,
  };

  const [reportVariables, setReportVariables] = useState(baseVariables);

  useEffect(() => {
    setReportVariables({
      ...baseVariables,
      search: searchValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currency,
    period.to,
    period.from,
    isAllTimeCpa,
    ordering.orderBy,
    ordering.direction,
  ]);

  const [
    loadCpaReferralLinkReport,
    { data, loading, error, fetchMore, refetch },
  ] = useGetCpaReferralLinkReportLazyQuery();

  const loadCpaReferralLinkReportCallback = useCallback(
    () => loadCpaReferralLinkReport({ variables: reportVariables }),
    [loadCpaReferralLinkReport, reportVariables],
  );

  useBlockComponentState({
    loadData: loadCpaReferralLinkReportCallback,
    loading,
    error,
  });

  const cpaReport = data?.mediaItem.cpaReport;
  const edges = cpaReport?.edges;
  const total = cpaReport?.total;
  const hasTotalRow = !!total;
  const endCursor = cpaReport?.pageInfo.endCursor;
  const hasNextPage = cpaReport?.pageInfo.hasNextPage;

  const cpaReferralLinkReportList = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  const mediaItemInfo = data && {
    name: data.mediaItem.name,
    owner: data.mediaItem.owner,
    code: data.mediaItem.code,
  };

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return {
    cpaReferralLinkReportList,
    refetch,
    mediaItemInfo,
    hasTotalRow,
    loadReport: () =>
      loadCpaReferralLinkReport({
        variables: { ...reportVariables, search: searchValue },
      }),
  };
};
