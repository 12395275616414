import { UserPrivilege } from 'generatedUserPrivilege';
import { getCountByWindowHeight, Heights } from 'utils/getCountByWindowHeight';
import { useAuth } from 'commonComponents/Auth';
import { useGetDepositsBlockLazyQuery } from '../queries/generated/GetDepositsBlock';
import { useGetDepositsLazyQuery } from '../queries/generated/GetDeposits';

const countPerPage = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetDepositsByTableSize = (
  playerId: string,
  isFullTable: boolean,
) => {
  const auth = useAuth();

  const [
    loadBlockDeposits,
    {
      data: blockData,
      loading: blockLoading,
      error: blockError,
      fetchMore: blockFetchMore,
      refetch: blockRefetch,
    },
  ] = useGetDepositsBlockLazyQuery({
    variables: {
      playerId,
      viewPaymentMethodAccount: auth.privileges.areEveryGranted({
        privileges: [UserPrivilege.ViewPaymentmethodAccount],
      }),
      first: countPerPage,
    },
  });

  const [
    loadPageDeposits,
    {
      data: pageData,
      loading: pageLoading,
      error: pageError,
      fetchMore: pageFetchMore,
      refetch: pageRefetch,
    },
  ] = useGetDepositsLazyQuery({
    variables: {
      playerId,
      viewPaymentMethodAccount: auth.privileges.areEveryGranted({
        privileges: [UserPrivilege.ViewPaymentmethodAccount],
      }),
      first: getCountByWindowHeight(cellHeight),
    },
  });

  const loadDeposits = isFullTable ? loadPageDeposits : loadBlockDeposits;
  const data = isFullTable ? pageData : blockData;
  const loading = isFullTable ? pageLoading : blockLoading;
  const error = isFullTable ? pageError : blockError;
  const fetchMore = isFullTable ? pageFetchMore : blockFetchMore;
  const refetch = isFullTable ? pageRefetch : blockRefetch;

  return {
    loadDeposits,
    data,
    loading,
    error,
    fetchMore,
    refetch,
  };
};
