import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { MediaItemReferralLinkData } from 'src/queries/generated/MediaItemReferralLinkData';
import { useMediaItemReferralLinkModal } from './hooks/useMediaItemReferralLinkModal';
import { useGetMediaItemsReferralLinksColumns } from './hooks/useGetMediaItemsReferralLinksColumns';

interface Props {
  mediaItemsReferralLink?: Array<MediaItemReferralLinkData>;
}

export const MediaItemsReferralLinkTable: FC<Props> = ({
  mediaItemsReferralLink,
}) => {
  const openModal = useMediaItemReferralLinkModal();
  const { columns } = useGetMediaItemsReferralLinksColumns();

  const handleRowClick = (
    mediaItemReferralLink: MediaItemReferralLinkData,
    initiatorId: string,
  ) => {
    openModal({
      mediaItemReferralLink,
      initiatorId,
    });
  };

  return (
    <BlockTable
      onClickOnRow={handleRowClick}
      columns={columns}
      data={mediaItemsReferralLink}
      isFullScreenTable
    />
  );
};
