import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useCallback } from 'react';
import { useGetCompanyRevShareRewardTariffsBlockLazyQuery } from '../queries/generated/GetCompanyRevShareRewardTariffsBlock';
import { useGetCompanyRevShareRewardTariffsLazyQuery } from '../queries/generated/GetCompanyRevShareRewardTariffs';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

interface Params {
  companyId: string;
  isFullScreenTable: boolean;
}

export const useGetRevShareRewardTariffsByTableSize = ({
  companyId,
  isFullScreenTable,
}: Params) => {
  const countPerPageByTableSize = isFullScreenTable
    ? getCountByWindowHeight(cellHeight)
    : COUNT_PER_PAGE;

  const currentUseGetCompanyRevShareRewardTariffs = isFullScreenTable
    ? useGetCompanyRevShareRewardTariffsLazyQuery
    : useGetCompanyRevShareRewardTariffsBlockLazyQuery;

  const [
    loadCompanyRevShareRewardTariffs,
    { data, loading, error, fetchMore, refetch },
  ] = currentUseGetCompanyRevShareRewardTariffs({
    variables: {
      companyId,
      first: countPerPageByTableSize,
    },
  });

  const revShareRewardTariffsData = data?.company.revShareRewardTariffs;
  const endCursor = revShareRewardTariffsData?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  return {
    loadCompanyRevShareRewardTariffs,
    fetchMoreCallback,
    revShareRewardTariffsData,
    loading,
    error,
    fetchMore,
    refetch,
  };
};
