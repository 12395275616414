import { RewardTariffType } from 'commonTypes';
import { useMemo } from 'react';
import { useGetRevShareRewardTariffsByTableSize } from '../components/RevShareRewardTariffsTable/hooks/useGetRevShareRewardTariffsByTableSize';
import { useGetRevShareRewardTariffsColumns } from '../components/RevShareRewardTariffsTable/hooks/useGetRevShareRewardTariffsColumns';
import { useGetCpaRewardTariffsColumns } from '../components/CpaRewardTariffsTable/hooks/useGetCpaRewardTariffsColumns';
import { useGetCpaRewardTariffsByTableSize } from '../components/CpaRewardTariffsTable/hooks/useGetCpaRewardTariffsByTableSize';

interface Params {
  rewardTariffType: RewardTariffType;
  partnerId: string;
  isFullScreenTable: boolean;
}

export const useGetRewardTariffsData = ({
  rewardTariffType,
  partnerId,
  isFullScreenTable,
}: Params) => {
  const {
    revShareRewardTariffsData,
    fetchMoreCallback: fetchMoreRevShareRewardTariffs,
    loadPartnerRevShareRewardTariffs,
    loading: loadingRevShareRewardTariffs,
    error: errorRevShareRewardTariffs,
    refetch: refetchRevShareRewardTariffs,
  } = useGetRevShareRewardTariffsByTableSize({
    isFullScreenTable,
    partnerId,
  });

  const {
    cpaRewardTariffsData,
    fetchMoreCallback: fetchMoreCpaRewardTariffs,
    loadPartnerCpaRewardTariffs,
    loading: loadingCpaRewardTariffs,
    error: errorCpaRewardTariffs,
    refetch: refetchCpaRewardTariffs,
  } = useGetCpaRewardTariffsByTableSize({
    isFullScreenTable,
    partnerId,
  });

  const isRevShareRewardTariff = rewardTariffType === RewardTariffType.RevShare;
  const fetchMore = isRevShareRewardTariff
    ? fetchMoreRevShareRewardTariffs
    : fetchMoreCpaRewardTariffs;
  const error = isRevShareRewardTariff
    ? errorRevShareRewardTariffs
    : errorCpaRewardTariffs;
  const loading = isRevShareRewardTariff
    ? loadingRevShareRewardTariffs
    : loadingCpaRewardTariffs;
  const refetch = isRevShareRewardTariff
    ? refetchRevShareRewardTariffs
    : refetchCpaRewardTariffs;

  const loadPartnerRewardTariffs = isRevShareRewardTariff
    ? loadPartnerRevShareRewardTariffs
    : loadPartnerCpaRewardTariffs;

  const rewardTariffsData = isRevShareRewardTariff
    ? revShareRewardTariffsData
    : cpaRewardTariffsData;

  const rewardTariffs = useMemo(
    () => rewardTariffsData?.edges.map(({ node }) => node),
    [rewardTariffsData],
  );

  const hasNextPage = rewardTariffsData?.pageInfo.hasNextPage;

  const { columns: cpaRewardTariffColumns } =
    useGetCpaRewardTariffsColumns(isFullScreenTable);
  const { columns: revShareRewardTariffColumns } =
    useGetRevShareRewardTariffsColumns(isFullScreenTable);

  const columns = isRevShareRewardTariff
    ? revShareRewardTariffColumns
    : cpaRewardTariffColumns;

  return {
    hasNextPage,
    rewardTariffs,
    rewardTariffsData,
    refetch,
    loadPartnerRewardTariffs,
    loading,
    error,
    fetchMore,
    columns,
  };
};
