/**
 * When using Apollo's `relayStylePagination` with `fetchPolicy: 'cache-and-network'`,
 * there's a known issue where the UI initially displays all cached items,
 * even if we're only requesting a subset of them. This can cause a "jump" in the UI,
 * as it first shows all cached items, then updates to show only the requested items
 * once the network request completes.
 *
 * This hook is a workaround for that issue. It slices the data to the desired amount
 * on the first page, preventing the UI "jump".
 *
 * Note:
 * This is a temporary solution until the issue is addressed in Apollo Client.
 * For more details on the problem, see the related issue:
 * https://github.com/apollographql/apollo-client/issues/11087
 */

import * as Apollo from '@apollo/client';
import { useCallback } from 'react';
import { OperationVariables } from '@apollo/client';
import { FetchMoreOptions } from '../types';
import { usePaginationData } from './usePaginationData';

interface PaginationDataParams<TData, TVariables extends OperationVariables> {
  originalData: TData | undefined;
  originalFetchMore: <TFetchData, TFetchVariables>(
    fetchMoreOptions: FetchMoreOptions<TFetchVariables, TFetchData>,
  ) => Promise<Apollo.ApolloQueryResult<TFetchData>>;
  options: Apollo.QueryHookOptions<TData, TVariables> | undefined;
}

interface LazyPaginationDataParams<
  TData,
  TVariables extends Apollo.OperationVariables,
> extends PaginationDataParams<TData, TVariables> {
  originalLazyQueryExecFunction: Apollo.LazyQueryExecFunction<
    TData,
    TVariables
  >;
}

export const useLazyPaginationData = <
  TData,
  TVariables extends Apollo.OperationVariables,
>({
  originalLazyQueryExecFunction,
  ...rest
}: LazyPaginationDataParams<TData, TVariables>) => {
  const { data, setPerPage, fetchMore } = usePaginationData({
    ...rest,
  });

  const interceptedLazyQueryExecFunction: Apollo.LazyQueryExecFunction<
    TData,
    TVariables
  > = useCallback(
    (
      opts?:
        | Partial<Apollo.LazyQueryHookOptions<TData, TVariables>>
        | undefined,
    ) => {
      if (opts?.variables?.first) {
        setPerPage(opts?.variables?.first);
      } else {
        setPerPage(rest.options?.variables?.first);
      }

      return originalLazyQueryExecFunction(opts);
    },
    [rest.options?.variables?.first, originalLazyQueryExecFunction, setPerPage],
  );

  return {
    data,
    interceptedLazyQueryExecFunction,
    fetchMore,
  };
};
