import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { SEARCH_INPUT_HEIGHT } from 'src/const';
import { useSearchContext } from 'src/contexts/SearchContext';
import { useGetPlayersLazyQuery } from '../queries/generated/GetPlayers';

const cellHeight = Heights.defaultCellHeight;
const COUNT_PER_PAGE = 10;

export const useGetPlayers = () => {
  const { searchValue } = useSearchContext();

  const baseVariables = {
    first: getCountByWindowHeight(cellHeight, SEARCH_INPUT_HEIGHT),
    search: '',
  };

  const [loadPlayersVariables, setLoadPlayersVariables] =
    useState(baseVariables);

  useEffect(() => {
    setLoadPlayersVariables({
      ...baseVariables,
      search: searchValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loadPlayersList, { data, loading, error, fetchMore, refetch }] =
    useGetPlayersLazyQuery({
      variables: loadPlayersVariables,
    });

  useBlockComponentState({
    loadData: loadPlayersList,
    loading,
    error,
  });

  const playersList = useMemo(
    () => data?.players.edges.map(({ node }) => node),
    [data?.players.edges],
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: data?.players.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [data?.players.pageInfo.endCursor, fetchMore],
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.players.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  return {
    refetchPlayersList: refetch,
    playersList,
    handleSearchSubmit: () =>
      loadPlayersList({
        variables: { ...loadPlayersVariables, search: searchValue },
      }),
  };
};
