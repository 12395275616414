import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { useApolloClient } from '@apollo/client';
import { CompanyBasicData } from 'src/queries/generated/CompanyBasicData';
import { UpdateAccessToTariffsModal } from '../components/UpdateAccessToTariffsModal';

interface Params {
  companyId: string;
  isFullScreenTable: boolean;
}

export const useOpenUpdateAccessToTariffsModal = ({
  companyId,
  isFullScreenTable,
}: Params) => {
  const { dispatch: modalDispatch } = useContext(ModalContext);
  const client = useApolloClient();

  const company = client.readFragment({
    id: `Company:${companyId}`,
    fragment: CompanyBasicData,
  });

  const companyName = company?.name;

  const openModal = useCallback(() => {
    modalDispatch({
      type: ModalActions.Open,
      payload: {
        title: `Управление тарифами`,
        subTitle: `${companyName} ${addHashTagToId(companyId)}`,
        content: (
          <UpdateAccessToTariffsModal
            companyId={companyId}
            isFullScreenTable={isFullScreenTable}
          />
        ),
      },
    });
  }, [modalDispatch, companyName, isFullScreenTable, companyId]);

  return openModal;
};
