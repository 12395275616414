import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { CompanyPartnerReportOrderBy } from 'generatedGraphql';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetRevShareCompanyPartnersReportLazyQuery } from '../queries/generated/GetRevShareCompanyPartnersReport';
import { useGetCurrency } from '../../../hooks/useGetCurrency';
import { useGetOrdering } from '../../../hooks/useGetOrdering';

const cellHeight = Heights.defaultCellHeight;

export const useGetRevShareCompanyPartnersReport = (companyId: string) => {
  const period = useGetPeriodOfStatistic();
  const ordering = useGetOrdering<CompanyPartnerReportOrderBy>(
    CompanyPartnerReportOrderBy.AllDeposit,
  );

  const { currency } = useGetCurrency();

  const [
    loadRevShareCompanyPartnersReport,
    { data, loading, error, fetchMore, refetch },
  ] = useGetRevShareCompanyPartnersReportLazyQuery({
    variables: {
      first: getCountByWindowHeight(cellHeight, 0),
      currency,
      id: companyId,
      ordering,
      period,
    },
  });

  const revShareCompanyPartnerReport =
    data?.company.revShareCompanyPartnerReport;
  const edges = revShareCompanyPartnerReport?.edges;
  const total = revShareCompanyPartnerReport?.total;
  const hasTotalRow = !!total;
  const company = revShareCompanyPartnerReport?.company;
  const endCursor = revShareCompanyPartnerReport?.pageInfo.endCursor;
  const hasNextPage = revShareCompanyPartnerReport?.pageInfo.hasNextPage;

  const revShareCompanyPartnersReportList = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    report?.unshift(company);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [company, edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  return {
    data,
    loadRevShareCompanyPartnersReport,
    loading,
    error,
    fetchMoreCallback,
    refetch,
    revShareCompanyPartnersReportList,
    company: data?.company,
    hasNextPage,
    hasTotalRow,
  };
};
