import { useCallback, useEffect, useMemo, useState } from 'react';
import { getCountByWindowHeight, Heights } from 'utils/getCountByWindowHeight';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useSearchContext } from 'src/contexts/SearchContext';
import { useGetCompanyApplicationsLazyQuery } from '../queries/generated/GetCompanyApplications';
import { CompanyApplicationData } from '../queries/generated/CompanyApplicationData';

const cellHeight = Heights.defaultCellHeight;

export const useCompanyApplications = () => {
  const { searchValue } = useSearchContext();

  const [variables, setVariables] = useState({
    first: getCountByWindowHeight(cellHeight, 0),
  });

  useEffect(() => {
    setVariables((oldVariables) => ({
      ...oldVariables,
      search: searchValue,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    loadCompanyApplications,
    { data, loading, error, fetchMore, refetch },
  ] = useGetCompanyApplicationsLazyQuery();

  const loadCompanyApplicationsCallback = useCallback(
    () => loadCompanyApplications({ variables }),
    [loadCompanyApplications, variables],
  );

  useBlockComponentState({
    loadData: loadCompanyApplicationsCallback,
    loading,
    error,
  });

  const companyApplications: Array<CompanyApplicationData> | undefined =
    useMemo(
      () => data?.companyApplications?.edges.map(({ node }) => node),
      [data?.companyApplications?.edges],
    );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: data?.companyApplications?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [data?.companyApplications?.pageInfo.endCursor, fetchMore],
  );

  return {
    companyApplications,
    loadCompanyApplications: () =>
      loadCompanyApplications({
        variables: { ...variables, search: searchValue },
      }),
    data,
    loading,
    fetchMoreCallback,
    error,
    refetch,
  };
};
