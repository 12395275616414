import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { useApolloClient } from '@apollo/client';
import { PartnerBasicData } from 'src/queries/generated/PartnerBasicData';
import { UpdateAccessToTariffsModal } from '../components/UpdateAccessToTariffsModal';

interface Params {
  partnerId: string;
  isFullScreenTable: boolean;
}

export const useOpenUpdateAccessToTariffsModal = ({
  partnerId,
  isFullScreenTable,
}: Params) => {
  const { dispatch: modalDispatch } = useContext(ModalContext);
  const client = useApolloClient();

  const partner = client.readFragment({
    id: `Partner:${partnerId}`,
    fragment: PartnerBasicData,
  });

  const partnerName = partner?.name;

  const openModal = useCallback(() => {
    modalDispatch({
      type: ModalActions.Open,
      payload: {
        title: `Управление тарифами`,
        subTitle: `${partnerName} ${addHashTagToId(partnerId)}`,
        content: (
          <UpdateAccessToTariffsModal
            partnerId={partnerId}
            isFullScreenTable={isFullScreenTable}
          />
        ),
      },
    });
  }, [modalDispatch, partnerName, isFullScreenTable, partnerId]);

  return openModal;
};
