import { FC } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { Route } from 'src/router/routes.const';
import { AffiliateTypeName } from 'commonTypes';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { STATISTIC_QUERY_PARAMS } from 'src/const';
import {
  REV_SHARE_COMPANY_PARTNERS_STATISTIC_BLOCK_TITLE,
  STATISTIC_NO_DATA,
} from '../../const';
import { BlockSubHeaderAffiliateInfo } from '../../components/BlockSubHeaderAffiliateInfo';
import { useGeneratePathWithStatisticsQueryParams } from '../../hooks/useGeneratePathWithStatisticsQueryParams';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import { useGetRevShareCompanyPartnersReport } from './hooks/useGetRevShareCompanyPartnersReport';
import { RevShareCompanyPartnersReportTable } from './components/RevShareCompanyPartnersReportTable';
import { useGetExportRevShareCompanyPartnerReport } from './hooks/useGetExportRevShareCompanyPartnerReport';

interface Props {
  companyId: string;
}

const Component: FC<Props> = withBlockProvider(({ companyId }) => {
  const {
    data,
    revShareCompanyPartnersReportList,
    loadRevShareCompanyPartnersReport,
    refetch,
    loading,
    error,
    fetchMoreCallback,
    hasNextPage,
    hasTotalRow,
    company,
  } = useGetRevShareCompanyPartnersReport(companyId);

  const exportReportConfig = useGetExportRevShareCompanyPartnerReport({
    companyId,
  });

  useBlockComponentState({
    loadData: loadRevShareCompanyPartnersReport,
    loading,
    error,
  });

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  const { generatePathWithStatisticsQueryParams } =
    useGeneratePathWithStatisticsQueryParams();

  const customPathToPrevPage = generatePathWithStatisticsQueryParams({
    originalPath: Route.STATISTIC,
    queryParams: STATISTIC_QUERY_PARAMS,
  });

  const listExist = hasList(revShareCompanyPartnersReportList);

  return (
    <Block
      title={REV_SHARE_COMPANY_PARTNERS_STATISTIC_BLOCK_TITLE}
      id="revShareCompanyPartnerStatistic"
      headerContent={
        <StatisticBlockHeaderContent
          hasList={listExist}
          exportReportConfig={exportReportConfig}
          handleRefetchClick={refetch}
        />
      }
      subHeader={
        company && (
          <BlockSubHeaderAffiliateInfo
            name={company.name}
            email={company.email}
            affiliateType={AffiliateTypeName.Company}
          />
        )
      }
      shrinkLoaderWrapper={false}
      emptyText={STATISTIC_NO_DATA}
      isEmpty={isListEmpty(revShareCompanyPartnersReportList)}
      shouldReturnToPrevPage
      isPermanentOpened
      customPathToPrevPage={customPathToPrevPage}
    >
      {listExist && (
        <RevShareCompanyPartnersReportTable
          revShareCompanyPartnersReport={revShareCompanyPartnersReportList}
          hasTotalRow={hasTotalRow}
        />
      )}
    </Block>
  );
});

export const RevShareCompanyPartnersReport = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return <Component companyId={id} />;
};
