import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { CompanyPromoCodeMediaItemsData } from '../queries/generated/CompanyPromoCodeMediaItemsData';
import { PromoCodeMediaItemModal } from '../../PromoCodeMediaItemModal';

interface UseOpenReferralLinkDetailsModalResult<T> {
  openPromoCodeDetailsModal: (arg: T, initiatorId: string) => void;
}

export const useOpenPromoCodeDetailsModal = <T extends object>(
  companyId: string,
): UseOpenReferralLinkDetailsModalResult<T> => {
  const { dispatch } = useContext(ModalContext);

  const openPromoCodeDetailsModal = useCallback(
    (referralLinkMediaItem: T, initiatorId: string) => {
      const { id: mediaItemPromoCodeId } =
        referralLinkMediaItem as CompanyPromoCodeMediaItemsData;

      dispatch({
        type: ModalActions.Open,
        payload: {
          title: addHashTagToId(mediaItemPromoCodeId),
          subTitle: 'Промокод',
          content: (
            <PromoCodeMediaItemModal
              companyId={companyId}
              promoCodeId={mediaItemPromoCodeId}
            />
          ),
          initiatorId,
        },
      });
    },
    [dispatch, companyId],
  );

  return {
    openPromoCodeDetailsModal,
  };
};
