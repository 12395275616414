export enum ColumnId {
  Id = 'id',
  Title = 'name',
  AccountType = 'owner',
  AffiliateName = 'owner.name',
  ReferralCode = 'code',
  MediaCampaign = 'mediaCampaign',
  CreatedAt = 'createdAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Title]: 'Название',
  [ColumnId.MediaCampaign]: 'Медиа кампания',
  [ColumnId.AccountType]: 'Тип аккаунта',
  [ColumnId.AffiliateName]: 'Имя/Название аккаунта',
  [ColumnId.ReferralCode]: 'Реферальный код',
  [ColumnId.CreatedAt]: 'Создан',
};

export const columnsIds = [
  ColumnId.Id,
  ColumnId.Title,
  ColumnId.MediaCampaign,
  ColumnId.AccountType,
  ColumnId.AffiliateName,
  ColumnId.ReferralCode,
  ColumnId.CreatedAt,
];
