import { useEffect } from 'react';
import { UserPrivilege } from 'generatedUserPrivilege';
import { ApolloError } from '@apollo/client';
import { Currency } from 'commonTypes';
import { SumInput } from 'generatedGraphql';
import { useAuth } from 'commonComponents/Auth';
import { useGetConvertCurrencyLazyQuery } from '../queries/generated/GetConvertCurrency';

interface ConvertCurrencyInput {
  variables: { from: SumInput; to: Currency };
}

interface ConvertPaymentCurrency {
  convertCurrency: ({ variables: { from, to } }: ConvertCurrencyInput) => void;
  convertLoading: boolean;
  convertError?: ApolloError;
  convertRemainingAmount: ({
    variables: { from, to },
  }: ConvertCurrencyInput) => void;
  convertRemainingAmountLoading: boolean;
  convertRemainingAmountError?: ApolloError;
  hasConvertCurrencyPrivilege: boolean;
}

export const useConvertPaymentCurrency = (
  setPaymentAmount: (convertedAmount: number) => void,
  setPaymentRemainingAmount: (convertedAmount: number) => void,
): ConvertPaymentCurrency => {
  const { privileges } = useAuth();

  const [
    convertCurrency,
    { data: convertedData, loading: convertLoading, error: convertError },
  ] = useGetConvertCurrencyLazyQuery();

  useEffect(() => {
    if (convertedData) {
      setPaymentAmount(convertedData.convertCurrency.amount);
    }
  }, [convertedData, setPaymentAmount]);

  const [
    convertRemainingAmount,
    {
      data: convertedRemainingAmountData,
      loading: convertRemainingAmountLoading,
      error: convertRemainingAmountError,
    },
  ] = useGetConvertCurrencyLazyQuery();

  useEffect(() => {
    if (convertedRemainingAmountData) {
      setPaymentRemainingAmount(
        convertedRemainingAmountData.convertCurrency.amount,
      );
    }
  }, [convertedRemainingAmountData, setPaymentRemainingAmount]);

  const hasConvertCurrencyPrivilege = privileges.areEveryGranted({
    privileges: [UserPrivilege.ConvertCurrency],
  });

  return {
    convertCurrency,
    convertLoading,
    convertError,
    convertRemainingAmount,
    convertRemainingAmountLoading,
    convertRemainingAmountError,
    hasConvertCurrencyPrivilege,
  };
};
