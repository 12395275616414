import { FC } from 'react';
import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import {
  GroupWithoutIsActive,
  NavigationButtonData,
  NavigationGroupItemData,
  NavigationLinkData,
} from '../../types';
import { NavigationItem } from '../NavigationItem';
import { SubItem } from './components/SubItem';
import styles from './NavigationGroup.module.scss';

interface Props {
  groupTitle: string;
  groupIcon: IconDefinition;
  className?: string;
  subNavigationClassName?: string;
  group?: NavigationGroupItemData['group'];
  isOpen: boolean;
  isActive: boolean;
  toggleNavigationDropdown: (arg: GroupWithoutIsActive) => void;
  openNavigationDropdownItem: (id: string) => void;
}

export const NavigationGroup: FC<Props> = ({
  groupTitle,
  groupIcon,
  group,
  className,
  subNavigationClassName,
  isOpen,
  isActive,
  toggleNavigationDropdown,
  openNavigationDropdownItem,
}) => {
  if (!group) {
    return null;
  }

  return (
    <div className={className}>
      <NavigationItem
        onClick={() =>
          toggleNavigationDropdown({ id: groupTitle, isOpen: !isOpen })
        }
        className={styles.itemGroup}
        icon={groupIcon}
        title={groupTitle}
        isActive={isActive}
        isOpen={isOpen}
        isDropdown
      />
      {isOpen && (
        <div className={classNames(subNavigationClassName, styles.list)}>
          {group.map((item) => {
            if ('route' in item) {
              const { route, title } = item as NavigationLinkData;

              return (
                <SubItem
                  onClick={() => openNavigationDropdownItem(groupTitle)}
                  key={route}
                  route={route}
                  title={title}
                />
              );
            }

            if ('onClick' in item) {
              const { onClick, title } = item as NavigationButtonData;

              return (
                <SubItem
                  onClick={() => {
                    onClick();
                    openNavigationDropdownItem(groupTitle);
                  }}
                  key={title}
                  title={title}
                />
              );
            }

            return null;
          })}
        </div>
      )}
    </div>
  );
};
