import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { NoteData } from 'queries/generated/NoteData';
import { ResetPartnerName } from '../components/ResetPartnerName/ResetPartnerName';
import {
  UpdatePartnerNote,
  useUpdatePartnerNote,
} from '../../../queries/generated/UpdatePartnerNote';

interface PartnerQueries {
  updatePartnerNoteHandler: (value: string) => void;
  updatePartnerNoteLoading: boolean;
  updatePartnerNoteResult?: UpdatePartnerNote | null;
  note?: NoteData;
  resetPartnerName: () => void;
}

interface UsePartnerQueriesParams {
  id: string;
  name: string;
  defaultName: string;
}

export const usePartnerQueries = ({
  id,
  name,
  defaultName,
}: UsePartnerQueriesParams): PartnerQueries => {
  const { dispatch } = useContext(ModalContext);

  const [
    updatePartnerNote,
    { data: updatePartnerNoteResult, loading: updatePartnerNoteLoading },
  ] = useUpdatePartnerNote();

  const updatePartnerNoteHandler = (updatedText: string) => {
    updatePartnerNote({
      variables: {
        input: {
          partnerId: id,
          text: updatedText,
        },
      },
    });
  };

  const resetPartnerName = useCallback(() => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        content: (
          <ResetPartnerName
            partnerId={id}
            partnerName={name}
            partnerDefaultName={defaultName}
          />
        ),
        title: 'Сброс имени аккаунта',
        subTitle: `${name} #${id}`,
      },
    });
  }, [defaultName, dispatch, id, name]);

  return {
    updatePartnerNoteHandler,
    updatePartnerNoteLoading,
    updatePartnerNoteResult,
    resetPartnerName,
  };
};
