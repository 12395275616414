import { useMemo, useState } from 'react';
import { LocalFile } from '../types';
import { LoadingFileStatus } from '../const';

export const useLocalFiles = () => {
  const [localFilesMap, setLocalFilesMap] = useState<Map<string, LocalFile>>(
    new Map(),
  );

  const localFiles = useMemo(
    () => Array.from(localFilesMap.values()),
    [localFilesMap],
  );

  const removeLocalFile = async (
    id: string,
    status: LoadingFileStatus,
    abortFunction?: () => void,
  ) => {
    if (status === LoadingFileStatus.Loading) {
      abortFunction?.();
    }

    setLocalFilesMap((prev) => {
      const newLocalFiles = new Map(prev);

      newLocalFiles.delete(id);

      return newLocalFiles;
    });
  };

  const updateLocalFilesItem = (
    id: string,
    dataToUpdate: Partial<LocalFile>,
  ) => {
    setLocalFilesMap((prev) => {
      const newLocalFiles = new Map(prev);
      const currentLocalFile = newLocalFiles.get(id);

      if (!currentLocalFile) {
        return prev;
      }

      newLocalFiles.set(id, { ...currentLocalFile, ...dataToUpdate });

      return newLocalFiles;
    });
  };

  const addLocalFiles = (files: Array<LocalFile>) => {
    if (files.length === 0) {
      return;
    }

    setLocalFilesMap((prev) => {
      const newLocalFiles: Map<string, LocalFile> = new Map();

      files.forEach((file) => {
        newLocalFiles.set(file.id, file);
      });

      prev.forEach((value, key) => {
        newLocalFiles.set(key, value);
      });

      return newLocalFiles;
    });
  };

  return {
    localFiles,
    addLocalFiles,
    removeLocalFile,
    updateLocalFilesItem,
  };
};
