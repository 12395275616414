import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdateAccessToPartnerRevShareRewardTariffsVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateAccessToPartnerRevShareRewardTariffsInput;
}>;


export type UpdateAccessToPartnerRevShareRewardTariffs = { __typename: 'Mutation', updateAccessToPartnerRevShareRewardTariffs: { __typename: 'UpdateAccessToPartnerRevShareRewardTariffsPayload', id: string, accessToRevShareRewardTariffs: Array<{ __typename: 'AccessToRevShareRewardTariff', accessible: boolean, id: string }> } };


export const UpdateAccessToPartnerRevShareRewardTariffsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateAccessToPartnerRevShareRewardTariffs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateAccessToPartnerRevShareRewardTariffsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateAccessToPartnerRevShareRewardTariffs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessToRevShareRewardTariffs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessible"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type UpdateAccessToPartnerRevShareRewardTariffsMutationFn = Apollo.MutationFunction<UpdateAccessToPartnerRevShareRewardTariffs, UpdateAccessToPartnerRevShareRewardTariffsVariables>;

/**
 * __useUpdateAccessToPartnerRevShareRewardTariffs__
 *
 * To run a mutation, you first call `useUpdateAccessToPartnerRevShareRewardTariffs` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessToPartnerRevShareRewardTariffs` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessToPartnerRevShareRewardTariffs, { data, loading, error }] = useUpdateAccessToPartnerRevShareRewardTariffs({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccessToPartnerRevShareRewardTariffs(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccessToPartnerRevShareRewardTariffs, UpdateAccessToPartnerRevShareRewardTariffsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAccessToPartnerRevShareRewardTariffs, UpdateAccessToPartnerRevShareRewardTariffsVariables>(UpdateAccessToPartnerRevShareRewardTariffsDocument, options);
      }
export type UpdateAccessToPartnerRevShareRewardTariffsHookResult = ReturnType<typeof useUpdateAccessToPartnerRevShareRewardTariffs>;
export type UpdateAccessToPartnerRevShareRewardTariffsMutationResult = Apollo.MutationResult<UpdateAccessToPartnerRevShareRewardTariffs>;
export type UpdateAccessToPartnerRevShareRewardTariffsMutationOptions = Apollo.BaseMutationOptions<UpdateAccessToPartnerRevShareRewardTariffs, UpdateAccessToPartnerRevShareRewardTariffsVariables>;