import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { AffiliateMediaItemsSteps } from '../../AffiliatePromoCodeItem/const';
import { GO_TO_FIRST_STEP_BUTTON_INITIATOR_ID } from '../../CreateAffiliatePromoCode/const';

interface UseUpdateAffiliateModalHeaderParams {
  companyAffiliateId: string;
  companyAffiliateName: string;
  currentPromoCodeId?: string;
  currentReferralLinkId?: string;
}

export const useUpdateAffiliateModalHeader = ({
  companyAffiliateId,
  companyAffiliateName,
  currentPromoCodeId,
  currentReferralLinkId,
}: UseUpdateAffiliateModalHeaderParams): void => {
  const {
    state: { step, initiatorId },
    dispatch,
  } = useContext(ModalContext);

  useEffect(() => {
    switch (step) {
      case AffiliateMediaItemsSteps.PromoCodeDetails:
        dispatch({
          type: ModalActions.UpdateModalProps,
          payload: {
            title: addHashTagToId(currentPromoCodeId),
            subTitle: 'Промокод',
            previousStep: AffiliateMediaItemsSteps.BaseStep,
          },
        });
        break;
      case AffiliateMediaItemsSteps.CreatePromoCodeBaseInfo:
        dispatch({
          type: ModalActions.UpdateModalProps,
          payload: {
            title: 'Создание промокода',
            subTitle: 'Шаг 1',
            previousStep:
              initiatorId === GO_TO_FIRST_STEP_BUTTON_INITIATOR_ID
                ? AffiliateMediaItemsSteps.CreatePromoCodeConnection
                : AffiliateMediaItemsSteps.BaseStep,
          },
        });
        break;
      case AffiliateMediaItemsSteps.CreatePromoCodeConnection:
        dispatch({
          type: ModalActions.UpdateModalProps,
          payload: {
            title: 'Создание промокода',
            subTitle: 'Шаг 2',
            previousStep: AffiliateMediaItemsSteps.BaseStep,
          },
        });
        break;
      case AffiliateMediaItemsSteps.CreatePromoCodeConfirm:
        dispatch({
          type: ModalActions.UpdateModalProps,
          payload: {
            title: 'Создание промокода',
            subTitle: 'Подтверждение',
            previousStep: AffiliateMediaItemsSteps.BaseStep,
          },
        });
        break;
      case AffiliateMediaItemsSteps.CreateReferralLink:
        dispatch({
          type: ModalActions.UpdateModalProps,
          payload: {
            title: 'Создание реферальной ссылки',
            subTitle: companyAffiliateName,
          },
        });
        break;
      case AffiliateMediaItemsSteps.ReferralLinkDetails:
        dispatch({
          type: ModalActions.UpdateModalProps,
          payload: {
            title: addHashTagToId(currentReferralLinkId),
            subTitle: 'Медиа элемент',
            previousStep: AffiliateMediaItemsSteps.BaseStep,
          },
        });
        break;
      case AffiliateMediaItemsSteps.BaseStep:
      default:
        dispatch({
          type: ModalActions.UpdateModalProps,
          payload: {
            title: `#${companyAffiliateId}`,
            subTitle: companyAffiliateName,
          },
        });
        break;
    }
  }, [
    companyAffiliateId,
    companyAffiliateName,
    currentPromoCodeId,
    currentReferralLinkId,
    dispatch,
    initiatorId,
    step,
  ]);
};
