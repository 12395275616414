import { ApolloQueryResult } from '@apollo/client/core/types';
import errorToast from 'ui/Toast/ErrorToast';
import { FetchMoreCustomParams, FetchMoreOptions } from '../types';
import { getCodeFromGraphQLError } from '../../getCodeFromGraphQLError';

/**
 * Previously, the default implementation of fetchMore was to throw an error. This behavior has now been modified.
 * Currently, if you set shouldShowErrorToast=true (default is true) when invoking fetchMore, and if a server error
 * occurs, a toast message will be displayed. Similarly, if a network error takes place, the error will be caught,
 * a toast message will be displayed, and the error will not be propagated further.
 */
export const fetchMoreCustom = async <TFetchData, TVariables>(
  fetchMoreOptions: FetchMoreOptions<TVariables, TFetchData>,
  fetchMore: (
    fetchMoreOptions: FetchMoreOptions<TVariables, TFetchData>,
  ) => Promise<ApolloQueryResult<TFetchData>>,
  fetchMoreParams: FetchMoreCustomParams = { shouldShowErrorToast: true },
): Promise<ApolloQueryResult<TFetchData> | undefined> => {
  if (!fetchMoreParams.shouldShowErrorToast) {
    return fetchMore(fetchMoreOptions);
  }

  try {
    const fetchData = await fetchMore(fetchMoreOptions);

    fetchData.errors?.forEach((err) => {
      errorToast({
        header: getCodeFromGraphQLError(err),
        text: err.message,
        toastId: err.path?.join() || 'errorToast',
      });
    });

    return fetchData;
  } catch (e) {
    errorToast({
      header: 'Соединение с\u00a0сервером потеряно',
      text: 'Если интернет-соединение стабильно, обратитесь к\u00a0системному администратору',
      toastId: 'network-error',
    });

    return undefined;
  }
};
