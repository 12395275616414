import {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import classnames from 'classnames';
import { ModalActions, ModalContext } from 'ui/Modal/ModalStore';
import styles from './TabsWrapper.module.scss';

export interface Tabs {
  title: string | ReactElement;
  content: ReactElement | null;
  isVisible?: boolean;
}

interface Props {
  tabs: Array<Tabs>;
  activeTabDefault?: number;
  tabsWrapper?: string;
}

export const TabsWrapper: FC<Props> = ({
  tabs,
  tabsWrapper,
  activeTabDefault = 0,
}) => {
  const { state, dispatch } = useContext(ModalContext);

  const changeActive = useCallback(
    (activeTab: number) => {
      dispatch({
        type: ModalActions.UpdateActiveTab,
        payload: {
          activeTab,
        },
      });
    },
    [dispatch],
  );

  useEffect(() => {
    changeActive(activeTabDefault);
  }, [activeTabDefault, changeActive]);

  const filteredTabs = useMemo(
    () => tabs.filter(({ isVisible }) => isVisible !== false),
    [tabs],
  );

  return (
    <div id="tabsWrapper" className={tabsWrapper}>
      <div className={styles.tabsContainer}>
        {filteredTabs.map(({ title }, index) => {
          const active = index === state.activeTab ? 'active' : '';

          if (!title) return null;

          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={() => changeActive(index)}
              className={classnames(styles.tab, styles[active])}
            >
              {title}
            </div>
          );
        })}
      </div>
      <div id="tabsContent">
        {filteredTabs.map(({ content }, index) => {
          if (index === state.activeTab) {
            // eslint-disable-next-line react/no-array-index-key
            return <div key={index}>{content}</div>;
          }

          return null;
        })}
      </div>
    </div>
  );
};
