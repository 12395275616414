import { FC } from 'react';
import { Route } from 'src/router/routes.const';
import { Link } from 'react-router-dom';
import { STATISTIC_QUERY_PARAMS } from 'src/const';
import { CpaCompanyPartnersReportRow } from '../../types';
import { MultilineCellWithLinkWithPeriodQueryParams } from '../../../../components/MultilineCellWithLinkWithPeriodQueryParams';
import { useGeneratePathWithStatisticsQueryParams } from '../../../../hooks/useGeneratePathWithStatisticsQueryParams';

interface Props {
  row: CpaCompanyPartnersReportRow;
}

export const CpaMediaOwnerCell: FC<Props> = ({ row }) => {
  const { generatePathWithStatisticsQueryParams } =
    useGeneratePathWithStatisticsQueryParams();

  if (row.company) {
    const { id, name, email } = row.company;

    return (
      <MultilineCellWithLinkWithPeriodQueryParams
        id={id}
        title={`Компания ${name}`}
        subTitle={email}
        route={Route.STATISTIC_CPA_COMPANY}
      />
    );
  }

  const {
    id: affiliateId,
    name,
    company: { id: companyId },
  } = row.companyPartner;

  const path = generatePathWithStatisticsQueryParams({
    originalPath: Route.STATISTIC_CPA_COMPANY_AFFILIATE,
    params: { companyId, affiliateId },
    queryParams: STATISTIC_QUERY_PARAMS,
  });

  return (
    <Link to={path} target="_self" rel="noopener noreferrer">
      {name}
    </Link>
  );
};
