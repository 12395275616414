import { FC } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import styles from './NavigationItem.module.scss';

interface Props {
  title: string;
  isActive: boolean;
  isOpen?: boolean;
  isDropdown: boolean;
  icon: IconProp;
  onClick?: () => void;
  className?: string;
}

export const NavigationItem: FC<Props> = ({
  isActive,
  isOpen,
  icon,
  title,
  onClick,
  isDropdown,
  className,
}) => (
  <div
    onClick={onClick}
    className={classnames(
      className,
      styles.groupHeader,
      isActive && styles.active,
      isOpen && styles.isOpen,
    )}
  >
    <div className={styles.groupHeaderLeftSide}>
      <div className={styles.iconContainer}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <span>{title}</span>
    </div>
    {isDropdown && (
      <div className={styles.dropdownIcon}>
        <FontAwesomeIcon icon={faCaretUp} />
      </div>
    )}
  </div>
);
