import { FC } from 'react';
import { Checkbox, CheckboxSize } from 'ui/Checkbox';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { RewardTariffType } from 'commonTypes';
import { rewardTariffMap } from 'src/const';
import styles from './TariffCard.module.scss';

interface Props {
  id: string;
  name: string;
}

export const TariffCard: FC<Props> = ({ name, id }) => (
  <label htmlFor={id} className={styles.container}>
    <Checkbox
      className={styles.checkbox}
      checkboxSize={CheckboxSize.Small}
      label=""
      name={id}
    />
    <div className={styles.header}>
      <div className={styles.tariffName}>{name}</div>
      <div className={styles.tariffId}>{addHashTagToId(id)}</div>
    </div>
    {/* the div is needed for the display: grid in the container */}
    <div />
    <div className={styles.content}>
      <div className={styles.tariffType}>
        {rewardTariffMap[RewardTariffType.RevShare]}
      </div>
    </div>
  </label>
);
