import { CSSProperties } from 'react';
import { LoadingFileStatus } from '../../const';

export const MIN_BLUR_PX = 1;
export const MAX_BLUR_PX = 7;

const calculateBlur = (percentage: number): number =>
  MIN_BLUR_PX + (MAX_BLUR_PX - MIN_BLUR_PX) * (1 - percentage / 100);

export const getBlurCssStyleValue = (
  status: LoadingFileStatus,
  percentage?: number,
): CSSProperties | undefined => {
  if (status !== LoadingFileStatus.Loading) {
    return undefined;
  }

  if (percentage === undefined) {
    return { filter: `blur(${MAX_BLUR_PX}px)` };
  }

  return {
    filter: `blur(${calculateBlur(percentage)}px)`,
  };
};
