import { RevShareGeneralReportData } from '../components/RevShareGeneralReport/queries/generated/RevShareGeneralReportData';
import { CpaGeneralReportData } from '../components/CpaGeneralReport/queries/generated/CpaGeneralReportData';
import { AffiliateType } from '../../MediaItems/components/MediaItemsReferralLink/const';

type GeneralReportDataAffiliate =
  | RevShareGeneralReportData['affiliate']
  | CpaGeneralReportData['affiliate'];

export const getAffiliateInfo = <T extends GeneralReportDataAffiliate>(
  affiliate: T,
) => {
  if (affiliate.__typename === AffiliateType.Partner) {
    return {
      id: affiliate.id,
      name: affiliate.name,
      email: affiliate.partnerEmail,
      typeName: affiliate.__typename,
    };
  }

  return {
    id: affiliate.id,
    name: affiliate.name,
    email: affiliate.companyEmail,
    typeName: affiliate.__typename,
  };
};
