import { UserPrivilege } from 'generatedUserPrivilege';
import { useCallback } from 'react';
import { useGetExportCompanyAffiliateClickIdReportLazyQuery } from '../queries/generated/GetExportCompanyAffiliateClickIdReport';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useExportClickIdReportHandlers } from '../../../helpers/useExportClickIdReportHandlers';

interface Params {
  companyId: string;
  affiliateId: string;
}

export const useGetExportCompanyAffiliateClickIdReport = ({
  companyId,
  affiliateId,
}: Params) => {
  const period = useGetPeriodOfStatistic();
  const {
    handleCompleteExportClickIdReport,
    handleErrorExportClickIdReport,
    downloadClickIdReportFileLoading,
  } = useExportClickIdReportHandlers();

  const [exportReport, { loading: getExportClickIdReportLinkLoading }] =
    useGetExportCompanyAffiliateClickIdReportLazyQuery({
      onCompleted: (data) => {
        handleCompleteExportClickIdReport(
          data.company.affiliate.exportClickIdReport,
        );
      },
      onError: handleErrorExportClickIdReport,
    });

  const getClickIdReport = useCallback(() => {
    exportReport({
      variables: {
        companyId,
        affiliateId,
        period,
      },
    });
  }, [affiliateId, companyId, exportReport, period]);

  return {
    getReport: getClickIdReport,
    loading:
      getExportClickIdReportLinkLoading || downloadClickIdReportFileLoading,
    privilege: UserPrivilege.ExportCompanyAffiliateClickIdReport,
  };
};
