import { addHashTagToId } from 'src/utils/addHashTagToId';
import { GetCompanyAffiliatesForDropdown } from '../queries/generated/GetCompanyAffiliatesForDropdown';

export const getCompanyAffiliatesOptions = (
  affiliates?: GetCompanyAffiliatesForDropdown['company']['affiliates']['edges'],
) =>
  affiliates?.map(({ node }) => ({
    label: node.name,
    id: node.id,
    labelRight: addHashTagToId(node.id),
  }));
