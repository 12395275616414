import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { ApolloError } from '@apollo/client';
import { DeclinePaymentInput, PaymentStatus, Sum } from 'generatedGraphql';
import undoActionToast from 'ui/Toast/UndoActionToast';
import { useDeclinePayment } from './queries/generated/DeclinePayment';
import {
  WithdrawalItemActions,
  WithdrawalItemContext,
} from '../../../../WithdrawalItemStore';
import { WithdrawalModalSteps } from '../../types';
import { PaymentData } from '../../queries/generated/PaymentData';

interface UpdateModalStep {
  step: number;
  paymentId: string;
  paymentPaidSum?: Sum | null;
}

interface Value {
  error?: ApolloError;
  loading: boolean;
  onDeclinePayment: (obj: DeclinePaymentInput) => void;
  updateModalStep: (obj: UpdateModalStep) => void;
}

export const useButtonsUpdatePayment = (payment: PaymentData): Value => {
  const { dispatch } = useContext(ModalContext);
  const { dispatch: withdrawalItemDispatch } = useContext(
    WithdrawalItemContext,
  );
  const [declinePayment, { error, loading }] = useDeclinePayment();

  const onDeclinePayment = ({ id }: DeclinePaymentInput) => {
    const paymentData = { ...payment };

    paymentData.status = PaymentStatus.Declined;
    declinePayment({
      variables: { input: { id } },
      context: { delayed: true },
      optimisticResponse: {
        __typename: 'Mutation',
        declinePayment: {
          __typename: 'DeclinePaymentPayload',
          payment: paymentData,
        },
      },
    });

    undoActionToast('Платеж отменен');
  };
  const updateModalStep = useCallback(
    async ({ step, paymentId, paymentPaidSum }: UpdateModalStep) => {
      dispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          step,
          previousStep: WithdrawalModalSteps.BaseStep,
        },
      });

      withdrawalItemDispatch({
        type: WithdrawalItemActions.setData,
        payload: {
          paymentPaidSum,
          paymentId,
        },
      });
    },
    [dispatch, withdrawalItemDispatch],
  );

  return {
    onDeclinePayment,
    error,
    loading,
    updateModalStep,
  };
};
