import React, { FC } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import Input from 'ui/Input';
import { MediaCampaignDropdown } from 'commonComponents/MediaCampaignDropdown';
import { Dropdown } from 'ui/Dropdown';
import { RewardTariffType } from 'commonTypes';
import { CpaTariffDropdown } from 'commonComponents/CpaTariffDropdown';
import { RevShareTariffDropdown } from 'commonComponents/RevShareTariffDropdown';
import { Tooltip } from 'ui/Tooltip';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { useGenerateReferralCode } from 'src/utils/hooks/useGenerateReferralCode';
import { rewardTypeOptions } from 'src/helpers';
import Error from 'ui/Error';
import {
  FieldName,
  mapFieldNameToTitle,
  MEDIA_CAMPAIGN_PLACEHOLDER,
  NAME_PLACEHOLDER,
  TYPE_REWARD_TARIFF_PLACEHOLDER,
} from './const';
import { CreateAffiliateReferralLinkValues, validate } from './validation';
import { useCreateAffiliateReferralLink } from './hooks/useCreateAffiliateReferralLink';

interface Props {
  companyAffiliateId: string;
}

export const CreateAffiliateReferralLink: FC<Props> = ({
  companyAffiliateId,
}) => {
  const { referralCodeLoading, referralCode } = useGenerateReferralCode();

  const initialValues: CreateAffiliateReferralLinkValues = {
    [FieldName.MediaCampaignId]: '',
    [FieldName.Name]: '',
    [FieldName.Code]: referralCode || null,
    [FieldName.TypeRewardTariff]: null,
    [FieldName.CPARewardTariffId]: '',
    [FieldName.FallbackTariffId]: '',
    [FieldName.RevShareRewardTariffId]: '',
  };

  const { onCreate, loading, error } = useCreateAffiliateReferralLink();

  const onPaymentModelChangeHandler = (
    setFieldValue: FormikHelpers<typeof initialValues>['setFieldValue'],
    setTouched: FormikHelpers<typeof initialValues>['setTouched'],
  ) => {
    setFieldValue(FieldName.CPARewardTariffId, '');
    setFieldValue(FieldName.FallbackTariffId, '');
    setFieldValue(FieldName.RevShareRewardTariffId, '');
    setTouched(
      {
        [FieldName.CPARewardTariffId]: false,
        [FieldName.FallbackTariffId]: false,
        [FieldName.RevShareRewardTariffId]: false,
      },
      false,
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onCreate(companyAffiliateId, values);
      }}
      validate={validate}
      validateOnChange={false}
      enableReinitialize
    >
      {({ isValid, dirty, values, setFieldValue, setTouched }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <FormRow isWide>
              <Input
                name={FieldName.Name}
                label={mapFieldNameToTitle[FieldName.Name]}
                placeholder={NAME_PLACEHOLDER}
                maxLength={50}
              />
            </FormRow>
            <FormRow isWide>
              <MediaCampaignDropdown
                name={FieldName.MediaCampaignId}
                label={mapFieldNameToTitle[FieldName.MediaCampaignId]}
                placeholder={MEDIA_CAMPAIGN_PLACEHOLDER}
              />
            </FormRow>
            <FormRow isWide>
              <Input
                name={FieldName.Code}
                label={mapFieldNameToTitle[FieldName.Code]}
                maxLength={36}
              />
            </FormRow>
            <FormRow isWide>
              <Dropdown
                onChange={() => {
                  onPaymentModelChangeHandler(setFieldValue, setTouched);
                }}
                name={FieldName.TypeRewardTariff}
                label={mapFieldNameToTitle[FieldName.TypeRewardTariff]}
                options={rewardTypeOptions}
                placeholder={TYPE_REWARD_TARIFF_PLACEHOLDER}
              />
            </FormRow>

            {values[FieldName.TypeRewardTariff] === RewardTariffType.Cpa && (
              <>
                <FormRow isWide>
                  <CpaTariffDropdown
                    name={FieldName.CPARewardTariffId}
                    label={mapFieldNameToTitle[FieldName.CPARewardTariffId]}
                  />
                </FormRow>
                <FormRow isWide>
                  <RevShareTariffDropdown
                    name={FieldName.FallbackTariffId}
                    label={mapFieldNameToTitle[FieldName.FallbackTariffId]}
                  />
                </FormRow>
              </>
            )}

            {values[FieldName.TypeRewardTariff] ===
              RewardTariffType.RevShare && (
              <RevShareTariffDropdown
                name={FieldName.RevShareRewardTariffId}
                label={mapFieldNameToTitle[FieldName.RevShareRewardTariffId]}
              />
            )}
          </FormRowsWrapper>
          <Tooltip
            tooltipContent={
              !isValid || !dirty ? 'Заполните все поля' : undefined
            }
            placement="right"
          >
            <Button
              size={ButtonSize.Large}
              theme={ButtonTheme.Process}
              type="submit"
              isLoading={loading || referralCodeLoading}
              disabled={!isValid || !dirty}
            >
              Создать
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
