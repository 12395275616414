import { useContext } from 'react';
import { BlockContext } from 'ui/Block/store';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { Checkbox } from 'ui/Checkbox';
import { RewardTariffType } from 'commonTypes';
import { Formik } from 'formik';
import { UserPrivilege } from 'generatedUserPrivilege';
import Privilege from 'commonComponents/Privilege';
import styles from './StatisticBlockHeaderContent.module.scss';
import { StatisticDatePeriod } from './components/StatisticDatePeriod';
import { CurrencyDropdownWithFormik } from './components/CurrencyDropdownWithFormik/CurrencyDropdownWithFormik';

const SHOW_ALL_CPA_FIELD = 'showAllCpa';

interface Props {
  handleRefetchClick: () => void;
  rewardTariffType?: RewardTariffType;
  showAllCpa?: (value: boolean) => void;
  exportReportConfig: {
    loading: boolean;
    getReport: () => void;
    privilege: UserPrivilege;
  };
  exportClickIdReportConfig?: {
    loading: boolean;
    getReport: () => void;
    privilege: UserPrivilege;
  };
  hasList: boolean;
}
export const StatisticBlockHeaderContent = ({
  handleRefetchClick,
  showAllCpa,
  rewardTariffType,
  exportReportConfig,
  exportClickIdReportConfig,
  hasList,
}: Props) => {
  const { state } = useContext(BlockContext);

  const buttonsLoading =
    state.isLoading ||
    exportReportConfig.loading ||
    exportClickIdReportConfig?.loading;

  return (
    <div className={styles.headerContent}>
      <StatisticDatePeriod />
      {rewardTariffType === RewardTariffType.Cpa && (
        <Formik
          initialValues={{ [SHOW_ALL_CPA_FIELD]: false }}
          onSubmit={() => undefined}
        >
          <Checkbox
            isDisabled={buttonsLoading}
            label="Показать все CPA"
            name={SHOW_ALL_CPA_FIELD}
            onChange={(value) => {
              showAllCpa?.(value);
            }}
          />
        </Formik>
      )}
      {rewardTariffType !== RewardTariffType.Cpa && (
        <CurrencyDropdownWithFormik />
      )}
      <Privilege privileges={[exportReportConfig.privilege]}>
        <ActionButton
          actionType={ButtonType.Archive}
          loading={exportReportConfig.loading}
          disabled={!hasList || state.isLoading}
          onClick={exportReportConfig.getReport}
          dataTestId="export-statistic-report-btn"
        />
      </Privilege>
      {exportClickIdReportConfig && (
        <Privilege privileges={[exportClickIdReportConfig.privilege]}>
          <ActionButton
            actionType={ButtonType.FileExport}
            loading={exportClickIdReportConfig.loading}
            disabled={!hasList || state.isLoading}
            onClick={exportClickIdReportConfig.getReport}
            dataTestId="export-statistic-click-id-report-btn"
          />
        </Privilege>
      )}
      <ActionButton
        actionType={ButtonType.Update}
        disabled={buttonsLoading}
        onClick={() => handleRefetchClick()}
        dataTestId="refetch-statistic-report-btn"
      />
    </div>
  );
};
