import { RewardTariffType } from 'commonTypes';
import { ColumnDef } from '@tanstack/react-table';
import { Route as RouteEnum } from 'src/router/routes.const';
import { generatePath } from 'react-router';
import { useMemo } from 'react';
import { RevShareRewardTariffsTable } from '../RevShareRewardTariffsTable';
import { CpaRewardTariffsTable } from '../CpaRewardTariffsTable';

interface Props<T extends object> {
  rewardTariffsType: RewardTariffType;
  companyId: string;
  isFullScreenTable: boolean;
  rewardTariffs?: Array<T>;
  columns: Array<ColumnDef<T>>;
}

export const RewardTariffsTable = <T extends { __typename: string }>({
  companyId,
  rewardTariffsType,
  isFullScreenTable,
  rewardTariffs,
  columns,
}: Props<T>) => {
  const routeToCustomPage = useMemo(
    () =>
      !isFullScreenTable
        ? generatePath(RouteEnum.COMPANY_REWARD_TARIFFS, { id: companyId })
        : undefined,
    [companyId, isFullScreenTable],
  );

  if (rewardTariffsType === RewardTariffType.RevShare) {
    return (
      <RevShareRewardTariffsTable
        isFullScreenTable={isFullScreenTable}
        revShareRewardTariffs={rewardTariffs}
        columns={columns}
        routeToCustomPage={routeToCustomPage}
      />
    );
  }

  return (
    <CpaRewardTariffsTable
      isFullScreenTable={isFullScreenTable}
      cpaRewardTariffs={rewardTariffs}
      columns={columns}
      routeToCustomPage={routeToCustomPage}
    />
  );
};
