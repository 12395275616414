import { UserPrivilege } from 'generatedUserPrivilege';
import { useCallback } from 'react';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useExportClickIdReportHandlers } from '../../../helpers/useExportClickIdReportHandlers';
import { useGetExportReferralLinkMediaItemClickIdReportLazyQuery } from '../queries/generated/GetExportReferralLinkMediaItemClickIdReport';

interface Params {
  referralLinkId: string;
}

export const useGetExportReferralLinkMediaItemClickIdReport = ({
  referralLinkId,
}: Params) => {
  const period = useGetPeriodOfStatistic();
  const {
    handleCompleteExportClickIdReport,
    handleErrorExportClickIdReport,
    downloadClickIdReportFileLoading,
  } = useExportClickIdReportHandlers();

  const [exportClickIdReport, { loading: getExportClickIdReportLinkLoading }] =
    useGetExportReferralLinkMediaItemClickIdReportLazyQuery({
      onCompleted: (data) => {
        handleCompleteExportClickIdReport(data.mediaItem.exportClickIdReport);
      },
      onError: handleErrorExportClickIdReport,
    });

  const getClickIdReport = useCallback(() => {
    exportClickIdReport({
      variables: {
        id: referralLinkId,
        period,
      },
    });
  }, [exportClickIdReport, period, referralLinkId]);

  return {
    getReport: getClickIdReport,
    loading:
      getExportClickIdReportLinkLoading || downloadClickIdReportFileLoading,
    privilege: UserPrivilege.ExportReferralLinkMediaItemClickIdReport,
  };
};
