import { MutableRefObject } from 'react';
import styles from '../Popup.module.scss';

export const closeModalWithTimeout = (
  elementRef: MutableRefObject<HTMLDivElement | null>,
  closeFunction: (isOpened: boolean) => void,
  isOpened: boolean,
) => {
  if (elementRef.current) {
    elementRef.current.classList.add(styles.inActive);
  }

  setTimeout(() => {
    closeFunction(isOpened);
  }, 300);
};
