import FormRowsWrapper from 'ui/FormRowsWrapper';
import Input from 'ui/Input';
import { INVALID_INTEGER_CHARS } from 'src/utils/blockInvalidChars';
import { CountriesDropdown } from 'src/components/CountriesDropdown';

export const CreateCpaForm = () => (
  <>
    <FormRowsWrapper>
      <Input
        name="title"
        label="Название"
        maxLength={100}
        placeholder="Введите название тарифа"
      />
    </FormRowsWrapper>
    <FormRowsWrapper>
      <CountriesDropdown
        placeholder="Укажите геолокацию"
        name="countries"
        label="Геолокация"
      />
    </FormRowsWrapper>
    <FormRowsWrapper>
      <Input.Number
        invalidChars={INVALID_INTEGER_CHARS}
        placeholder="Укажите Baseline"
        name="baseline"
        label="Baseline"
      />
    </FormRowsWrapper>
    <FormRowsWrapper>
      <Input.Number
        invalidChars={INVALID_INTEGER_CHARS}
        placeholder="Укажите вознаграждение"
        name="reward"
        label="Вознаграждение"
      />
    </FormRowsWrapper>
  </>
);
