import { FormikErrors } from 'formik';

export interface DeclinePlayerAccountValues {
  confirmation: string;
}

export interface DeclinePlayerAccountErrors {
  confirmation: string;
}
export const validate = (
  values: DeclinePlayerAccountValues,
): FormikErrors<DeclinePlayerAccountErrors> => {
  const errors: FormikErrors<DeclinePlayerAccountErrors> = {};

  if (values.confirmation.toLowerCase() !== 'сбросить') {
    errors.confirmation = 'Необходимо ввести подтверждение';
  }

  return errors;
};
