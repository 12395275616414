import { FormikErrors } from 'formik';
import { EditTariffValues } from './types';

export const validate = (
  values: EditTariffValues,
): FormikErrors<EditTariffValues> => {
  const errors: FormikErrors<EditTariffValues> = {};

  if (!values.percent && values.percent !== 0) {
    errors.percent = 'Необходимо ввести процент';
  }

  if (values.percent && values.percent > 99) {
    errors.percent = 'Не может быть больше 99';
  }

  return errors;
};
