export enum Route {
  ADMINS = '/admins',
  COMPANIES = '/companies',
  COMPANY = '/companies/:id',
  COMPANY_AFFILIATES = '/companies/:id/affiliates',
  COMPANY_AFFILIATE_APPLICATIONS = '/company-affiliate-applications',
  COMPANY_APPLICATIONS = '/company-applications',
  COMPANY_MEDIA_CAMPAIGNS = '/companies/:id/media-campaigns',
  COMPANY_MEDIA_ITEMS = '/companies/:id/media-items',
  COMPANY_OUTGOING_MONEY_TRANSFERS = '/companies/:id/outgoing-money-transfers',
  COMPANY_REWARD_TARIFFS = '/companies/:id/reward-tariffs',
  QUALIFIED_REFERRAL_CONFIRMATION_SETTINGS = '/qualified-referral-confirmation-settings',
  LOGIN = '/login',
  MEDIA_CAMPAIGNS = '/media-campaigns',
  PROMO_CODE_MEDIA_ITEMS = '/media-items/promo-code',
  REFERRAL_LINK_MEDIA_ITEMS = '/media-items/referral-link',
  PARTNER = '/partners/:id',
  PARTNERS = '/partners',
  PARTNER_APPLICATIONS = '/partner-applications',
  PARTNER_MEDIA_CAMPAIGNS = '/partners/:id/media-campaigns',
  PARTNER_MEDIA_ITEMS = '/partners/:id/media-items',
  PARTNER_OUTGOING_MONEY_TRANSFERS = '/partners/:id/outgoing-money-transfers',
  PARTNER_REWARD_TARIFFS = '/partners/:id/reward-tariffs',
  PASSWORD = '/password/:action',
  PLAYER = '/players/:id',
  PLAYERS = '/players',
  PLAYER_ACCOUNTS_HISTORY = '/players/:id/accounts-history',
  PLAYER_BONUSES = '/players/:id/bonuses',
  PLAYER_DEPOSITS = '/players/:id/deposits',
  PLAYER_DOCUMENTS = '/players/:id/documents',
  PLAYER_FREESPINS = '/players/:id/freespins',
  PLAYER_MESSAGES = '/players/:id/messages',
  PLAYER_EXTERNAL_MONEY_TRANSFERS = '/players/:id/external-money-transfers',
  PLAYER_RESTRICTIONS = '/players/:id/restrictions',
  PLAYER_SIGN_IN_RESTRICTION_HISTORY = '/players/:id/sign-in-restriction-history',
  PLAYER_TOURNAMENT_REBUY = '/players/:id/tournament-rebuys',
  PLAYER_TRANSFERS = '/players/:id/transfers',
  PLAYER_WITHDRAWALS = '/players/:id/withdrawals',
  QUALIFIED_REFERRALS = '/qualified-referrals',
  REWARDS = '/rewards',
  REWARD_TARIFFS = '/reward-tariffs',
  STATISTIC = '/statistic',
  STATISTIC_CPA_COMPANY = '/statistic/cpa/companies/:id',
  STATISTIC_CPA_COMPANY_AFFILIATE = '/statistic/cpa/companies/:companyId/affiliates/:affiliateId',
  STATISTIC_CPA_COMPANY_PARTNERS = '/statistic/cpa/companies-with-partners/:id',
  STATISTIC_CPA_PARTNER = '/statistic/cpa/partners/:id',
  STATISTIC_CPA_REFERRAL_LINK = '/statistic/cpa/referral-links/:id',
  STATISTIC_REV_SHARE_COMPANY = '/statistic/rev-share/companies/:id',
  STATISTIC_REV_SHARE_COMPANY_AFFILIATE = '/statistic/rev-share/companies/:companyId/affiliates/:affiliateId',
  STATISTIC_REV_SHARE_COMPANY_PARTNERS = '/statistic/rev-share/companies-with-partners/:id',
  STATISTIC_REV_SHARE_PARTNER = '/statistic/rev-share/partners/:id',
  STATISTIC_REV_SHARE_REFERRAL_LINK = '/statistic/rev-share/referral-links/:id',
  TWO_FACTOR = '/two-factor',
  WITHDRAWAL = '/withdrawals',
}

export const privateDefaultPath = Route.PLAYERS;
export const publicDefaultPath = Route.LOGIN;
