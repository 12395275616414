import { FC, ReactNode } from 'react';
import FormWarning from 'ui/FormWarning/FormWarning';
import { getLimitWarningText } from './helpers';

interface Props {
  comparisonResult?: string | ReactNode;
  isCryptoPaymentMethod?: boolean;
}

export const FormWarningLimit: FC<Props> = ({
  comparisonResult,
  isCryptoPaymentMethod,
}) => {
  const { title, message } = getLimitWarningText(
    comparisonResult,
    isCryptoPaymentMethod,
  );

  return (
    <FormWarning title={comparisonResult} subTitle={title}>
      {message}
    </FormWarning>
  );
};
