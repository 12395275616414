import { useCallback, useContext } from 'react';
import Block from 'ui/Block';
import { BlockContext } from 'ui/Block/store';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import Privilege from 'src/components/Privilege';
import { UserPrivilege } from 'generatedUserPrivilege';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import BlockTable from 'ui/Block/BlockTable';
import { ColumnDef } from '@tanstack/react-table';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import styles from './MessagesTable.module.scss';
import { useGetMessagesByTableSize } from '../../hooks/useGetMessagesByTableSize';
import { useCreateMessage } from '../../hooks/useCreateMessage';
import { useMessageOpenModal } from '../../hooks/useMessageOpenModal';

interface Props<T extends object> {
  playerId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

const countPerPage = 10;

const MessagesTable = <T extends { __typename: string }>({
  playerId,
  columns,
  isFullScreenTable,
  shouldReturnToPrevPage,
  routeToCustomPage,
}: Props<T>) => {
  const { openModal } = useMessageOpenModal<T>(isFullScreenTable);
  const { state } = useContext(BlockContext);

  const { loadMessages, data, loading, error, fetchMore, refetch } =
    useGetMessagesByTableSize(playerId, isFullScreenTable);

  const messages = data?.player.messages;
  const messagesList =
    messages && (messages.edges.map(({ node }) => node) as unknown as Array<T>);
  const hasNextPage = messages?.pageInfo.hasNextPage;

  const { onCreateMessage } = useCreateMessage<T>(
    playerId,
    isFullScreenTable,
    messagesList,
  );

  useBlockComponentState({
    loadData: loadMessages,
    loading,
    error,
  });

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            playerId,
            first: countPerPage,
            after: messages?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages?.pageInfo.endCursor, playerId],
  );

  useBlockTableComponentState({
    data,
    loading,
    hasNextPage,
    isFullScreenTable,
    fetchMoreCallback,
    refetch,
  });

  const refetchMessages = () => {
    refetch?.();
  };

  return (
    <Block
      title="Сообщения"
      id="playerMessages"
      headerContent={
        <div className={styles.headerContent}>
          <Privilege privileges={[UserPrivilege.CreateMessage]}>
            <ActionButton
              actionType={ButtonType.Add}
              onClick={onCreateMessage}
            />
          </Privilege>
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={refetchMessages}
          />
        </div>
      }
      shrinkLoaderWrapper={false}
      emptyText="Игроку не отправлялись сообщения"
      isEmpty={isListEmpty(messagesList)}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(messagesList) && (
        <BlockTable
          isFullScreenTable={isFullScreenTable}
          data={messagesList}
          columns={columns}
          onClickOnRow={openModal}
          routeToCustomPage={routeToCustomPage}
        />
      )}
    </Block>
  );
};

export default MessagesTable;
