import { Dispatch, FC, SetStateAction } from 'react';
import classnames from 'classnames';
import { useField } from 'formik';
import { useGetHandlers } from './hooks/useGetHandlers';
import { Option } from '../../types';
import styles from './DropdownOptions.module.scss';

interface Props {
  options: Array<Option> | null;
  isCheckbox?: boolean;
  name: string;
  onChange?: (value: string | Array<string>) => void;
  isValidationOnSubmitCalled: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  checkedOptionsIds: Array<string>;
  setCheckedOptionsIds: Dispatch<SetStateAction<Array<string>>>;
}

export const DropdownOptions: FC<Props> = ({
  options,
  isCheckbox,
  name,
  onChange,
  isValidationOnSubmitCalled,
  setOpened,
  checkedOptionsIds,
  setCheckedOptionsIds,
}) => {
  const [field] = useField(name);
  const { value } = field;

  const { onCheckBoxClick, onOptionSelect } = useGetHandlers({
    onChange,
    setOpened,
    checkedOptionsIds,
    setCheckedOptionsIds,
    isCheckbox,
    name,
    isValidationOnSubmitCalled,
  });

  return options?.map((option: Option) => (
    <div
      className={classnames(
        styles.option,
        !isCheckbox && value === option.id && styles.selectedOption,
        isCheckbox && styles.checkboxOption,
      )}
      key={option.id}
      role={isCheckbox ? 'menuitemcheckbox' : 'option'}
      onClick={() =>
        isCheckbox ? onCheckBoxClick(option.id) : onOptionSelect(option.id)
      }
    >
      {option.label}
      {option.labelRight && (
        <span className={styles.labelRight}>{option.labelRight}</span>
      )}
    </div>
  ));
};
