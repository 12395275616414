import { FormikErrors } from 'formik';
import { RewardTariffType } from 'commonTypes';
import { CreateMediaItemReferralLinkValues } from '../../types';
import { AffiliateType } from '../../const';

export const validate = (
  values: CreateMediaItemReferralLinkValues,
): FormikErrors<CreateMediaItemReferralLinkValues> => {
  const errors: FormikErrors<CreateMediaItemReferralLinkValues> = {};

  if (!values.name) {
    errors.name = 'Введите название медиа элемента';
  }

  if (values.name && values.name.length > 50) {
    errors.name = 'Название медиа элемента не может быть длиннее 50 символов';
  }

  if (!values.code) {
    errors.code = 'Необходимо ввести реферальный код';
  }

  if (!values.typeRewardTariff) {
    errors.typeRewardTariff = 'Выберите модель оплаты';
  }

  if (
    values.typeRewardTariff &&
    values.typeRewardTariff === RewardTariffType.Cpa &&
    !values.cpaRewardTariffId
  ) {
    errors.cpaRewardTariffId = 'Выберите партнёрский тариф';
  }

  if (
    values.typeRewardTariff &&
    values.typeRewardTariff === RewardTariffType.RevShare &&
    !values.revShareRewardTariffId
  ) {
    errors.revShareRewardTariffId = 'Выберите партнёрский тариф';
  }

  if (
    values.typeRewardTariff === RewardTariffType.Cpa &&
    !values.fallbackTariffId
  ) {
    errors.fallbackTariffId = 'Выберите резервный тариф';
  }

  if (values.affiliateType === AffiliateType.Partner && !values.partnerId) {
    errors.partnerId = 'Выберите партнера';
  }

  if (values.affiliateType === AffiliateType.Company && !values.companyId) {
    errors.companyId = 'Выберите компанию';
  }

  if (!values.mediaCampaignId) {
    errors.mediaCampaignId = 'Выберите медиа кампанию';
  }

  return errors;
};
