import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { ProgressBar } from 'ui/ProgressBar';
import { CopyModalRow } from 'ui/CopyModalRow';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { promoCodeUsageTypeMap } from 'src/const';
import Button, { ButtonTheme } from 'ui/Button';
import { CompanyAffiliatePromoCodeBasicData } from '../AffiliatePromoCodesList/queries/generated/CompanyAffiliatePromoCodeBasicData';

interface Props {
  promoCode: CompanyAffiliatePromoCodeBasicData;
  onDetailsClick: (currentPromoCodeId: string) => void;
}

export const AffiliatePromoCodeItem: FC<Props> = ({
  promoCode,
  onDetailsClick,
}) => {
  const {
    id: promoCodeId,
    promoCode: { token, activation },
    mediaCampaign: { name, url },
    referralLink,
    createdAt,
    usageType,
  } = promoCode;

  const activated = activation?.activated;
  const limit = activation?.limit;

  const hasActivations = activated !== undefined && limit !== undefined;

  const rows = [
    {
      title: 'Медиа кампания',
      value: name,
    },
    {
      title: 'URL медиа кампании',
      value: url,
    },
    {
      title: 'Использование',
      value: promoCodeUsageTypeMap[usageType],
    },
    {
      title: 'Реферальная ссылка',
      value: referralLink && (
        <CopyModalRow
          value={referralLink?.referralLink}
          toastText="Реферальная ссылка скопирована"
        />
      ),
    },
    {
      title: 'Создан',
      value: formatDate(createdAt, DateFormat.DefaultDateOld),
    },
  ];

  return (
    <DataCard
      header={{ leftTitle: token, leftSubTitle: `#${promoCodeId}` }}
      customSubHeader={
        hasActivations && (
          <ProgressBar
            percent={(activated / limit) * 100}
            startTitle={`Активировано: ${activated}`}
            endTitle={`${limit}`}
          />
        )
      }
      rows={rows}
      withBorder
      customFooter={
        <Button
          theme={ButtonTheme.Process}
          onClick={() => {
            onDetailsClick(promoCodeId);
          }}
        >
          Подробнее
        </Button>
      }
    />
  );
};
