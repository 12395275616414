import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCompanyAccessToRevShareRewardTariffsVariables = SchemaTypes.Exact<{
  companyId: SchemaTypes.Scalars['ID']['input'];
}>;


export type GetCompanyAccessToRevShareRewardTariffs = { __typename: 'Query', company: { __typename: 'Company', id: string, accessToRevShareRewardTariffs: Array<{ __typename: 'AccessToRevShareRewardTariff', accessible: boolean, id: string, name: string }> } };


export const GetCompanyAccessToRevShareRewardTariffsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanyAccessToRevShareRewardTariffs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"company"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessToRevShareRewardTariffs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessible"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetCompanyAccessToRevShareRewardTariffs__
 *
 * To run a query within a React component, call `useGetCompanyAccessToRevShareRewardTariffs` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAccessToRevShareRewardTariffs` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAccessToRevShareRewardTariffs({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyAccessToRevShareRewardTariffs(baseOptions: ApolloReactHooks.QueryHookOptions<GetCompanyAccessToRevShareRewardTariffs, GetCompanyAccessToRevShareRewardTariffsVariables> & ({ variables: GetCompanyAccessToRevShareRewardTariffsVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCompanyAccessToRevShareRewardTariffs, GetCompanyAccessToRevShareRewardTariffsVariables>(GetCompanyAccessToRevShareRewardTariffsDocument, options);
      }
export function useGetCompanyAccessToRevShareRewardTariffsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyAccessToRevShareRewardTariffs, GetCompanyAccessToRevShareRewardTariffsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCompanyAccessToRevShareRewardTariffs, GetCompanyAccessToRevShareRewardTariffsVariables>(GetCompanyAccessToRevShareRewardTariffsDocument, options);
        }
export function useGetCompanyAccessToRevShareRewardTariffsSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetCompanyAccessToRevShareRewardTariffs, GetCompanyAccessToRevShareRewardTariffsVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetCompanyAccessToRevShareRewardTariffs, GetCompanyAccessToRevShareRewardTariffsVariables>(GetCompanyAccessToRevShareRewardTariffsDocument, options);
        }
export type GetCompanyAccessToRevShareRewardTariffsHookResult = ReturnType<typeof useGetCompanyAccessToRevShareRewardTariffs>;
export type GetCompanyAccessToRevShareRewardTariffsLazyQueryHookResult = ReturnType<typeof useGetCompanyAccessToRevShareRewardTariffsLazyQuery>;
export type GetCompanyAccessToRevShareRewardTariffsSuspenseQueryHookResult = ReturnType<typeof useGetCompanyAccessToRevShareRewardTariffsSuspenseQuery>;
export type GetCompanyAccessToRevShareRewardTariffsQueryResult = Apollo.QueryResult<GetCompanyAccessToRevShareRewardTariffs, GetCompanyAccessToRevShareRewardTariffsVariables>;