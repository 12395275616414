import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { ErrorMessage } from 'formik';
import { Tooltip } from 'ui/Tooltip';
import { Placement } from '@floating-ui/react';
import { TooltipTheme } from 'ui/Tooltip/types';
import { useValidation } from 'ui/FormFieldWrapper/hooks/useValidation';
import styles from './FormFieldWrapper.module.scss';

const TOOLTIP_ARROW_DEFAULT_OFFSET = '10px';

export interface FormFieldWrapperProps {
  name: string;
  idForLabel?: string;
  wrapperClassName?: string;
  label?: string;
  errorTooltipPlacement?: Placement;
  errorTooltipArrowOffset?: string | number | null;
  horizontalOffset?: number;
}

export const FormFieldWrapper: FC<
  PropsWithChildren<FormFieldWrapperProps & HTMLAttributes<HTMLElement>>
> = ({
  wrapperClassName,
  label,
  name,
  idForLabel,
  children,
  errorTooltipPlacement,
  errorTooltipArrowOffset = TOOLTIP_ARROW_DEFAULT_OFFSET,
  horizontalOffset,
}) => {
  const { isTooltipVisible, handleBlur, handleFocus, handlerChange } =
    useValidation(name);

  return (
    <div
      className={classnames(styles.formFieldWrapper, wrapperClassName)}
      onFocus={handleFocus}
      onChange={handlerChange}
      onBlur={handleBlur}
    >
      {label && (
        <label htmlFor={idForLabel} className={styles.label}>
          {label}
        </label>
      )}
      <Tooltip
        placement={errorTooltipPlacement}
        isOpen={isTooltipVisible}
        tooltipContent={<ErrorMessage name={name} />}
        tooltipTheme={TooltipTheme.Light}
        arrowOffset={errorTooltipArrowOffset}
        shouldSetWidthFitContent={false}
        horizontalOffset={horizontalOffset}
        tabIndex={-1}
      >
        {children}
      </Tooltip>
    </div>
  );
};
