import { nbsp } from 'src/const';

export enum CompanyApplicationModalSteps {
  BaseStep = 1,
  CreateReferralLink,
  UpdateReferralLink,
  DeclineStep,
}

export const COMPANY_APPLICATIONS_SEARCH_PLACEHOLDER = `Найти заявку по${nbsp}её ID или по${nbsp}почте компании`;

export const COMPANY_APPLICATIONS_SEARCH_INPUT_NAME =
  'companyApplicationsSearch';
