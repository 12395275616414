import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AffiliateInfo, AffiliatesValues } from '../types';

interface UseAddNewAffiliateItemResult {
  addNewAffiliateItem: (
    values: AffiliatesValues,
    setFieldValue: (field: string, value: Array<AffiliateInfo>) => void,
  ) => void;
}

export const useAddNewAffiliateItem = (): UseAddNewAffiliateItemResult => {
  const partnerIndexRef = useRef(1);

  const addNewAffiliateItem = (
    values: AffiliatesValues,
    setFieldValue: (field: string, value: Array<AffiliateInfo>) => void,
  ) => {
    partnerIndexRef.current += 1;

    const newAffiliates = [
      ...values.affiliates,
      { id: uuidv4(), name: `Partner#${partnerIndexRef.current}` },
    ];

    setFieldValue('affiliates', newAffiliates);
  };

  return {
    addNewAffiliateItem,
  };
};
