import { MediaItemRewardTariffTypeName } from 'commonTypes';
import { AffiliateReferralLinkMediaItemRewardTariffData } from 'queries/generated/AffiliateReferralLinkMediaItemRewardTariffData';

export const getMediaItemCpaRewardTariffData = (
  mediaItemRewardTariff: AffiliateReferralLinkMediaItemRewardTariffData['mediaItemRewardTariff'],
) => {
  const isMediaItemCpaRewardTariff =
    mediaItemRewardTariff.__typename === MediaItemRewardTariffTypeName.Cpa;

  const mediaItemCpaRewardTariff = isMediaItemCpaRewardTariff
    ? mediaItemRewardTariff
    : undefined;
  const cpaRewardTariff = mediaItemCpaRewardTariff?.rewardTariff;

  const fallbackTariff = mediaItemCpaRewardTariff?.fallbackRewardTariff;
  const geolocation = cpaRewardTariff?.countries;
  const baselineSum = cpaRewardTariff?.baselineSum;
  const rewardSum = cpaRewardTariff?.rewardSum;

  return {
    fallbackTariff,
    geolocation,
    baselineSum,
    rewardSum,
  };
};
