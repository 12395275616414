import { FC, useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { FormFieldWrapper, FormFieldWrapperProps } from 'ui/FormFieldWrapper';
import ClickOutside, { useClickOutside } from 'ui/ClickOutside';
import Loader from 'ui/Loader';
import { LoaderTheme } from 'ui/Loader/Loader';
import { ApolloError } from '@apollo/client';
import { DropdownToggle } from './components/DropdownToggle';
import { DropdownList } from './components/DropdownList';
import { Option } from './types';
import styles from './Dropdown.module.scss';

// TODO: provide override functions with props
export interface DropdownProps extends FormFieldWrapperProps {
  className?: string;
  options?: Array<Option> | null;
  onChange?: (value: string | Array<string>) => void;
  disabled?: boolean;
  defaultValue?: string;
  placeholder?: string;
  isCheckbox?: boolean;
  loading?: boolean;
  loadingError?: ApolloError;
  onSearchInList?: (value: string) => void;
  fetchMoreConfig?: {
    fetchMore?: () => void;
    hasNextPage?: boolean;
  };
  searchMinLength?: number;
  emptyListText?: string;
}

export const Dropdown: FC<DropdownProps> = ({
  className,
  wrapperClassName,
  options = [],
  onChange,
  disabled,
  defaultValue,
  label,
  name,
  isCheckbox,
  loading,
  loadingError,
  onSearchInList,
  searchMinLength,
  emptyListText = 'Ничего не найдено',
  fetchMoreConfig,
  placeholder = 'Выберите',
}) => {
  const { isSubmitting } = useFormikContext();
  const [field, meta] = useField(name);

  const { value } = field;
  const { touched, error } = meta;

  const [opened, setOpened] = useState(false);
  const [checkedOptionsIds, setCheckedOptionsIds] = useState(value || []);

  const [isValidationOnSubmitCalled, setValidationOnSubmitCalled] =
    useState(false);

  useEffect(() => {
    if (isSubmitting) {
      setValidationOnSubmitCalled(true);
    }
  }, [isSubmitting]);

  const onClickOutside = useClickOutside(setOpened);

  const onInputClick = () => {
    if (!disabled) {
      setOpened(!opened);
    }
  };

  return (
    <FormFieldWrapper
      name={name}
      label={label}
      wrapperClassName={wrapperClassName}
    >
      <ClickOutside
        callback={onClickOutside}
        className={styles.dropdownWrapper}
      >
        {loading && !opened ? (
          <Loader theme={LoaderTheme.Dark} data-testid="dropdownLoader" />
        ) : (
          <DropdownToggle
            checkedOptionsIds={checkedOptionsIds}
            placeholder={placeholder}
            name={name}
            onClick={onInputClick}
            touched={touched}
            className={className}
            isCheckbox={isCheckbox}
            disabled={disabled}
            opened={opened}
            value={value}
            error={error}
            loadingError={loadingError}
            defaultValue={defaultValue}
            options={options}
          />
        )}
        {opened && (
          <DropdownList
            onSearchInList={onSearchInList}
            isCheckbox={isCheckbox}
            fetchMoreConfig={fetchMoreConfig}
            loading={loading}
            emptyListText={emptyListText}
            searchMinLength={searchMinLength}
            isValidationOnSubmitCalled={isValidationOnSubmitCalled}
            name={name}
            onChange={onChange}
            setOpened={setOpened}
            checkedOptionsIds={checkedOptionsIds}
            setCheckedOptionsIds={setCheckedOptionsIds}
            options={options}
          />
        )}
      </ClickOutside>
    </FormFieldWrapper>
  );
};
