import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { useLocation } from 'react-router';
import { useSignInRestrictionModal } from '../../hooks/useSignInRestrictionModal';
import { SignInRestrictionHistoryData } from '../../queries/generated/SignInRestrictionHistoryData';
import { SignInRestrictionHistoryBlockData } from '../../queries/generated/SignInRestrictionHistoryBlockData';
import { useGetSignInRestrictionsHistoryColumns } from './hooks/useGetSignInRestrictionsHistoryColumns';

interface Props {
  signInRestrictions?: Array<
    SignInRestrictionHistoryData | SignInRestrictionHistoryBlockData
  >;
  isFullScreenTable: boolean;
}

export const SignInRestrictionHistoryTable: FC<Props> = ({
  signInRestrictions,
  isFullScreenTable,
}) => {
  const location = useLocation();

  const openSignInRestrictionModal = useSignInRestrictionModal();
  const { columns } = useGetSignInRestrictionsHistoryColumns(isFullScreenTable);

  const handleRowClick = (
    signInRestriction:
      | SignInRestrictionHistoryData
      | SignInRestrictionHistoryBlockData,
    initiatorId: string,
  ) => {
    openSignInRestrictionModal({
      signInRestriction,
      initiatorId,
    });
  };

  return (
    <BlockTable
      onClickOnRow={handleRowClick}
      columns={columns}
      data={signInRestrictions}
      routeToCustomPage={
        isFullScreenTable
          ? undefined
          : `${location.pathname}/sign-in-restriction-history`
      }
      isFullScreenTable={isFullScreenTable}
    />
  );
};
