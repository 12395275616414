import { Dispatch, FC, SetStateAction } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';
import classnames from 'classnames';
import { DateFormat, formatDate } from 'utils/formatDate';
import capitalize from 'utils/capitalize';
import { useHeaderData } from './hooks/useHeaderData';
import styles from './Header.module.scss';
import { DatePickerStates } from '../../types';

interface Props {
  setSelectsRange: Dispatch<SetStateAction<boolean>>;
  datePickerStates: DatePickerStates;
  setDatePickerStates: Dispatch<SetStateAction<DatePickerStates>>;
  datePickerCustomHeaderProps: ReactDatePickerCustomHeaderProps;
}

export const Header: FC<Props> = ({
  setSelectsRange,
  datePickerStates,
  setDatePickerStates,
  datePickerCustomHeaderProps,
}) => {
  const { date } = datePickerCustomHeaderProps;

  const {
    isRenderMonth,
    handlePrevClick,
    handleNextClick,
    isDisableNextButton,
    isDisablePrevButton,
    handleMonthClick,
    handleYearClick,
  } = useHeaderData({
    datePickerStates,
    setDatePickerStates,
    setSelectsRange,
    datePickerCustomHeaderProps,
  });

  if (datePickerStates.showYearPicker) {
    return null;
  }

  return (
    <div className={styles.header}>
      <button
        type="button"
        className={styles.button}
        onClick={handlePrevClick}
        disabled={isDisablePrevButton}
      >
        <FontAwesomeIcon icon={faChevronLeft} className={styles.icon} />
      </button>

      <div className={styles.dateContainer}>
        {isRenderMonth && (
          <div onClick={handleMonthClick} className={styles.month}>
            {capitalize(formatDate(date, DateFormat.Month, false))}
          </div>
        )}
        <div onClick={handleYearClick} className={classnames(styles.year)}>
          {formatDate(date, DateFormat.Year, false)}
        </div>
      </div>

      <button
        type="button"
        className={styles.button}
        onClick={handleNextClick}
        disabled={isDisableNextButton}
      >
        <FontAwesomeIcon icon={faChevronRight} className={styles.icon} />
      </button>
    </div>
  );
};
