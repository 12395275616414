import { useAuth } from 'commonComponents/Auth';
import errorToast from 'ui/Toast/ErrorToast';
import { ApolloError } from '@apollo/client';
import { nbsp } from 'src/const';
import { useState } from 'react';

export const useExportClickIdReportHandlers = () => {
  const auth = useAuth();
  const [
    downloadClickIdReportFileLoading,
    setDownloadClickIdReportFileLoading,
  ] = useState(false);

  const handleCompleteExportClickIdReport = (url: string) => {
    const { accessToken } = auth;

    setDownloadClickIdReportFileLoading(true);

    fetch(url, {
      headers: {
        Authorization: accessToken.current.token
          ? `Bearer ${accessToken.current.token}`
          : '',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = blobUrl;
        link.setAttribute('download', 'click-id-report.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);

        return null;
      })
      .finally(() => {
        setDownloadClickIdReportFileLoading(false);
      })
      .catch(() => {
        errorToast({
          header: 'Произошла ошибка загрузки',
          text: `Не удалось скачать отчет действий`,
          toastId: 'get-export-click-id-report-error',
        });
      });
  };

  const handleErrorExportClickIdReport = (error: ApolloError) => {
    errorToast({
      header: `Не удалось получить ссылку на${nbsp}скачивание отчета действий`,
      text: error.message,
      toastId: 'get-export-click-id-report-link-error',
    });
  };

  return {
    handleCompleteExportClickIdReport,
    handleErrorExportClickIdReport,
    downloadClickIdReportFileLoading,
  };
};
