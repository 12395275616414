import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { useCallback } from 'react';
import { useGetPartnerCpaRewardTariffsBlockLazyQuery } from '../queries/generated/GetPartnerCpaRewardTariffsBlock';
import { useGetPartnerCpaRewardTariffsLazyQuery } from '../queries/generated/GetPartnerCpaRewardTariffs';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

interface Params {
  partnerId: string;
  isFullScreenTable: boolean;
}

export const useGetCpaRewardTariffsByTableSize = ({
  partnerId,
  isFullScreenTable,
}: Params) => {
  const countPerPageByTableSize = isFullScreenTable
    ? getCountByWindowHeight(cellHeight)
    : COUNT_PER_PAGE;

  const currentUseGetPartnerCpaRewardTariffs = isFullScreenTable
    ? useGetPartnerCpaRewardTariffsLazyQuery
    : useGetPartnerCpaRewardTariffsBlockLazyQuery;

  const [
    loadPartnerCpaRewardTariffs,
    { data, loading, error, fetchMore, refetch },
  ] = currentUseGetPartnerCpaRewardTariffs({
    variables: {
      partnerId,
      first: countPerPageByTableSize,
    },
  });

  const cpaRewardTariffsData = data?.partner.cpaRewardTariffs;
  const endCursor = cpaRewardTariffsData?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [endCursor, fetchMore],
  );

  return {
    loadPartnerCpaRewardTariffs,
    fetchMoreCallback,
    cpaRewardTariffsData,
    loading,
    error,
    fetchMore,
    refetch,
  };
};
