import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

export const useGetTableColumns = <T, K>(
  columnsIds: Array<T>,
  columnsSource: Array<ColumnDef<K>>,
) =>
  useMemo(() => {
    const columns: Array<ColumnDef<K>> = [];

    columnsIds.forEach((columnId) => {
      const column = columnsSource.find(
        (columnSource) => columnSource.id === columnId,
      );

      if (column) {
        columns.push(column);
      }
    });

    return columns;
  }, [columnsIds, columnsSource]);
