import { useCallback, useMemo } from 'react';
import { useGetExternalMoneyTransfersByTableSize } from './useGetExternalMoneyTransfersByTableSize';

interface UseGetExternalMoneyTransfersParams {
  id: string;
  isFullScreenTable: boolean;
}

const COUNT_PER_PAGE = 10;

export const useGetExternalMoneyTransfers = <T>({
  id,
  isFullScreenTable,
}: UseGetExternalMoneyTransfersParams) => {
  const {
    loadExternalMoneyTransfers,
    data,
    loading,
    error,
    fetchMore,
    refetch,
  } = useGetExternalMoneyTransfersByTableSize(id, isFullScreenTable);

  const externalMoneyTransfers = data?.player.externalMoneyTransfers;
  const hasNextPage = externalMoneyTransfers?.pageInfo.hasNextPage;
  const endCursor = externalMoneyTransfers?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            id,
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [id, endCursor, fetchMore],
  );

  const externalMoneyTransfersList = useMemo(
    () =>
      data?.player.externalMoneyTransfers.edges.map(
        ({ node }) => node,
      ) as unknown as Array<T>,
    [data],
  );

  return {
    loadExternalMoneyTransfers,
    externalMoneyTransfersList,
    loading,
    error,
    refetch,
    fetchMoreCallback,
    hasNextPage,
    data,
  };
};
