import { useSearchParams } from 'react-router-dom';
import { STATISTIC_CURRENCY } from 'src/const';
import { logError } from 'src/utils/logError';

export const useHandleChange = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (newCurrency: string | Array<string>) => {
    if (Array.isArray(newCurrency)) {
      logError('An unexpected array of strings was provided.');

      return;
    }

    const newParams = new URLSearchParams(searchParams);

    newParams.set(STATISTIC_CURRENCY, newCurrency || '');

    setSearchParams(newParams);
  };

  return handleChange;
};
