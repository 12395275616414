import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useAuth } from 'commonComponents/Auth';
import { useGetWithdrawalsListLazyQuery } from '../queries/generated/GetWithdrawalsList';
import { WithdrawalListData } from '../queries/generated/WithdrawalListData';

const cellHeight = Heights.defaultCellHeight;
const COUNT_PER_PAGE = 10;

export const useGetWithdrawals = () => {
  const auth = useAuth();

  const [loadWithdrawals, { data, loading, error, fetchMore, refetch }] =
    useGetWithdrawalsListLazyQuery({
      variables: {
        first: getCountByWindowHeight(cellHeight, 0),
        viewPaymentMethodAccount: auth.privileges.areEveryGranted({
          privileges: [UserPrivilege.ViewPaymentmethodAccount],
        }),
      },
    });

  const withdrawals: Array<WithdrawalListData> | undefined = useMemo(
    () => data?.withdrawals?.edges.map(({ node }) => node),
    [data?.withdrawals?.edges],
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: data?.withdrawals?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [data?.withdrawals?.pageInfo.endCursor, fetchMore],
  );

  return {
    withdrawals,
    loadWithdrawals,
    data,
    loading,
    fetchMoreCallback,
    error,
    refetch,
  };
};
