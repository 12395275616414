import { UserPrivilege } from 'generatedUserPrivilege';
import { useCallback } from 'react';
import { Currency } from 'commonTypes';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetExportPartnerCpaReportLazyQuery } from '../queries/generated/GetExportPartnerCpaReport';
import { useExportReportHandlers } from '../../../helpers/useHandleCompleteExportReport';

interface UseGetExportPartnerCpaReportParams {
  partnerId: string;
  isAllTimeCpa: boolean;
}

export const useGetExportPartnerCpaReport = ({
  partnerId,
  isAllTimeCpa,
}: UseGetExportPartnerCpaReportParams) => {
  const period = useGetPeriodOfStatistic();
  const {
    handleCompleteExportReport,
    handleErrorExportReport,
    downloadReportFileLoading,
  } = useExportReportHandlers();

  const [exportReport, { loading: getExportReportLinkLoading }] =
    useGetExportPartnerCpaReportLazyQuery({
      onCompleted: (data) => {
        handleCompleteExportReport(data.partner.exportCpaReport);
      },
      onError: handleErrorExportReport,
    });

  // TODO: uncomment the line when the backend is ready [ALS-719]
  // const { currency } = useGetCurrency();
  const currency = Currency.Usd;

  const getReport = useCallback(() => {
    exportReport({
      variables: {
        id: partnerId,
        currency,
        period,
        showAllTimeCpa: isAllTimeCpa,
      },
    });
  }, [currency, exportReport, isAllTimeCpa, partnerId, period]);

  return {
    getReport,
    loading: getExportReportLinkLoading || downloadReportFileLoading,
    privilege: UserPrivilege.ExportPartnerCpaReport,
  };
};
