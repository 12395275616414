import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import {
  AcceptDepositInput,
  DepositStatus,
  RevertDepositToPresumablyDeclinedInput,
} from 'generatedGraphql';
import { UserPrivilege } from 'generatedUserPrivilege';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { useAuth } from 'commonComponents/Auth';
import { useAcceptDeposit } from './queries/generated/AcceptDeposit';
import { useRevertDepositToPresumablyDeclined } from './queries/generated/RevertDepositToPresumablyDeclined';

interface Value {
  error?: ApolloError;
  loading: boolean;
  onAcceptDeposit: (obj: AcceptDepositInput) => void;
  onRevertDepositToPresumablyDeclined: (
    obj: RevertDepositToPresumablyDeclinedInput,
  ) => void;
  isAcceptButton?: boolean;
  isRejectButton?: boolean;
  isReturnButton?: boolean;
  updateModalStep: (step: number) => void;
}

export enum DepositModalSteps {
  BaseStep = 1,
  ConfirmationStep,
}

export const useButtonsUpdateDeposit = (
  depositStatus?: DepositStatus,
): Value => {
  const { state, dispatch } = useContext(ModalContext);
  const [
    acceptDeposit,
    {
      data: updateAcceptDeposit,
      error: errorAcceptDeposit,
      loading: loadingAcceptDeposit,
    },
  ] = useAcceptDeposit();
  const [
    revertDepositToPresumablyDeclined,
    {
      data: updateRevertDepositToPresumablyDeclined,
      error: errorRevertDepositToPresumablyDeclined,
      loading: loadingRevertDepositToPresumablyDeclined,
    },
  ] = useRevertDepositToPresumablyDeclined();

  const onAcceptDeposit = async ({ id, amount }: AcceptDepositInput) => {
    await acceptDeposit({
      variables: { input: { id, amount } },
    });
  };

  const onRevertDepositToPresumablyDeclined = async ({
    id,
  }: RevertDepositToPresumablyDeclinedInput) => {
    await revertDepositToPresumablyDeclined({
      variables: { input: { id } },
    });
  };

  const updateResult =
    updateRevertDepositToPresumablyDeclined || updateAcceptDeposit;

  const error = errorRevertDepositToPresumablyDeclined || errorAcceptDeposit;

  const loading =
    loadingRevertDepositToPresumablyDeclined || loadingAcceptDeposit;

  const { updateModalStep } = useUpdateModalStep<DepositModalSteps>();

  useEffect(() => {
    if (!updateResult) {
      return;
    }

    if (state.step === DepositModalSteps.ConfirmationStep) {
      updateModalStep(DepositModalSteps.BaseStep);
      toast.success('Депозит изменён');
    } else if (state.step === DepositModalSteps.BaseStep) {
      dispatch({
        type: ModalActions.Close,
      });
      toast.success('Депозит изменён');
    }
  }, [updateResult, dispatch, state.step, updateModalStep]);

  const auth = useAuth();

  const isAllowedToUpdateDeposit = (privileges: Array<UserPrivilege>) =>
    auth.privileges.areEveryGranted({
      privileges,
    });

  const isAcceptButton =
    isAllowedToUpdateDeposit([UserPrivilege.AcceptDeposit]) &&
    depositStatus &&
    [
      DepositStatus.InProgress,
      DepositStatus.Pending,
      DepositStatus.PresumablyDeclined,
    ].includes(depositStatus);

  const isReturnButton =
    isAllowedToUpdateDeposit([
      UserPrivilege.RevertDepositToPresumablyDeclined,
    ]) && depositStatus === DepositStatus.Declined;

  return {
    onAcceptDeposit,
    onRevertDepositToPresumablyDeclined,
    isAcceptButton,
    isReturnButton,
    error,
    loading,
    updateModalStep,
  };
};
