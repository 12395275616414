import { FC, useContext } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { PageTypeStorage } from 'utils/storage';
import SearchInput from 'ui/SearchInput';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { useCompanyAffiliateApplicationsData } from './hooks/useCompanyAffiliateApplicationsData';
import { CompanyAffiliateApplicationsTable } from './components/CompanyAffiliateApplicationsTable';
import {
  COMPANY_AFFILIATE_APPLICATIONS_SEARCH_INPUT_NAME,
  COMPANY_AFFILIATE_APPLICATIONS_SEARCH_PLACEHOLDER,
} from './const';

const Component: FC = withBlockProvider(() => {
  const {
    refetchCompanyAffiliateApplicationsList,
    loadCompanyAffiliateApplications,
    companyAffiliateApplicationsList,
  } = useCompanyAffiliateApplicationsData();
  const { state } = useContext(BlockContext);

  return (
    <>
      <SearchInput
        name={COMPANY_AFFILIATE_APPLICATIONS_SEARCH_INPUT_NAME}
        placeholder={COMPANY_AFFILIATE_APPLICATIONS_SEARCH_PLACEHOLDER}
        onSubmit={loadCompanyAffiliateApplications}
      />
      <Block
        title="Заявки партнёров компании"
        id="сompanyAffiliateApplications"
        headerContent={
          <ActionButton
            actionType={ButtonType.Update}
            hidden={false}
            disabled={state.isLoading}
            onClick={() => refetchCompanyAffiliateApplicationsList()}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText="Заявок партнёров компаний нет"
        isEmpty={isListEmpty(companyAffiliateApplicationsList)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(companyAffiliateApplicationsList) && (
          <CompanyAffiliateApplicationsTable
            companyAffiliateApplications={companyAffiliateApplicationsList}
          />
        )}
      </Block>
    </>
  );
});

export const CompanyAffiliateApplications = () => (
  <SearchContextProvider
    pageType={PageTypeStorage.CompanyAffiliateApplicationsPage}
  >
    <Component />
  </SearchContextProvider>
);
