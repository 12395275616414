import { useSearchParams } from 'react-router-dom';

export const useClearSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const clearSearchParams = (clearableSearchParams: Array<string>) => {
    const newParams = new URLSearchParams(searchParams);

    clearableSearchParams.forEach((param) => newParams.delete(param));
    setSearchParams(newParams);
  };

  return { clearSearchParams };
};
