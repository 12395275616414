import { DatePicker, DatePickerHandleProps } from 'ui/DatePicker';
import { FormFieldWrapperProps } from 'ui/FormFieldWrapper';
import Button, { LinkButtonTheme } from 'ui/Button';
import classnames from 'classnames';
import { DateRanges } from 'commonComponents/DatePickerWithRange/const';
import { useDatePickerWithRange } from './hooks/useDatePickerWithRange';
import styles from './DatePickerWithRange.module.scss';

interface Props extends FormFieldWrapperProps {
  onDateSubmit: (date: DatePickerHandleProps) => void;
  disabled?: boolean;
  name: string;
  openDateInitial?: Date;
  minDateInitial?: Date | null | undefined;
  startDateInitial?: Date | null | undefined;
  endDateInitial?: Date | null | undefined;
  availableDateRanges: Array<DateRanges>;
}

export const DatePickerWithRange = ({
  onDateSubmit,
  label,
  disabled,
  wrapperClassName,
  endDateInitial,
  startDateInitial,
  minDateInitial,
  openDateInitial,
  availableDateRanges,
  ...rest
}: Props) => {
  const {
    customRanges,
    activeCustomRange,
    startDate,
    endDate,
    handleSubmit,
    handleChange,
    dateTitle,
  } = useDatePickerWithRange({
    minDateInitial,
    onDateSubmit,
    startDateInitial,
    endDateInitial,
  });

  return (
    <div className={classnames(styles.datePickerContainer, wrapperClassName)}>
      <div className={styles.rangesContainer}>
        {availableDateRanges.map((availableRange) => {
          const { title, onClick } = customRanges[availableRange];

          return (
            <Button
              key={availableRange}
              theme={LinkButtonTheme.Grey}
              className={classnames(
                styles.range,
                activeCustomRange === availableRange && styles.active,
              )}
              linkStyled
              onClick={onClick}
            >
              {title}
            </Button>
          );
        })}
      </div>
      <div className={styles.datePicker}>
        <div className={styles.dateTitle}>{dateTitle}</div>
        <DatePicker
          {...rest}
          openDateInitial={openDateInitial}
          isSelectsRange
          className={styles.dropdown}
          onChange={handleChange}
          startDateInitial={startDate}
          endDateInitial={endDate}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};
