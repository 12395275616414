import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext, useModalManageData } from 'ui/Modal';
import { useGetCpaRewardTariff } from '../../../../../queries/generated/GetCpaRewardTariff';
import { CpaRewardTariffsModalSteps } from '../const';

interface UseCpaRewardTariffModalParams {
  tariffId: string;
}

export const useCpaRewardTariffModal = ({
  tariffId,
}: UseCpaRewardTariffModalParams) => {
  const { state, dispatch: modalDispatch } = useContext(ModalContext);
  const { data, loading, error } = useGetCpaRewardTariff({
    variables: {
      tariffId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'cpa-reward-tariffs-error' },
    hasData: !!data,
  });

  useEffect(() => {
    if (state.step === CpaRewardTariffsModalSteps.BaseStep) {
      modalDispatch({
        type: ModalActions.UpdateModalProps,
        payload: {
          title: `#${tariffId}`,
          subTitle: 'Партнёрский тариф',
        },
      });

      return;
    }

    modalDispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        title: 'Редактировать название',
        subTitle: `Партнёрский тариф #${tariffId}`,
      },
    });
  }, [tariffId, modalDispatch, state.step]);

  const goToEditName = () => {
    modalDispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        step: CpaRewardTariffsModalSteps.EditNameStep,
      },
    });
  };

  return {
    goToEditName,
    cpaRewardTariff: data?.cpaRewardTariff,
    step: state.step,
  };
};
