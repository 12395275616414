import { ApolloError } from '@apollo/client';
import {
  AssignWithdrawalInput,
  DeclineWithdrawalInput,
  SuspendWithdrawalInput,
  WithdrawalStatus,
} from 'generatedGraphql';
import { UserPrivilege } from 'generatedUserPrivilege';
import undoActionToast from 'ui/Toast/UndoActionToast';
import { useAuth } from 'commonComponents/Auth';
import { WithdrawalData } from '../WithdrawalsTable/queries/generated/WithdrawalData';
import { useAssignWithdrawal } from './queries/generated/AssignWithdrawal';
import { useDeclineWithdrawal } from './queries/generated/DeclineWithdrawal';
import { useSuspendWithdrawal } from './queries/generated/SuspendWithdrawal';

interface Value {
  error?: ApolloError;
  loadingSuspendWithdrawal: boolean;
  loadingAssignWithdrawal: boolean;
  loadingDeclineWithdrawal: boolean;
  onAssignWithdrawal: (obj: AssignWithdrawalInput) => void;
  onDeclineWithdrawal: (obj: DeclineWithdrawalInput) => void;
  onSuspendWithdrawal: (obj: SuspendWithdrawalInput) => void;
  isAssignButton?: boolean;
  isDeclineButton?: boolean;
  isSuspendButton?: boolean;
  hasUnfinalizedPayments: boolean;
}

export interface GetWithdrawal extends WithdrawalData {
  hasUnfinalizedPayments: boolean;
  admin?: {
    firstName: string;
    id: string;
    lastName: string;
  } | null;
}

export const useButtonsUpdateWithdrawal = (
  withdrawal: GetWithdrawal,
): Value => {
  const [
    assignWithdrawal,
    { error: errorAssignWithdrawal, loading: loadingAssignWithdrawal },
  ] = useAssignWithdrawal();

  const [
    declineWithdrawal,
    { error: errorDeclineWithdrawal, loading: loadingDeclineWithdrawal },
  ] = useDeclineWithdrawal();

  const [
    suspendWithdrawal,
    { error: errorSuspendWithdrawal, loading: loadingSuspendWithdrawal },
  ] = useSuspendWithdrawal();

  const onAssignWithdrawal = async ({ id }: AssignWithdrawalInput) => {
    await assignWithdrawal({
      variables: { input: { id } },
    });
  };

  const onDeclineWithdrawal = ({ id }: DeclineWithdrawalInput) => {
    const withdrawalData = { ...withdrawal };

    withdrawalData.status = WithdrawalStatus.Declined;
    declineWithdrawal({
      variables: { input: { id } },
      context: { delayed: true },
      optimisticResponse: {
        __typename: 'Mutation',
        declineWithdrawal: {
          __typename: 'DeclineWithdrawalPayload',
          withdrawal: withdrawalData,
        },
      },
    });

    undoActionToast('Вывод отклонен');
  };

  const onSuspendWithdrawal = async ({ id }: SuspendWithdrawalInput) => {
    await suspendWithdrawal({
      variables: { input: { id } },
    });
  };

  const { hasUnfinalizedPayments } = withdrawal;
  const auth = useAuth();

  const userId = auth.user.id;

  const withdrawalAdminId = withdrawal.admin?.id;
  const isCreatorWithdrawal = userId === withdrawalAdminId;

  const isAllowedToUpdateWithdrawal = (privileges: Array<UserPrivilege>) =>
    auth.privileges.areEveryGranted({
      privileges,
    });

  const isAssignButton =
    isAllowedToUpdateWithdrawal([UserPrivilege.AssignWithdrawal]) &&
    withdrawal.status === WithdrawalStatus.Pending;

  const isDeclineButton =
    isCreatorWithdrawal &&
    !hasUnfinalizedPayments &&
    isAllowedToUpdateWithdrawal([UserPrivilege.DeclineWithdrawal]) &&
    withdrawal.status === WithdrawalStatus.InProgress;

  const isSuspendButton =
    isCreatorWithdrawal &&
    !hasUnfinalizedPayments &&
    isAllowedToUpdateWithdrawal([UserPrivilege.SuspendWithdrawal]) &&
    withdrawal.status === WithdrawalStatus.InProgress;

  return {
    onAssignWithdrawal,
    onDeclineWithdrawal,
    onSuspendWithdrawal,
    isAssignButton,
    isDeclineButton,
    isSuspendButton,
    hasUnfinalizedPayments,
    loadingSuspendWithdrawal,
    loadingAssignWithdrawal,
    loadingDeclineWithdrawal,
    error:
      errorSuspendWithdrawal || errorAssignWithdrawal || errorDeclineWithdrawal,
  };
};
