import { ReferralStatus } from 'generatedGraphql';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { ReactElement } from 'react';
import { getReferralStatusIcon } from './getReferralStatusIcon';
import { referralStatusMap } from '../../../../../const';

export const getReferralStatus = (
  status: ReferralStatus,
): undefined | ReactElement => {
  if (status === undefined) {
    return undefined;
  }

  const iconConfig = getReferralStatusIcon(status);
  const title = referralStatusMap[status];

  return MultilineCellWithIcon({ title, iconConfig });
};
