import { FC, useContext } from 'react';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { UserPrivilege } from 'generatedUserPrivilege';
import Privilege from 'commonComponents/Privilege';
import { HeaderActionButtonsWrapper } from 'ui/Block/BlockTable/components/HeaderActionButtonsWrapper';
import { useGetQualifiedReferralConfirmationSettings } from './hooks/useGetQualifiedReferralConfirmationSettings';
import { QualifiedReferralConfirmationSettingsTable } from './components/QualifiedReferralConfirmationSettingsTable';
import { useCreateQualifiedReferralConfirmationSettingModal } from './hooks/useCreateQualifiedReferralConfirmationSettingModal';

export const QualifiedReferralConfirmationSettings: FC = withBlockProvider(
  () => {
    const { state } = useContext(BlockContext);

    const { openCreateQualifiedReferralConfirmationSettingModal } =
      useCreateQualifiedReferralConfirmationSettingModal();

    const {
      loadQualifiedReferralConfirmationSettingsList,
      data,
      loading,
      error,
      refetch,
      fetchMoreCallback,
      hasNextPage,
      confirmationSettingsList,
    } = useGetQualifiedReferralConfirmationSettings();

    useBlockComponentState({
      loadData: loadQualifiedReferralConfirmationSettingsList,
      loading,
      error,
    });

    useBlockTableComponentState({
      data,
      fetchMoreCallback,
      refetch,
      loading,
      hasNextPage,
      isFullScreenTable: true,
    });

    return (
      <Block
        title="Настройки подтверждения"
        id="qualifiedReferralConfirmationSettings"
        headerContent={
          <HeaderActionButtonsWrapper>
            <Privilege
              privileges={[
                UserPrivilege.CreateQualifiedReferralConfirmationSetting,
              ]}
            >
              <ActionButton
                actionType={ButtonType.Add}
                disabled={state.isLoading}
                onClick={openCreateQualifiedReferralConfirmationSettingModal}
              />
            </Privilege>
            <ActionButton
              actionType={ButtonType.Update}
              disabled={state.isLoading}
              onClick={() => refetch()}
            />
          </HeaderActionButtonsWrapper>
        }
        shrinkLoaderWrapper={false}
        emptyText="Настройки подтверждения не были созданы"
        isEmpty={isListEmpty(confirmationSettingsList)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(confirmationSettingsList) && (
          <QualifiedReferralConfirmationSettingsTable
            confirmationSettingsList={confirmationSettingsList}
          />
        )}
      </Block>
    );
  },
);
