import { FC, useState } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { RewardTariffType } from 'commonTypes';
import SearchInput from 'ui/SearchInput';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'src/utils/storage';
import { STATISTIC_QUERY_PARAMS } from 'src/const';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import { STATISTIC_NO_DATA } from '../../const';
import { useGetCpaReferralLinkReport } from './hooks/useGetCpaReferralLinkReport';
import { CpaReferralLinkReportTable } from './components/CpaReferralLinkReportTable';
import { ReferralLinkReportBlockSubHeader } from '../../components/ReferralLinkReportBlockSubHeader';
import { useGeneratePathWithStatisticsQueryParams } from '../../hooks/useGeneratePathWithStatisticsQueryParams';
import { getPathParamsToOwnerPreviousCpaReport } from './helpers';
import {
  CPA_REFERRAL_LINK_INPUT_NAME,
  CPA_REFERRAL_LINK_REPORT_BLOCK_TITLE,
  CPA_REFERRAL_LINK_SEARCH_PLACEHOLDER,
} from './const';
import { useGetExportReferralLinkMediaItemCpaReport } from './hooks/useGetExportReferralLinkMediaItemCpaReport';
import { useGetExportReferralLinkMediaItemClickIdReport } from './hooks/useGetExportReferralLinkMediaItemClickIdReport';

interface Props {
  referralLinkId: string;
}

export const Component: FC<Props> = withBlockProvider(({ referralLinkId }) => {
  const [isAllTimeCpa, setIsAllTimeCpa] = useState(false);
  const {
    cpaReferralLinkReportList,
    refetch,
    mediaItemInfo,
    hasTotalRow,
    loadReport,
  } = useGetCpaReferralLinkReport({
    referralLinkId,
    isAllTimeCpa,
  });

  const exportReportConfig = useGetExportReferralLinkMediaItemCpaReport({
    referralLinkId,
    isAllTimeCpa,
  });

  const exportClickIdReportConfig =
    useGetExportReferralLinkMediaItemClickIdReport({
      referralLinkId,
    });

  const { generatePathWithStatisticsQueryParams } =
    useGeneratePathWithStatisticsQueryParams();
  const { route, params } = getPathParamsToOwnerPreviousCpaReport(
    mediaItemInfo?.owner,
  );

  const backToCpaPartnerReportUrl =
    mediaItemInfo?.owner &&
    generatePathWithStatisticsQueryParams({
      originalPath: route,
      queryParams: STATISTIC_QUERY_PARAMS,
      params,
    });

  const listExist = hasList(cpaReferralLinkReportList);

  return (
    <>
      <SearchInput
        name={CPA_REFERRAL_LINK_INPUT_NAME}
        placeholder={CPA_REFERRAL_LINK_SEARCH_PLACEHOLDER}
        onSubmit={loadReport}
      />
      <Block
        title={CPA_REFERRAL_LINK_REPORT_BLOCK_TITLE}
        id="cpaReferralLinkReport"
        headerContent={
          <StatisticBlockHeaderContent
            hasList={listExist}
            exportReportConfig={exportReportConfig}
            handleRefetchClick={refetch}
            showAllCpa={(value) => setIsAllTimeCpa(value)}
            rewardTariffType={RewardTariffType.Cpa}
            exportClickIdReportConfig={exportClickIdReportConfig}
          />
        }
        subHeader={
          mediaItemInfo && (
            <ReferralLinkReportBlockSubHeader mediaItemInfo={mediaItemInfo} />
          )
        }
        shrinkLoaderWrapper={false}
        emptyText={STATISTIC_NO_DATA}
        isEmpty={isListEmpty(cpaReferralLinkReportList)}
        isPermanentOpened
        shouldReturnToPrevPage
        customPathToPrevPage={backToCpaPartnerReportUrl}
      >
        {listExist && (
          <CpaReferralLinkReportTable
            report={cpaReferralLinkReportList}
            hasTotalRow={hasTotalRow}
          />
        )}
      </Block>
    </>
  );
});

export const CpaReferralLinkReport = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <SearchContextProvider pageType={PageTypeStorage.CPAReferralLinkReport}>
      <Component referralLinkId={id} />
    </SearchContextProvider>
  );
};
