import {
  AffiliateInApproveProcessStatus,
  AffiliatesInProgress,
} from '../../../store';

export const isProcessedAffiliate = (
  affiliatesInProgress: AffiliatesInProgress | null,
) => {
  const processedAffiliates = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key in affiliatesInProgress) {
    if (
      affiliatesInProgress[key] !== AffiliateInApproveProcessStatus.InProcess
    ) {
      processedAffiliates.push(key);
    }
  }

  return !!processedAffiliates.length;
};
