import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { useFormikContext } from 'formik';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { FieldName, mapCreatePromoCodeFieldToTitle } from '../../../../const';
import { CreateAffiliatePromoCodeValues } from '../../../../types';
import styles from './DataCardBonusLink.module.scss';

export const DataCardBonusLink: FC = () => {
  const { values } = useFormikContext<CreateAffiliatePromoCodeValues>();

  return (
    <DataCard
      customSubHeader={
        <span className={styles.blockTitle}>Связь с&nbsp;бонусом</span>
      }
      rows={[
        values[FieldName.BonusAmount] && {
          title: mapCreatePromoCodeFieldToTitle[FieldName.BonusAmount],
          value: formatSumWithCurrency(
            values[FieldName.BonusAmount],
            values[FieldName.BonusCurrency],
          ),
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.BonusWager],
          value: values[FieldName.BonusWager],
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.BonusCashout],
          value: values[FieldName.BonusCashout],
        },
        values[FieldName.BonusMinBalance] && {
          title: mapCreatePromoCodeFieldToTitle[FieldName.BonusMinBalance],
          value: formatSumWithCurrency(
            values[FieldName.BonusMinBalance],
            values[FieldName.BonusCurrency],
          ),
        },
      ]}
    />
  );
};
