import { useFormikContext } from 'formik';
import { useUpdateModalStep } from 'ui/Modal/hooks/useUpdateModalStep';
import { CreateAffiliatePromoCodeValues } from '../../../types';
import {
  FieldName,
  GO_TO_FIRST_STEP_BUTTON_INITIATOR_ID,
} from '../../../const';
import { AffiliateMediaItemsSteps } from '../../../../AffiliatePromoCodeItem/const';

interface useGoToCreatePromoCodeStepsResult {
  goToFirstStep: () => void;
  goToConfirmStep: () => boolean;
}

export const useGoToCreatePromoCodeSteps =
  (): useGoToCreatePromoCodeStepsResult => {
    const { values, isValid, setTouched } =
      useFormikContext<CreateAffiliatePromoCodeValues>();
    const { updateModalStep } = useUpdateModalStep<AffiliateMediaItemsSteps>();

    const goToFirstStep = () => {
      updateModalStep(
        AffiliateMediaItemsSteps.CreatePromoCodeBaseInfo,
        GO_TO_FIRST_STEP_BUTTON_INITIATOR_ID,
      );
    };

    // Set touched fields to show error after click on next step
    const goToConfirmStep = () => {
      setTouched(
        {
          ...(values[FieldName.ConnectionWithFreespins]
            ? {
                [FieldName.FreespinCount]: true,
              }
            : null),
          ...(values[FieldName.ConnectionWithBonus]
            ? {
                [FieldName.BonusAmount]: true,
              }
            : null),
        },
        true,
      );

      if (isValid) {
        updateModalStep(AffiliateMediaItemsSteps.CreatePromoCodeConfirm);
      }

      return false;
    };

    return {
      goToFirstStep,
      goToConfirmStep,
    };
  };
