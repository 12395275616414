import React, { FC, ReactElement } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { Form, Formik } from 'formik';
import Input from 'ui/Input';
import { Dropdown } from 'ui/Dropdown';
import { RestrictionTypeName } from 'commonTypes';
import { Checkbox, CheckboxSize } from 'ui/Checkbox';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import { useModalManageData } from 'ui/Modal';
import Error from 'ui/Error';
import FormWarning from 'ui/FormWarning/FormWarning';
import { Tooltip } from 'ui/Tooltip';
import { useAddPlayerNameToSidebarSubTitle } from 'utils/hooks/useAddPlayerNameToSidebarSubTitle';
import { CreateRestrictionValues, validate } from './validation';
import { restrictionOptions } from '../../const';
import { useCreateRestrictionForm } from './useCreateRestrictionForm';
import styles from './CreateRestrictionForm.module.scss';
import { useGetLastRestrictionsCreateForm } from './queries/generated/GetLastRestrictionsCreateForm';

interface Props {
  playerId: string;
  isFullScreenTable: boolean;
}

const CreateRestrictionForm: FC<Props> = ({ playerId, isFullScreenTable }) => {
  useAddPlayerNameToSidebarSubTitle(playerId);

  const {
    data,
    loading: GetLastRestrictionsLoading,
    error,
  } = useGetLastRestrictionsCreateForm({
    variables: { playerId },
  });

  useModalManageData({
    loading: GetLastRestrictionsLoading,
    hasData: !!data,
    errorConfig: { error, toastId: 'get_last_restrictions' },
  });

  const lastRestrictions = data?.player.lastRestrictions;
  const initialValues: CreateRestrictionValues = {
    reason: undefined,
    restrictionType: undefined,
    bypassRestrictionIfApproved: false,
  };

  const {
    create,
    loading,
    error: createRestrictionError,
  } = useCreateRestrictionForm({ playerId, isFullScreenTable });

  const onSubmit = (values: CreateRestrictionValues) => {
    if (create) {
      create(values);
    }
  };

  const getDescription = (
    values: CreateRestrictionValues,
  ): ReactElement | null => {
    if (values.restrictionType === RestrictionTypeName.ChargeBack) {
      if (
        !lastRestrictions?.badBankRestriction ||
        lastRestrictions?.badBankRestriction.canceledAt !== null
      ) {
        return <p>Будут доступны только криптовалютные методы пополнения</p>;
      }

      return (
        <FormWarning subTitle="Активно ограничение Badbank">
          <p>
            У&nbsp;пользователя установлено ограничение на&nbsp;совершение
            депозитов Badbank. При&nbsp;дополнительной блокировке Chargeback
            будут доступны только криптовалютные методы.
          </p>
        </FormWarning>
      );
    }

    if (values.restrictionType === RestrictionTypeName.BadBank) {
      if (
        !lastRestrictions?.chargeBackRestriction ||
        lastRestrictions?.chargeBackRestriction.canceledAt !== null
      ) {
        return (
          <p>Будут доступны все методы пополнения, кроме&nbsp;банковских</p>
        );
      }

      return (
        <FormWarning subTitle="Активно ограничение Chargeback">
          <p>
            У&nbsp;пользователя установлено ограничение на&nbsp;совершение
            депозитов Chargeback. При&nbsp;ополнительной блокировке Badbank
            будут доступны только криптовалютные методы.
          </p>
        </FormWarning>
      );
    }

    return null;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnChange={false}
    >
      {({ values, isValid, dirty }) => (
        <Form>
          {createRestrictionError && <Error error={createRestrictionError} />}

          <FormRowsWrapper>
            <FormRow isWide>
              <Dropdown
                label="Вид ограничения"
                options={restrictionOptions}
                name="restrictionType"
              />
            </FormRow>
            <FormRow isWide>
              <Input
                name="reason"
                label="Причина"
                placeholder="Введите значение"
                errorTooltipPlacement="left"
              />
            </FormRow>
            {values.restrictionType === RestrictionTypeName.ReceiveGift && (
              <FormRow isWide>
                <Checkbox
                  checkboxSize={CheckboxSize.Small}
                  label="Разрешить начисление после&nbsp;согласования"
                  name="bypassRestrictionIfApproved"
                />
              </FormRow>
            )}
            {values.restrictionType === RestrictionTypeName.Withdrawal && (
              <FormRow isWide>
                <Checkbox
                  checkboxSize={CheckboxSize.Small}
                  label="Разрешить создание платежа после&nbsp;согласования"
                  name="bypassRestrictionIfApproved"
                />
              </FormRow>
            )}
          </FormRowsWrapper>
          <div className={styles.desc}>{getDescription(values)}</div>

          <Tooltip
            placement="right"
            tooltipContent={
              !isValid || !dirty ? 'Необходимо заполнить все поля' : undefined
            }
          >
            <Button
              theme={ButtonTheme.Success}
              type="submit"
              isLoading={loading}
              disabled={!isValid || !dirty}
            >
              Установить
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};

export default CreateRestrictionForm;
