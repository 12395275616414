import { FormikErrors } from 'formik';
import { UpdateReferralLinkMediaItemNameInput } from 'generatedGraphql';

export const validate = (
  values: UpdateReferralLinkMediaItemNameInput,
): FormikErrors<UpdateReferralLinkMediaItemNameInput> => {
  const errors: FormikErrors<UpdateReferralLinkMediaItemNameInput> = {};

  if (!values.name) {
    errors.name = 'Введите название';
  }

  return errors;
};
