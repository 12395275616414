import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { Route } from 'src/router/routes.const';
import { formatSumAmount } from 'src/utils/accounting';
import { formatTableHeaderCellWithCurrency } from 'src/pages/Statistic/helpers';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { generatePath } from 'react-router';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { twoEmDash } from 'src/const';
import { Currency } from 'commonTypes';
import { MediaItemReportOrderBy } from 'generatedGraphql';
import { ColumnId, columnsIds, mapColumnIdToName } from '../const';
import { RevShareReferralLinkReportData } from '../../../queries/generated/RevShareReferralLinkReportData';
import {
  defaultMediaItemReportOrderBy,
  STATISTIC_TOTAL_ROW_TITLE,
} from '../../../../../const';
import { AddTwoLinedSubTitle } from '../../../../../components/AddTwoLinedSubTitle';
import { getFirstDepositInfo } from '../../../../helpers';
import { useGetCurrency } from '../../../../../hooks/useGetCurrency';
import { TableHeaderCellWithSort } from '../../../../../components/TableHeaderCellWithSort';

const columnHelper = createColumnHelper<RevShareReferralLinkReportData>();

const buildColumnsSource = (
  currency: Currency,
): ColumnsSource<RevShareReferralLinkReportData> => [
  columnHelper.accessor(ColumnId.Player, {
    id: ColumnId.Player,
    header: mapColumnIdToName[ColumnId.Player],
    cell: ({ row }) => {
      if (!row.original.player) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name, email, phone } = row.original.player;
      const playerUrl = generatePath(Route.PLAYER, { id });

      return (
        <MultilineCellWithLink
          isOpenInNewWindow
          title={name}
          subTitle={
            <AddTwoLinedSubTitle firstValue={email} secondValue={phone} />
          }
          route={playerUrl}
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.FirstDeposit, {
    id: ColumnId.FirstDeposit,
    header: mapColumnIdToName[ColumnId.FirstDeposit],
    cell: ({ row }) =>
      MayBeNullCell(getFirstDepositInfo(row.original.firstDeposit), twoEmDash),
  }),
  columnHelper.accessor(ColumnId.AllDeposit, {
    id: ColumnId.AllDeposit,
    header: () => (
      <TableHeaderCellWithSort
        defaultOrderBy={defaultMediaItemReportOrderBy}
        orderBy={MediaItemReportOrderBy.AllDeposit}
      >
        {formatTableHeaderCellWithCurrency(
          mapColumnIdToName[ColumnId.AllDeposit],
          currency,
        )}
      </TableHeaderCellWithSort>
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.allDeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.AllRedeposit, {
    id: ColumnId.AllRedeposit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AllRedeposit],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.allRedeposit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Redeposits, {
    id: ColumnId.Redeposits,
    header: mapColumnIdToName[ColumnId.Redeposits],
    cell: ({ row }) => formatNumberWithRuLocale(row.original.redeposits),
  }),
  columnHelper.accessor(ColumnId.IncomingMoneyTransfer, {
    id: ColumnId.IncomingMoneyTransfer,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.IncomingMoneyTransfer],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.incomingMoneyTransfer;

      return formatSumAmount(amount);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Withdrawal, {
    id: ColumnId.Withdrawal,
    header: () => (
      <TableHeaderCellWithSort
        defaultOrderBy={defaultMediaItemReportOrderBy}
        orderBy={MediaItemReportOrderBy.Withdrawal}
      >
        {formatTableHeaderCellWithCurrency(
          mapColumnIdToName[ColumnId.Withdrawal],
          currency,
        )}
      </TableHeaderCellWithSort>
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.withdrawal;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.DepositFee, {
    id: ColumnId.DepositFee,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.DepositFee],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.depositFee;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.WithdrawalFee, {
    id: ColumnId.WithdrawalFee,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.WithdrawalFee],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.withdrawalFee;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.AdminFee, {
    id: ColumnId.AdminFee,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.AdminFee],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.adminFee;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Bonus, {
    id: ColumnId.Bonus,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Bonus],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.bonus;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.TournamentPrize, {
    id: ColumnId.TournamentPrize,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.TournamentPrize],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.tournamentPrize;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.Jackpot, {
    id: ColumnId.Jackpot,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Jackpot],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.jackpot;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.NGR, {
    id: ColumnId.NGR,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.NGR],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } = row.original.playerStatistic.financialIndicators.ngr;

      return formatSumAmount(amount);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(ColumnId.Profit, {
    id: ColumnId.Profit,
    header: formatTableHeaderCellWithCurrency(
      mapColumnIdToName[ColumnId.Profit],
      currency,
    ),
    cell: ({ row }) => {
      const { amount } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumAmount(amount);
    },
  }),
  columnHelper.accessor(ColumnId.AffiliateUpcomingReward, {
    id: ColumnId.AffiliateUpcomingReward,
    header: () => (
      <TableHeaderCellWithSort
        defaultOrderBy={defaultMediaItemReportOrderBy}
        orderBy={MediaItemReportOrderBy.UpcomingReward}
      >
        {formatTableHeaderCellWithCurrency(
          mapColumnIdToName[ColumnId.AffiliateUpcomingReward],
          currency,
        )}
      </TableHeaderCellWithSort>
    ),
    cell: ({ row }) => {
      const { amount } = row.original.affiliateUpcomingReward;

      return formatSumAmount(amount);
    },
  }),
];

export const useGetRevShareReferralLinksColumns = () => {
  const { currency } = useGetCurrency();
  const columnsSource = buildColumnsSource(currency);
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
