import { FC } from 'react';
import { useModalManageData } from 'ui/Modal';
import { Falsy, MediaItemRewardTariffTypeName } from 'commonTypes';
import { KeyValueRowProps } from 'ui/KeyValueRow';
import { CopyModalRow } from 'ui/CopyModalRow';
import { DataCard } from 'commonComponents/DataCard';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { getCountryISOtoDisplayName } from 'src/utils/getCountryISOtoDisplayName';
import { useGetAffiliateReferralLinkItemDetails } from './queries/generated/GetAffiliateReferralLinkItemDetails';

interface Props {
  companyId: string;
  affiliateId: string;
  referralLinkId: string;
}

export const AffiliateReferralLinkItemDetails: FC<Props> = ({
  companyId,
  affiliateId,
  referralLinkId,
}) => {
  const { data, loading, error } = useGetAffiliateReferralLinkItemDetails({
    variables: {
      companyId,
      affiliateId,
      referralLinkId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: {
      error,
      toastId: 'get-company-affiliate-referral-link-error',
    },
    hasData: !!data,
  });

  const referralLinkMediaItem = data?.company.affiliate.referralLinkMediaItem;

  if (!referralLinkMediaItem) {
    return null;
  }

  const {
    name,
    createdAt,
    mediaItemRewardTariff,
    mediaCampaign,
    referralLink,
    updatedAt,
  } = referralLinkMediaItem;

  const isCpaTariff =
    mediaItemRewardTariff.__typename === MediaItemRewardTariffTypeName.Cpa;

  const rewardSum = isCpaTariff && mediaItemRewardTariff.rewardTariff.rewardSum;
  const baselineSum =
    isCpaTariff && mediaItemRewardTariff.rewardTariff.baselineSum;

  const rows: Array<KeyValueRowProps | Falsy> = [
    {
      title: 'Название',
      value: name,
    },
    {
      title: 'Название медиа кампании',
      value: mediaCampaign?.name,
    },
    {
      title: 'URL медиа кампании',
      value: mediaCampaign?.url,
    },
    {
      title: 'Партнёрский тариф',
      value: mediaItemRewardTariff.rewardTariff.title,
    },
    isCpaTariff && {
      title: 'Резервный тариф',
      value: mediaItemRewardTariff.fallbackRewardTariff?.title,
    },
    isCpaTariff && {
      title: 'Геолокация',
      value: getCountryISOtoDisplayName(
        mediaItemRewardTariff.rewardTariff.countries,
      ),
    },
    baselineSum && {
      title: 'Baseline',
      value: formatSumWithCurrency(baselineSum.amount, baselineSum.currency),
    },
    rewardSum && {
      title: 'Вознаграждение',
      value: formatSumWithCurrency(rewardSum.amount, rewardSum.currency),
    },
    {
      title: 'Реферальная ссылка',
      value: (
        <CopyModalRow
          value={referralLink}
          toastText="Реферальная ссылка скопирована"
        />
      ),
    },
    {
      title: 'Создан',
      value: formatDate(createdAt, DateFormat.DefaultDateOld),
    },
    {
      title: 'Обновлен',
      value: formatDate(updatedAt, DateFormat.DefaultDateOld),
    },
  ];

  return <DataCard rows={rows} />;
};
