import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { AccountEventGroupBasicData } from '../queries/fragments/generated/AccountEventGroupBasicData';
import { AccountEventGroupData } from '../queries/fragments/generated/AccountEventGroupData';
import { mapEventGroupTypeToName } from '../const';
import { AccountEventGroupModal } from '../components/AccountEventGroupModal';

interface OpenModalParams {
  accountEventGroup: AccountEventGroupBasicData | AccountEventGroupData;
  initiatorId: string;
}

type UseAccountEventGroupModalResult = (params: OpenModalParams) => void;

export const useAccountEventGroupModal =
  (): UseAccountEventGroupModalResult => {
    const { dispatch: modalDispatch } = useContext(ModalContext);

    const openModal = useCallback(
      async ({ accountEventGroup, initiatorId }: OpenModalParams) => {
        const { type, id } = accountEventGroup;
        const subTitle = mapEventGroupTypeToName[type];

        modalDispatch({
          type: ModalActions.Open,
          payload: {
            title: `#${id}`,
            subTitle,
            content: <AccountEventGroupModal eventGroupId={id} />,
            initiatorId,
          },
        });
      },
      [modalDispatch],
    );

    return openModal;
  };
