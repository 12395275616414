import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetExportReferralLinkMediaItemClickIdReportVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
  period: SchemaTypes.DatePeriodInput;
}>;


export type GetExportReferralLinkMediaItemClickIdReport = { __typename: 'Query', mediaItem: { __typename: 'ReferralLinkMediaItem', exportClickIdReport: string, id: string } };


export const GetExportReferralLinkMediaItemClickIdReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetExportReferralLinkMediaItemClickIdReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"period"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DatePeriodInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ReferralLinkMediaItem"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportClickIdReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"period"},"value":{"kind":"Variable","name":{"kind":"Name","value":"period"}}}]}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetExportReferralLinkMediaItemClickIdReport__
 *
 * To run a query within a React component, call `useGetExportReferralLinkMediaItemClickIdReport` and pass it any options that fit your needs.
 * When your component renders, `useGetExportReferralLinkMediaItemClickIdReport` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportReferralLinkMediaItemClickIdReport({
 *   variables: {
 *      id: // value for 'id'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetExportReferralLinkMediaItemClickIdReport(baseOptions: ApolloReactHooks.QueryHookOptions<GetExportReferralLinkMediaItemClickIdReport, GetExportReferralLinkMediaItemClickIdReportVariables> & ({ variables: GetExportReferralLinkMediaItemClickIdReportVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetExportReferralLinkMediaItemClickIdReport, GetExportReferralLinkMediaItemClickIdReportVariables>(GetExportReferralLinkMediaItemClickIdReportDocument, options);
      }
export function useGetExportReferralLinkMediaItemClickIdReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportReferralLinkMediaItemClickIdReport, GetExportReferralLinkMediaItemClickIdReportVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetExportReferralLinkMediaItemClickIdReport, GetExportReferralLinkMediaItemClickIdReportVariables>(GetExportReferralLinkMediaItemClickIdReportDocument, options);
        }
export function useGetExportReferralLinkMediaItemClickIdReportSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<GetExportReferralLinkMediaItemClickIdReport, GetExportReferralLinkMediaItemClickIdReportVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetExportReferralLinkMediaItemClickIdReport, GetExportReferralLinkMediaItemClickIdReportVariables>(GetExportReferralLinkMediaItemClickIdReportDocument, options);
        }
export type GetExportReferralLinkMediaItemClickIdReportHookResult = ReturnType<typeof useGetExportReferralLinkMediaItemClickIdReport>;
export type GetExportReferralLinkMediaItemClickIdReportLazyQueryHookResult = ReturnType<typeof useGetExportReferralLinkMediaItemClickIdReportLazyQuery>;
export type GetExportReferralLinkMediaItemClickIdReportSuspenseQueryHookResult = ReturnType<typeof useGetExportReferralLinkMediaItemClickIdReportSuspenseQuery>;
export type GetExportReferralLinkMediaItemClickIdReportQueryResult = Apollo.QueryResult<GetExportReferralLinkMediaItemClickIdReport, GetExportReferralLinkMediaItemClickIdReportVariables>;