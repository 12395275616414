import { FormikHelpers } from 'formik';
import { CreateConfirmationSettingValues } from '../types';
import { FieldName } from '../const';

export const partialResetForm = (
  setFieldValue: FormikHelpers<CreateConfirmationSettingValues>['setFieldValue'],
  resetForm: FormikHelpers<CreateConfirmationSettingValues>['resetForm'],
  values: CreateConfirmationSettingValues,
) => {
  const settingType = values[FieldName.settingType];
  const comment = values[FieldName.comment];

  resetForm();

  setFieldValue(FieldName.settingType, settingType);
  setFieldValue(FieldName.comment, comment);
};
