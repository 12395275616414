import NowrapCell from 'ui/Table/components/NowrapCell/NowrapCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { contactForCommunicationTypeMap } from 'src/const';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { CompanyApplicationData } from '../../../queries/generated/CompanyApplicationData';
import { getCompanyApplicationStatus } from '../../../helpers';
import { mapColumnIdToName, ColumnId, columnsIds } from '../const';

const columnHelper = createColumnHelper<CompanyApplicationData>();

const columnsSource: ColumnsSource<CompanyApplicationData> = [
  columnHelper.accessor(ColumnId.Id, {
    id: ColumnId.Id,
    header: mapColumnIdToName[ColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(ColumnId.Company, {
    id: ColumnId.Company,
    header: mapColumnIdToName[ColumnId.Company],
    cell: ({ row }) => {
      const { companyEmail, companyName } = row.original;

      return MultilineCellWithIcon({
        title: companyName,
        subTitle: companyEmail || null,
      });
    },
  }),
  columnHelper.accessor(ColumnId.ContactForCommunication, {
    id: ColumnId.ContactForCommunication,
    header: mapColumnIdToName[ColumnId.ContactForCommunication],
    cell: ({ row }) => {
      const { value, type } = row.original.contactForCommunication;

      return MultilineCellWithIcon({
        title: value,
        subTitle: contactForCommunicationTypeMap[type],
      });
    },
  }),
  columnHelper.accessor(ColumnId.Company, {
    id: ColumnId.WebSite,
    header: mapColumnIdToName[ColumnId.WebSite],
    cell: ({ row }) => MayBeNullCell(row.original.website),
  }),
  columnHelper.accessor(ColumnId.TrafficSource, {
    id: ColumnId.TrafficSource,
    header: mapColumnIdToName[ColumnId.TrafficSource],
  }),
  columnHelper.accessor(ColumnId.Status, {
    id: ColumnId.Status,
    header: mapColumnIdToName[ColumnId.Status],
    cell: ({ row }) => {
      const { status, admin, declineReason } = row.original;
      const [title, iconConfig, subTitle] = getCompanyApplicationStatus(
        status,
        admin,
        declineReason,
      );

      return MultilineCellWithIcon({ title, iconConfig, subTitle });
    },
  }),
  columnHelper.accessor(ColumnId.CreatedAt, {
    id: ColumnId.CreatedAt,
    header: mapColumnIdToName[ColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(ColumnId.UpdatedAt, {
    id: ColumnId.UpdatedAt,
    header: mapColumnIdToName[ColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetCompanyApplicationsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
