import { OwnerTypeName } from 'commonTypes';
import { GetPostbackDisabledNotificationData } from '../../../queries/generated/GetPostbackDisabledNotificationData';

export const getOwnerNameByTypeName = (
  owner: GetPostbackDisabledNotificationData['referralLinkMediaItem']['owner'],
) => {
  if (owner.__typename === OwnerTypeName.CompanyAffiliate) {
    return owner.company.name;
  }

  return owner.name;
};
