import { FC, useContext, useRef } from 'react';
import { Form, Formik } from 'formik';
import { ModalContext } from 'ui/Modal';
import { Currency } from 'commonTypes';
import { generatePromoCodeToken } from 'src/utils/generatePromoCodeToken';
import Error from 'ui/Error';
import { useSetDefaultToken } from 'src/utils/hooks/useSetDefaultToken';
import { validate } from './validation';
import { getCreateAffiliatePromoCodeFormByStep } from './helpers';
import { FieldName } from './const';
import { CreateAffiliatePromoCodeValues } from './types';
import { useCreateAffiliatePromoCode } from './hooks/useCreateAffiliatePromoCode';

interface Props {
  companyId: string;
  companyAffiliateId: string;
  companyAffiliateName: string;
}

export const CreateAffiliatePromoCode: FC<Props> = ({
  companyId,
  companyAffiliateId,
  companyAffiliateName,
}) => {
  const { state } = useContext(ModalContext);
  const token = generatePromoCodeToken();
  const tokenRef = useRef<string>(token);

  useSetDefaultToken({ tokenRef });

  const {
    onCreateAffiliatePromoCodeSubmitHandler,
    createCompanyAffiliatePromoCodeLoading,
    createCompanyAffiliatePromoCodeError,
  } = useCreateAffiliatePromoCode({ companyAffiliateId });

  const initialValues: CreateAffiliatePromoCodeValues = {
    [FieldName.Partner]: `#${companyAffiliateId} ${companyAffiliateName}`,
    [FieldName.ActivationLimit]: null,
    [FieldName.Token]: tokenRef.current,
    [FieldName.InvalidToken]: undefined,
    [FieldName.ReferralLinkUrl]: '',
    [FieldName.ReferralLinkId]: '',
    [FieldName.MediaCampaignName]: '',
    [FieldName.MediaCampaignUrl]: '',
    [FieldName.Usage]: undefined,

    [FieldName.FreespinGameId]: '',
    [FieldName.FreespinGameName]: '',
    [FieldName.FreespinCurrency]: Currency.Rub,
    [FieldName.FreespinCount]: undefined,
    [FieldName.FreespinWager]: undefined,
    [FieldName.FreespinMinBalance]: undefined,

    [FieldName.BonusAmount]: undefined,
    [FieldName.BonusCurrency]: Currency.Rub,
    [FieldName.BonusWager]: undefined,
    [FieldName.BonusCashout]: undefined,
    [FieldName.BonusMinBalance]: undefined,

    [FieldName.ConnectionWithFreespins]: false,
    [FieldName.ConnectionWithBonus]: false,
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => validate(values, state.step)}
      validateOnChange={false}
      onSubmit={onCreateAffiliatePromoCodeSubmitHandler}
      enableReinitialize
    >
      <Form>
        {createCompanyAffiliatePromoCodeError && (
          <Error error={createCompanyAffiliatePromoCodeError} />
        )}
        {getCreateAffiliatePromoCodeFormByStep({
          step: state.step,
          companyId,
          companyAffiliateId,
          createCompanyAffiliatePromoCodeLoading,
        })}
      </Form>
    </Formik>
  );
};
