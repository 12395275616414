import { Option } from '../types';
import { CheckBoxOption } from '../components/CheckBoxOption';

interface GetCheckBoxOptionsParams {
  options: Array<Option> | null;
  checkedOptionsIds: Array<string>;
}

export const getCheckBoxOptions = ({
  options,
  checkedOptionsIds,
}: GetCheckBoxOptionsParams): Array<Option> | null =>
  options &&
  options.map((option: Option) => ({
    id: option.id,
    label: (
      <CheckBoxOption
        isChecked={checkedOptionsIds.includes(option.id)}
        option={option}
      />
    ),
  }));
