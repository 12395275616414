import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { CopyModalRow } from 'ui/CopyModalRow';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import Button, { ButtonTheme } from 'ui/Button';
import { CompanyAffiliateReferralLinkBasicData } from '../../queries/generated/CompanyAffiliateReferralLinkBasicData';

interface Props {
  referralLink: CompanyAffiliateReferralLinkBasicData;
  onDetailsClick: (referralLinkId: string) => void;
}

export const AffiliateReferralLinkItem: FC<Props> = ({
  referralLink,
  onDetailsClick,
}) => {
  const {
    name,
    id: referralLinkId,
    mediaCampaign: { name: mediaCampaignName, url: mediaCampaignNameUrl },
    referralLink: referralLinkUrl,
    createdAt,
  } = referralLink;

  const rows = [
    {
      title: 'Медиа кампания',
      value: mediaCampaignName,
    },
    {
      title: 'URL медиа кампании',
      value: mediaCampaignNameUrl,
    },
    {
      title: 'Реферальная ссылка',
      value: (
        <CopyModalRow
          value={referralLinkUrl}
          toastText="Реферальная ссылка скопирована"
        />
      ),
    },
    {
      title: 'Создан',
      value: formatDate(createdAt, DateFormat.DefaultDateOld),
    },
  ];

  return (
    <DataCard
      header={{
        leftTitle: name,
        leftSubTitle: addHashTagToId(referralLinkId),
      }}
      rows={rows}
      withBorder
      customFooter={
        <Button
          theme={ButtonTheme.Process}
          onClick={() => {
            onDetailsClick(referralLinkId);
          }}
        >
          Подробнее
        </Button>
      }
    />
  );
};
