import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useSearchContext } from 'src/contexts/SearchContext';
import { getCountByWindowHeight, Heights } from 'utils/getCountByWindowHeight';
import { useGetMediaItemsReferralLinkLazyQuery } from '../queries/generated/GetMediaItemsReferralLink';

const cellHeight = Heights.defaultCellHeight;

export const useMediaItemsReferralLink = () => {
  const { searchValue } = useSearchContext();

  const [variables, setVariables] = useState({
    first: getCountByWindowHeight(cellHeight, 0),
  });

  useEffect(() => {
    setVariables((oldVariables) => ({
      ...oldVariables,
      search: searchValue,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loadMediaItems, { data, loading, error, fetchMore, refetch }] =
    useGetMediaItemsReferralLinkLazyQuery();

  const loadMediaItemsCallback = useCallback(
    () => loadMediaItems({ variables }),
    [loadMediaItems, variables],
  );

  useBlockComponentState({
    loadData: loadMediaItemsCallback,
    loading,
    error,
  });

  const mediaItems = useMemo(
    () => data?.mediaItems?.edges.map(({ node }) => node),
    [data?.mediaItems?.edges],
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: data?.mediaItems?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [data?.mediaItems?.pageInfo.endCursor, fetchMore],
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.mediaItems?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  return {
    refetchMediaItems: refetch,
    mediaItems,
    loadMediaItems: () =>
      loadMediaItems({
        variables: { ...variables, search: searchValue },
      }),
  };
};
