import { SET_AFFILIATES_IN_PROGRESS } from '../actionTypes';
import { AffiliateInApproveProcessStatus } from '../const';

interface Payload {
  affiliatesInProgress: { [key: string]: AffiliateInApproveProcessStatus };
}

export interface SetAffiliatesInProgress {
  type: typeof SET_AFFILIATES_IN_PROGRESS;
  payload: Payload;
}

export const setAffiliatesInProgressAction = (
  payload: Payload,
): SetAffiliatesInProgress => ({
  type: SET_AFFILIATES_IN_PROGRESS,
  payload,
});
