import { WithdrawalStatus } from 'generatedGraphql';
import { CardIconColor, CardIconConfig } from 'ui/Card/CardIcon';
import {
  faBan,
  faCheckCircle,
  faCircleCheck,
  faHourglassEmpty,
  faSpinner,
} from '@fortawesome/pro-solid-svg-icons';

export const getWithdrawalStatusIconConfig = (
  withdrawalStatus: WithdrawalStatus,
): CardIconConfig => {
  switch (withdrawalStatus) {
    case WithdrawalStatus.Accepted:
      return {
        icon: faCircleCheck,
        iconColor: CardIconColor.GREEN,
      };
    case WithdrawalStatus.Canceled:
    case WithdrawalStatus.Declined:
    case WithdrawalStatus.InterruptedByCancel:
    case WithdrawalStatus.InterruptedByDecline:
      return {
        icon: faBan,
        iconColor: CardIconColor.RED,
      };
    case WithdrawalStatus.InProgress:
    case WithdrawalStatus.Automatic:
      return {
        icon: faSpinner,
        iconColor: CardIconColor.BLUE,
      };
    case WithdrawalStatus.Pending:
      return {
        icon: faHourglassEmpty,
        iconColor: CardIconColor.ORANGE,
      };

    default:
      return {
        icon: faCheckCircle,
        iconColor: CardIconColor.GREEN,
      };
  }
};
