import { useCallback, useContext } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { PartnerPromoCodeMediaItemsData } from '../queries/generated/PartnerPromoCodeMediaItemsData';
import { PromoCodeMediaItemModal } from '../../PromoCodeMediaItemModal';

interface UseOpenReferralLinkDetailsModalResult<T> {
  openPromoCodeDetailsModal: (arg: T, initiatorId: string) => void;
}

export const useOpenPromoCodeDetailsModal = <T extends object>(
  partnerId: string,
): UseOpenReferralLinkDetailsModalResult<T> => {
  const { dispatch } = useContext(ModalContext);

  const openPromoCodeDetailsModal = useCallback(
    (referralLinkMediaItem: T, initiatorId: string) => {
      const { id: mediaItemPromoCodeId } =
        referralLinkMediaItem as PartnerPromoCodeMediaItemsData;

      dispatch({
        type: ModalActions.Open,
        payload: {
          title: addHashTagToId(mediaItemPromoCodeId),
          subTitle: 'Промокод',
          content: (
            <PromoCodeMediaItemModal
              partnerId={partnerId}
              promoCodeId={mediaItemPromoCodeId}
            />
          ),
          initiatorId,
        },
      });
    },
    [dispatch, partnerId],
  );

  return {
    openPromoCodeDetailsModal,
  };
};
