import { FC } from 'react';
import { faSplit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import ListLoader from 'ui/ListLoader';
import styles from './PostbackDisabledNotificationsBlock.module.scss';
import { useOpenPostbackDisabledNotificationsListModal } from './hooks/useOpenPostbackDisabledNotificationsListModal';
import { getPostbackDisabledNotificationsMessage } from './helpers/getPostbackDisabledNotificationsMessage';
import { PostbackDisabledNotificationsConfig } from './types';

interface Props {
  config: PostbackDisabledNotificationsConfig;
}

export const PostbackDisabledNotificationsBlock: FC<Props> = ({ config }) => {
  const openModal = useOpenPostbackDisabledNotificationsListModal();
  const { loading, error, hasPostbackDisabledNotifications, refetch } = config;

  if (loading || error) {
    return (
      <ListLoader
        className={styles.listLoaderContainer}
        refetch={refetch}
        isLoading={loading}
        isError={!!error}
      />
    );
  }

  const handleClick = () => {
    if (hasPostbackDisabledNotifications) {
      openModal();
    }
  };

  return (
    <div
      className={classnames(
        styles.container,
        hasPostbackDisabledNotifications && styles.clickable,
      )}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faSplit} className={styles.icon} />
      <div className={styles.message}>
        {getPostbackDisabledNotificationsMessage(
          hasPostbackDisabledNotifications,
        )}
      </div>
    </div>
  );
};
