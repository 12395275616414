import { FC } from 'react';

import { Link } from 'react-router-dom';
import {
  Group,
  GroupWithoutIsActive,
  NavigationButtonData,
  NavigationGroupItemData,
  NavigationItemData,
  NavigationLinkData,
} from '../../../../types';
import styles from './NavigationGroupItemsList.module.scss';
import { NavigationGroup } from '../../../NavigationGroup';
import { NavigationItem } from '../../../NavigationItem';

interface Props {
  links: Array<NavigationItemData | NavigationGroupItemData>;
  subNavigationClassName?: string;
  navigationState: Array<Group>;
  toggleNavigationDropdown: (group: GroupWithoutIsActive) => void;
  openNavigationDropdownItem: (id: string) => void;
  selectNavigationItem: (id: string) => void;
}

export const NavigationGroupItemsList: FC<Props> = ({
  links,
  openNavigationDropdownItem,
  toggleNavigationDropdown,
  navigationState,
  selectNavigationItem,
  subNavigationClassName,
}) => (
  <>
    {links.map((link) => {
      if ('group' in link) {
        const {
          title: groupTitle,
          icon: groupIcon,
          group,
        } = link as NavigationGroupItemData;

        const sidebarGroupState = navigationState.find(
          (item) => item.id === groupTitle,
        );

        return (
          <NavigationGroup
            openNavigationDropdownItem={openNavigationDropdownItem}
            toggleNavigationDropdown={toggleNavigationDropdown}
            isOpen={!!sidebarGroupState?.isOpen}
            isActive={!!sidebarGroupState?.isActive}
            key={groupTitle}
            groupIcon={groupIcon}
            groupTitle={groupTitle}
            group={group}
            className={styles.item}
            subNavigationClassName={subNavigationClassName}
          />
        );
      }

      if ('route' in link) {
        const { title, route, icon } = link as NavigationLinkData;

        const sidebarItemState = navigationState.find(
          (item) => item.id === title,
        );

        return (
          <Link className={styles.item} key={route} to={route}>
            <NavigationItem
              onClick={() => selectNavigationItem(title)}
              isActive={!!sidebarItemState?.isActive}
              isOpen={!!sidebarItemState?.isOpen}
              isDropdown={false}
              title={title}
              icon={icon}
            />
          </Link>
        );
      }

      if ('onClick' in link) {
        const { title, onClick, icon } = link as NavigationButtonData;

        return (
          <NavigationItem
            key={title}
            onClick={onClick}
            isActive={false}
            isOpen={false}
            isDropdown={false}
            title={title}
            icon={icon}
          />
        );
      }

      return null;
    })}
  </>
);
