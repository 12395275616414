export enum ColumnId {
  Id = 'id',
  Title = 'title',
  Percent = 'percent',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapColumnIdToName: Record<ColumnId, string> = {
  [ColumnId.Id]: 'ID',
  [ColumnId.Title]: 'Название',
  [ColumnId.Percent]: 'Процент',
  [ColumnId.CreatedAt]: 'Создан',
  [ColumnId.UpdatedAt]: 'Обновлён',
};

export const blockColumnsIds = [ColumnId.Title, ColumnId.CreatedAt];

export const pageColumnsIds = [
  ColumnId.Id,
  ColumnId.Title,
  ColumnId.Percent,
  ColumnId.CreatedAt,
  ColumnId.UpdatedAt,
];
