import { KeyboardEvent } from 'react';

export const INVALID_INTEGER_CHARS = ['e', 'E', '+', '-', '.', ','];

export const blockInvalidChars = (
  event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  invalidChars: Array<string>,
) => {
  if (invalidChars.includes(event.key)) {
    event.preventDefault();
  }
};
