import { FC, useContext } from 'react';
import Privilege from 'commonComponents/Privilege/Privilege';
import Button, { ButtonTheme } from 'ui/Button';
import undoActionToast from 'ui/Toast/UndoActionToast';
import { ModalActions, ModalContext, useModalManageData } from 'ui/Modal';
import { BonusStatus } from 'generatedGraphql';
import { ProgressBar } from 'ui/ProgressBar';
import { UserPrivilege } from 'generatedUserPrivilege';
import { DataCard } from 'commonComponents/DataCard';
import capitalize from 'utils/capitalize';
import { formatSumWithCurrency } from 'utils/accounting';
import { DateFormat, formatDate } from 'utils/formatDate';
import { GetBonus, useGetBonus } from './queries/generated/GetBonus';
import { bonusStatusMap } from '../../const';
import { getBonusStatusIcon } from '../../helpers';
import { useCancelGiftBonus } from './queries/generated/CancelGiftBonus';
import { useBonusItem } from './useBonusItem';

interface Props {
  bonusId: string;
}
const BonusItem: FC<Props> = ({ bonusId }) => {
  const { data, loading, error } = useGetBonus({
    variables: {
      bonusId,
    },
  });

  const {
    status,
    createdAt,
    sum,
    winSum,
    updatedAt,
    promoCode,
    freespin,
    minAccountBalance,
    cashout,
    winConditions,
    cancellable,
    rewardPeriod,
    getProgressBarConfig,
  } = useBonusItem(data);

  const [cancelBonus, { loading: cancelBonusLoading }] = useCancelGiftBonus({
    variables: {
      input: {
        id: bonusId,
      },
    },
  });

  const { dispatch } = useContext(ModalContext);

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-bonus-error' },
    hasData: !!data,
  });

  const bonus: GetBonus | undefined = data;

  const onCancel = () => {
    if (bonus?.bonus.__typename === 'GiftBonus') {
      const giftBonus = { ...bonus.bonus };

      giftBonus.status = BonusStatus.Canceled;

      cancelBonus({
        variables: { input: { id: bonusId } },
        context: { delayed: true },
        optimisticResponse: {
          __typename: 'Mutation',
          cancelGiftBonus: {
            __typename: 'CancelGiftBonusPayload',
            bonus: giftBonus,
          },
        },
      });

      undoActionToast('Бонус отменен');
      dispatch({
        type: ModalActions.Close,
      });
    }
  };

  const isProgressBarVisible =
    !!getProgressBarConfig && status !== BonusStatus.Pending;

  return (
    <DataCard
      statusConfig={
        status && {
          status: bonusStatusMap[status],
          iconConfig: getBonusStatusIcon(status),
        }
      }
      customSubHeader={
        isProgressBarVisible && <ProgressBar {...getProgressBarConfig()} />
      }
      rows={[
        promoCode?.token && {
          title: 'Промокод',
          value: promoCode.token,
        },
        rewardPeriod && {
          title: 'Отчётный период',
          value: capitalize(formatDate(rewardPeriod, DateFormat.ShortDate)),
        },
        freespin?.id && {
          title: 'ID фриспинов',
          value: `#${freespin.id}`,
        },
        sum && {
          title: 'Сумма',
          value: formatSumWithCurrency(sum.amount, sum.currency),
        },
        winConditions?.wager && {
          title: 'Вейджер',
          value: winConditions.wager,
        },
        {
          title: 'Кэшаут',
          value: cashout || '—',
        },
        minAccountBalance && {
          title: 'Минимальный баланс для&nbsp;активации',
          value: formatSumWithCurrency(
            minAccountBalance.amount,
            minAccountBalance.currency,
          ),
        },
        winSum && {
          title: 'Выигрыш',
          value: formatSumWithCurrency(winSum.amount, winSum.currency),
        },
        {
          title: 'Создан',
          value: createdAt && formatDate(createdAt, DateFormat.FullDate),
        },
        updatedAt && {
          title: 'Обновлён',
          value: formatDate(updatedAt, DateFormat.FullDate),
        },
      ]}
      customFooter={
        cancellable && (
          <Privilege privileges={[UserPrivilege.CancelGiftBonus]}>
            <Button
              type="submit"
              onClick={() => onCancel()}
              isLoading={cancelBonusLoading}
              theme={ButtonTheme.Important}
            >
              Отменить
            </Button>
          </Privilege>
        )
      }
    />
  );
};

export default BonusItem;
