import { Form, Formik } from 'formik';
import { FC } from 'react';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import Input from 'ui/Input';
import Error from 'ui/Error';
import { INVALID_INTEGER_CHARS } from 'src/utils/blockInvalidChars';
import { CountriesDropdown } from 'src/components/CountriesDropdown';
import { validate } from './validation';
import { useEditTariffModal } from './hooks/useEditTariffModal';
import { FieldName } from './const';
import { EditTariffValues } from './types';

interface Props {
  tariffId: string;
  baselineAmount: number;
  rewardAmount: number;
  countries: Array<string>;
  title: string;
}

export const EditTariffModal: FC<Props> = ({
  tariffId,
  baselineAmount,
  rewardAmount,
  title,
  countries,
}) => {
  const initialValues: EditTariffValues = {
    [FieldName.Title]: title,
    [FieldName.RewardAmount]: rewardAmount,
    [FieldName.BaselineAmount]: baselineAmount,
    [FieldName.Countries]: countries,
  };

  const { onEditTariff, loading, error } = useEditTariffModal({
    tariffId,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onEditTariff}
      enableReinitialize
      validate={validate}
      validateOnChange={false}
    >
      {({ isValid, dirty }) => (
        <Form>
          {error && <Error error={error} />}
          <FormRowsWrapper>
            <Input
              name={FieldName.Title}
              label="Название"
              maxLength={100}
              placeholder="Введите название тарифа"
            />
          </FormRowsWrapper>
          <FormRowsWrapper>
            <CountriesDropdown
              placeholder="Укажите геолокацию"
              name={FieldName.Countries}
              label="Геолокация"
            />
          </FormRowsWrapper>
          <FormRowsWrapper>
            <Input.Number
              invalidChars={INVALID_INTEGER_CHARS}
              placeholder="Укажите Baseline"
              name={FieldName.BaselineAmount}
              label="Baseline"
            />
          </FormRowsWrapper>
          <FormRowsWrapper>
            <Input.Number
              invalidChars={INVALID_INTEGER_CHARS}
              placeholder="Укажите вознаграждение"
              name={FieldName.RewardAmount}
              label="Вознаграждение"
            />
          </FormRowsWrapper>
          <Button
            size={ButtonSize.Large}
            theme={ButtonTheme.Process}
            type="submit"
            isLoading={loading}
            disabled={!isValid || !dirty}
          >
            Сохранить
          </Button>
        </Form>
      )}
    </Formik>
  );
};
