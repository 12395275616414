import { PartnerApplicationStatus } from 'generatedGraphql';
import { CardIconConfig } from 'ui/Card/CardIcon';
import { nbsp, partnerApplicationStatusMap } from '../../../const';
import { getPartnerApplicationStatusIcon } from './getPartnerApplicationStatusIcon';
import { PartnerApplicationData } from '../queries/generated/PartnerApplicationData';

export const getPartnerApplicationStatus = (
  status: PartnerApplicationStatus,
  admin?: PartnerApplicationData['admin'] | null,
  declineReason?: string | null,
): [string, CardIconConfig, string | null] => {
  const formattedStatus = partnerApplicationStatusMap[status];
  const iconConf = getPartnerApplicationStatusIcon(status);

  let statusText = formattedStatus;
  let subTitle = null;

  if (status === PartnerApplicationStatus.InProgress && admin) {
    const { firstName, lastName } = admin;

    statusText = `${formattedStatus}:${nbsp}${firstName}${nbsp}${lastName}`;
  }

  if (status === PartnerApplicationStatus.Declined && declineReason) {
    subTitle = declineReason;
  }

  return [statusText, iconConf, subTitle];
};
