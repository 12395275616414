import { FC, ReactElement, useMemo } from 'react';
import { KeyValueRow } from 'ui/KeyValueRow/KeyValueRow';
import { Status } from 'ui/Status';
import classnames from 'classnames';
import { CardIcon, CardIconColor } from 'ui/Card/CardIcon';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { DataCardProps } from 'commonComponents/DataCard/types';
import styles from './DataCard.module.scss';

export const DataCard: FC<DataCardProps> = ({
  withBorder,
  header,
  statusConfig,
  customSubHeader,
  rows,
  customFooter,
  containerClassName,
  dropDownConfig,
}) => {
  const rowsList = useMemo(() => {
    const list: Array<ReactElement> = [];

    rows?.forEach((row) => {
      if (row) {
        const { title: rowTitle, value, isBoldValue, emptyText } = row;

        list.push(
          <KeyValueRow
            key={rowTitle + value}
            title={rowTitle}
            emptyText={emptyText}
            isBoldValue={isBoldValue}
            value={value}
          />,
        );
      }
    });

    return list;
  }, [rows]);

  const isOpen = dropDownConfig?.isOpen;

  return (
    <div
      data-testid="data-card-container"
      className={classnames(
        styles.mainContainer,
        (withBorder || dropDownConfig) && styles.withBorder,
        containerClassName,
      )}
    >
      {header && (
        <div
          className={classnames(
            styles.contentContainer,
            dropDownConfig && styles.collapsable,
          )}
        >
          <div
            onClick={dropDownConfig?.onTitleClick}
            className={classnames(
              styles.title,
              dropDownConfig && styles.clickable,
            )}
          >
            <div className={styles.leftTitleContainer}>
              <div>{header.leftTitle}</div>
              {dropDownConfig && (
                <CardIcon
                  className={classnames(styles.icon, !isOpen && styles.closed)}
                  config={{ icon: faCaretUp, iconColor: CardIconColor.BLACK }}
                />
              )}
            </div>
            <div>{header.rightTitle}</div>
          </div>
          <div
            className={classnames(
              styles.subTitle,
              dropDownConfig && !isOpen && styles.closed,
            )}
          >
            {header.leftSubTitle}
          </div>
        </div>
      )}

      {(!dropDownConfig || (dropDownConfig && isOpen)) && (
        <div>
          {statusConfig && (
            <Status {...statusConfig} className={styles.contentContainer} />
          )}

          {customSubHeader && (
            <div className={styles.contentContainer}>{customSubHeader}</div>
          )}

          {Boolean(rowsList.length) && (
            <div className={styles.contentContainer}>{rowsList}</div>
          )}

          {customFooter && (
            <div className={styles.contentContainer}>{customFooter}</div>
          )}
        </div>
      )}
    </div>
  );
};
