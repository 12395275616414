/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-noninteractive-tabindex */
import { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import Error from 'ui/Error';
import { ApolloError } from '@apollo/client';
import { Option } from 'ui/Dropdown/types';
import styles from './DropdownToggle.module.scss';

interface Props {
  opened: boolean;
  touched: boolean;
  value: string;
  error?: string;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
  isCheckbox?: boolean;
  loadingError?: ApolloError;
  name: string;
  placeholder: string;
  defaultValue?: string;
  options?: Array<Option> | null;
  checkedOptionsIds: Array<string> | null;
}

export const DropdownToggle: FC<Props> = ({
  opened,
  touched,
  value,
  error,
  disabled,
  className,
  onClick,
  isCheckbox,
  loadingError,
  name,
  placeholder,
  defaultValue,
  options,
  checkedOptionsIds,
}) => {
  const [initialOptions, setInitialOptions] = useState<Array<Option>>([]);
  const selectedOption = options?.find(({ id }) => id === value);

  useEffect(() => {
    if (!initialOptions.length && options?.length) {
      setInitialOptions(options);
    }
  }, [initialOptions, options]);

  const getCheckboxSelectedText = () =>
    initialOptions
      ?.filter(({ id }) => checkedOptionsIds?.includes(id))
      .map((option) => option.label)
      .join(', ');

  const getDropdownSelectedText = () =>
    selectedOption ? selectedOption.label : null;

  const selectedText =
    (isCheckbox ? getCheckboxSelectedText() : getDropdownSelectedText()) ||
    defaultValue ||
    placeholder;

  const hasError = touched && error && !opened;
  const isTouched = touched || value;

  return (
    <>
      <div
        className={classnames(
          styles.dropdown,
          opened && styles.dropdownOpened,
          isTouched && styles.dropdownTouched,
          hasError && styles.dropdownError,
          disabled && styles.dropdownDisabled,
          className,
        )}
        role="list"
        tabIndex={0}
        onClick={onClick}
        data-testid={name}
      >
        <span className={styles.selectedText}>{selectedText}</span>
        <div
          className={classnames(
            styles.arrow,
            styles.arrowDown,
            error && touched && !opened && styles.arrowDownError,
            opened && styles.arrowOpened,
            opened && isCheckbox && styles.arrowOpenedCheckbox,
          )}
        />
      </div>
      {loadingError && !opened && (
        <Error error={loadingError} className={styles.errorWrapper} />
      )}
    </>
  );
};
