import {
  useCallback,
  useLayoutEffect,
  useState,
  useRef,
  RefObject,
} from 'react';
import { round } from 'lodash-es';

let headerInitialTop: number | undefined;
let multiplier: number;
const animationY = 70;

interface Value {
  heightHeader: number;
  headerRef: RefObject<HTMLDivElement>;
}

const useClientHeader = (): Value => {
  const headerRef = useRef<HTMLDivElement>(null);
  const mediumBreakpoint = 768;
  const [heightHeader, setHeightHeader] = useState(0);

  const changeHeaderStyles = useCallback(() => {
    if (!headerRef?.current) return;
    multiplier = headerInitialTop
      ? round(
          Math.min(Math.max(window.scrollY - headerInitialTop, 0), animationY) /
            animationY,
          2,
        )
      : 0;

    const editBtn = headerRef.current.querySelector(
      `[data-id=editBtn]`,
    ) as HTMLDivElement;

    if (multiplier > 0 && editBtn) {
      editBtn.style.display = 'none';
      editBtn.style.height = '0';
    } else if (editBtn) {
      editBtn.style.display = 'block';
      editBtn.style.height = '24px';
    }

    // Header Block Animation
    headerRef.current.style.background = `rgba(255, 255, 255, ${multiplier})`;
    headerRef.current.style.boxShadow = `0 2px 2px rgba(158, 171, 192, ${
      multiplier * 0.24
    })`;

    // Markers appearance
    const markersUncoloredElement = headerRef.current.querySelector(
      `[data-id=uncolored]`,
    ) as HTMLDivElement;
    const markersUncoloredStyle =
      markersUncoloredElement &&
      window.getComputedStyle(markersUncoloredElement, null);
    const markersUncoloredOpacity =
      markersUncoloredStyle && parseInt(markersUncoloredStyle.opacity, 10);

    if (multiplier < 1 && markersUncoloredOpacity === 1) {
      setHeightHeader(0);
      markersUncoloredElement.style.opacity = '0';
      if (window.innerWidth <= mediumBreakpoint) {
        markersUncoloredElement.style.display = 'none';
      }
    }

    if (multiplier >= 1 && markersUncoloredOpacity === 0) {
      if (headerRef.current) {
        setHeightHeader(headerRef.current?.getBoundingClientRect().height);
      }

      markersUncoloredElement.style.opacity = '1';
      if (window.innerWidth <= mediumBreakpoint) {
        markersUncoloredElement.style.display = 'block';
      } else {
        markersUncoloredElement.style.display = 'block';
      }
    }
  }, [headerRef]);

  useLayoutEffect(() => {
    const onScroll = () => {
      if (!headerInitialTop) {
        headerInitialTop = headerRef.current?.getBoundingClientRect().top;
      }

      requestAnimationFrame(changeHeaderStyles);
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [changeHeaderStyles, headerRef]);

  return { heightHeader, headerRef };
};

export default useClientHeader;
