import { FC } from 'react';
import classnames from 'classnames';
import styles from './Loader.module.scss';

export enum LoaderTheme {
  Light = 'light',
  Dark = 'dark',
}

interface Props {
  className?: string;
  theme?: LoaderTheme;
}

const Loader: FC<Props> = ({
  theme = LoaderTheme.Light,
  className,
  ...rest
}) => (
  <div
    className={classnames(
      styles.loader,
      styles[`${theme}ThemeLoader`],
      className,
    )}
    {...rest}
  >
    <div className={styles.dot1} />
    <div className={styles.dot2} />
    <div className={styles.dot3} />
  </div>
);

export default Loader;
