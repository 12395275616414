import { useCallback, useContext, useState } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'src/ui/ActionButton';
import { BlockContext } from 'ui/Block/store';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { TableFilter } from 'ui/TableFilter';
import BlockTable from 'ui/Block/BlockTable';
import { ColumnDef } from '@tanstack/react-table';
import { nbsp } from 'src/const';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useTransferOpenModal } from '../../hooks/useTransferOpenModal';
import { useGetTransfersLazyQuery } from '../../queries/generated/GetTransfers';
import TransferAccount from '../TransferAccount/TransferAccount';
import styles from './TransfersTable.module.scss';
import { useGetMoneyTransferFilters } from './hooks/useGetMoneyTransferFilters';
import { allMoneyTransferFilters } from '../../const';

const countPerPage = 10;

interface Props<T extends object> {
  playerId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

const TransfersTable = <T extends { __typename: string }>({
  playerId,
  columns,
  shouldReturnToPrevPage,
  routeToCustomPage,
  isFullScreenTable,
}: Props<T>) => {
  const { openModal } = useTransferOpenModal<T>();
  const { state } = useContext(BlockContext);
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  const [loadTransfers, { data, loading, error, fetchMore, refetch }] =
    useGetTransfersLazyQuery({
      variables: {
        playerId,
        first: countPerPage,
      },
    });

  const moneyTransferAccount = data?.player.moneyTransferAccount;
  const transfers = data?.player.moneyTransfers;
  const transfersList =
    transfers &&
    (transfers.edges.map(({ node }) => node) as unknown as Array<T>);
  const hasNextPage = data?.player.moneyTransfers.pageInfo.hasNextPage;

  useBlockComponentState({
    loadData: loadTransfers,
    loading,
    error,
  });

  const refetchTransfers = () => {
    refetch?.();
  };

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            playerId,
            first: countPerPage,
            after: transfers?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [playerId, transfers?.pageInfo.endCursor, fetchMore],
  );

  useBlockTableComponentState({
    data,
    loading,
    hasNextPage,
    isFullScreenTable,
    fetchMoreCallback,
    refetch,
  });

  const {
    onChangeFilters,
    availableMoneyTransferFilters,
    onSubmitFilter,
    isFiltered,
  } = useGetMoneyTransferFilters({
    playerId,
    loadTransfers,
  });

  const isEmptyList = isListEmpty(transfersList);

  const isFilterBtnDisabled = isEmptyList && !isFiltered;

  const emptyText =
    isEmptyList && isFiltered
      ? `Результаты${nbsp}по${nbsp}заданным фильтрам отсутствуют`
      : 'Игрок не совершал переводы';

  return (
    <Block
      title="Переводы"
      id="playerTransfers"
      headerContent={
        <div className={styles.actionsContainer}>
          {isFullScreenTable && (
            <ActionButton
              actionType={ButtonType.Filter}
              hidden={!state.isOpened}
              disabled={isFilterBtnDisabled}
              onClick={() => setIsOpenFilters(!isOpenFilters)}
            />
          )}
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={refetchTransfers}
          />
        </div>
      }
      isEmpty={isEmptyList}
      emptyText={emptyText}
      shrinkLoaderWrapper={false}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
      extendedHeaderClassname={styles.extendedHeader}
      extendedHeader={
        !isEmptyList &&
        moneyTransferAccount && (
          <TransferAccount moneyTransferAccount={moneyTransferAccount} />
        )
      }
    >
      <>
        {isOpenFilters && isFullScreenTable && (
          <div className={styles.filterContainer}>
            <TableFilter
              availableFilters={availableMoneyTransferFilters}
              allFilters={allMoneyTransferFilters}
              onChangeFilters={onChangeFilters}
              onSubmit={onSubmitFilter}
            />
          </div>
        )}
        {hasList(transfersList) && (
          <BlockTable
            isFullScreenTable={isFullScreenTable}
            data={transfersList}
            columns={columns}
            onClickOnRow={openModal}
            routeToCustomPage={routeToCustomPage}
          />
        )}
      </>
    </Block>
  );
};

export default TransfersTable;
