import { useSearchParams } from 'react-router-dom';
import { DEFAULT_CURRENCY, STATISTIC_CURRENCY } from 'src/const';
import { useEffect, useMemo } from 'react';
import { Currency } from 'commonTypes';

interface Params {
  currency: Currency;
}

export const useGetCurrency = (): Params => {
  const [searchParams, setSearchParams] = useSearchParams();
  const newParams = useMemo(
    () => new URLSearchParams(searchParams),
    [searchParams],
  );

  const currency = searchParams.get(STATISTIC_CURRENCY) || DEFAULT_CURRENCY;

  useEffect(() => {
    if (!searchParams.has(STATISTIC_CURRENCY)) {
      newParams.set(STATISTIC_CURRENCY, currency);
      setSearchParams(newParams);
    }
  }, [currency, newParams, setSearchParams, searchParams]);

  return {
    currency: <Currency>currency,
  };
};
