import { FC, useContext, useMemo } from 'react';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { SignInRestrictionHistoryTable } from './components/SignInRestrictionHistoryTable';
import { useSignInRestrictionHistory } from './hooks/useSignInRestrictionHistory';

interface Props {
  id: string;
  shouldReturnToPrevPage?: boolean;
  isFullScreenTable?: boolean;
}

export const SignInRestrictionHistoryBlock: FC<Props> = withBlockProvider(
  ({
    id: playerId,
    shouldReturnToPrevPage = false,
    isFullScreenTable = false,
  }) => {
    const { state } = useContext(BlockContext);

    const {
      signInRestrictionHistoryData,
      error,
      loading,
      refetch,
      fetchMoreCallaback,
    } = useSignInRestrictionHistory({ playerId, isFullScreenTable });

    useBlockComponentState({
      loading,
      error,
    });

    useBlockTableComponentState({
      data: signInRestrictionHistoryData,
      fetchMoreCallback: fetchMoreCallaback,
      refetch,
      loading,
      hasNextPage: signInRestrictionHistoryData?.pageInfo.hasNextPage,
      isFullScreenTable,
    });

    const signInRestrictionsList = useMemo(
      () => signInRestrictionHistoryData?.edges.map(({ node }) => node),
      [signInRestrictionHistoryData],
    );

    return (
      <Block
        title="История блокировок"
        id="signInRestrictionHistory"
        headerContent={
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={() => refetch()}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText="Игроку не устанавливались блокировки"
        isEmpty={isListEmpty(signInRestrictionsList)}
        shouldReturnToPrevPage={shouldReturnToPrevPage}
      >
        {hasList(signInRestrictionsList) && (
          <SignInRestrictionHistoryTable
            signInRestrictions={signInRestrictionsList}
            isFullScreenTable={isFullScreenTable}
          />
        )}
      </Block>
    );
  },
);
