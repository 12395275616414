import { FC } from 'react';
import DropdownWithQuery from 'ui/DropdownWithQuery/DropdownWithQuery';
import { useGetCompanyAffiliatesQuery } from './hooks/useGetCompanyAffiliatesQuery';
import { getCompanyAffiliatesOptions } from './helpers';

interface Props {
  name: string;
  label: string;
  companyId: string;
  disabled?: boolean;
}

export const CompanyAffiliatesDropdown: FC<Props> = ({
  name,
  label,
  companyId,
  disabled,
}) => {
  const {
    loadCompanyAffiliateWithThrottle,
    data,
    loading,
    hasNextPage,
    fetchMore,
    error,
  } = useGetCompanyAffiliatesQuery(companyId);

  const companyAffiliatesOptions = getCompanyAffiliatesOptions(
    data?.company.affiliates.edges,
  );

  return (
    <DropdownWithQuery
      fetchMoreConfig={{
        fetchMore: () => {
          if (hasNextPage) {
            fetchMore();
          }
        },
        hasNextPage,
      }}
      loading={loading}
      loadingError={error}
      name={name}
      label={label}
      options={companyAffiliatesOptions}
      searchMinLength={3}
      loadList={loadCompanyAffiliateWithThrottle}
      disabled={disabled}
    />
  );
};
