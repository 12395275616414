import React, { FC, useEffect } from 'react';
import { Maybe } from 'generatedGraphql';
import { createPortal } from 'react-dom';
import DocumentSidebarContent from '../DocumentSidebarContent';
import styles from './DocumentSidebar.module.scss';

interface DocumentSidebarType {
  documentId: string;
  createdAt: string;
  note: Maybe<string>;
  onDelete: (id: string) => void;
  isDeleteLoading: boolean;
}

const DocumentSidebar: FC<DocumentSidebarType> = ({
  documentId,
  note,
  createdAt,
  onDelete,
  isDeleteLoading,
}) => {
  const container = document.createElement('div');

  container.classList.add(styles.lightboxSidebar);

  useEffect(() => {
    const root = document.querySelector('.ril__outer');

    if (root) {
      root.appendChild(container);
    }

    return () => {
      if (root) {
        root.removeChild(container);
      }
    };
  }, [container]);

  return createPortal(
    <DocumentSidebarContent
      documentId={documentId}
      createdAt={createdAt}
      note={note}
      onDelete={onDelete}
      isDeleteLoading={isDeleteLoading}
    />,
    container,
  );
};

export default DocumentSidebar;
