import { FC, useContext, useState } from 'react';
import { RewardTariffType } from 'commonTypes';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { isListEmpty } from 'ui/Block/BlockTable/utils';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { RewardTariffsTabs } from 'commonComponents/RewardTariffsTabs';
import { UserPrivilege } from 'generatedUserPrivilege';
import { HeaderActionButtonsWrapper } from 'ui/Block/BlockTable/components/HeaderActionButtonsWrapper';
import Privilege from 'commonComponents/Privilege';
import { RewardTariffsTable } from './components/RewardTariffsTable';
import { useGetRewardTariffsData } from './hooks/useGetRewardTariffsData';
import { useOpenUpdateAccessToTariffsModal } from './hooks/useOpenUpdateAccessToTariffsModal';

interface Props {
  id: string;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
}

export const CompanyRewardTariffsBlock: FC<Props> = withBlockProvider(
  ({ id, isFullScreenTable, shouldReturnToPrevPage }) => {
    const { state } = useContext(BlockContext);

    const openModal = useOpenUpdateAccessToTariffsModal({
      companyId: id,
      isFullScreenTable,
    });

    const [rewardTariffsType, setRewardTariffsType] = useState(
      RewardTariffType.RevShare,
    );

    const {
      rewardTariffs,
      refetch,
      loadCompanyRewardTariffs,
      loading,
      error,
      fetchMore,
      hasNextPage,
      rewardTariffsData,
      columns,
    } = useGetRewardTariffsData({
      rewardTariffType: rewardTariffsType,
      companyId: id,
      isFullScreenTable,
    });

    useBlockComponentState({
      loadData: loadCompanyRewardTariffs,
      loading,
      error,
    });

    useBlockTableComponentState({
      data: rewardTariffsData,
      fetchMoreCallback: fetchMore,
      refetch,
      loading,
      hasNextPage,
      isFullScreenTable,
    });

    return (
      <Block
        title="Партнёрские тарифы"
        id="companiesPartnerRewardTariffs"
        headerContent={
          <HeaderActionButtonsWrapper>
            <Privilege
              privileges={[
                UserPrivilege.UpdateAccessToCompanyRevShareRewardTariffs,
              ]}
            >
              <ActionButton
                actionType={ButtonType.Edit}
                hidden={!state.isOpened}
                disabled={state.isLoading}
                onClick={openModal}
              />
            </Privilege>
            <ActionButton
              actionType={ButtonType.Update}
              hidden={!state.isOpened}
              disabled={state.isLoading}
              onClick={() => refetch()}
            />
          </HeaderActionButtonsWrapper>
        }
        subHeader={
          <RewardTariffsTabs
            currentTabId={rewardTariffsType}
            setRewardTariffsType={setRewardTariffsType}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText="Партнерские тарифы не найдены"
        isEmpty={isListEmpty(rewardTariffs)}
        shouldReturnToPrevPage={shouldReturnToPrevPage}
      >
        <RewardTariffsTable
          isFullScreenTable={isFullScreenTable}
          rewardTariffsType={rewardTariffsType}
          rewardTariffs={rewardTariffs}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          columns={columns as any}
          companyId={id}
        />
      </Block>
    );
  },
);
