import { FormikErrors } from 'formik';
import { checkEmailValidity } from 'utils/validation';

export interface LogInValues {
  email: string;
  password: string;
}

interface LogInErrors {
  email?: string;
  password?: string;
}

export interface TwoFactorValues {
  authCode: string;
}

interface TwoFactorErrors {
  authCode?: string;
}

export interface PasswordChangeValues {
  password: string;
  passwordRepeat: string;
}

interface PasswordChangeErrors {
  password?: string;
  passwordRepeat?: string;
}

export const validateLogIn = (
  values: LogInValues,
): FormikErrors<LogInErrors> => {
  const errors: FormikErrors<LogInErrors> = {};

  if (!values.email) {
    errors.email = 'Необходимо ввести почту';
  } else if (!checkEmailValidity(values.email)) {
    errors.email = 'Введите верную почту';
  }

  if (!values.password) {
    errors.password = 'Необходимо ввести пароль';
  }

  return errors;
};

export const validateTwoFactor = (
  values: TwoFactorValues,
): FormikErrors<TwoFactorErrors> => {
  const errors: FormikErrors<TwoFactorErrors> = {};

  if (!values.authCode) {
    errors.authCode = 'Необходимо ввести код аутентификации';
  }

  return errors;
};

export const validatePasswordChange = (
  values: PasswordChangeValues,
): FormikErrors<PasswordChangeErrors> => {
  const errors: FormikErrors<PasswordChangeErrors> = {};

  if (!values.password) {
    errors.password = 'Необходимо ввести пароль';
  }

  if (!values.passwordRepeat) {
    errors.passwordRepeat = 'Необходимо повторно ввести пароль';
  }

  if (values.passwordRepeat !== values.password) {
    errors.passwordRepeat = 'Пароли должны совпадать';
  }

  return errors;
};
