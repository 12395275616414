import * as SchemaTypes from '../../types/generated/gql';

import * as CommonType from 'commonTypes'
import { DocumentNode } from 'graphql';
import { MediaItemReferralLinkData } from './MediaItemReferralLinkData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'src/utils/customApolloQuery';
const defaultOptions = {} as const;
export type CreateCompanyAffiliateReferralLinkMediaItemVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreateCompanyAffiliateReferralLinkMediaItemInput;
}>;


export type CreateCompanyAffiliateReferralLinkMediaItem = { __typename: 'Mutation', createCompanyAffiliateReferralLinkMediaItem: { __typename: 'CreateCompanyAffiliateReferralLinkMediaItemPayload', referralLinkMediaItem: { __typename: 'ReferralLinkMediaItem', code: any, createdAt: string, id: string, name: string, mediaCampaign: { __typename: 'MediaCampaign', id: string, name: string, url: string }, owner: { __typename: 'Company', id: string, name: string, companyEmail: string } | { __typename: 'CompanyAffiliate', id: string, name: string, company: { __typename: 'Company', email: string, id: string } } | { __typename: 'Partner', id: string, name: string, partnerEmail?: string | null } } } };


export const CreateCompanyAffiliateReferralLinkMediaItemDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateCompanyAffiliateReferralLinkMediaItem"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateCompanyAffiliateReferralLinkMediaItemInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createCompanyAffiliateReferralLinkMediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referralLinkMediaItem"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaItemReferralLinkData"}}]}}]}}]}},...MediaItemReferralLinkData.definitions]} as unknown as DocumentNode;
export type CreateCompanyAffiliateReferralLinkMediaItemMutationFn = Apollo.MutationFunction<CreateCompanyAffiliateReferralLinkMediaItem, CreateCompanyAffiliateReferralLinkMediaItemVariables>;

/**
 * __useCreateCompanyAffiliateReferralLinkMediaItem__
 *
 * To run a mutation, you first call `useCreateCompanyAffiliateReferralLinkMediaItem` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyAffiliateReferralLinkMediaItem` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyAffiliateReferralLinkMediaItem, { data, loading, error }] = useCreateCompanyAffiliateReferralLinkMediaItem({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyAffiliateReferralLinkMediaItem(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyAffiliateReferralLinkMediaItem, CreateCompanyAffiliateReferralLinkMediaItemVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateCompanyAffiliateReferralLinkMediaItem, CreateCompanyAffiliateReferralLinkMediaItemVariables>(CreateCompanyAffiliateReferralLinkMediaItemDocument, options);
      }
export type CreateCompanyAffiliateReferralLinkMediaItemHookResult = ReturnType<typeof useCreateCompanyAffiliateReferralLinkMediaItem>;
export type CreateCompanyAffiliateReferralLinkMediaItemMutationResult = Apollo.MutationResult<CreateCompanyAffiliateReferralLinkMediaItem>;
export type CreateCompanyAffiliateReferralLinkMediaItemMutationOptions = Apollo.BaseMutationOptions<CreateCompanyAffiliateReferralLinkMediaItem, CreateCompanyAffiliateReferralLinkMediaItemVariables>;