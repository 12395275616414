import { nbsp } from '../../../../const';

export const signInRestrictionProtectedToType = (
  isProtected: boolean,
): string => {
  if (isProtected) {
    return `Без${nbsp}права на${nbsp}восстановление`;
  }

  return 'Обычная';
};
