import { Storage } from '../../storage';
import { authStorageKeys } from './types';

export class AuthStorage {
  setLoggedInKey = (key: string): void => {
    Storage.local.setValue(authStorageKeys.loggedInKey, key);
  };

  getLoggedInKey = (): string => {
    const loggedInKey = Storage.local.getValue<string>(
      authStorageKeys.loggedInKey,
    );

    if (loggedInKey) {
      return loggedInKey;
    }

    return '';
  };

  clearLoggedInKey = (): void => {
    Storage.local.removeValue(authStorageKeys.loggedInKey);
  };
}
