import Button from 'ui/Button';
import { ReactElement } from 'react';
import { toast, ToastContent, ToastOptions } from 'react-toastify';

import DelayedQueueLink from 'src/DelayedQueueLink';
import { UndoActionToastType } from 'ui/Toast/const';
import styles from './UndoActionToast.module.scss';

interface Props {
  message: string;
  onUndo: () => void;
  closeToast?: () => void;
}

const UndoActionToast = ({
  message,
  onUndo,
  closeToast,
}: Props): ReactElement => {
  const handleClick = () => {
    onUndo();
    if (closeToast) {
      closeToast();
    }
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.message}>{message}</p>
      <Button className={styles.button} onClick={handleClick}>
        Отменить
      </Button>
    </div>
  );
};

const undoActionToast = (
  message: string,
  toastType: UndoActionToastType = UndoActionToastType.Success,
): void => {
  const content: ToastContent = (
    <UndoActionToast
      message={message}
      onUndo={() => {
        DelayedQueueLink.clean();
      }}
    />
  );

  const options: ToastOptions = {
    onClose: () => {
      DelayedQueueLink.run();
    },
  };

  switch (toastType) {
    case UndoActionToastType.Success:
      toast.success(content, options);
      break;
    case UndoActionToastType.Error:
      toast.error(content, options);
      break;
    default:
      toast.success(content, options);
  }
};

export default undoActionToast;
