import { FormikErrors } from 'formik';
import { EditTariffValues } from './types';

export const validate = (
  values: EditTariffValues,
): FormikErrors<EditTariffValues> => {
  const errors: FormikErrors<EditTariffValues> = {};

  if (!values.title) {
    errors.title = 'Необходимо ввести название';
  }

  if (!values.countries.length) {
    errors.countries = 'Необходимо выбрать геолокацию';
  }

  if (!values.baselineAmount) {
    errors.baselineAmount = 'Необходимо ввести Baseline';
  }

  if (!values.rewardAmount) {
    errors.rewardAmount = 'Необходимо ввести вознаграждение';
  }

  return errors;
};
