import { FC } from 'react';
import Button, { ButtonTheme } from 'ui/Button';
import { useModalManageData } from 'ui/Modal';
import { RestrictionTypeName } from 'commonTypes';
import {
  ReceiveGiftRestriction,
  WithdrawalRestriction,
} from 'generatedGraphql';
import FormWarning from 'ui/FormWarning/FormWarning';
import Privilege from 'commonComponents/Privilege';
import { DataCard } from 'commonComponents/DataCard';
import { DateFormat, formatDate } from 'utils/formatDate';
import { useGetRestriction } from './queries/generated/GetRestriction';
import styles from './RestrictionModal.module.scss';
import {
  bypassRestrictionIfApprovedText,
  bypassRestrictionIfApprovedTitle,
  getStatusAndIconConfig,
  isCancelable,
} from '../../helpers';
import { useRestrictionModal } from './useRestrictionModal';

interface Props {
  restrictionId: string;
  playerId: string;
}

const RestrictionModal: FC<Props> = ({ restrictionId, playerId }) => {
  const { data, loading, error } = useGetRestriction({
    variables: {
      restrictionId,
    },
  });

  useModalManageData({
    loading,
    errorConfig: { error, toastId: 'get-restriction' },
    hasData: !!data,
  });

  const restriction = data?.restriction as
    | WithdrawalRestriction
    | ReceiveGiftRestriction;

  const {
    iconConfig: { icon, iconColor },
    status,
  } = getStatusAndIconConfig(restriction);

  const typeName = restriction?.__typename as RestrictionTypeName;

  const { cancelRestriction, loading: cancelRestrictionLoading } =
    useRestrictionModal(typeName, playerId);

  if (!restriction) {
    return null;
  }

  const cancel = () => {
    if (cancelRestriction && playerId) {
      cancelRestriction();
    }
  };

  const isShowFormWarning =
    (typeName === RestrictionTypeName.Withdrawal ||
      typeName === RestrictionTypeName.ReceiveGift) &&
    restriction?.bypassRestrictionIfApproved;

  return (
    <>
      <div className={styles.reason}>
        {restriction?.reason || (
          <div className={styles.noValue}>Причина не указана</div>
        )}
      </div>
      <DataCard
        statusConfig={{
          status,
          iconConfig: { icon, iconColor },
        }}
        customSubHeader={
          isShowFormWarning && (
            <FormWarning subTitle={bypassRestrictionIfApprovedTitle[typeName]}>
              <p>{bypassRestrictionIfApprovedText[typeName]}</p>
            </FormWarning>
          )
        }
        rows={[
          {
            title: 'Создан',
            value: formatDate(restriction?.createdAt, DateFormat.FullDate),
          },
          {
            title: 'Обновлён',
            value: formatDate(restriction?.updatedAt, DateFormat.FullDate),
          },
        ]}
        customFooter={
          !restriction.canceledAt && (
            <Privilege privileges={[isCancelable(typeName)]}>
              <Button
                type="submit"
                onClick={cancel}
                isLoading={cancelRestrictionLoading}
                theme={ButtonTheme.Important}
                disabled={!cancelRestriction || !playerId}
              >
                Отменить
              </Button>
            </Privilege>
          )
        }
      />
    </>
  );
};

export default RestrictionModal;
