import {
  NavigationGroupItemData,
  NavigationItemData,
} from 'commonComponents/Navigation/types';
import { useEffect, useRef } from 'react';

type NavigationItems = Array<NavigationItemData | NavigationGroupItemData>;

interface Result {
  topLinks: NavigationItems;
  bottomLinks: NavigationItems;
}

export const useGetNavigationGroups = (links: NavigationItems): Result => {
  const topLinks = useRef<NavigationItems>([]);
  const bottomLinks = useRef<NavigationItems>([]);

  useEffect(() => {
    if (topLinks.current.length && bottomLinks.current.length) {
      return;
    }

    links.forEach((link) => {
      if (!link.isBottom) {
        topLinks.current.push(link);
      } else {
        bottomLinks.current.push(link);
      }
    });
  }, [bottomLinks, links, topLinks]);

  return {
    topLinks: topLinks.current,
    bottomLinks: bottomLinks.current,
  };
};
