export enum ButtonType {
  Delete,
  Close,
  GoBack,
  Update,
  Add,
  History,
  Share,
  Filter,
  Archive,
  Edit,
  Check,
  CheckDouble,
  Cancel,
  FileExport,
}

export enum ButtonSize {
  Small = 'small',
  Big = 'big',
}

export enum HoverColor {
  BLUE = 'blue',
  RED = 'red',
}
