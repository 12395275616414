import { WithdrawalStatus as WithdrawalStatusEnum } from 'generatedGraphql';
import { getWithdrawalStatusIconConfig } from 'src/helpers';
import { withdrawalStatusMap } from 'src/const';
import { getDropdownListByMap } from 'utils/getDropdownListByMap';
import { WithdrawalStatus } from '../../WithdrawalStatus';

export const getWithdrawalStatusesList = () =>
  getDropdownListByMap(withdrawalStatusMap).map(({ label, id }) => {
    const iconConfig = getWithdrawalStatusIconConfig(
      id as WithdrawalStatusEnum,
    );

    return {
      id,
      label: <WithdrawalStatus status={label} iconConfig={iconConfig} />,
      textForSearch: label,
    };
  });
