import { Option } from 'ui/Dropdown';
import { AdminRole } from 'commonTypes';

export const adminRoleMap: { [key: string]: string } = {
  [AdminRole.RoleAnalyst]: 'Аналитик',
  [AdminRole.RoleCashier]: 'Фин. Отдел',
  [AdminRole.RoleCashierLead]: 'Фин. Отдел Админ',
  [AdminRole.RoleCashierPlus]: 'Фин. Отдел плюс',
  [AdminRole.RoleContentManager]: 'Контент-менеджер',
  [AdminRole.RolePartnerSecurity]: 'Безопасность партнёров',
  [AdminRole.RolePartnerSupport]: 'Поддержка партнёров',
  [AdminRole.RolePartnerSupportLead]: 'Поддержка партнёров Админ',
  [AdminRole.RolePartnerSupportPlus]: 'Поддержка партнёров плюс',
  [AdminRole.RolePaymentSystemManager]: 'Менеджер платежных систем',
  [AdminRole.RoleSecurity]: 'Безопасность',
  [AdminRole.RoleSecurityLead]: 'Безопасность Админ',
  [AdminRole.RoleSuperAdmin]: 'Супер Админ',
  [AdminRole.RoleSupport]: 'Саппорт',
  [AdminRole.RoleSupportLead]: 'Саппорт Админ',
  [AdminRole.RoleSupportLeadTournament]: 'Саппорт турниров Админ',
  [AdminRole.RoleSupportPlus]: 'Саппорт плюс',
  [AdminRole.RoleProviderManager]: 'Провайдер-менеджер',
};

export const rolesOptions: Array<Option<AdminRole>> = Object.values(
  AdminRole,
).map((role: AdminRole) => ({
  label: adminRoleMap[role],
  id: role,
}));

export const ADMINS_SEARCH_PLACEHOLDER = 'Поиск администратора';
export const ADMINS_SEARCH_INPUT_NAME = 'adminsSearch';
