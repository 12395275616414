import { UserPrivilege } from 'generatedUserPrivilege';
import { PaymentStatus } from 'generatedGraphql';
import { useAuth } from 'commonComponents/Auth';

interface UseCheckIsUpdatePaymentButtonsVisibleResult {
  isAcceptButtonVisible: boolean;
  isDeclineButtonVisible: boolean;
}

export const useCheckIsUpdatePaymentButtonsVisible = (
  paymentStatus: PaymentStatus,
): UseCheckIsUpdatePaymentButtonsVisibleResult => {
  const auth = useAuth();

  const isAllowedToUpdatePayment = (privileges: Array<UserPrivilege>) =>
    auth.privileges.areEveryGranted({
      privileges,
    });

  const isAcceptButtonVisible =
    isAllowedToUpdatePayment([UserPrivilege.AcceptPayment]) &&
    paymentStatus &&
    [PaymentStatus.Awaiting, PaymentStatus.InProgress].includes(paymentStatus);

  const isDeclineButtonVisible =
    isAllowedToUpdatePayment([UserPrivilege.DeclinePayment]) &&
    paymentStatus &&
    [
      PaymentStatus.Awaiting,
      PaymentStatus.InProgress,
      PaymentStatus.Pending,
    ].includes(paymentStatus);

  return { isAcceptButtonVisible, isDeclineButtonVisible };
};
