import { OrderDirection } from 'generatedGraphql';
import { useSearchParams } from 'react-router-dom';
import { defaultDirection } from '../../../const';
import { StatisticOrdering } from '../../../../../const';

interface HandleChangeOrderingParams<T> {
  isSelected: boolean;
  orderBy: T;
  defaultOrderBy: T;
  selectedDirection: OrderDirection;
}

export const useChangeOrdering = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setOrdering = <T extends string>(
    orderBy: T,
    direction: OrderDirection,
  ) => {
    const newParams = new URLSearchParams(searchParams);

    newParams.set(StatisticOrdering.OrderBy, orderBy);
    newParams.set(StatisticOrdering.Direction, direction);

    setSearchParams(newParams);
  };

  const handleChangeOrdering = <T extends string>({
    isSelected,
    orderBy,
    defaultOrderBy,
    selectedDirection,
  }: HandleChangeOrderingParams<T>) => {
    if (isSelected && selectedDirection === OrderDirection.Desc) {
      setOrdering<T>(orderBy, OrderDirection.Asc);

      return;
    }

    if (!isSelected) {
      setOrdering<T>(orderBy, defaultDirection);

      return;
    }

    setOrdering<T>(defaultOrderBy, defaultDirection);
  };

  return handleChangeOrdering;
};
