import React, { FC, useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import QRCode from 'qrcode';
import Input from 'ui/Input';
import { Form, Formik } from 'formik';
import Button, { ButtonTheme } from 'ui/Button';
import Loader from 'ui/Loader';
import { LoaderTheme } from 'ui/Loader/Loader';
import Error from 'ui/Error';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import { Tooltip } from 'ui/Tooltip';
import { privateDefaultPath } from '../../../../router/routes.const';
import styles from '../Auth.module.scss';
import { useAuth } from '../../Auth';
import { TwoFactorValues, validateTwoFactor } from '../../validation';
import { AuthErrorMessages, AuthStatuses } from '../../const';
import isTwoFactorRequired from '../../helpers';
import AutoSubmitAuthCode from './AutoSubmitAuthCode';

const TwoFactor: FC = () => {
  const location = useLocation();
  const { from } = (location.state as { from: { pathname: string } }) || {
    from: { pathname: privateDefaultPath },
  };

  const auth = useAuth();
  const [error, setError] = useState('');
  const [isQrLoading, setIsQrLoading] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [secret, setSecret] = useState('');

  const { authStatus } = auth.user;

  useEffect(() => {
    if (authStatus === AuthStatuses.TwoFactorGenerationRequired) {
      setIsQrLoading(true);

      // eslint-disable-next-line promise/catch-or-return
      auth
        .createTwoFactor()
        .then((req) => {
          setSecret(req.secret);

          return QRCode.toDataURL(req.qr_content, { margin: 0 });
        })
        .then((qrCodeDataUrl) => setQrCode(qrCodeDataUrl))
        .finally(() => setIsQrLoading(false));
    }
  }, [auth, authStatus]);

  const twoFactorInitialValues = { authCode: '', secret: secret || '' };

  const onTwoFactor = useCallback(
    async (values: TwoFactorValues) => {
      setError('');
      try {
        await auth.checkTwoFactorCode(values.authCode);
      } catch (e) {
        if (e instanceof Object) {
          if ((e as { status: number }).status === 401) {
            setError(AuthErrorMessages.TwoFactor401);
          } else {
            setError((e as { detail: string }).detail);
          }
        }
      }
    },
    [auth],
  );

  const onSecretClick = useCallback(() => {
    const copyText = document.querySelector('#secret') as HTMLTextAreaElement;

    copyText.select();
    document.execCommand('copy');
  }, []);

  if (!isTwoFactorRequired(authStatus)) {
    return <Navigate to={from.pathname} />;
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Двухфакторная аутентификация</h1>
      <div className={styles.container}>
        <Formik
          initialValues={twoFactorInitialValues}
          validate={validateTwoFactor}
          onSubmit={onTwoFactor}
          enableReinitialize
          validateOnChange={false}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form>
              {error && <Error error={error} />}

              <FormRowsWrapper>
                {authStatus === AuthStatuses.TwoFactorGenerationRequired && (
                  <>
                    <span>
                      Установите приложение для&nbsp;получения кода
                      аутентификации, например, Google Authenticator.
                      <br />
                      <br />
                      Просканируйте QR-код или введите секретный ключ, указанный
                      ниже, в&nbsp;приложении. Затем, чтобы подтвердить верную
                      настройку приложения, введите 6-значный код аутентификации
                      из&nbsp;приложения в&nbsp;поле ниже.
                    </span>
                    <div className={styles.qrCodeWrapper}>
                      {isQrLoading ? (
                        <Loader
                          theme={LoaderTheme.Dark}
                          className={styles.loader}
                        />
                      ) : (
                        qrCode && (
                          <img
                            className={styles.qrCode}
                            alt="qrCode"
                            src={qrCode}
                          />
                        )
                      )}
                    </div>
                    <FormRow isWide>
                      <Input.Textarea
                        id="secret"
                        name="secret"
                        label="Секретный ключ"
                        inputClassName={styles.secret}
                        readOnly
                        onClick={onSecretClick}
                      />
                    </FormRow>
                  </>
                )}

                <FormRow isWide>
                  <Input
                    name="authCode"
                    label="Код аутентификации"
                    autoFocus
                    autoComplete="off"
                  />
                </FormRow>
              </FormRowsWrapper>

              <Tooltip
                tooltipContent={
                  !isValid || !dirty
                    ? 'Необходимо ввести код аутентификации'
                    : undefined
                }
                placement="bottom"
                shouldSetWidthFitContent={false}
              >
                <Button
                  theme={ButtonTheme.Primary}
                  className={styles.button}
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  Подтвердить
                </Button>
              </Tooltip>
              <AutoSubmitAuthCode />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default TwoFactor;
