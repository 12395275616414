import { FormikErrors } from 'formik';
import { UpdateClientIdentitiesPayload } from 'commonComponents/UpdateClientIdentities/types';
import { checkEmailValidity } from 'utils/validators';

export const validate = (
  values: UpdateClientIdentitiesPayload,
): FormikErrors<UpdateClientIdentitiesPayload> => {
  const errors: FormikErrors<UpdateClientIdentitiesPayload> = {};

  if (!values.email) {
    errors.email = 'Это поле обязательное';

    return errors;
  }

  if (!checkEmailValidity(values.email)) {
    errors.email = 'Неверный формат';
  }

  return errors;
};
