import { FC, useContext } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { SearchContextProvider } from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'utils/storage';
import SearchInput from 'ui/SearchInput';
import styles from './CompanyApplications.module.scss';
import { CompanyApplicationsTable } from './components/CompanyApplicationsTable';
import { useCompanyApplications } from './hooks/useCompanyApplications';
import {
  COMPANY_APPLICATIONS_SEARCH_INPUT_NAME,
  COMPANY_APPLICATIONS_SEARCH_PLACEHOLDER,
} from './const';

const Component: FC = withBlockProvider(() => {
  const { state } = useContext(BlockContext);

  const {
    data,
    fetchMoreCallback,
    loadCompanyApplications,
    loading,
    refetch,
    companyApplications,
  } = useCompanyApplications();

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.companyApplications?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  return (
    <>
      <SearchInput
        name={COMPANY_APPLICATIONS_SEARCH_INPUT_NAME}
        placeholder={COMPANY_APPLICATIONS_SEARCH_PLACEHOLDER}
        onSubmit={loadCompanyApplications}
      />
      <Block
        title="Заявки компаний"
        id="companyApplications"
        headerContent={
          <div className={styles.headerContent}>
            <ActionButton
              actionType={ButtonType.Update}
              hidden={!state.isOpened}
              disabled={state.isLoading}
              onClick={() => refetch()}
            />
          </div>
        }
        shrinkLoaderWrapper={false}
        emptyText="Заявок компаний нет"
        isEmpty={isListEmpty(companyApplications)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(companyApplications) && (
          <CompanyApplicationsTable companyApplications={companyApplications} />
        )}
      </Block>
    </>
  );
});

export const CompanyApplications = () => (
  <SearchContextProvider pageType={PageTypeStorage.CompanyApplicationsPage}>
    <Component />
  </SearchContextProvider>
);
