import { FC } from 'react';
import { DateFormat, formatDate } from 'utils/formatDate';
import styles from './DocumentThumbItem.module.scss';

interface Props {
  documentIndex: number;
  thumbnailImageUrl: string;
  toggleLightbox: (idx: number) => void;
  createdAt: string;
  note?: string | null;
}

const DocumentThumbItem: FC<Props> = ({
  documentIndex,
  thumbnailImageUrl,
  toggleLightbox,
  createdAt,
  note,
}) => {
  const onThumbClick = () => {
    if (!window.getSelection()?.toString()) {
      toggleLightbox(documentIndex);
    }
  };

  return (
    <div className={styles.container} onClick={onThumbClick}>
      <div className={styles.containerImg}>
        <div className={styles.responsiveImgContainer}>
          <img
            src={thumbnailImageUrl}
            className={styles.img}
            data-testid="documentImg"
            alt=""
          />
        </div>
        <div className={styles.content}>
          <p className={styles.date}>
            {formatDate(createdAt, DateFormat.FullDate)}
          </p>
          <p className={styles.note} data-testid="note">
            {note && note}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DocumentThumbItem;
