import { useParams } from 'react-router';
import { PartnerPageContainer } from '../../components/PartnerPageContainer/PartnerPageContainer';
import { PartnerRewardTariffsBlock } from '../../components/PartnerRewardTariffsBlock';

export const PartnerRewardTariffs = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PartnerPageContainer partnerId={id}>
      <PartnerRewardTariffsBlock
        id={id}
        isFullScreenTable
        shouldReturnToPrevPage
      />
    </PartnerPageContainer>
  );
};
