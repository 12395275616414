import { useContext, useEffect } from 'react';
import { ModalActions, ModalContext } from 'ui/Modal/ModalStore';
import { useApolloClient } from '@apollo/client';
import { PlayerData } from 'queries/generated/PlayerData';

export const useAddPlayerNameToSidebarSubTitle = (playerId: string) => {
  const client = useApolloClient();

  const { state: modalState, dispatch: modalDispatch } =
    useContext(ModalContext);

  const player = client.readFragment({
    id: `Player:${playerId}`,
    fragment: PlayerData,
  });

  useEffect(() => {
    modalDispatch({
      type: ModalActions.UpdateModalProps,
      payload: {
        title: modalState.title,
        subTitle: `${player?.name} #${playerId}`,
      },
    });
  }, [modalDispatch, modalState.title, player?.name, playerId]);
};
