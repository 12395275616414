import { FormikErrors } from 'formik';
import { AffiliateType } from 'generatedGraphql';
import { CreateConfirmationSettingValues } from './types';

interface CreatePaymentErrors {
  settingType: string;
  partnerId: string;
  companyId: string;
  affiliateType: string;
  comment: string;
}

export const validate = (
  values: CreateConfirmationSettingValues,
): FormikErrors<CreatePaymentErrors> => {
  const errors: FormikErrors<CreatePaymentErrors> = {};

  if (!values.settingType) {
    errors.settingType = 'Поле не может быть пустым';
  }

  if (!values.affiliateType) {
    errors.affiliateType = 'Поле не может быть пустым';
  }

  if (values.affiliateType === AffiliateType.Company && !values.companyId) {
    errors.companyId = 'Поле не может быть пустым';
  }

  if (values.affiliateType === AffiliateType.Partner && !values.partnerId) {
    errors.partnerId = 'Поле не может быть пустым';
  }

  if (!values.comment) {
    errors.comment = 'Поле не может быть пустым';
  }

  return errors;
};
