import { FormikErrors } from 'formik';
import { Currency } from 'commonTypes';

export interface CreateBonusValues {
  player: string;
  amount: number;
  currency: Currency;
  wager?: number;
  cashout?: number;
  expiredAt?: Date;
  minAccountBalanceAmount?: number;
  approved?: boolean;
}

export interface CreateBonusErrors {
  amount?: string;
  currency?: string;
  wager?: string;
  cashout?: string;
  expiredAt?: string;
}

export const validate = (
  values: CreateBonusValues,
): FormikErrors<CreateBonusErrors> => {
  const errors: FormikErrors<CreateBonusErrors> = {};

  if (!values.amount) {
    errors.amount = 'Необходимо ввести сумму';
  }

  if (!values.currency) {
    errors.currency = 'Необходимо выбрать валюту';
  }

  if (!values.wager) {
    errors.wager = 'Необходимо ввести количество';
  }

  return errors;
};
