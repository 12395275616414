import { Option } from '../types';
import { getCheckBoxOptions } from './getCheckBoxOptions';

interface GetDropdownOptionsParams {
  options: Array<Option> | null;
  isCheckbox?: boolean;
  checkedOptionsIds: Array<string>;
}

export const getDropdownOptions = ({
  options,
  isCheckbox,
  checkedOptionsIds,
}: GetDropdownOptionsParams): Array<Option> | null =>
  isCheckbox ? getCheckBoxOptions({ options, checkedOptionsIds }) : options;
