import { UserPrivilege } from 'generatedUserPrivilege';
import { useCallback } from 'react';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetExportCompanyClickIdReportLazyQuery } from '../queries/generated/GetExportCompanyClickIdReport';
import { useExportClickIdReportHandlers } from '../../../helpers/useExportClickIdReportHandlers';

interface Params {
  companyId: string;
}

export const useGetExportCompanyClickIdReport = ({ companyId }: Params) => {
  const period = useGetPeriodOfStatistic();
  const {
    handleCompleteExportClickIdReport,
    handleErrorExportClickIdReport,
    downloadClickIdReportFileLoading,
  } = useExportClickIdReportHandlers();

  const [exportReport, { loading: getExportClickIdReportLinkLoading }] =
    useGetExportCompanyClickIdReportLazyQuery({
      onCompleted: (data) => {
        handleCompleteExportClickIdReport(data.company.exportClickIdReport);
      },
      onError: handleErrorExportClickIdReport,
    });

  const getClickIdReport = useCallback(() => {
    exportReport({
      variables: {
        id: companyId,
        period,
      },
    });
  }, [exportReport, companyId, period]);

  return {
    getReport: getClickIdReport,
    loading:
      getExportClickIdReportLinkLoading || downloadClickIdReportFileLoading,
    privilege: UserPrivilege.ExportCompanyClickIdReport,
  };
};
