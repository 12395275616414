import { useSearchParams } from 'react-router-dom';
import { StatisticOrdering } from 'src/const';
import { useEffect, useMemo } from 'react';
import { OrderDirection } from 'generatedGraphql';
import { defaultDirection } from '../const';

interface Result<T> {
  orderBy: T;
  direction: OrderDirection;
}

export const useGetOrdering = <T>(orderByDefault: T): Result<T> => {
  const [searchParams, setSearchParams] = useSearchParams();
  const newParams = useMemo(
    () => new URLSearchParams(searchParams),
    [searchParams],
  );

  const orderBy =
    (searchParams.get(StatisticOrdering.OrderBy) as T) || orderByDefault;
  const direction =
    (searchParams.get(StatisticOrdering.Direction) as OrderDirection) ||
    defaultDirection;

  useEffect(() => {
    if (!searchParams.has(StatisticOrdering.OrderBy)) {
      newParams.set(StatisticOrdering.OrderBy, orderBy as string);
      setSearchParams(newParams);
    }

    if (!searchParams.has(StatisticOrdering.Direction)) {
      newParams.set(StatisticOrdering.Direction, direction as string);
      setSearchParams(newParams);
    }
  }, [orderBy, direction, newParams, setSearchParams, searchParams]);

  return {
    orderBy,
    direction,
  };
};
