import { UserPrivilege } from 'generatedUserPrivilege';
import { useAuth } from 'commonComponents/Auth';

interface PartnerPrivileges {
  canUpdateNote: boolean;
  canViewPartnerNote: boolean;
  canResetPartnerName: boolean;
}

export const usePartnerPrivileges = (): PartnerPrivileges => {
  const auth = useAuth();

  const canResetPartnerName = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.ResetPartnerName],
  });

  const canUpdateNote = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.UpdatePartnerNote],
  });

  const canViewPartnerNote = auth.privileges.areEveryGranted({
    privileges: [UserPrivilege.ViewPartnerNote],
  });

  return {
    canUpdateNote,
    canViewPartnerNote,
    canResetPartnerName,
  };
};
