import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { RewardTariffType } from 'generatedGraphql';
import { useGetRewardsLazyQuery } from '../queries/generated/GetRewards';

const cellHeight = Heights.defaultCellHeight;

export const useGetRewards = (tariffType: RewardTariffType) => {
  const [loadRewards, { data, loading, error, fetchMore, refetch }] =
    useGetRewardsLazyQuery({
      variables: {
        first: getCountByWindowHeight(cellHeight, 0),
        tariffType,
      },
    });

  const rewardsList = useMemo(
    () => data?.rewards?.edges.map(({ node }) => node),
    [data?.rewards?.edges],
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: data?.rewards?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false },
      ),
    [data?.rewards?.pageInfo.endCursor, fetchMore],
  );

  return {
    rewardsList,
    loadRewards,
    loading,
    error,
    data,
    refetch,
    fetchMoreCallback,
  };
};
