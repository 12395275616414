import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { RevShareRewardTariffData } from '../../../../queries/generated/RevShareRewardTariffData';
import { useRevShareRewardTariffsModal } from '../../hooks/useRevShareRewardTariffsModal';
import { useGetRevShareRewardTariffsColumns } from './hooks/useGetRevShareRewardTariffsColumns';

interface Props {
  cpaRewardTariffs?: Array<RevShareRewardTariffData>;
}

export const RevShareRewardTariffsTable: FC<Props> = ({ cpaRewardTariffs }) => {
  const openModal = useRevShareRewardTariffsModal();
  const { columns } = useGetRevShareRewardTariffsColumns();

  const handleRowClick = (
    cpaRewardTariff: RevShareRewardTariffData,
    initiatorId: string,
  ) => {
    openModal({
      cpaRewardTariff,
      initiatorId,
    });
  };

  return (
    <BlockTable
      onClickOnRow={handleRowClick}
      columns={columns}
      data={cpaRewardTariffs}
      isFullScreenTable
    />
  );
};
