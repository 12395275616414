import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { Route as RouteEnum } from 'src/router/routes.const';
import { generatePath } from 'react-router';
import { CompanyMediaCampaignsTable } from './components/CompanyMediaCampaignsTable';
import { useGetCompanyMediaCampaignsColumns } from './components/CompanyMediaCampaignsTable/hooks/useGetCompanyMediaCampaignsColumns';

interface Props {
  id: string;
}

export const CompanyMediaCampaignsBlock: FC<Props> = withBlockProvider(
  ({ id }) => {
    const { columns } = useGetCompanyMediaCampaignsColumns();

    return (
      <CompanyMediaCampaignsTable
        companyId={id}
        columns={columns}
        routeToCustomPage={generatePath(RouteEnum.COMPANY_MEDIA_CAMPAIGNS, {
          id,
        })}
        isFullScreenTable={false}
      />
    );
  },
);
