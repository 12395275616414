import { OwnerTypeName } from 'commonTypes';
import { Route } from 'src/router/routes.const';
import { GetPostbackDisabledNotificationData } from '../../../queries/generated/GetPostbackDisabledNotificationData';

export const getRouteByTypeName = (
  owner: GetPostbackDisabledNotificationData['referralLinkMediaItem']['owner'],
) => {
  switch (owner.__typename) {
    case OwnerTypeName.Company:
      return `${Route.COMPANIES}/${owner.id}`;
    case OwnerTypeName.Partner:
      return `${Route.PARTNERS}/${owner.id}`;
    case OwnerTypeName.CompanyAffiliate:
      return `${Route.COMPANIES}/${owner.company.id}`;

    default:
      return Route.REFERRAL_LINK_MEDIA_ITEMS;
  }
};
