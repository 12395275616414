import { FC, SyntheticEvent, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { CardIconColor } from 'ui/Card';
import { CardIcon } from 'ui/Card/CardIcon';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIconAndColorByType } from './getIcon';
import { ButtonSize, ButtonType } from './const';
import styles from './ActionButton.module.scss';

interface Props {
  onClick?: (e: SyntheticEvent) => void;
  dataId?: string;
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
  actionType?: ButtonType;
  size?: ButtonSize;
  dataTestId?: string;
  type?: 'button' | 'submit' | 'reset';
  noHoverBg?: boolean;
  loading?: boolean;
}

export const ActionButton: FC<PropsWithChildren<Props>> = ({
  children,
  onClick,
  dataId,
  className,
  disabled,
  hidden,
  actionType,
  size = ButtonSize.Small,
  dataTestId,
  type = 'button',
  noHoverBg = false,
  loading,
}) => {
  const click = (e: SyntheticEvent) => {
    if (onClick) {
      e.stopPropagation();
      if (!disabled) {
        onClick(e);
      }
    }
  };

  const { icon, hoverColor } = getIconAndColorByType(actionType);

  const iconColor = CardIconColor.GREY;

  return hidden ? null : (
    <button
      data-testid={dataTestId}
      data-id={dataId}
      onClick={click}
      disabled={loading || disabled}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classnames(
        styles.button,
        className,
        actionType && styles[actionType],
        ButtonSize && styles[size],
        hoverColor && styles[hoverColor],
        noHoverBg && styles.withoutBackground,
        loading && styles.loading,
      )}
      onMouseDown={(e: SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      {loading ? (
        <FontAwesomeIcon
          data-testid="action-button-spinner"
          className={styles.loader}
          icon={faSpinnerThird}
          spin
        />
      ) : (
        <CardIcon config={{ icon, iconColor }} />
      )}
      {children}
    </button>
  );
};
