import { Option } from '../types';

interface GetIsSearchWarningRenderParams {
  searchMinLength?: number;
  onSearchInList?: (value: string) => void;
  loading?: boolean;
  fieldInputValue: string;
  options: Array<Option> | null;
}

export const getIsSearchWarningRender = ({
  searchMinLength,
  onSearchInList,
  loading,
  fieldInputValue,
  options,
}: GetIsSearchWarningRenderParams) =>
  searchMinLength &&
  (fieldInputValue?.length < searchMinLength ||
    !fieldInputValue ||
    options === null) &&
  onSearchInList &&
  !loading;
